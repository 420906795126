import { ComputationType, DimensionDTO, MetricDTO, ReportingDataSetDTO } from 'domain/types'
import { BarSeriesOption } from 'echarts'
import WidgetDataUtil from 'domain/widget/WidgetDataUtil'
import ArrayUtil from 'shared/util/ArrayUtil'
import DimensionUtil from 'shared/util/DimensionUtil'
import { NOT_AVAILABLE } from 'Constants'

/**
 * Generates series data for the stacked bar widget.
 * Combines all elements after [topNElements] to "other" if [showOthers] is true
 *
 * @param dataSet
 * @param firstDimensionDTO
 * @param secondDimensionDTO
 * @param metricDTO
 * @param topNElements
 * @param showOthers
 * @param limitFirstDimension
 * @param customColors
 * @param othersDataSet
 */
const getTopNBarWidgetSeriesOptions = (
    dataSet: ReportingDataSetDTO,
    firstDimensionDTO: DimensionDTO,
    secondDimensionDTO: DimensionDTO,
    metricDTO: MetricDTO,
    topNElements: number,
    showOthers: boolean = true,
    limitFirstDimension: boolean = true,
    customColors: { [key: string]: string } = {},
    othersDataSet?: ReportingDataSetDTO,
): BarSeriesOption[] => {
    const firstDimensionColumnName = DimensionUtil.getNameColumn(firstDimensionDTO)
    const secondDimensionColumnName = DimensionUtil.getNameColumn(secondDimensionDTO)
    const firstDimensionValues = limitFirstDimension
        ? WidgetDataUtil.getColumnValuesOrderedByMetricDesc(firstDimensionColumnName, dataSet.rows, metricDTO.identifier)
        : WidgetDataUtil.getUniqueValues(firstDimensionColumnName, dataSet.rows)
    const secondDimensionValues =
        WidgetDataUtil.getColumnValuesOrderedByMetricDesc(secondDimensionColumnName, dataSet.rows, metricDTO.identifier)

    let result =
        ArrayUtil.getFirstNElements(secondDimensionValues, topNElements).map(secondDimensionValue => {
            return {
                name: WidgetDataUtil.formatItemName(secondDimensionValue),
                data: ArrayUtil.getFirstNElements(firstDimensionValues, limitFirstDimension ? topNElements : Number.MAX_SAFE_INTEGER).map(dimensionValue => {
                    const row =
                        WidgetDataUtil.findRow(
                            dataSet.rows,
                            firstDimensionColumnName,
                            dimensionValue,
                            secondDimensionColumnName,
                            secondDimensionValue,
                        )

                    // metric value
                    return row ? row[metricDTO.identifier].value : 0
                }),
            } as BarSeriesOption
        })

    // Only show the "Others" entry for sum computation metrics because those are currently the only ones where we can correctly calculate the total value
    if (showOthers && metricDTO.computationType === ComputationType.CT_SUM) {
        const othersSecondDimensionDisplayName = WidgetDataUtil.getOtherTitle(secondDimensionDTO)
        const othersSeries =
            ArrayUtil.getFirstNElements(firstDimensionValues, topNElements)
                .reduce((acc, firstDimensionValue) => {
                        const allRow = othersDataSet.rows.find(row => WidgetDataUtil.getNameOrValueOrNA(row, firstDimensionColumnName) == firstDimensionValue)
                        const sumAll = allRow[metricDTO.identifier] ? allRow[metricDTO.identifier].value : 0

                        const sumTopN = ArrayUtil.getFirstNElements(secondDimensionValues, topNElements).map(dimensionValue => {
                            const row =
                                WidgetDataUtil.findRow(
                                    dataSet.rows,
                                    firstDimensionColumnName,
                                    firstDimensionValue,
                                    secondDimensionColumnName,
                                    dimensionValue,
                                )

                            // metric value
                            return row ? row[metricDTO.identifier].value : 0
                        }).reduce((sumAcc, value) => sumAcc + value, 0)

                        const sumOthers = sumAll - sumTopN
                        acc.data.push(sumOthers)

                        return acc
                    },
                    { name: othersSecondDimensionDisplayName, data: [] },
                )

        if (othersSeries.data.some(value => value > 0)) {
            result = result.concat([othersSeries])
        }

        if (firstDimensionValues.length > topNElements) {
            const firstDimensionValuesTail = ArrayUtil.tail(firstDimensionValues, topNElements)
            result.forEach(series => {
                const seriesName = series.name
                let sumOthers = 0
                if (seriesName == othersSecondDimensionDisplayName /*&& secondDimensionValues.length > topNElements*/) {
                    sumOthers = firstDimensionValuesTail.reduce((xAcc, firstDimensionValue) => {
                        const allRow = othersDataSet.rows.find(row => WidgetDataUtil.getNameOrValueOrNA(row, firstDimensionColumnName) == firstDimensionValue)
                        const sumAll = allRow[metricDTO.identifier] ? allRow[metricDTO.identifier].value : 0

                        const sumTopN = ArrayUtil.getFirstNElements(secondDimensionValues, topNElements).map(dimensionValue => {
                            const row =
                                WidgetDataUtil.findRow(
                                    dataSet.rows,
                                    firstDimensionColumnName,
                                    firstDimensionValue,
                                    secondDimensionColumnName,
                                    dimensionValue,
                                )

                            // metric value
                            return row ? row[metricDTO.identifier].value : 0
                        }).reduce((sumAcc, value) => sumAcc + value, 0)

                        const sumOthers = sumAll - sumTopN
                        return xAcc + sumOthers
                    }, 0)
                } else {
                    sumOthers = firstDimensionValuesTail.map(dimensionValue => {
                        const row =
                            WidgetDataUtil.findRow(
                                dataSet.rows,
                                firstDimensionColumnName,
                                dimensionValue,
                                secondDimensionColumnName,
                                seriesName,
                            )

                        // metric value
                        return row ? row[metricDTO.identifier].value : 0
                    }).reduce((sumAcc, value) => sumAcc + value, 0)
                }

                series.data = series.data.concat(sumOthers)
            })
        }
    }

    // apply custom colors
    Object.keys(customColors).forEach(name => {
        const color = customColors[name]
        result.forEach(serie => {
            if (serie.name == name) {
                // @ts-ignore
                serie.data = serie.data.map(dataValue => {
                    return {
                        value: dataValue,
                        itemStyle: { color: color },
                    }
                })
            }
        })
    })

    return result
}

const TopNBarWidgetDataUtil = {
    getTopNBarWidgetSeriesOptions: getTopNBarWidgetSeriesOptions,
}

export default TopNBarWidgetDataUtil
