import React, { useEffect, useState } from 'react'
import { HtmlContentElementProperties } from 'domain/types'
import ElementHeader from 'shared/component/layout/ElementHeader'
import { InfoCircleOutlined } from '@ant-design/icons'
import EmbeddingUtil from 'shared/util/EmbeddingUtil'
import { MESSAGE } from 'domain/messaging/MessageListener'
import { v1 as uuid } from 'uuid'

const HtmlContentElement: React.FC<HtmlContentElementProperties> = ({ layoutElementConfig }: HtmlContentElementProperties): JSX.Element => {

    /**
     * Unique id for the html element
     */
    const [id] = useState('html-element-' + uuid())

    useEffect(() => {
        processAction('addEventListener')

        return () => {
            processAction('removeEventListener')
        }
    })

    /**
     * Add or remove click listener on all link elements (with classname 'help-content') within this html elements
     *
     * @param action
     */
    const processAction = (action: 'addEventListener' | 'removeEventListener') => {
        const elements = document.querySelectorAll(`.${id} a.help-content`)

        if (elements && elements.length > 0) {
            elements.forEach(element => {
                element[action]('click', clickOnLink)
            })
        }
    }

    /**
     * Click handler sends the easyxdm open-iframe-message to classic ui
     * if you click on some link with url starting with '/content/'.
     *
     * @param event
     */
    const clickOnLink = (event) => {
        const href = event.target.href
        const path = href.split(window.location.host)[1]

        // open help window only for links starting with /content/ url
        if (path.startsWith('/content/')) {
            event.preventDefault()
            EmbeddingUtil.sendMessage(MESSAGE.OPEN_IFRAME_WINDOW, { newUiPath: path })

            return false
        } else {
            return true
        }
    }


    return <>
        <ElementHeader header={layoutElementConfig.header}/>
        {layoutElementConfig.content && <div className={`content ${id}`}>
            {layoutElementConfig.isInfoText && <InfoCircleOutlined/>}
            <span dangerouslySetInnerHTML={{ __html: layoutElementConfig.content }}/>
        </div>}
    </>
}

export default HtmlContentElement
