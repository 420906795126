import { MfaFactor, MfaFactorConfigDTO } from 'domain/types/backend/mfa.types'
import { Button, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'

type Props = {
    factorConfigs: MfaFactorConfigDTO[]
    select: (MfaFactor) => void
}

const MfaFactorSelectionForm: (props: Props) => JSX.Element = (props: Props): JSX.Element => {

    const [selectedFactor, setSelectedFactor] = useState<MfaFactor>(undefined)

    const factorDisplayName = (factor: MfaFactor) => ({
        [MfaFactor.Authenticator]: 'Authenticator App (recommended)',
        [MfaFactor.Email]: 'E-Mail',
    }[factor])

    const onChange = (event) => setSelectedFactor(event?.target?.value?.factor)

    useEffect(() => {
        if (props?.factorConfigs?.length === 1) setSelectedFactor(props?.factorConfigs[0].factor)
    }, [props.factorConfigs])

    return <>
        <div className={'mfa-form-segment'}>
            <h3><strong>Set Up Two-Factor Authentication</strong></h3>
            <p>To keep your account secure, your organisation requires you to set up a second authentication factor.</p>

            {props?.factorConfigs?.length > 1 &&
                <Radio.Group buttonStyle={'solid'} onChange={onChange}>
                    <Space direction="vertical" style={{ textAlign: 'left' }}>
                        {props?.factorConfigs?.map((config, index) =>
                            <Radio value={config} key={'factor_' + index}>{factorDisplayName(config.factor)}</Radio>,
                        )}
                    </Space>
                </Radio.Group>
            }
        </div>

        {selectedFactor &&
            <div className={'mfa-form-segment'}>

                {(selectedFactor == MfaFactor.Authenticator) && <>
                    <h3>Step 1: Install Authenticator App</h3>
                    <p>Popular choices include <a href="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345" target="_blank">Google Authenticator</a> (iOS, Android) and <a href="https://authy.com/download/"
                                                                                                                                                                                                   target="_blank">Authy</a> (iOS, Android,
                        desktop).</p>
                </>}

                {(selectedFactor == MfaFactor.Email) &&
                    <p>The e-mail address associated with your account will be used for authentication.</p>
                }

                <Button onClick={() => props?.select(selectedFactor)} icon={<ArrowRightOutlined/>} style={{ width: '180px' }} type="primary" size={'large'}>
                    Continue
                </Button>
            </div>
        }
    </>
}

export default MfaFactorSelectionForm
