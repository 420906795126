export type FormItemValidatorDTO = {
    type: string
    value: any
}

const requiredValidator = () => ({
    required: true,
    message: 'This field is required.',
})

const minLengthValidator = (requirement: number) => ({
    validator(rule, value) {
        if (typeof value === 'string' && value.length < requirement) {
            return Promise.reject(`Minimum length for this value is ${requirement} characters.`)
        } else {
            return Promise.resolve()
        }
    },
})

const maxLengthValidator = (requirement: number) => ({
    validator(rule, value) {
        if (typeof value === 'string' && value.length > requirement) {
            return Promise.reject(`Maximum length for this value is ${requirement} characters.`)
        } else {
            return Promise.resolve()
        }
    },
})

export const formValidator = (validators: FormItemValidatorDTO[]): any[] => {
    if (!validators) return []

    return validators.map(validation => {
        switch (validation.type) {
            case 'REQUIRED':
                return requiredValidator()
            case 'MIN_LENGTH':
                return minLengthValidator(validation.value)
            case 'MAX_LENGTH':
                return maxLengthValidator(validation.value)
            default: return null
        }
    }).filter(validation => !!validation)
}
