import React, { useRef } from 'react'
import { ColSize } from 'antd/lib/grid'
import { ActionIdentifier, AntTableColumn, ColumnConfigDTO, PageableDTO } from 'domain/types'
import { useDataGridContext } from 'domain/widget/generic/DataGridContext'
import { v1 as uuid } from 'uuid'
import { Empty } from 'antd'
import { RowActions } from 'domain/actions/RowActions'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { columnRenderer } from 'shared/component/renderers/renderers'
import GridUtil from 'domain/widget/generic/GridUtil'
import DimensionService from 'domain/dimension/service/DimensionService'
import { DataGridComponent } from 'domain/datagrid/component/DataGridComponent'
import { FormattedColumnHeader } from 'domain/datagrid/component/FormattedColumnHeader'

export const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
        <Empty description={'No results found'}/>
    </div>
)

export const SELECTED_FIELD = '__selected'
export const SELECTED_ID_FIELD = DimensionService.getDimensionValueColumn(SELECTED_FIELD)
export const ACTIONS_FIELD = '__actions__'
export const ACTIONS_ID_FIELD = DimensionService.getDimensionValueColumn(ACTIONS_FIELD)
export const CONTEXT_MENU_FIELD = '__context_menu__'
export const CONTEXT_MENU_FIELD_ID = DimensionService.getDimensionValueColumn(CONTEXT_MENU_FIELD)

export type DataGridProps = {
    columns: Array<ColumnConfigDTO>,
    onSort?: (orderBy: string, sortAscending: boolean) => void,
    supportsRowSelection: boolean,
    onClickOnContextMenuAction?: (actionIdentifier: ActionIdentifier, invokeRowIndices: number[]) => void,
    pagination: PageableDTO,
    showFooter?: boolean
    style?: ColSize
}

export type Row = {
    rowActions: RowActions
    rowIndex: number
}


type DefaultHeaderProps = { displayName: string }
export const DefaultHeader: React.FC<DefaultHeaderProps> = React.memo((props: DefaultHeaderProps): JSX.Element => {
    return <span className={'column-header-wrapper'} data-tip-classname={'long-text-tip'} data-tip={props.displayName}><FormattedColumnHeader displayName={props.displayName}/></span>
})

type SortableHeaderProps = {
    sortAscending: boolean
    displayName: string
    sortingEnabled: boolean
}

export const SortableHeader: React.FC<SortableHeaderProps> = React.memo((props: SortableHeaderProps): JSX.Element => {
    const icon = props.sortAscending ? <UpOutlined style={{ marginLeft: 3 }}/> : <DownOutlined style={{ marginLeft: 3 }}/>

    return <span data-tip={props.displayName} className={'column-header-wrapper'} data-tip-classname={'long-text-tip'}>
        <FormattedColumnHeader displayName={props.displayName}/>
        {props.sortingEnabled && <div key={uuid()} style={{ fontSize: 8 }}>{icon}</div>}
    </span>
})

export const DataGrid: React.FC<DataGridProps> = React.memo((props: DataGridProps): JSX.Element => {

    const { onToggleAll, toggleAllHeader, onSelect, getSelectedRowIndices, getRows } = useDataGridContext()
    const currentContextMenuRowsReference = useRef([{} as Row])

    const mapHeader = (column: ColumnConfigDTO, orderBy: string[], sortAscending?: boolean) => {
        if (column.columnIdentifier === SELECTED_ID_FIELD) {
            return toggleAllHeader?.()
        } else if (column.gridColumnProperties.sortable === true) {
            return <SortableHeader sortAscending={!!sortAscending} displayName={column.gridColumnProperties.columnHeader} sortingEnabled={orderBy.indexOf(column.columnIdentifier) > -1}/>
        } else {
            return <DefaultHeader displayName={column.gridColumnProperties.columnHeader}/>
        }
    }

    const {
        columns,
        supportsRowSelection = true,
        showFooter = true,
        onClickOnContextMenuAction,
    } = props

    const getFixed = (columnConfigDTO: ColumnConfigDTO, index: number): string => {
        if (columnConfigDTO.gridColumnProperties.isFixed ||
            !columnConfigDTO.gridColumnProperties.isMetric && index <= 2 ||
            (index === 3 && !columnConfigDTO.gridColumnProperties.isMetric && supportsRowSelection))
            return 'left'
        if (index === columns.length - 1 &&
            columnConfigDTO.columnIdentifier === ACTIONS_FIELD + '.value')
            return 'right'
        return null
    }
    const columnConfigs = GridUtil.mapColumns(
        columns,
        (column: ColumnConfigDTO) => {
            return column.gridColumnProperties.renderer ? columnRenderer(column) : null
        },
        (column: ColumnConfigDTO) => {
            return mapHeader(column, props.pagination.sortProperties, props.pagination.sortAscending)
        },
        (column: AntTableColumn) => () => {
            if (column.dataIndex === SELECTED_ID_FIELD) {
                onToggleAll?.()
            } else if (column.isSortable) {
                const sortAsc = props.pagination.sortProperties.indexOf(column.dataIndex) > -1
                    ? !props.pagination.sortAscending
                    : true
                props.onSort?.(column.dataIndex, sortAsc)
            }
        },
        (columnConfigDTO: ColumnConfigDTO, index: number): string => {
            return getFixed(columnConfigDTO, index)
        },
        (columnConfigDTO: ColumnConfigDTO, index: number): number => {
            const fixed = getFixed(columnConfigDTO, index)
            if (fixed == 'left') {
                return columnConfigDTO.gridColumnProperties.width || 180
            }
            if (columnConfigDTO.columnIdentifier === ACTIONS_FIELD + '.value')
                return 80

            // todo carina continue here, something is still not right (see http://my-newui.exactag.com:3000/ui/integration/provider enabled & ID columns)
            return columnConfigDTO.gridColumnProperties.width
        },
    )

    return (
        <DataGridComponent rows={getRows()}
                           selectedRowIndices={getSelectedRowIndices()}
                           onSelect={onSelect}
                           supportsRowSelection={supportsRowSelection}
                           showFooter={showFooter}
                           columns={columnConfigs}
                           currentContextMenuRowsReference={currentContextMenuRowsReference}
                           onClickOnContextMenuAction={onClickOnContextMenuAction}
        />
    )
})
