import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { ToolsContext } from 'domain/widget/ToolsContext'

type Props = {
    hasSearch: boolean
}

export const GenericDataGridSearchForm: React.FC<Props> = React.memo((props: Props): JSX.Element => {

    const toolsContext = useContext(ToolsContext)

    let searchInput: any = undefined
    const formRef = React.createRef<any>()
    const [searchTerm, setSearchTerm] = useState('')

    const handleSubmit = (e: any) => {
        e.preventDefault()

        toolsContext?.updateSearchTerm(searchTerm)
    }

    const clear = () => {
        const form = formRef.current
        form.resetFields()
        searchInput?.focus()
        setSearchTerm('')
    }

    useEffect(() => {
        setSearchTerm(toolsContext?.searchTerm)
    }, [toolsContext?.searchTerm])

    useEffect(() => {
        toolsContext?.updateSearchTerm(searchTerm)
    }, [searchTerm])

    const onChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const suffix = searchTerm
        ? <CloseCircleOutlined onClick={clear} style={{ color: 'rgba(0, 0, 0, .25)', cursor: 'pointer' }}/>
        : null

    return props.hasSearch
        ? <div className={'panel-searchform search'}>
                <Form layout="inline" onFinish={handleSubmit} ref={formRef}>
                    <Form.Item style={{ marginRight: 0 }}>
                        <Input placeholder={"Search"}
                               prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)', cursor: 'pointer' }} onClick={handleSubmit}/>}
                               value={searchTerm}
                               size={'small'}
                               suffix={suffix}
                               onChange={onChange}
                               ref={node => searchInput = node}/>
                    </Form.Item>
                </Form>
        </div>
        : <div/>
})

export default GenericDataGridSearchForm
