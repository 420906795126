import * as React from 'react'
import { ColumnResponseDTO } from 'domain/types'
import { NOT_AVAILABLE, PREFIX_SEPARATOR } from 'Constants'

export const prefixRenderer = (value: ColumnResponseDTO) => {
    const prefix = value?.prefix ? `${getValue(value?.prefix)} ${PREFIX_SEPARATOR} ` : ''
    const mainValue = getValue(value?.name)

    return `${prefix}${mainValue}`
}

const getValue = (value?: string) => {
    return value ? value : NOT_AVAILABLE
}
