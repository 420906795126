import React, { useContext, useEffect, useState } from 'react'
import ContainerElement from 'shared/component/layout/ContainerElement'
import PanelElement from 'shared/component/layout/PanelElement'
import FormElement from 'shared/component/layout/FormElement'
import GridElement from 'shared/component/layout/GridElement'
import { ContainerElementDTO, FORM_ELEMENT_TYPES, FormElementDTO, LayoutElementDTO, LayoutElementProperties } from 'domain/types'
import HtmlContentElement from 'shared/component/layout/HtmlContentElement'
import { useRootElementContext } from 'shared/component/layout/context/RootElementContext'
import LayoutUtil from 'shared/util/LayoutUtil'
import FormContext from 'shared/component/forms/context/FormContext'
import WidgetElement from 'shared/component/layout/WidgetElement'
import ToolAwarePanelElement from 'shared/component/layout/ToolAwarePanelElement'

const layoutComponents = {
    CONTAINER: ContainerElement,
    PANEL: PanelElement,
    TAB: PanelElement,
    FORM_ELEMENT_INPUT: FormElement,
    NUMBER_FORM_ELEMENT_INPUT: FormElement,
    FORM_ELEMENT_SELECT: FormElement,
    FORM_ELEMENT_TEXTAREA: FormElement,
    FORM_ELEMENT_TOGGLE_BUTTON: FormElement,
    FORM_ELEMENT_CHECKBOX: FormElement,
    HTML_CONTENT: HtmlContentElement,
    GRID: GridElement,
    WIDGET_ELEMENT: WidgetElement,
    TOOL_AWARE_PANEL: ToolAwarePanelElement,
}

const LayoutElement: React.FC<LayoutElementProperties> = ({ layoutElementConfig, styleRules = {}, additionalCssClasses = [] }: LayoutElementProperties): JSX.Element => {
    const { elementSettings } = useRootElementContext()
    const context = useContext(FormContext)
    const [isVisible, setIsVisible] = useState(true)

    /**
     * Updates isVisible state once after the element is mounted
     */
    useEffect(() => {
        updateIsVisible()
    }, [])

    /**
     * Updates isVisible state after the context settings was changed
     */
    useEffect(() => {
        updateIsVisible()
    }, [elementSettings])

    /**
     * Updates isVisible state if the context settings match the element visibleOn conditions
     */
    const updateIsVisible = () => {
        LayoutUtil.evaluateElementSettingWithConditionThenUpdate(
            layoutElementConfig.renderSettings?.visibleOn,
            elementSettings,
            (newIsVisible: boolean) => {
                if (newIsVisible && !isVisible && context) { // "isVisible" is the old state, "newIsVisible" is the new one
                    // If element is set to editable, then restore his initial value and update if necessary the context settings.
                    if (isFormElement(layoutElementConfig)) {
                        context.setFormElementValueAndUpdateContextSettingsForInitialValues(layoutElementConfig as FormElementDTO)
                    } else {
                        // If the element is not the form element (container element), the find all children form elements
                        // and restore theirs initial value and update if necessary the context settings.
                        LayoutUtil.findFormElements(layoutElementConfig as ContainerElementDTO).forEach(element => {
                            context.setFormElementValueAndUpdateContextSettingsForInitialValues(element as FormElementDTO)
                        })
                    }
                }

                setIsVisible(newIsVisible)
            },
        )
    }

    /**
     * Checks whether the element is the form element
     * @param layoutElementConfig
     */
    const isFormElement = (layoutElementConfig: LayoutElementDTO) =>
        FORM_ELEMENT_TYPES.indexOf(layoutElementConfig.elementType) > -1

    additionalCssClasses.push('layout-element')
    additionalCssClasses.push('layout-element-' + layoutElementConfig.elementType.toLowerCase())

    const layoutMode = (layoutElementConfig as ContainerElementDTO)?.layoutConfig?.layoutMode
    if (layoutMode) {
        additionalCssClasses.push('layout-mode-' + layoutMode.toLowerCase())
    }

    // this is not a proper solution yet (flex-basis only applies to one axis, depending on flex direction) but was good enough for the time being
    if (layoutElementConfig.renderSettings?.width) {
        styleRules.minWidth = layoutElementConfig.renderSettings.width
        styleRules.flexBasis = layoutElementConfig.renderSettings.width
    }
    if (layoutElementConfig.renderSettings?.height) {
        styleRules.minHeight = layoutElementConfig.renderSettings.height
        styleRules.flexBasis = layoutElementConfig.renderSettings.height
    }

    const classList = additionalCssClasses.concat(layoutElementConfig.renderSettings?.additionalCssClasses)

    const Component = layoutComponents[layoutElementConfig.elementType]
    const componentProps = { layoutElementConfig, styleRules, additionalCssClasses }

    return <>
        {isVisible && <div style={styleRules} className={classList.join(' ')}>
            <Component {...componentProps}/>
        </div>}
    </>
}

export default LayoutElement
