import { Dispatch } from 'redux'
import { WidgetSettingsDTO, WidgetType } from 'domain/types/backend/widget.types'

export const actionTypes = {
    WIDGET_SETTINGS_CHANGED: 'WIDGET_SETTINGS_CHANGED',
}

export type WidgetSettingsState = {
    widgetSettings: WidgetSettingsDTO
}

export type WidgetSettingsAction = { type: string, widgetSettings: WidgetSettingsDTO }

const storeWidgetSettings = (widgetSettings: WidgetSettingsDTO) => (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.WIDGET_SETTINGS_CHANGED, widgetSettings: widgetSettings })
}

export const WidgetReducer = {
    storeWidgetSettings: storeWidgetSettings,
}

const initialState: WidgetSettingsState = {
    widgetSettings: {
        // todo we need to either add theme option everywhere or define it as optional and provide a default (exactag theme);
        //  second option sounds better
        type: WidgetType.TOP_N_WIDGET,
        querySettings: null,
        dataColumns: [],
    }
}

export const reducer = (state: WidgetSettingsState = initialState, action: WidgetSettingsAction) => {
    switch (action.type) {
        case actionTypes.WIDGET_SETTINGS_CHANGED:
            return { widgetSettings: action.widgetSettings }
        default:
            return state
    }
}
