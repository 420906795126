import { applyMiddleware, CombinedState, combineReducers, createStore, Reducer, Store, StoreEnhancer } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { AuthenticationState, reducer as authentication } from 'domain/authentication/redux/authentication.reducer'
import { WidgetSettingsState, reducer as widgetSettingsState } from 'domain/widget/widget.reducer'
import { AppContextState, reducer as appContext } from 'domain/core/redux/appcontext.reducer'
import { reducer as user, UserConfigs } from 'domain/user/redux/user.reducer'
import { DimensionsState, reducer as dimensions } from 'domain/dimension/redux/dimension.reducer'
import { reducer as modals } from 'shared/component/modals/redux/modals.reducer'
import * as env from 'shared/util/env'
import { composeWithDevTools } from 'redux-devtools-extension'
import { ModalConfig } from 'domain/types'

declare const window: any

const middleware = []
middleware.push(thunkMiddleware)

// we do not add Redux middleware logging in prod
if (!env.isProd()) {
    middleware.push(createLogger())
}

const enhancer: StoreEnhancer = composeWithDevTools(
    applyMiddleware(...middleware),
)

export type RootReducer = {
    widgetSettingsState: WidgetSettingsState,
    authentication: AuthenticationState,
    appContext: AppContextState,
    user: UserConfigs,
    dimensions: DimensionsState,
    modals: ModalConfig[],
}

const rootReducer: Reducer<CombinedState<RootReducer>> = combineReducers({
    widgetSettingsState,
    authentication,
    appContext,
    user,
    dimensions,
    modals,
})

const store: Store<RootReducer, any> = createStore(rootReducer, {}, enhancer)
export default store
