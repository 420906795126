import React from 'react'
import numbro from 'numbro'
import ReactEcharts from 'echarts-for-react'
import { ReportingDataSetDTO, ScenarioDTO } from 'domain/types'
import { CHANNEL_DIMENSION_IDENTIFIER, SUB_CAMPAIGN_DIMENSION_IDENTIFIER } from 'domain/adspend-optimizer/context/AdSpendOptimizerContext'

/**
 * NOTE: this chart should be made more generic in the future and probably be turned into a global component instead; but I wanted to start as simple as  possible...
 */

type Props = {
    data?: ReportingDataSetDTO,
    scenario1?: ScenarioDTO,
    scenario2?: ScenarioDTO,
}

/**
 * Renders a line chart based on the given configuration
 */
export class StackedBarChart extends React.Component<Props, {}> {

    render() {
        const { data, scenario1, scenario2 } = this.props
        const { rows } = data


        const scenario1Name = scenario1 !== undefined ? scenario1.adspend_scenario_name : 'Historic Performance' // TODO change hardcoded default name
        const scenario2Name = scenario2 !== undefined ? scenario2.adspend_scenario_name : 'Recommended Scenario' // TODO change hardcoded default name
        const stackedBarNames = [
            scenario1Name,
            scenario2Name,
        ]

        const kpiColumn = 'adspend_costs' // TODO make dynamic
        const dimensionIdentifier = rows[0][CHANNEL_DIMENSION_IDENTIFIER]?.value ? CHANNEL_DIMENSION_IDENTIFIER : SUB_CAMPAIGN_DIMENSION_IDENTIFIER // TODO make dynamic

        const allWeeksOrdered = new Set()
        const allDimensionIdsOrdered = new Set()
        const allDimensionNames = {}
        const seriesDataScenario1 = {}
        const seriesDataScenario2 = {}
        const weeklyDataScenario1 = {}
        const weeklyDataScenario2 = {}

        rows.forEach((row, index) => {
            const week = row['weekly']['name']
            const costs1 = row.slices.data[0].data[kpiColumn]
            const costs2 = row.slices.data[1].data[kpiColumn]
            const kpiValueScenario1 = costs1?.value ? costs1?.value : 0
            const kpiValueScenario2 = costs2?.value ? costs2?.value : 0

            allWeeksOrdered.add(week)
            if (!(week in weeklyDataScenario1)) {
                weeklyDataScenario1[week] = 0
            }

            if (!(week in weeklyDataScenario2)) {
                weeklyDataScenario2[week] = 0
            }

            const seriesId = row[dimensionIdentifier]?.value
            allDimensionIdsOrdered.add(seriesId)
            if (!(seriesId in seriesDataScenario1)) {
                seriesDataScenario1[seriesId] = []
            }
            if (!(seriesId in seriesDataScenario2)) {
                seriesDataScenario2[seriesId] = []
            }
            if (!(seriesId in allDimensionNames)) {
                allDimensionNames[seriesId] = row[dimensionIdentifier]?.name
            }

            weeklyDataScenario1[week] = weeklyDataScenario1[week] + kpiValueScenario1
            weeklyDataScenario2[week] = weeklyDataScenario2[week] + kpiValueScenario2

            seriesDataScenario1[seriesId].push(kpiValueScenario1)

            seriesDataScenario2[seriesId].push(kpiValueScenario2)
        })

        let yAxisMaxScenario1 = 0
        for (const week in weeklyDataScenario1) {
            const maxPerWeek = weeklyDataScenario1[week]
            if (yAxisMaxScenario1 < maxPerWeek) {
                yAxisMaxScenario1 = maxPerWeek
            }
        }

        let yAxisMaxScenario2 = 0
        for (const week in weeklyDataScenario2) {
            const maxPerWeek = weeklyDataScenario2[week]
            if (yAxisMaxScenario2 < maxPerWeek) {
                yAxisMaxScenario2 = maxPerWeek
            }
        }
        const yAxisMax = Math.round(Math.max(yAxisMaxScenario1, yAxisMaxScenario2)) // 37.177
        const log10 = Math.floor(Math.log10(yAxisMax)) // 4
        const firstNumber = Number.parseInt(yAxisMax.toString().substr(0, 2), 10) // 37

        const logBase = (firstNumber + 1) * Math.pow(10, log10 - 1) // 38.000

        const intervalSize = Math.ceil(logBase / 6) // 7.600
        const log10Interval = Math.floor(Math.log10(intervalSize)) // 3
        const firstNumberInterval = Number.parseInt(intervalSize.toString().substr(0, 1), 10) // 7
        const logBaseInteval = (firstNumberInterval + 1) * Math.pow(10, log10Interval) // 8.000

        let yAxisMaxNormalized = logBaseInteval * 6
        for (let i = 1; i <= 5; i++) {
            if (yAxisMax < yAxisMaxNormalized - logBaseInteval) {
                yAxisMaxNormalized = yAxisMaxNormalized - logBaseInteval
            } else {
                break
            }
        }

        const labelOption = {
            normal: {
                show: false,
                // position: config.position,
                // distance: config.distance,
                // align: config.align,
                // verticalAlign: config.verticalAlign,
                // rotate: config.rotate,
                formatter: '{c}  {name|{a}}',
                fontSize: 16,
                rich: {
                    name: {
                        textBorderColor: '#fff',
                    },
                },
            },
        }

        const legend = []
        const series = []
        allDimensionIdsOrdered.forEach((seriesId: number) => {
            legend.push(allDimensionNames[seriesId])

            const currentSerieScenario1 = {
                // name: '<b>' + stackedBarNames[0] + '</b><br>' + allDimensionNames[seriesId],
                name: allDimensionNames[seriesId],
                type: 'bar',
                scenarioIndex: 0,
                barMaxWidth: 20,
                barCategoryGap: 5,
                stack: 'ScenarioStack1',
                label: labelOption,
                data: seriesDataScenario1[seriesId],
                xAxisIndex: 0,
                yAxisIndex: 0,
            }

            series.push(currentSerieScenario1)

            const currentSerieScenario2 = {
                // name: '<b>' + stackedBarNames[1] + '</b><br>' + allDimensionNames[seriesId],
                name: allDimensionNames[seriesId],
                type: 'bar',
                scenarioIndex: 1,
                barMaxWidth: 20,
                barCategoryGap: 5,
                stack: 'ScenarioStack2',
                label: labelOption,
                data: seriesDataScenario2[seriesId],
                xAxisIndex: 1,
                yAxisIndex: 1,
            }

            series.push(currentSerieScenario2)
        })

        const xAxisLabels = []
        allWeeksOrdered.forEach((week) => {
            xAxisLabels.push(week)
        })

        const option = {
            // color: ['#113263', '#006699', '#4cabce', '#e5323e'],
            grid: [
                {
                    left: 0,
                    width: '49%',
                    bottom: 0,
                    'containLabel': true,
                },
                {
                    right: 0,
                    width: '49%',
                    bottom: 0,
                    'containLabel': true,
                },
            ],
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: function(params, ticket, callback) {
                    const nameIndex = params.seriesIndex % 2
                    const name = stackedBarNames[nameIndex]

                    // @ts-ignore
                    const formattedNumber = numbro(params.data).format({
                        thousandSeparated: true,
                        mantissa: 2,
                        trimMantissa: true,
                    })

                    return '<b>' + name + '</b> (' + params.seriesName + ')<br>' + 'Budget: ' + formattedNumber + '€'
                },
            },
            legend: {
                show: true,
                data: legend,
            },
            calculable: true,
            xAxis: [
                {
                    axisLabel: {
                        rotate: 90,

                        formatter: function(value, index) {
                            if (value.length > 20)
                                return '...' + value.substring(value.length - 20, value.length)
                            else
                                return value
                        },
                    },
                    type: 'category',
                    axisTick: { show: false },
                    data: xAxisLabels,
                    gridIndex: 0,
                },
                {
                    axisLabel: {
                        rotate: 90,

                        formatter: function(value, index) {
                            if (value.length > 20)
                                return '...' + value.substring(value.length - 20, value.length)
                            else
                                return value
                        },
                    },
                    type: 'category',
                    axisTick: { show: false },
                    data: xAxisLabels,
                    gridIndex: 1,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    interval: logBaseInteval,
                    max: yAxisMaxNormalized,
                    gridIndex: 0,
                },
                {
                    type: 'value',
                    interval: logBaseInteval,
                    max: yAxisMaxNormalized,
                    gridIndex: 1,
                },
            ],
            series: series,
        }

        return (
            <ReactEcharts
                style={{ height: '450px' }}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                theme={'exactag'}
                opts={{ renderer: 'svg' }}/>
        )
    }
}
