import { createContext } from 'react'
import { ActionPopupConfig, FieldKeyValue, FormElementDTO, UIFormConfig } from 'domain/types'

export type FormContextProperties = {
    form?: any

    uiFormConfig?: UIFormConfig
    popupConfig?: ActionPopupConfig
    initialValues?: Record<string, any>[]

    showButtons?: boolean
    setFormElementValueAndUpdateContextSettingsForInitialValues?: (element: FormElementDTO) => void
    setReadOnlyElements?: Function
    readOnlyElements?: string[]
    setField?: (fieldIdentifier: string, newValue: any) => void

    onSubmit?: () => void
    onCancel?: () => void
    onChange?: (value: any, field: string) => void

    setRules?: (opts: any, validation: any) => void
    userSetValues?: FieldKeyValue[]

    resetField?: (field: any) => void
    customFormFieldListeners?: { [key: string]: (string) => void }
    loadingFieldStates: { [key: string]: boolean }
}

const FormContext = createContext<FormContextProperties | null>(null)
export default FormContext
