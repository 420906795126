import React from 'react'
import numbro from 'numbro'
import { Col, Row } from 'antd'
import { ContentBox } from 'domain/core/component/ContentBox'
import { CustomIcon } from 'domain/core/component/CustomIcon'
import { ComparisonDataDTO } from 'domain/types'
import { NOT_AVAILABLE } from 'Constants'

type Props = {
    className?: string
    scenarioName1: string
    scenarioName2: string
    comparisonData?: ComparisonDataDTO
}

class KPIEntry extends React.Component<{ kpiValue: string, label: string }> {

    // see http://numbrojs.com/format.html and https://github.com/BenjaminVanRyseghem/numbro
    formatNumber(num: number) {
        if (num === null) {
            return NOT_AVAILABLE
        }

        return numbro(num).format({
            average: true, mantissa: 1, trimMantissa: true, output: 'percent',
        })
    }

    renderArrow(num: number) {
        if (!num) {
            return ''
        }

        if (num > 0) {
            return <CustomIcon icon={'arrow-big-up'} additionalCssClass={'arrow'}/>
        } else {
            return <CustomIcon icon={'arrow-big-down'} additionalCssClass={'arrow'}/>
        }
    }

    renderNumber(props) {
        if (props.kpiValue) {
            return <React.Fragment>
                {this.formatNumber(props.kpiValue)}
                {this.renderArrow(props.kpiValue)}
            </React.Fragment>
        }
        return <span className={'spacer'}>00,0k</span>
    }

    render() {
        return <Col span={6}>
            <div className={'kpi'}>
                <div className={'label-and-number'}>
                    <div className={'number'}>
                        {this.renderNumber(this.props)}
                    </div>
                    <div className={'label'}>{this.props.label}</div>
                </div>
            </div>
        </Col>
    }
}

export const ScenarioComparisonBox = (props: Props) => {
    const relativeChange = props.comparisonData.totals.compareValues.data.relativeChange

    return (
        <React.Fragment>
            <ContentBox
                className={'scenario-info-box comparison-box ' + props.className}>
                <Row>
                    <Col span={6}>
                        <div className={'meta-data'}>
                            <h3>{props.scenarioName2}</h3>
                            <p>reached the following results, compared with <strong>{props.scenarioName1}</strong>:
                            </p>
                        </div>
                    </Col>
                    <Col span={18}>
                        <Row>
                            <KPIEntry kpiValue={relativeChange?.adspend_total_price?.value} label={'Total Price'}/>
                            <KPIEntry kpiValue={relativeChange?.adspend_costs?.value} label={'Budget'}/>
                            <KPIEntry kpiValue={relativeChange?.adspend_conversions?.value} label={'Conversions'}/>
                            <KPIEntry kpiValue={relativeChange?.adspend_roas?.value} label={'ROAS'}/>
                        </Row>
                    </Col>
                </Row>
            </ContentBox>
        </React.Fragment>
    )
}
