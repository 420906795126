import React from 'react'

export type HeaderProperties = {
    header?: string,
}

const ElementHeader: React.FC<HeaderProperties> = ({ header }: HeaderProperties): JSX.Element => <>
    {header && <h3 className={'element-header'} dangerouslySetInnerHTML={{ __html: header }}/>}
</>

export default ElementHeader
