import React from 'react'
import { WidgetElementProperties } from 'domain/types'
import { WidgetContextProvider } from 'domain/widget/WidgetContext'
import WidgetFactory from 'domain/widget/WidgetFactory'
import ElementHeader from 'shared/component/layout/ElementHeader'
import ErrorBoundary from 'shared/component/ErrorBoundary'

const WidgetElement: React.FC<WidgetElementProperties> = ({ layoutElementConfig }: WidgetElementProperties): JSX.Element => {

    if (!layoutElementConfig.widgetSettings.querySettings) {
        layoutElementConfig.widgetSettings.querySettings = {
            // todo carina
            columnNames: [
                'jenkins_job.value',
                'jenkins_job.name',
                'last_build.value',
                'last_success.value',
                'last_failure.value',
                'latest_build_id.value',
                'latest_build_number.value',
                'latest_build_count_failure.value',
                'latest_build_count_success.value',
                'latest_build_count_skipped.value',
                'latest_build_comment.value',
                'latest_build_status.value',
                'latest_build_duration.value',
                'latest_build_link.value',
            ],
        }
    }
    return <>
        <ErrorBoundary>
            <ElementHeader header={layoutElementConfig.header}/>
            <WidgetContextProvider widgetSettings={layoutElementConfig.widgetSettings}>
                {WidgetFactory.create(layoutElementConfig.widgetSettings)}
            </WidgetContextProvider>
        </ErrorBoundary>

    </>


}

export default WidgetElement
