import React, { memo } from 'react'
import { Button, Form } from 'antd'
import FormContextProvider from 'shared/component/forms/context/FormContextProvider'
import { useFormContext } from 'shared/component/forms/context/FormContextHook'
import { ActionPopupConfig, ConditionClauseDTO, UIFormConfig } from 'domain/types'
import LayoutRenderer from 'shared/component/layout/renderers/LayoutRenderer'
import ErrorBoundary from 'shared/component/ErrorBoundary'

type GenericFormProps = {
    onSubmit?: () => void
    onCancel?: () => void
    additionalFilters?: ConditionClauseDTO
}

type FormWrapperComponentProps = {
    uiFormConfig: UIFormConfig
    popupConfig: ActionPopupConfig
    // do we need this?
    // showButtons: boolean
}

const GenericForm: React.FC<GenericFormProps> = (props: GenericFormProps): JSX.Element => {
    const formContext = useFormContext()
    const { uiFormConfig, popupConfig, showButtons } = formContext
    const formIdentifier = uiFormConfig.formConfig.mainDimension?.identifier + '-form ' + uiFormConfig.formConfig.mainDimension?.identifier + '-' + uiFormConfig.formConfig.type

    return (
        <ErrorBoundary>
            <Form form={formContext.form} onFinish={popupConfig.onSubmit} className={'generic-form-wrapper ' + formIdentifier}>
                {<LayoutRenderer layoutElementConfig={uiFormConfig.formConfig.layoutConfig} additionalCssClasses={['generic-form']}/>}

                {showButtons && <>
                    <Button htmlType="button" type="default" onClick={props.onCancel}>
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </>}
            </Form>
        </ErrorBoundary>
    )
}

export default memo((props: FormWrapperComponentProps): JSX.Element => {
    const { uiFormConfig, popupConfig } = props

    return <FormContextProvider uiFormConfig={uiFormConfig} popupConfig={popupConfig}>
        <GenericForm/>
    </FormContextProvider>
})
