import { ActionIdentifier, AntTableColumn, GridDataRowDTO, ReportingDataSetDTO } from 'domain/types'
import React, { MutableRefObject, useState } from 'react'
import { log } from 'shared/util/log'
import { RowActions } from 'domain/actions/RowActions'
import { GridActionsContextMenu } from 'domain/actions/GridActionsContextMenu'
import DataGridService from 'domain/datagrid/service/datagrid.service'
import { ConfigProvider, Table } from 'antd'
import { ACTIONS_FIELD, ACTIONS_ID_FIELD, customizeRenderEmpty, Row, SELECTED_ID_FIELD } from 'domain/datagrid/component/DataGrid'
import { AutoHeightTable } from 'domain/datagrid/component/AutoHeightTable'

export type DataGridComponentProps = {
    onSelect: (selectedRow: number, shiftKey: boolean) => void,
    rows: ReportingDataSetDTO,
    selectedRowIndices: number[],
    showFooter: boolean,
    supportsRowSelection: boolean,
    columns: AntTableColumn[],
    currentContextMenuRowsReference: MutableRefObject<Row[]>,
    onClickOnContextMenuAction: (actionIdentifier: ActionIdentifier, invokeRowIndices: number[]) => void,
}

type DataGridComponentState = {
    invokedRows: Row[]
}

/**
 * Gets row class name
 *
 * @param rowData
 */
const getRowClassName = (rowData: GridDataRowDTO): string => {
    if (rowData[SELECTED_ID_FIELD]?.value === true) {
        return 'datagrid-table-row selected-row'
    }

    return 'datagrid-table-row'
}

export class DataGridComponent extends React.Component<DataGridComponentProps, DataGridComponentState> {
    constructor(props) {
        super(props)

        this.state = {
            invokedRows: [{} as Row],
        }
    }

    /**
     * ATTENTION: we do not understand yet why this is happening but onRow has to be contained in a React.Component. When it is contained in a fcuntional component,
     * any calls to useState will work unreliably.
     * @param rowData
     * @param rowIndex
     */
    onRow = (rowData: GridDataRowDTO, rowIndex: number) => {
        return {
            className: getRowClassName(rowData),
            onClick: (event) => {
                log.debug('grid onClick event')

                return this.props.onSelect && this.props.supportsRowSelection
                    ? this.props.onSelect(rowIndex, event.shiftKey)
                    : {}
            },
            onContextMenu: (event) => {
                log.debug('grid onContextMenu event')
                event.preventDefault()

                const selectedRowIndices = this.props.selectedRowIndices
                const rows = this.props.rows?.rows
                const currentContextMenuRowsReference = this.props.currentContextMenuRowsReference

                // If clicking on some row, that is not selected in grid, then open context menu for just this one row.
                // If the clicked row belongs to the selected grid rows, then open context menu for all selected rows.
                if (selectedRowIndices.indexOf(rowIndex) === -1) {
                    const rowActions = rowData[ACTIONS_ID_FIELD]?.data as RowActions
                    currentContextMenuRowsReference.current = [{ rowActions: rowActions, rowIndex: rowIndex } as Row]
                } else {
                    currentContextMenuRowsReference.current = selectedRowIndices.map(selectedRowIndex => {
                            const row = rows.find((row, index) => index === selectedRowIndex)
                            const rowActions = row[ACTIONS_FIELD].data as RowActions

                            return { rowActions: rowActions, rowIndex: selectedRowIndex } as Row
                        },
                    )
                }
            },
        }
    }

    /**
     * Dropdown, that wraps the tr row element
     * @param properties
     */
    dropdownRowWrapper = (properties) => {
        return (
            <GridActionsContextMenu currentContextMenuRowsReference={this.props.currentContextMenuRowsReference}
                                    onClickOnContextMenuAction={this.props.onClickOnContextMenuAction}>
                <tr {...properties} />
            </GridActionsContextMenu>
        )
    }

    render() {
        // append key to each row
        const data = this.props.rows?.rows.map((row, index) => ({ ...DataGridService.convertRowToTableStructure(row), key: index }))

        return <React.Fragment>
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <AutoHeightTable
                    className={'ant-table ant-table-small data-grid-table ' + (this.props.supportsRowSelection ? 'data-grid-selectable' : 'data-grid-non-selectable')}
                    components={{ body: { row: this.dropdownRowWrapper } }}
                    columns={this.props.columns}
                    dataSource={data}
                    pagination={false}
                    onRow={this.onRow}
                />
            </ConfigProvider>

        </React.Fragment>
    }
}
