import * as api from 'shared/service'
import _orderBy from 'lodash/orderBy'
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios'

import {
    ActionIdentifier,
    ColumnConfigDTO,
    ConditionClauseDTO,
    DataRowDTO,
    DimensionValueListDTO,
    FilterConfigDTO,
    GridColumnPropertiesDTO,
    GridConfigDTO,
    GridDataRowDTO,
    LoadResponseDTO,
    MappedGridConfig,
    PageableDTO,
    QuerySettingsDTO,
} from 'domain/types'
import { ColumnRendererDTO } from 'shared/component/renderers/renderers'
import store from 'shared/redux/store'
import { log } from 'shared/util/log'
import { MergedDataGridWidgetSettings } from 'domain/widget/generic/GenericDataGridWidgetWrapper'
import { DataSettingsState } from 'domain/widget/generic/GenericDataGridWidget'
import { SELECTED_FIELD, SELECTED_ID_FIELD } from 'domain/datagrid/component/DataGrid'
import { getColumnNamesForShow } from 'domain/widget/generic/GridUtil'
import UrlService from 'shared/service/url.service'
import ConditionClauseService from 'shared/service/conditionClauseService'
import DimensionService from 'domain/dimension/service/DimensionService'

// temporary: handle data responses from monitoring api separately due to different data format
// const monitoringApiUrl = 'http://my-newui.exactag.com:8080'

const getCancelTokenSource = (): CancelTokenSource => axios.CancelToken.source()

const fetchRows = async (querySettings: QuerySettingsDTO, path: string, baseURL?: string, cancelToken?: CancelTokenSource): Promise<LoadResponseDTO> => {
    querySettings.appContext = store.getState().appContext.appContext
    try {
        const result: LoadResponseDTO = await post<LoadResponseDTO>(querySettings, path, { baseURL: baseURL, cancelToken: cancelToken?.token })

        // TODO: mapping?
        // TODO do we need this?
        // if (baseURL === monitoringApiUrl) {
        //     return mapMonitoringRows(result)
        // }
        result.dataSet.rows.forEach((entry, index) => {
            entry[SELECTED_FIELD] = { value: false }

            return entry
        })

        return result
    } catch (error) {
        if (axios.isCancel(error) || error.message === 'Cancel') {
            log.debug('cancelled request: ', error.message)
        }
        return error
    }
}

const loadData = (dataSettingsState: DataSettingsState, filter: ConditionClauseDTO, cancelToken?: CancelTokenSource): Promise<LoadResponseDTO> => {
    const { settings, columns, pagination, searchTerm, timespanSettings } = dataSettingsState

    const { paths, embedded } = settings

    const columnNames = getColumnNamesForShow(columns)

    const paginationSettings: PageableDTO = {
        page: pagination.page,
        pageSize: embedded ? 5 : pagination.pageSize,
        sortAscending: pagination.sortAscending,
        sortProperties: pagination.sortProperties,
    }

    const querySettings: QuerySettingsDTO = {
        columnNames,
        filter,
        paginationSettings: paginationSettings,
        timespanSettings: timespanSettings,
    }

    return DataGridService.fetchRows(querySettings, `${paths.base}${paths.data}`, UrlService.getBaseUrl(), cancelToken)
}

const fetchFilterConfig = async (path: string, baseURL?: string, cancelToken?: CancelToken): Promise<any> => {
    return await fetch<FilterConfigDTO>(path, { baseURL, cancelToken })
}

const fetchFilterEntries = async (querySettings: QuerySettingsDTO, path: string, cancelToken?: CancelToken): Promise<DimensionValueListDTO> => {
    querySettings.appContext = store.getState().appContext.appContext
    return await post<DimensionValueListDTO>(querySettings, path, { cancelToken })
}

/**
 * If there are some actions (except download), then create the action column and return a new list with the prepended action column
 *
 * @param gridConfig
 */
const appendActionColumn = (gridConfig: GridConfigDTO): ColumnConfigDTO[] => {
    const { actions, columnConfigs } = gridConfig

    return actions?.filter(action => action.identifier != ActionIdentifier.DOWNLOAD)?.length ? [
        {
            columnIdentifier: SELECTED_ID_FIELD,
            gridColumnProperties: {
                columnHeader: '[A]',
                width: 30,
                renderer: { type: 'ROW_SELECTOR' } as ColumnRendererDTO,
                sortable: false,
                editable: false,
            } as GridColumnPropertiesDTO,
        } as ColumnConfigDTO,
        ...columnConfigs,
    ] : [...columnConfigs]
}

/**
 * map grid config to something antd can actually create a table from
 *
 * @param config
 */
const mapGridConfig = (config: MergedDataGridWidgetSettings): MappedGridConfig => {
    return {
        ...config.gridConfig,
        columnConfigs: appendActionColumn(config.gridConfig),
        filterConfigs: _orderBy(config.gridConfig.filterConfigs, 'sortOrder', 'asc'),
        paths: config.paths,
    }
}

/**
 * Converts GridDataRowDTO to table structure
 *
 * @param row
 */
const convertRowToTableStructure = (row: GridDataRowDTO): DataRowDTO => {
    const result: DataRowDTO = {}
    Object.keys(row).forEach(dimensionIdentifier => {
        const columnData = row[dimensionIdentifier]
        result[DimensionService.getDimensionValueColumn(dimensionIdentifier)] = columnData
        result[DimensionService.getDimensionNameColumn(dimensionIdentifier)] = columnData
    })

    return result
}

const DataGridService = {
    fetchRows,
    fetchFilterConfig,
    fetchFilterEntries,
    getCancelTokenSource,
    mapGridConfig,
    loadData: loadData,
    convertRowToTableStructure: convertRowToTableStructure,
}
export default DataGridService

const fetch = <T>(path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.get(path, config)
}

const post = <T>(data: any, path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.post(path, data, config)
}
