import * as React from 'react'
import { isDev } from 'shared/util/env'

declare let baseUrlFrontend: string

export type WhiteLabelDevelopmentProps = {
    enabled: boolean,
    client: string
}

/**
 * This component is solely for testing the white-label feature in development. This component
 * renders a link to a CSS file only available due to settings in the local webpack build.
 *
 * For deployments, the CSS data will be read from within the generated WAR file.
 */
export class WhiteLabelDevelopment extends React.Component<WhiteLabelDevelopmentProps, {}> {

    static defaultProps = {
        enabled: false,
        client: 'groupm',
    }

    componentDidMount(): void {
        if (!isDev() || !this.props.enabled) return

        // const style = document.createElement("link")
        //
        // style.href = baseUrlFrontend + `/config/${this.props.client}.css`
        // style.rel = 'stylesheet'
        // style.type = 'text/css'
        //
        // document.getElementsByTagName('head')[0].appendChild(style)
    }

    render() {
        return <React.Fragment/>
    }
}