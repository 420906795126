import * as React from 'react'
import Icon from '@ant-design/icons'

type Props = {
    title: string
    type?: string
}

type State = {}


const PageErrorSvg = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 50H45V12.963L32 0H5V50Z" fill="#BEEAFD"/>
        <path d="M31 14H45L31 0V14Z" fill="#96DCF9"/>
        <path
            d="M20.3199 22.8346C20.3423 22.805 20.363 22.7745 20.3813 22.7426C20.4966 22.5036 20.4966 22.2246 20.3813 21.9856C20.3634 21.9537 20.3428 21.9228 20.3199 21.8936C20.2755 21.8152 20.219 21.7443 20.1531 21.6824C20.141 21.6716 20.1365 21.6559 20.1235 21.6456L15.531 17.9716C15.135 17.655 14.5564 17.7191 14.2398 18.1152C13.9227 18.5112 13.9869 19.0897 14.3829 19.4063L16.9307 21.4456H14.0384C13.5312 21.4456 13.1199 21.8569 13.1199 22.3641C13.1199 22.8713 13.5312 23.2826 14.0384 23.2826H16.9307L14.3829 25.321C13.9869 25.6376 13.9227 26.2161 14.2398 26.6121C14.5564 27.0082 15.135 27.0723 15.531 26.7552L20.1235 23.0812C20.1365 23.0714 20.141 23.0557 20.1531 23.0449C20.219 22.9835 20.2755 22.9126 20.3199 22.8346Z"
            fill="#4291CE"/>
        <path
            d="M34.2453 21.4456H31.3531L33.9009 19.4072C34.2969 19.0906 34.361 18.5121 34.044 18.116C33.7273 17.72 33.1488 17.6559 32.7528 17.973L28.1603 21.647C28.1473 21.6568 28.1428 21.6725 28.1307 21.6833C28.0648 21.7447 28.0083 21.8156 27.9639 21.8936C27.9414 21.9232 27.9208 21.9537 27.9024 21.9856C27.7872 22.2246 27.7872 22.5036 27.9024 22.7426C27.9208 22.7744 27.9414 22.8049 27.9639 22.8345C28.0083 22.9126 28.0648 22.9834 28.1307 23.0449C28.1428 23.0556 28.1473 23.0713 28.1603 23.0812L32.7528 26.7552C33.1488 27.0723 33.7273 27.0081 34.044 26.6121C34.361 26.2161 34.2969 25.6376 33.9009 25.3209L31.3531 23.2826H34.2453C34.7526 23.2826 35.1638 22.8713 35.1638 22.3641C35.1638 21.8568 34.7526 21.4456 34.2453 21.4456Z"
            fill="#4291CE"/>
        <path
            d="M30.9552 37.3972C30.5812 37.3972 30.2444 37.1703 30.104 36.8231C29.1223 34.3923 26.7633 32.8007 24.1419 32.8007C21.5205 32.8007 19.1614 34.3923 18.1797 36.8231C17.9873 37.29 17.4545 37.5138 16.9863 37.3245C16.5185 37.1353 16.2907 36.6034 16.4768 36.1343C17.7393 33.0097 20.772 30.9637 24.1419 30.9637C27.5118 30.9637 30.5444 33.0097 31.8069 36.1343C31.9213 36.4173 31.8876 36.7388 31.7168 36.9922C31.5459 37.2452 31.2607 37.3972 30.9552 37.3972Z"
            fill="#4291CE"/>
    </svg>


)

const PageErrorIcon = props => (
    <Icon component={PageErrorSvg} {...props} style={{ width: 50, height: 50, left: 8 }}/>
)


// A customized version of the Antd Alert component that allows e.g. HTML content in the message
//this currently only supports one hardcoded icon but could later be extended to be more dynamic
export class PageErrorMessage extends React.Component<Props, State> {
    render() {
        return (
            <div data-show="true"
                 className={'page-error-message ant-alert ant-alert-' + (this.props.type || 'info') + ' ant-alert-with-description'}>
                <PageErrorIcon className={'ant-alert-icon'}/>
                <div className="ant-alert-content">
                    <div className="ant-alert-message">{this.props.title}</div>
                    <div className="ant-alert-description">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

}
