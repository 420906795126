import * as api from 'shared/service'
import { AxiosRequestConfig } from 'axios'
import store from 'shared/redux/store'
import { QuerySettingsDTO } from 'domain/types'

declare let baseUrlBackend: string

const EXPORT_CREATE_URL = '/createexportdata'
const EXPORT_URL = '/exportdata'

const exportData = (querySettings: QuerySettingsDTO, settings: any): Promise<any> => {
    const appContext = store.getState().appContext.appContext
    return post<any>({
        appContext,
        querySettingsDTO: querySettings,
        fileType: 'xslx',
    }, `${settings.paths.base}${EXPORT_CREATE_URL}`, { baseURL: baseUrlBackend })
        .then(triggerResponse => {
            if (triggerResponse.success == true) {
                const identifier = triggerResponse.identifier
                const link = document.createElement('a')
                link.href = `${baseUrlBackend}${settings.paths.base}${EXPORT_URL}/${identifier}`
                link.setAttribute('download', triggerResponse.filename)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                throw new Error('There was an error processing your export.')
            }
        })
}

const post = <T>(data: any, path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.post(path, data, config)
}

const ExportService = { exportData }
export default ExportService
