import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, Form } from 'antd'
import { FormToggleButtonElementProperties } from 'domain/types'
import FormContext from 'shared/component/forms/context/FormContext'
import { ElementSetting, useRootElementContext } from 'shared/component/layout/context/RootElementContext'

const FormCheckboxElement: React.FC<FormToggleButtonElementProperties> = ({ layoutElementConfig, onChangeListener, disabled }: FormToggleButtonElementProperties): JSX.Element => {
    const formContext = useContext(FormContext)
    const { updateElementSettings } = useRootElementContext()
    const { displayName, defaultValue, dimensionIdentifier } = layoutElementConfig.formFieldConfig

    /**
     * onChange handler, that updates context settings for the element if the element has  useAsSetting=true
     *
     * @param event
     */
    const onChange = (event) => {
        const checked = event.target.checked
        if (layoutElementConfig.useAsSetting) {
            updateElementSettings({ key: dimensionIdentifier, value: checked } as ElementSetting)
        }

        if (onChangeListener) {
            onChangeListener(checked)
        }
    }

    const element = <Checkbox defaultChecked={defaultValue} onChange={onChange} disabled={disabled} className={'checkbox ' + dimensionIdentifier}>{displayName}</Checkbox>

    const tailLayout = {
        wrapperCol: { offset: 9, span: 15 },
    }

    /**
     * If the element belongs to the form context, then wrap the element with Form.Item,
     * so that is is possible to set its initial value. An example for toggle elements that do not have a form context are the expert mode
     * toggle buttons. They will not be wrapped in a Form.Item and do therefore not support certain features such as the "extra" info text.
     */
    return formContext
        ? <Form.Item className={'form-item-checkbox'} {...tailLayout} name={dimensionIdentifier} extra={layoutElementConfig.formFieldConfig.infoText} valuePropName={'checked'}>{element}</Form.Item>
        : <span>{displayName} {element}</span>
}

export default FormCheckboxElement
