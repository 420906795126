import React, { createContext, ReactNode, useState } from 'react'
import { ConditionClauseDTO } from 'domain/types'

export type AdditionalFilterContextProperties = {
    additionalFilters?: ConditionClauseDTO,
    updateAdditionalFilters?: (additionalFilters?: ConditionClauseDTO) => void
}
export const AdditionalFilterContext = createContext<AdditionalFilterContextProperties>({ additionalFilters: undefined })

type AdditionalFilterContextProviderProps = AdditionalFilterContextProperties & {
    children?: ReactNode
}

export const AdditionalFilterContextProvider: React.FC<AdditionalFilterContextProviderProps> = (props: AdditionalFilterContextProviderProps): JSX.Element => {
    const [additionalFilters, updateAdditionalFilters] = useState(props.additionalFilters)

    const context = {
        additionalFilters,
        updateAdditionalFilters
    } as AdditionalFilterContextProperties

    return <AdditionalFilterContext.Provider value={context}>
        {props.children}
    </AdditionalFilterContext.Provider>
}
