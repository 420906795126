import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Input, Row } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import { SystemService } from 'domain/core/service'
import { passwordResetService } from 'domain/password/service'
import { FormResponseDTO } from 'domain/types'

interface Props extends FormComponentProps {
    match: any // added by React Router
}

const PasswordResetFormPage: React.FC<Props> = (props: Props): JSX.Element => {

    const [form] = Form.useForm()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [resetDone, setResetDone] = useState(false)
    const [response, setResponse] = useState(undefined)

    const [, forceUpdate] = useState() // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({})
    }, [])

    const handleFormResponse = (formResponse: FormResponseDTO) => {
        if (formResponse.successful) {
            setIsSubmitting(false)
            setResetDone(true)
            setResponse({
                type: 'success',
                caption: 'Password reset successful',
                message: 'Your password has been updated.',
            })

            return
        }

        let errorsMsg
        if (formResponse.errorMsg) {
            errorsMsg = formResponse.errorMsg
        } else if (formResponse.errors) {
            const messages = []
            for (const name in formResponse.errors) {
                messages.push(formResponse.errors[name])
            }
            errorsMsg = messages.join('. ')
        } else {
            errorsMsg = 'An unknown error happened'
        }

        setResponse({
            type: 'error',
            caption: 'Password update failed.',
            message: errorsMsg,
        })
        setIsSubmitting(false)
        setResetDone(false)
    }

    const handleBackToLogin = (_) => {
        SystemService.showCoreUi()
    }

    // onFinish is only triggered when validation has already passed; no further validation is needed here
    const onFinish = (values) => {
        setIsSubmitting(true)

        const token = props.match.params.token
        passwordResetService
            .reset(token, values.password1, values.password2)
            .then(handleFormResponse, handleFormResponse)
    }

    return (
        <Row justify="center">
            <div className={'content-body standalone-form password-reset-form'}>
                <h3>Reset your password</h3>

                {response && <Alert showIcon className={'form-response-alert'}
                                    type={response.type} message={response.message}/>
                }

                <Row style={{ marginTop: 16 }}>
                    <Col span={24}>
                        <Form form={form} onFinish={onFinish}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="password1" validateFirst
                                               rules={[
                                                   { required: true, message: 'Please enter your new password' },
                                                   { min: 15, message: 'Password must be at least 15 characters long' },
                                               ]}
                                    >
                                        <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                                        disabled={isSubmitting || resetDone}
                                                        size={'large'}
                                                        placeholder="New password"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="password2" validateFirst dependencies={['password1']}
                                               rules={[
                                                   { required: true, message: 'Please confirm your new password' },
                                                   { min: 15, message: 'Password must be at least 15 characters long' },
                                                   ({ getFieldValue }) => ({
                                                       validator(rule, value) {
                                                           if (!value || getFieldValue('password1') === value) {
                                                               return Promise.resolve()
                                                           }
                                                           return Promise.reject('Passwords do not match')
                                                       },
                                                   }),
                                               ]}
                                    >
                                        <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                                        disabled={isSubmitting || resetDone}
                                                        size={'large'}
                                                        placeholder="Confirm new password"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ marginTop: 16 }}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button style={{ width: '100%' }} type="dashed" htmlType="button"
                                                size={'large'}
                                                onClick={handleBackToLogin}>
                                            Back to Login
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button style={{ width: '100%' }} type="primary" htmlType="submit"
                                                    size={'large'}
                                                    loading={isSubmitting}
                                                    disabled={
                                                        !form.isFieldsTouched(true) ||
                                                        form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                                        resetDone
                                                    }
                                            >
                                                Set Password
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Row>
    )
}

export default PasswordResetFormPage
