/**
 * Returns true if one of the following applies:
 * - GET parameter embedded=true has been appended to the URL (this triggers easyXdm setup)
 * - GET parameter simulateEmbedded=true has been appended to the URL (this is useful for local layout testing without triggering easyXdm)
 * - the page has been embedded in an iFrame
 */
const isEmbedded = (): any => {
    const url = new URL(window.location.href)
    const embeddedParam = url.searchParams.get('embedded')
    const simulateEmbeddedParam = url.searchParams.get('simulateEmbedded')
    const isInIframe = window != window.top
    return isInIframe || embeddedParam === 'true' || simulateEmbeddedParam === 'true'
}

const EmbeddingService = { isEmbedded }
export default EmbeddingService