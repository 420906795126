import * as React from 'react'
import { useEffect, useState } from 'react'
import { Layout, Spin } from 'antd'
import { MainMenu } from 'shared/component/MainMenu'
import { ArticlePage } from 'domain/content/ArticlePage'
import { ArticleService } from 'domain/content/ArticleService'
import { ContentType, MenuRootDTO } from 'domain/types'
import MenuUtil from 'shared/service/MenuUtil'
import { ArticleLayoutContextProvider } from 'domain/content/ArticleLayoutContext'
import { useHistory } from 'react-router-dom'

type Props = {
    articleIdentifier: string
}

export const ArticleLayout = (props: Props): JSX.Element => {

    const history = useHistory();

    /**
     * Menu configuration state
     */
    const [menu, setMenu] = useState<MenuRootDTO>({ root: [] })

    /**
     * When the component is mounted, then loads menu configuration from the backend service
     */
    useEffect(() => fetchMenu(), [])

    /**
     * Loads article menu configuration of type HELP and saves it in the [menu] state
     */
    const fetchMenu = () => {
        ArticleService.loadMenu(ContentType.HELP)
            .then((data: MenuRootDTO) => {
                setMenu(data)
            })
    }

    // find menu node with the path ending with [props.articleIdentifier]
    const menuNode = MenuUtil.getMenuNodeEndingWith(props.articleIdentifier, menu)
    let content = <div className={'aaa'}/>

    if (menuNode) {
        if (menuNode.path !== window.location.pathname) {
            // Redirect to the full path if the current url has only a shortened version
            // e.g. /ui/content/4404610144786-How-does-Anonymous-Attribution-s-data-collection-work-
            // -> /ui/content/help/articles/anonymous-attribution/4404610144786-How-does-Anonymous-Attribution-s-data-collection-work-
            history.push(menuNode.path)
        }

        // remove "/ui" prefix from the menu node path
        const articlePath = menuNode.path.replace(/^\/ui/, '')

        content = <Layout className="article-layout">
            <MainMenu key={menuNode.path} showLogo={false} firstEntryPath={menuNode.path} entries={menu.root} inlineIndent={19}/>
            <ArticlePage path={articlePath} title={menuNode?.title}/>
        </Layout>
    }

    return <ArticleLayoutContextProvider menu={menu}>
        <Spin spinning={menu.root.length == 0}>
            {content}
        </Spin>
    </ArticleLayoutContextProvider>

}
