import React from 'react'
import { render } from 'react-dom'
import { isLocalEnvironment } from 'shared/util/env'
import { log } from 'shared/util/log'
import setupECharts from 'shared/echart/setup'
import Root from './Root'
// TODO: some antd components get freezing with React 18. Activate React 18 after it is fixed in antd or react. See UI-1990
// import { createRoot } from 'react-dom/client'
import { unregister } from 'registerServiceWorker'

declare const module: any

// Setting the default level for logging
log.setDefaultLevel(isLocalEnvironment() ? 'trace' : 'silent')

// Register the Exactag echarts theme
setupECharts()

log.info('Setup done.')

if (module.hot) {
    module.hot.accept()
}

// TODO: this is react 17 way. Please use react 18 after the freezing issue is fixed.
render(
    <Root/>,
    document.getElementById('root'),
)

// TODO: some antd components get freezing with React 18. Activate React 18 after it is fixed in antd or react.
// const container = document.getElementById('root')
// const root = createRoot(container)
// root.render(<Root/>)

unregister()
