import * as React from 'react'
import _assignIn from 'lodash/assignIn'
import * as queryString from 'query-string'
import { AppContextDTO } from 'domain/types'
import { log } from 'shared/util/log'
import { actions } from 'domain/core/redux/appcontext.reducer'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

interface Props extends DispatchProps {
    children: React.ReactNode,
    storeAppContext: (appContext: AppContextDTO) => any,
    location: any
}

interface State {
}

class AppContextFromQuery extends React.Component<Props, State> {

    componentDidMount() {
        const { location } = this.props
        const appContext: AppContextDTO = { advertiserId: -1, advertiserName: '' }

        if (!location || !location.search) {
            return
        }

        const query = queryString.parse(location.search)
        if (!query.appContextDTO) {
            return
        }

        try {
            // @ts-ignore
            _assignIn(appContext, JSON.parse(query.appContextDTO))
        } catch (err) {
            log.error(`Error during parsing query string ${location.search}`, err)
        }

        this.props.storeAppContext(appContext)
    }

    render() {
        return this.props.children
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        storeAppContext: (appContext: AppContextDTO) => actions.storeAppContext(appContext)(dispatch),
    }
}

type DispatchProps = typeof mapDispatchToProps

export default connect(null, mapDispatchToProps)(AppContextFromQuery)
