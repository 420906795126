import React from 'react'
import { Col, Row } from 'antd'
import { HelpBubble } from 'domain/core/component/HelpBubble'

const Help = () =>
    <HelpBubble title={'Media Plan Settings'}>
        <div>
            <p>
                When creating a scenario, you can set the budget either on the <em>Campaign level</em>, which
                is across all your activity, on the <em>Channel</em> level, or on a <em>Sub Campaign</em> level. A
                scenario can only work at single optimization level, so when switching, your changes will be lost.
            </p>
            <p>
                You may also set the <strong>flighting pattern</strong> which helps the tool understand how your budget
                is to be applied over the time period:
                <ul>
                    <li>
                        <em>Even</em>: The budget will be distributed evenly across the scenario's timeframe
                    </li>
                    <li>
                        <em>Front</em>: The budget will be front-loaded into the first weeks, with a linear function,
                        such that approx. 70% of the budget is spent in the first half.
                    </li>
                    <li>
                        <em>Back</em>: The budget will be back-loaded into the later weeks, with a linear function, such
                        that approx. 70% of the budget is spent in the later half.
                    </li>
                    <li>
                        <em>Burst</em>: The budget will follow weekly patterns of on/off, evenly distributing across the
                        on periods.
                    </li>
                </ul>
            </p>
        </div>
    </HelpBubble>

export const MediaPlanHeadline = () =>
    <Row style={{ marginTop: 20 }}>
        <Col>
            <h3>Scenario Media Plan <Help/></h3>
        </Col>
    </Row>

