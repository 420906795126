import { AppContextDTO, ConditionClauseDTO, DataRowDTO, PaginationInfoDTO, ReportingDataSetDTO } from 'domain/types'

export type ApiErrorDTO = {
    httpStatus?: any,
    message: string
};

export type ResponseDTO = {
    messages?: { [key: string]: string[] }
    httpStatus?: string
    debugInfo?: any[]
    success: boolean
}

export type LoadResponseDTO = {
    dataSet: ReportingDataSetDTO
    paginationInfo?: PaginationInfoDTO
    response: ResponseDTO
    ignoredFilterDimensions: string[]
}

export type ErrorResponse = {
    errors: string[],
    message: string,
}

export type FormResponseDTO = {
    successful: boolean
    errorMsg: string
    errors: { [key: string]: string[] }
}

export enum QueryModeType {
    COMPARE = 'COMPARE',
    PLOT = 'PLOT',
    FORM = 'FORM'
}

export type QueryModeDTO = {
    type: QueryModeType
}

export type PageableDTO = {
    page: number
    pageSize: number
    sortAscending?: boolean
    sortProperties: string[]
}

export interface QuerySettingsDTO {
    appContext?: AppContextDTO
    columnNames: string[]
    filter?: ConditionClauseDTO
    paginationSettings?: PageableDTO
    timespanSettings?: TimespanSettingsDTO
    mode?: QueryModeDTO
}

export interface TimespanSettingsDTO {
    start?: string
    end?: string
}


export type PingDTO = {
    successful: boolean,
}

export type UpdateResponseDTO = {
    response: ResponseDTO
    updatedData?: DataRowDTO[]
    validationErrors: { [key: string]: string[] }
}
