import { post, put } from 'shared/service/api'
import DataGridService from 'domain/datagrid/service/datagrid.service'
import { CancelToken } from 'axios'
import store from 'shared/redux/store'
import LayoutUtil from 'shared/util/LayoutUtil'
import {
    DataRowDTO,
    ConditionClauseDTO,
    FormConfigDTO,
    FormSubmitMethod,
    LoadResponseDTO,
    QueryModeType,
    QuerySettingsDTO,
    SelectFormElementDTO,
    UpdateResponseDTO, GridDataRowDTO, PageableDTO, LoadFormConfigDTO, FormType,
} from 'domain/types'

const FormService = {
    loadFormConfig: async (formType: FormType, filter: ConditionClauseDTO, path: string, baseURL: string): Promise<any> => {
        const appContext = store.getState().appContext.appContext
        const loadFormConfigDTO: LoadFormConfigDTO = {
            type: formType,
            filter,
            appContext,
        }
        const result = await post<any>(path, loadFormConfigDTO, { baseURL })
        return FormService.mapFormConfig(result)
    },

    /**
     * @param filter should contain the IDs of the items to be edited, can contain any additional filters that might also be useful when loading content
     * @param idColumn
     * @param formConfig
     * @param path
     * @param baseURL
     */
    loadFormEntities: async (filter: ConditionClauseDTO | null, idColumn: string, formConfig: FormConfigDTO, path: string, baseURL: string): Promise<GridDataRowDTO[]> => {
        const appContext = store.getState().appContext.appContext
        const columnNames = LayoutUtil.findFormElements(formConfig.layoutConfig)
            .map(element => {
                const { readOnly, dimensionIdentifier, dimensionNameMapping } = element.formFieldConfig

                // Dropdowns are configured in the backend only with value column. The name information will be shown via
                // dropdowns. But if the element is in readonly mode, the dropdown will be not rendered. In this case we have to load
                // the name column instead of the value column in order to show the human-readable name.
                if (readOnly && dimensionNameMapping && dimensionIdentifier != dimensionNameMapping) {
                    return [dimensionIdentifier, dimensionNameMapping]
                } else {
                    return [dimensionIdentifier]
                }
            }).reduce((acc, val) => acc.concat(val), []) // flat()
            .filter(e => e !== null && e !== undefined)

        columnNames.push(idColumn)

        const paginationSettings: PageableDTO = {
            page: 0,
            pageSize: 1000,
            sortProperties: [idColumn],
            sortAscending: true,
        }

        const payload: QuerySettingsDTO = { appContext, columnNames, filter, paginationSettings, timespanSettings: null }

        const result = await post<LoadResponseDTO>(path, payload, { baseURL })
        return result.dataSet.rows
    },


    submitForm: async (data: any, path: string, method: string, baseURL: string): Promise<UpdateResponseDTO> => {
        const appContext = store.getState().appContext.appContext
        const payload = { rows: [...data], appContext }

        switch (method) {
            case 'POST':
                return await post<UpdateResponseDTO>(path, payload, { baseURL })
            case 'PUT':
                return await put<UpdateResponseDTO>(path, payload, { baseURL })
        }
    },

    loadSelectElementEntries: (element: SelectFormElementDTO, fallbackFilterEndpointUrl: string, filterQuery: ConditionClauseDTO, cancelToken?: CancelToken): Promise<void> => {
        const appContext = store.getState().appContext.appContext
        const identifier = element.formFieldConfig.dimensionIdentifier
        const nameMapping = element.formFieldConfig.dimensionNameMapping

        // use dimension name column if available but not all dimensions have a nameMapping
        const dimensionNameOrId = nameMapping || identifier
        const paginationSettings: PageableDTO = {
            page: 0,
            // In this request we load the dropdown list with mappings.
            // These mappings will be used also to show the appropriate names for the selected value ids of the editable element in the form.
            // If we load not enough elements then it can happen that some ids don't get the mappings.
            // But we also don't want to load too many entries that would impact the performance in then UI.
            // 50.000 is then a tradeoff between ui performance and usability.
            // This is a workaround. A proper solution would be loading data e.g. with pagination and live search-as-you-type support.
            pageSize: 50000,
            sortProperties: [dimensionNameOrId],
            sortAscending: true,
        }

        const mode = { type: QueryModeType.FORM }
        const querySettings: QuerySettingsDTO = {
            // at the moment, the backend always expected 2 column names even if there is no nameMapping
            columnNames: [identifier, dimensionNameOrId],
            paginationSettings,
            filter: filterQuery,
            appContext,
            mode,
        }

        const url = element.selectConfig.loadValuesUrl
            ? element.selectConfig.loadValuesUrl
            : fallbackFilterEndpointUrl

        return DataGridService.fetchFilterEntries(querySettings, `${url}/${identifier}`, cancelToken)
            .then(entries => {
                element.selectConfig.selectEntries = entries
            })
    },

    /**
     * Prepares the form config that was received from the backend for the usage in the frontend (to e.g. mock data or convert data structures if necessary)
     * @param formConfig
     */
    mapFormConfig: (formConfig: FormConfigDTO): FormConfigDTO => {
        const result = { ...formConfig } as FormConfigDTO

        Object.keys(result.actions).forEach(actionKey => {
            const action = result.actions[actionKey]
            if (action && actionKey === 'submit') {
                if (action.method === FormSubmitMethod.POST) result.actions[actionKey]['apiCall'] = FormService.submitForm
            }
        })
        return result
    },
}
export default FormService
