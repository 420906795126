import { ActionPopupConfig } from 'domain/types'
import { actions as modalActions } from 'shared/component/modals/redux/modals.reducer'
import store from 'shared/redux/store'
import { message } from 'antd'

export const openPopupAction = (popupConfig: ActionPopupConfig) => {
    store.dispatch(modalActions.open(popupConfig))

    if (popupConfig.loadContent) {
        popupConfig.setLoading(true)
        popupConfig.loadContent()
            .then(content => {
                popupConfig.showContent(content)
            })
            .catch(error => {
                // when an error happens while loading form data: prevent form from being stuck in loading state; instead: show an error message
                // and close the modal immediately
                const errorMessage = `We're sorry, an error occurred${error.status === 403 ? ` while accessing this item` : ``}.`
                message.error(errorMessage, 5)
                store.dispatch(modalActions.close(popupConfig.identifier))
            })
            .finally(() => {
                popupConfig.setLoading(false)
            })
    }
}
