import React from 'react'
import { Button, Col, Row } from 'antd'
import { WidgetContextProps } from 'domain/context/LegacyWidgetContext'
import withWidgetContext from 'domain/context/withWidgetContext'
import { DataGridWidgetSettingsDTO } from 'domain/types/backend/widget.types'

type Props = {} & WidgetContextProps<DataGridWidgetSettingsDTO>

type State = {
    showDataGroupsDialog?: boolean,
}

/**
 * Renders the settings toolbar for the DataGridWidget
 */
class DataGridSettingsToolbar extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = { showDataGroupsDialog: false }
    }

    showDataGroupsDialog = () => {
        this.setState({ showDataGroupsDialog: true })
    }

    render() {
        return (
            <React.Fragment>
                <Row justify="end" style={{ marginBottom: 6 }}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button onClick={this.showDataGroupsDialog}>Configure Columns</Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default withWidgetContext(DataGridSettingsToolbar)
