import moment, { Moment } from 'moment'
import accounting from 'accounting-js'
import { NOT_AVAILABLE } from 'Constants'

// default format settings (supposed to be in EUR)
accounting.settings = {
    currency: {
        symbol: '€',   // default currency symbol is '$'
        format: '%v %s', // controls output: %s = symbol, %v = value/number (can be object: see below)
        decimal: ',',  // decimal point separator
        thousand: '.',  // thousands separator
        precision: 2,   // decimal places
    },

    number: {
        thousand: '.',
        decimal: ',',
        precision: 2,
    },
}

const formatter = {

    settings: (): any => {
        return accounting.settings
    },

    setup: (settings: any) => {
        accounting.settings = settings
    },

    formatNumber: (round: number, num: number): string => {
        if (num === undefined) return ''

        return accounting.formatNumber(num, Object.assign(accounting.settings.number, { precision: round }))
    },

    parseCurrency: (text: string | null | undefined): number => {
        if (!text) return 0

        return accounting.unformat(text, ',', accounting.settings.currency.decimal)
    },

    formatCurrency: (amount: number | string | null | undefined) => {

        let num = 0

        if (typeof amount === 'string' && amount) {
            num = Number(amount)
        } else if (typeof amount === 'number') {
            num = amount
        }

        return accounting.formatMoney(num, accounting.settings.currency)
    },

    applyPostfix: (value: number | string | null | undefined, postfix: string) =>
        (value === null || value === undefined) ? '' : `${value}${postfix}`,

    parseDate: (text: string, pattern: string = 'YYYY-MM-DD'): Moment | null => {
        if (!text) return null

        return moment(text, pattern)
    },

    formatDate: (iso8601String: string | null | undefined, pattern: string = 'YYYY-MM-DD') => {
        if (!iso8601String) return ''
        if(iso8601String === NOT_AVAILABLE) return iso8601String

        const startDate = moment(iso8601String)
        return startDate.format(pattern)
    },

    formatDateTime: (iso8601String: string | null | undefined, pattern: string = 'YYYY-MM-DD HH:mm:ss') => {
        if (!iso8601String) return ''
        if(iso8601String === NOT_AVAILABLE) return iso8601String

        const startDate = moment(iso8601String)
        return startDate.format(pattern)
    },
}

export default formatter
