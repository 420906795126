import React from 'react'
import { ActionDTO, ActionIdentifier, ToolbarButtonDTO, ToolbarConfigDTO } from 'domain/types'
import ToolbarButtonComponent from 'shared/component/ToolbarButtonComponent'
import { ToolbarButton } from 'domain/types/backend/layout.types'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd'

type Props = {
    config: ToolbarConfigDTO
    actions: ActionDTO[]
    onInvoke?: (action: ActionDTO) => void
    selectedRows?: { [key: string]: number }
    showDownload?: boolean
    disableButtons?: boolean
    downloadProcessing?: boolean
    onDownload?: () => void
}

export const ToolbarComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {

    const invokeAction = (action: ActionDTO) => {
        const { onInvoke } = props
        onInvoke(action)
    }

    const createToolbarButtonComponent = (button: ToolbarButtonDTO, index: number, disabled: boolean) => {
        const { actions, selectedRows } = props
        const actionTypes = Object.keys(selectedRows)

        const action = actions.find(action => action.identifier === button.actionIdentifier)
        if (!action) {
            throw new Error('No action was found for the button actionIdentifier ' + button.actionIdentifier)
        }

        const toolbarButton = { toolbarButtonDTO: button, action: action } as ToolbarButton

        const rowCount = actionTypes.indexOf(toolbarButton.toolbarButtonDTO.actionIdentifier) == -1
            ? action.selectionConfig.minSelected
            : selectedRows[toolbarButton.toolbarButtonDTO.actionIdentifier]

        return <ToolbarButtonComponent disabled={disabled} button={toolbarButton} onInvoke={invokeAction} selectedRows={rowCount} key={index}/>
    }

    const buttons = props.config?.buttons || []
    const { onDownload, disableButtons, downloadProcessing } = props

    const configuredButtons = buttons.map((button, index) => {
        if (button.actionIdentifier == ActionIdentifier.DOWNLOAD) {
            return <div className={'toolbar-button-wrapper default-wrapper'} aria-label="Download" key={buttons.length}>
                <Button className="toolbar-button" type="default" htmlType="button"
                        onClick={onDownload} disabled={disableButtons || downloadProcessing}>
                    {downloadProcessing ? <LoadingOutlined/> : <DownloadOutlined/>}
                    <span className="toolbar-button-text">Download</span>
                </Button>
            </div>
        } else {
            return createToolbarButtonComponent(button, index, props.disableButtons)
        }
    })

    return <div className="toolbar action-toolbar">{configuredButtons}</div>
})
