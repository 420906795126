import * as React from 'react'
import { SystemService } from 'domain/core/service'
import { Polling } from 'shared/component'
import { PingDTO } from 'domain/types'

/**
 * Introduces a polling mechanism which keeps the backend session alive (see UI-79).
 */
export class BackendSessionKeepAlive extends React.Component<{}, {}> {

    render() {
        return (
            <Polling
                ms={55000}
                initialDelay={55000}
                api={() => SystemService.ping()}
                onData={(pingDto: PingDTO) => {
                    if (!pingDto.successful) {
                        SystemService.reloadPage()
                    }
                }}
                onError={ error => {
                    if (error.httpStatus && error.httpStatus === 'UNAUTHORIZED') {
                        SystemService.reloadPage()
                    }
                }}
            />
        )
    }
}
