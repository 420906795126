import NumberUtil from 'shared/util/NumberUtil'

/**
 * TODO ATTENTION THIS COMPONENT IS WORK IN PROGRESS
 * results are currently not correct!
 */
/**
 * @param value The actual value that should be represented via a spark bar (=tiny, inline, simple bar)
 * @param allValues All values, including the actual value; necessary to understand the overall context such as min and max value so that
 * bars can be scaled accordingly
 */
const getSparkBar = (value: number, allValues: number[]): string => {
    // min value to decide if we need to support negative bars (layout will be slightly different if yes)
    const minValue = NumberUtil.getMinValue(allValues)
    const hasNegativeValues = minValue < 0

    // max values to decide scale of current bar; max value = 100% width (or 50% width if negative values exist)
    const maxValue = NumberUtil.getMaxValue(allValues.map(value => Math.abs(value)))

    const className = hasNegativeValues ? 'positive-and-negative-values' : 'positive-values-only'
    let widthPercentage = (Math.abs(value) / maxValue) * 100
    if (hasNegativeValues) {
        // if negative bars need to be rendered too then we need twice the space so bars will be only half as long
        widthPercentage = widthPercentage / 2
    }

    const bar = `<div class="bar bar-${value < 0 ? 'negative' : 'positive'}" style="width:${widthPercentage}%"></div>`
    return `<div class="spark-bar ${className}">
                ${bar}
            </div>`
}


const SparkBarGenerator = {
    getSparkBar: getSparkBar,
}

export default SparkBarGenerator
