import * as React from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import { WidgetSettingsDTO } from 'domain/types/backend/widget.types'

export type WidgetContextProps<T extends WidgetSettingsDTO> = {
    settings: T
}

const InternalWidgetContext = React.createContext({})

/**
 * This class was renamed to "Legacy..." because our goal is to merge this Context together with the new WidgetContext
 * when the data grid has been changed so that it can work like other dashboard widgets.
 * We didn't want to introduce too many changes at once so for the time being we have two "WidgetContext" classes.
 *
 * Every widget comes with its own context of widget settings. withWidgetContext can be used to wrap
 * React components and inject the current WidgetContext. E.g. if there is a React component deep
 * down the component tree it can still access the current widget context.
 *
 * This class provides methods for modifying the widget context too. However, this class is seen as
 * immutable, changes will never have an effect on particular instances of this class but will create
 * new instances.
 *
 * @see domain/context/withWidgetContext
 */
class LegacyWidgetContext<T extends WidgetSettingsDTO> {

    settings: T
    update?: (widgetContext: LegacyWidgetContext<T>) => void

    static Consumer: any
    static Provider: any

    constructor(settings: T, update?: (widgetContext: LegacyWidgetContext<T>) => void) {
        this.settings = settings
        this.update = update
    }

    setKpis = (kpis: Array<string>) => {
        const settings = _cloneDeep(this.settings)
        settings.kpis = kpis

        if (this.update) {
            this.update(new LegacyWidgetContext(settings, this.update))
        }
    }
}

LegacyWidgetContext.Consumer = InternalWidgetContext.Consumer
LegacyWidgetContext.Provider = InternalWidgetContext.Provider

export default LegacyWidgetContext
