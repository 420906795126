import { BarSeriesOption, LineSeriesOption, SeriesOption } from 'echarts'
import { ComputationType, MetricDTO } from 'domain/types'
import { EChartsOption } from 'echarts-for-react/src/types'
import { BarDirection, TopNBarWidgetSettingsDTO, TopNWidgetType } from 'domain/types/backend/widget.types'
import MetricUtil from 'domain/widget/MetricUtil'

const BarWidgetSettingsUtil = {

    /**
     * Generate bar series options based on widget settings and metric dto
     *
     * @param seriesUiSettings
     * @param widgetSettings
     * @param metricDTO - used to format label values
     */
    generateSeriesOptions: (seriesUiSettings: BarSeriesOption | LineSeriesOption, widgetSettings: TopNBarWidgetSettingsDTO, metricDTO: MetricDTO): any => {
        const result = {
            ...seriesUiSettings,
            type: widgetSettings.topNWidgetType ? widgetSettings.topNWidgetType.toLowerCase() : TopNWidgetType.BAR.toLowerCase()
        }
        if (widgetSettings.stacked) {
            result.stack = 'total'
        }

        if (widgetSettings.showValues) {
            result.label = {
                ...(result.label ? result.label : {}),
                show: true,
                formatter: (labelParams: any): string => {
                    return MetricUtil.metricValueFormatter(metricDTO, true)(labelParams.value)
                },
                // @ts-ignore
                position: BarWidgetSettingsUtil.getBarsLabelPosition(widgetSettings)
            }
        }

        return result
    },

    /**
     * Get bars label value position based on bar direction and stacked configs
     *
     * @param widgetSettings
     */
    getBarsLabelPosition: (widgetSettings: TopNBarWidgetSettingsDTO): string => {
        if (widgetSettings.barDirection === BarDirection.VERTICAL) {
            return widgetSettings.stacked ? 'right' : 'top'
        } else {
            return widgetSettings.stacked ? 'bottom' : 'right'
        }
    },

    /**
     * Highlights the x axis to make it more easily distinguishable (bolder) from other grid lines
     *
     * @param echartsOption
     */
    highlightXAxisLine: (echartsOption: EChartsOption): EChartsOption => {
        return {
            ...echartsOption,
            xAxis: {
                ...echartsOption.xAxis,
                axisLine: {
                   ...(echartsOption.xAxis.axisLine ? echartsOption.xAxis.axisLine : {}),
                    lineStyle: {
                        width: 2,
                        type: 'solid',
                        color: '#6E7079',
                    }
                }
            }
        }
    },

    /**
     * Hide metric axis labels for metrics with average computation type and stacked mode
     *
     * @param barSeriesUiSettings
     * @param stacked
     * @param metricDTO
     */
    processMetricLabels: (barSeriesUiSettings: EChartsOption, stacked: boolean, metricDTO: MetricDTO): EChartsOption => {
        if (stacked && metricDTO.computationType == ComputationType.CT_AVERAGE) {
            return {
                ...barSeriesUiSettings,
                yAxis: {
                    ...barSeriesUiSettings.yAxis,
                    axisLabel: {
                        ...barSeriesUiSettings.yAxis.axisLabel,
                        show: false
                    }
                }
            }
        } else {
            return barSeriesUiSettings
        }
    }
}

export default BarWidgetSettingsUtil
