import React, { useContext, useEffect, useState } from 'react'
import { EChartsOption } from 'echarts-for-react/src/types'
import { ContainerSizeContext } from 'domain/widget/ContainerSizeContext'
import CustomEChart from 'domain/widget/CustomEChart'
import { log } from 'shared/util/log'
import { LoadResponseDTO } from 'domain/types'
import { Widget } from 'domain/widget/Widget'
import { Button, Tooltip } from 'antd'
import { WidgetContext } from 'domain/widget/WidgetContext'
import ResponsiveWidgetSettingsUtil from 'domain/widget/ResponsiveWidgetSettingsUtil'
import { Size } from 'domain/types/frontend/dimension.types'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { ResponsiveWidgetSettings } from 'domain/types/backend/widget.types'

type Props = {
    isLoading: boolean,
    hasLegend: boolean,
    response?: LoadResponseDTO,
    widgetSettingsPanel?: React.ReactNode,
    echartsOption: EChartsOption,
    // optional additional logic to adjust layout after resize
    onContainerResize?: (responsiveWidgetSettings: ResponsiveWidgetSettings, containerSize: Size) => ResponsiveWidgetSettings,
}

/**
 * This component handles generic resize logic for all echarts
 *
 * @param props
 * @constructor
 */
const EChartWidget = (props: Props) => {

    const [echartsOption, setEchartsOption] = useState<EChartsOption>({})
    const [isLegendExpanded, setIsLegendExpanded] = useState(false)
    const containerSizeContext = useContext(ContainerSizeContext)
    const widgetContext = useContext(WidgetContext)

    const applyResponsiveWidgetSettings = (): void => {
        // execute general [onContainerResize] logic
        const responsiveWidgetSettings: ResponsiveWidgetSettings =
            props.echartsOption
                ? onContainerResize(props.echartsOption, containerSizeContext.containerSize)
                : props.echartsOption

        // execute custom widget [props.onContainerResize] logic, if available
        const customizedResponsiveWidgetSettings = props.onContainerResize && responsiveWidgetSettings
            ? props.onContainerResize(responsiveWidgetSettings, containerSizeContext.containerSize)
            : responsiveWidgetSettings

        setEchartsOption(customizedResponsiveWidgetSettings.echartsOption)
    }

    useEffect(() => {
        applyResponsiveWidgetSettings()
    }, [props.echartsOption, containerSizeContext.containerSize])

    useEffect(() => {
        applyResponsiveWidgetSettings()
    }, [isLegendExpanded])

    /**
     * Callback for adjusting general echarts configs after container resize to make widget responsive
     *
     * @param echartsOption
     * @param containerSize
     */
    const onContainerResize = (echartsOption: EChartsOption, containerSize: Size): ResponsiveWidgetSettings => {
        log.debug(`width: ${containerSizeContext.containerSize.width}, height: ${containerSizeContext.containerSize.height}`)
        const responsiveWidgetSettings =
            ResponsiveWidgetSettingsUtil.getResponsiveWidgetSettings(containerSize, echartsOption, isLegendExpanded, props.hasLegend)

        widgetContext.updateIsLegendButtonVisible(responsiveWidgetSettings.isLegendButtonVisible)

        return responsiveWidgetSettings
    }

    const toggleLegend = () => {
        setIsLegendExpanded(prev => !prev)
    }

    const chart =
        echartsOption
            ? <CustomEChart echartsOption={echartsOption} theme={widgetContext.widgetSettings?.theme}/>
            : <div/>

    return <Widget isLoading={props.isLoading} response={props.response}>
        {props.widgetSettingsPanel}
        <Tooltip title={isLegendExpanded ? 'Collapse Legend' : 'Expand Legend'} placement="bottomRight">
            {props.hasLegend && <Button hidden={!widgetContext.isLegendButtonVisible} onClick={toggleLegend} size={'large'} type={'text'} icon={isLegendExpanded ? <UpCircleOutlined/> : <DownCircleOutlined/>}
                                        style={{ position: 'absolute', right: 0, top: 2, zIndex: 5, background: '#fff' }}/>}
        </Tooltip>
        {chart}
    </Widget>
}

export default EChartWidget
