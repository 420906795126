import React, { ReactElement, ReactNode, useContext, useState } from 'react'
import { ReportingDataSetDTO, GridDataRowDTO } from 'domain/types'
import { v1 as uuid } from 'uuid'
import _range from 'lodash/range'
import _cloneDeep from 'lodash/cloneDeep'
import _includes from 'lodash/includes'
import { ACTIONS_FIELD, SELECTED_FIELD } from 'domain/datagrid/component/DataGrid'
import { getAllSelectedRows } from 'domain/widget/generic/SelectRowsUtil'

export type DataGridContextProperties = {
    children?: ReactNode,
    getRows?: () => ReportingDataSetDTO,
    updateRows?: (newRows: ReportingDataSetDTO) => void,
    getSelectedRowIndices?: () => number[],
    updateSelectedRowIndices?: (newSelectedRowIndices: number[]) => void,

    resetSelectedRowIndices?: () => void,

    onSelect?: (selectedRow: number, shiftKey: boolean) => void
    toggleAllHeader?: () => JSX.Element,
    onToggleAll?: () => void
}
export const DataGridContext = React.createContext<DataGridContextProperties>({})

export const DataGridContextProvider = (props: DataGridContextProperties) => {
    const [rows, setRows] = useState(props.getRows ? props.getRows() as ReportingDataSetDTO : undefined)
    const [selectedRowIndices, setSelectedRowIndices] = useState([] as number[])

    const onToggleAll = () => {
        let newSelectedRows = []
        if (selectedRowIndices.length) {
            if (selectedRowIndices.length < rows.rows.length) {
                newSelectedRows = _range(rows.rows.length)
            }
        } else {
            newSelectedRows = _range(rows.rows.length)
        }

        const newRows: ReportingDataSetDTO = _cloneDeep(rows)
        newRows.rows.map((row: GridDataRowDTO, index) => {
            row[SELECTED_FIELD] = {value: !!_includes(newSelectedRows, index)}
            if (row[ACTIONS_FIELD].data) {
                row[ACTIONS_FIELD].data = rows.rows[index][ACTIONS_FIELD].data
            }
            return row
        })

        setRows(newRows)
        setSelectedRowIndices(newSelectedRows)
    }

    const onSelect = (selectedIndices: number[]) => {
        if (rows) {
            const rowsCopy = {
                ...rows,
                rows: [...rows.rows],
            } as ReportingDataSetDTO

            rowsCopy.rows.forEach((_, index) => {
                rowsCopy.rows[index] = { ...rows.rows[index] }
                rowsCopy.rows[index][SELECTED_FIELD] = {value: !!_includes(selectedIndices, index)}
            })

            setRows(rowsCopy)
            setSelectedRowIndices(selectedIndices)
        }
    }

    return (
        <DataGridContext.Provider
            value={{
                getRows: () => {
                    return rows
                },
                updateRows: (newRows: ReportingDataSetDTO) => {
                    setRows(newRows)
                },
                getSelectedRowIndices: () => selectedRowIndices,
                updateSelectedRowIndices: (newSelectedRowIndices: number[]) => {
                    setSelectedRowIndices(newSelectedRowIndices)
                },

                toggleAllHeader: (): ReactElement => {
                    const allRowsSelected = selectedRowIndices.length == rows?.rows.length
                    const title = allRowsSelected ? 'Select None' : 'Select All'
                    const className = allRowsSelected ? 'select-none' : 'select-all'

                    return <span data-tip={title} key={uuid()} onClick={onToggleAll} aria-label={title}
                              className={'icon toggle-select ' + className}>&nbsp;</span>
                },
                onSelect: (selectedRow: number, shiftKey: boolean = false) => {
                    const selectedRowsCopy = getAllSelectedRows(selectedRowIndices, selectedRow, shiftKey)

                    onSelect(selectedRowsCopy)
                },
                resetSelectedRowIndices: () => {
                    onSelect([])
                },
                onToggleAll: onToggleAll,
            }}>
            {props.children}
        </DataGridContext.Provider>
    )
}

export const useDataGridContext = (): DataGridContextProperties => {
    const context = useContext(DataGridContext)
    if (!context) {
        throw new Error('Missing DataGridContext in its parent.')
    }

    return context
}
