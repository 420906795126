import React from 'react'
import { Form, Input } from 'antd'
import { FormElementProperties } from 'domain/types'
import { formItemLayout } from 'shared/component/layout/FormElement'
import { formValidator } from 'shared/util/validation'
import { ElementSetting, useRootElementContext } from 'shared/component/layout/context/RootElementContext'

const FormInputElement: React.FC<FormElementProperties> = ({ onChangeListener, layoutElementConfig, label, editable }: FormElementProperties): JSX.Element => {
    const columnName: string = layoutElementConfig.formFieldConfig.dimensionIdentifier
    const validation = formValidator(layoutElementConfig.formFieldConfig.validation)
    const { updateElementSettings } = useRootElementContext()
    const identifier = layoutElementConfig.formFieldConfig.dimensionIdentifier

    /**
     * onChange handler, that updates context settings for the element if the element has  useAsSetting=true
     *
     * @param event
     */
    const onChange = (event) => {
        const value = event.target.value

        if (layoutElementConfig.useAsSetting) {
            updateElementSettings({ key: identifier, value: value } as ElementSetting)
        }

        if (onChangeListener) {
            onChangeListener(value)
        }
    }


    return <Form.Item className={'form-item-input'} {...formItemLayout} label={label} extra={layoutElementConfig.formFieldConfig.infoText} name={columnName} rules={editable ? validation : []}>
        <Input onChange={onChange} className={'filter-input ' + layoutElementConfig.formFieldConfig?.dimensionIdentifier} disabled={!editable}/>
    </Form.Item>
}

export default FormInputElement
