import React from 'react'
import numbro from 'numbro'
import ReactEcharts from 'echarts-for-react'
import { ReportingDataSetDTO } from 'domain/types'

/**
 * NOTE: this chart should be made more generic in the future and probably be turned into a global component instead; but I wanted to start as simple as  possible...
 */

type Props = {
    efficiencyData?: ReportingDataSetDTO, // TODO is there a better type than any?
    historicPerformanceData?: any, // TODO is there a better type than any?
    recommendedScenarioData?: any, // TODO is there a better type than any?
}

/**
 * Renders a line chart based on the given configuration
 */
export class EfficiencyChart extends React.Component<Props, {}> {

    render() {
        const { efficiencyData, historicPerformanceData, recommendedScenarioData } = this.props

        const highReliabilityZoneStart = efficiencyData.rows[0].high_reliability_zone_start.value
        const highReliabilityZoneEnd = efficiencyData.rows[0].high_reliability_zone_end.value

        const historicCosts = (historicPerformanceData && historicPerformanceData.data) ? historicPerformanceData.data.adspend_costs?.value : null
        const historicConversions = (historicPerformanceData && historicPerformanceData.data) ? historicPerformanceData.data.adspend_conversions?.value : null
        const recommendedCosts = (recommendedScenarioData && recommendedScenarioData.data) ? recommendedScenarioData.data.adspend_costs?.value : null
        const recommendedConversions = (recommendedScenarioData && recommendedScenarioData.data) ? recommendedScenarioData.data.adspend_conversions?.value : null

        const blue = '#2D9CDB'
        const grey = '#999'
        const areaOpacity = 0.15
        const colors = [grey, blue, grey]

        // mark points
        const markPointData = []

        if (typeof historicCosts == 'number' && typeof historicConversions == 'number') {
            markPointData.push({
                value: 'Historic',
                xAxis: historicCosts,
                yAxis: historicConversions,
                itemStyle: {
                    color: '#00994b',
                    borderColor: '#00773c',
                },
            })
        }

        if (typeof recommendedCosts == 'number' && typeof recommendedConversions == 'number') {
            markPointData.push({
                value: 'Recommended',
                xAxis: recommendedCosts,
                yAxis: recommendedConversions,
                itemStyle: {
                    color: '#44d390',
                    borderColor: '#00bb75',
                },
            })
        }

        // we create 3 series; first and last is for low prediction reliability, middle one is for high prediction reliability
        const seriesData = [
            [], [], [],
        ]

        let dataPointLeftFromHistoric = null
        let dataPointRightFromHistoric = null
        let dataPointLeftFromRecommended = null
        let dataPointRightFromRecommended = null

        efficiencyData.rows.forEach(item => {
            const row = [item.adspend_costs.value, item.adspend_conversions.value]

            if (item.adspend_costs.value < highReliabilityZoneStart) {
                seriesData[0].push(row)
            } else if (item.adspend_costs.value >= highReliabilityZoneStart && item.adspend_costs.value <= highReliabilityZoneEnd) {
                seriesData[1].push(row)

                // connect zones by putting border data point in both zones
                if (item.adspend_costs.value == highReliabilityZoneStart) {
                    seriesData[0].push(row)
                } else if (item.adspend_costs.value == highReliabilityZoneEnd) {
                    seriesData[2].push(row)
                }
            } else {
                seriesData[2].push(row)
            }

            if (typeof historicCosts == 'number' && !dataPointRightFromHistoric) {
                if (row [0] >= historicCosts) {
                    dataPointRightFromHistoric = row
                    if (row [0] === historicCosts) { // if exact match then only display a single tooltip
                        dataPointLeftFromHistoric = null
                    }
                } else {
                    dataPointLeftFromHistoric = row
                }
            }

            if (typeof recommendedCosts == 'number' && !dataPointRightFromRecommended) {
                if (row [0] >= recommendedCosts) {
                    dataPointRightFromRecommended = row
                    if (row [0] === historicCosts) { // if exact match then only display a single tooltip
                        dataPointLeftFromRecommended = null
                    }
                } else {
                    dataPointLeftFromRecommended = row
                }
            }
        })

        const series = [0, 1, 2].map(i => {
            return {
                type: 'line',
                areaStyle: {
                    color: colors[i],
                    opacity: areaOpacity,
                },
                lineStyle: { color: colors[i] },
                showSymbol: false,
                hoverAnimation: false,
                data: seriesData[i],
                markPoint: {
                    label: { show: false },
                    symbol: 'circle',
                    symbolSize: 15,
                    itemStyle: { borderWidth: 1 },
                    data: markPointData,
                },
            }
        })

        const costsFormat = { thousandSeparated: true, mantissa: 0, postfix: ' €' }
        const conversionsFormat = { thousandSeparated: true, mantissa: 0 }

        const option = {
            grid: [{
                left: 50,
                right: 10,
                top: 20,
                bottom: 80,
                containLabel: false,
            }],
            axisPointer: {
                type: 'shadow',
                snap: false,
            },
            tooltip: {
                trigger: 'axis',
                formatter: function(params, ticket, callback) {
                    let infoText = `Budget: ${numbro(params[0].value[0]).format(costsFormat)}`
                    infoText += `<br>Conversions: ${numbro(params[0].value[1]).format(conversionsFormat)}`

                    if (dataPointLeftFromHistoric && params[0].value[0] === dataPointLeftFromHistoric[0] ||
                        dataPointRightFromHistoric && params[0].value[0] === dataPointRightFromHistoric[0]) {
                        infoText += `<br><br>Historic Budget: ${numbro(historicCosts).format(costsFormat)}`
                        infoText += `<br>Conversions: ${numbro(historicConversions).format(conversionsFormat)}`
                    }

                    if (dataPointLeftFromRecommended && params[0].value[0] === dataPointLeftFromRecommended[0] ||
                        dataPointRightFromRecommended && params[0].value[0] === dataPointRightFromRecommended[0]) {
                        infoText += `<br><br>Recommended Budget: ${numbro(recommendedCosts).format(costsFormat)}`
                        infoText += `<br>Conversions: ${numbro(recommendedConversions).format(conversionsFormat)}`
                    }

                    return `<div class="efficiency-tooltip">${infoText}</div>`
                },
            },
            xAxis: [{
                type: 'value',
                splitLine: { show: true },
                boundaryGap: false,
                splitNumber: 8,
                max: 'dataMax',
                axisLabel: {
                    rotate: 90,
                    showMinLabel: false,
                    showMaxLabel: false,
                    formatter: '{value} €',
                },
            }],
            yAxis: [{
                type: 'value',
                splitLine: { show: true },
                axisLabel: {
                    showMinLabel: false,
                },
            }],
            series: series,
            color: colors,
        }

        return (
            <ReactEcharts
                style={{ height: 250 }}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                theme={'exactag'}
                opts={{ renderer: 'svg' }}/>
        )
    }
}
