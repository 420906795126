import { Button, Checkbox, Form } from 'antd'
import React, { useRef, useState } from 'react'
import { OtpFormResponseDTO } from 'domain/types/backend/mfa.types'
import AuthCode, { AuthCodeRef } from 'react-auth-code-input'
import { CheckOutlined } from '@ant-design/icons'
import Icon from '@ant-design/icons'

type Props = {
    codeCheck: (string, boolean) => Promise<OtpFormResponseDTO>,
    rememberMeDays: number,
    onSuccess: () => void,
    onError: () => void,
}

const OtpCode: (props: Props) => JSX.Element = (props: Props): JSX.Element => {

    const [form] = Form.useForm()
    const authCodeRef = useRef<AuthCodeRef>(null)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const onFinish = (values) => {
        setIsSubmitting(true)
        props?.codeCheck && props.codeCheck(values.code, values.rememberMe)
            .then((otpFormResponse: OtpFormResponseDTO) => {
                if (otpFormResponse.successful) {
                    props?.onSuccess()
                } else {
                    props?.onError()
                    form.setFieldsValue({code: ''})
                    authCodeRef.current?.clear()
                }
            })
            .finally(() => {
                setIsSubmitting(false)
                authCodeRef.current?.focus()
            })
    }

    return <Form form={form} onFinish={onFinish}>
        <Form.Item name="code" className={'otp-code'}>
            {/* @ts-ignore */}
            <AuthCode
                ref={authCodeRef}
                containerClassName={'otp-code-container'}
                inputClassName={'otp-code-input'}
                onChange={()=>{return}}
                allowedCharacters='numeric'
                autoFocus={true}
                length={6}
                disabled={isSubmitting}
            />
        </Form.Item>

        <Form.Item shouldUpdate>
            {() => (
                <Button style={{ width: '180px' }} type="primary" htmlType="submit"
                        icon={<CheckOutlined/>}
                        loading={isSubmitting}
                        size={'large'}
                        disabled={
                            !form.isFieldsTouched(['code']) ||
                            form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                        }>
                    <Icon type={'checked'}/> Verify Code
                </Button>
            )}
        </Form.Item>

        {props?.rememberMeDays > 0 &&
            <Form.Item name={'rememberMe'} valuePropName={'checked'} initialValue={true}>
                <Checkbox>Don't ask again for {props?.rememberMeDays} {props?.rememberMeDays == 1 ? 'day' : 'days'}</Checkbox>
            </Form.Item>
        }
    </Form>
}

export default OtpCode
