import React, { useEffect, useState } from 'react'
import { log } from 'shared/util/log'
import { Table, TableProps } from 'antd'
import { v1 as uuid } from 'uuid'
import { debounce } from 'throttle-debounce'

/**
 * This component is called "AutoHeightTable" since it is able to set the height of the Ant Table automatically, based on the available
 * space. Ant Tables would usually require a fixed height to be defined to make scrolling work.
 */
export const AutoHeightTable: React.FC<TableProps<any>> = React.memo((props: TableProps<any>): JSX.Element => {

    // defined in datagrid.less (hardcoded here to keep things simple)
    const TABLE_HEADER_HEIGHT = 51

    /**
     * Computes the maximal possible height for the grid body. We do unfortunately need some JS logic here because the Ant Table needs specific
     * x/y values for certain features related to scrolling to work correctly. That is why we layout the parent elements via pure CSS as far as
     * possible but the table itself receives a specific height & width via JS. Note that these dimensions have to be updated when e.g. the
     * window is resized.
     * @param windowObject
     */
    const updateGridDimensions = () => {
        const gridWrapperEl = document.getElementById(gridWrapperId)
        if (!gridWrapperEl) {
            log.debug('updateGridDimensions: found no el with gridWrapperId ', gridWrapperId)
            setGridDimensions(
                { height: 0, width: 0 },
            )
            return
        }

        const height = gridWrapperEl.offsetHeight - TABLE_HEADER_HEIGHT - 10
        const width = gridWrapperEl.offsetWidth
        if (width == 0) {
            // there must have been some issue that prevented us from calculating the correct dimensions
            // let's not update the dimensions in order not to hide the grid completely (better a grid with incorrect
            // dimensions than no grid at all);
            return
        }
        log.debug('updateGridDimensions: new dimensions are ', { gridWrapperId: gridWrapperId, height: height, width: width })
        setGridDimensions(
            { height: height, width: width },
        )
    }

    const handleResize = () => {
        setTimeout(() => updateGridDimensions(), 100)
    }

    // Dimensions of the grid body (grid header height is fixed and will be subtracted from the available total height)
    const [gridDimensions, setGridDimensions] = useState({ height: 0, width: 0 })
    const [gridWrapperId] = useState('grid-' + uuid())

    useEffect(() => {
        window.addEventListener('resize', debounce(100, handleResize))
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // we need to manually calculate the sum of the width of all columns so that we can apply it to the table config; this is unfortunately
    // necessary to make the scroll behaviour work correctly
    let totalWidth: number = 0
    props.columns.forEach(column => {
        let columnWidth = 150
        try {
            if (typeof column.width == 'string' && column.width.endsWith('px')) {
                columnWidth = parseFloat(column.width.substr(0, column.width.length - 2))
            }
        } catch (e) {
            log.error(e)
        }
        totalWidth += columnWidth
    })

    const hasHorizontalScrollbar = totalWidth > gridDimensions.width

    return (
        <div className={'auto-height-table' + (hasHorizontalScrollbar ? ' has-horizonal-scrollbar' : '')} id={gridWrapperId}>
            <Table
                {...props}
                tableLayout={'fixed'}
                scroll={{ x: totalWidth, y: gridDimensions.height }}
                sticky
                style={{
                    'width': gridDimensions.width + 'px',
                    // @ts-ignore
                    '--table-content-height': gridDimensions.height + 'px',
                }}
                onHeaderRow={() => {
                    return {
                        className: 'ant-table-thead',
                    }
                }}
            />
        </div>
    )
})
