import React from 'react'

type ReactHintProps = {
    attribute: string,
    autoPosition: boolean,
    className: string,
    additionalClassNames: string,
    delay: number,
    events: boolean,
    onRenderContent?: any,
    persist: boolean,
    position: string,
}

type ReactHintState = {
    target?: any,
    content?: any,
    at?: any,
    top?: any,
    left?: any,
}

const isEllipsisActive = (element) => {
    return element && element.offsetWidth < element.scrollWidth
}

/**
 * Tooltip fork of https://github.com/slmgc/react-hint/blob/master/src/index.js
 */
export class ReactHint extends React.Component<ReactHintProps, ReactHintState> {
    static defaultProps = {
        attribute: 'data-tip',
        autoPosition: true,
        className: 'react-hint',
        additionalClassNames: '',
        delay: 0,
        events: true,
        onRenderContent: (target, content) => {
            const forceTooltip = target.getAttribute('data-force-tooltip') === 'true'

            return (forceTooltip || isEllipsisActive(target.parentElement))
                && <div className={'react-hint__content'}>{content}</div>
        },
        persist: false,
        position: 'top',
    }

    constructor(props) {
        super(props)

        this.state = { target: null } as ReactHintState
    }

    _hint = null
    _container = null
    _timeout = null

    componentDidMount() {
        this.toggleEvents(this.props, true)
    }

    componentWillUnmount() {
        this.toggleEvents(this.props, false)
        clearTimeout(this._timeout)
    }

    toggleEvents = ({ events }: ReactHintProps, flag) => {
        const action = flag ? 'addEventListener' : 'removeEventListener'
        events && document[action]('mouseover', this.toggleHint)
    }

    toggleHint = ({ target = null } = {}) => {
        target = this.getHint(target)
        clearTimeout(this._timeout)
        this._timeout = setTimeout(() => this.setState(() => ({ target })), this.props.delay)
    }

    getHint = (el) => {
        const { attribute, persist } = this.props
        const { target } = this.state

        while (el) {
            if (el === document) break
            if (persist && el === this._hint) return target
            if (el.hasAttribute(attribute)) return el
            el = el.parentNode
        }
        return null
    }

    shouldComponentUpdate(props, state) {
        return !this.shallowEqual(state, this.state) ||
            !this.shallowEqual(props, this.props)
    }

    shallowEqual = (a, b) => {
        const keys = Object.keys(a)
        return keys.length === Object.keys(b).length &&
            keys.reduce((result, key) => result &&
                ((typeof a[key] === 'function' &&
                    typeof b[key] === 'function') ||
                    a[key] === b[key]), true)
    }

    componentDidUpdate() {
        if (this.state.target) this.setState(this.getHintData)
    }

    getHintData = ({ target }, { attribute, autoPosition, position }) => {

        if (!this._hint) {
            return { target: null }
        }

        const content = target.getAttribute(attribute) || ''
        let at = target.getAttribute(`${attribute}-at`) || position

        const {
            top: containerTop,
            left: containerLeft,
        } = this._container.getBoundingClientRect()

        const {
            width: hintWidth,
            height: hintHeight,
        } = this._hint.getBoundingClientRect()

        const {
            top: targetTop,
            left: targetLeft,
            width: targetWidth,
            height: targetHeight,
        } = target.getBoundingClientRect()

        if (autoPosition) {
            const isHoriz = ['left', 'right'].includes(at)

            const {
                clientHeight,
                clientWidth,
            } = document.documentElement

            const directions = {
                left: (isHoriz
                    ? targetLeft - hintWidth
                    : targetLeft + (targetWidth - hintWidth >> 1)) > 0,
                right: (isHoriz
                    ? targetLeft + targetWidth + hintWidth
                    : targetLeft + (targetWidth + hintWidth >> 1)) < clientWidth,
                bottom: (isHoriz
                    ? targetTop + (targetHeight + hintHeight >> 1)
                    : targetTop + targetHeight + hintHeight) < clientHeight,
                top: (isHoriz
                    ? targetTop - (hintHeight >> 1)
                    : targetTop - hintHeight) > 0,
            }

            switch (at) {
                case 'left':
                    if (!directions.left) at = 'right'
                    if (!directions.top) at = 'bottom'
                    if (!directions.bottom) at = 'top'
                    break

                case 'right':
                    if (!directions.right) at = 'left'
                    if (!directions.top) at = 'bottom'
                    if (!directions.bottom) at = 'top'
                    break

                case 'bottom':
                case 'bottom-left':
                    if (!directions.bottom) at = 'top'
                    if (!directions.left) at = 'right'
                    if (!directions.right) at = 'left'
                    break

                case 'top':
                case 'top-left':
                default:
                    if (!directions.top) at = 'bottom'
                    if (!directions.left) at = 'right'
                    if (!directions.right) at = 'left'
                    break
            }
        }

        let top, left
        switch (at) {
            case 'left':
                top = targetHeight - hintHeight >> 1
                left = -hintWidth
                break
            case 'right':
                top = targetHeight - hintHeight >> 1
                left = targetWidth
                break

            case 'bottom':
                top = targetHeight
                left = targetWidth - hintWidth >> 1
                break

            case 'bottom-left':
                // top-left & bottom-left was originally not supported but makes our life easier for tooltips in the datagrid; tooltips for long texts
                // would otherwise be displayed on the far right side because the target is longer than the actually visible area in the grid cell
                top = targetHeight
                left = -10
                break
            case 'top-left':
                top = -hintHeight
                left = -10
                break
            case 'top':
            default:
                top = -hintHeight
                left = targetWidth - hintWidth >> 1
        }

        return {
            content, at,
            top: (top + targetTop - containerTop) | 0,
            left: (left + targetLeft - containerLeft) | 0,
        }
    }

    render() {
        const { className, onRenderContent, attribute, additionalClassNames } = this.props
        const { target, content, at, top, left } = this.state
        const allAdditionalClassNames = additionalClassNames + (target ? target.getAttribute(`${attribute}-classname`) || '' : '')

        const result = onRenderContent
            ? target
                ? onRenderContent(target, content)
                : null
            : <div className={`${className}__content`}>{content}</div>

        return <div ref={(ref) => this._container = ref} style={{ position: 'relative' }}>
            {target && result &&
            <div className={`${className} ${className}--${at} ${allAdditionalClassNames}`}
                 ref={(ref) => this._hint = ref}
                 role="tooltip"
                 style={{ top, left }}>
                {result}
            </div>
            }
        </div>
    }
}

export default ReactHint
