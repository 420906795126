import React from 'react'
import { ColumnResponseDTO } from 'domain/types'

/**
 * Renderer that is used to create an ui view for ColumnResponseDTO.data, that must contain a list of ColumnResponseDTO
 *
 * @param value
 * @param cssClasses
 */
export const dataRenderer = (value: ColumnResponseDTO, cssClasses: string[]): JSX.Element => {
    const classes: string = cssClasses ? (cssClasses.length ? cssClasses.join(' ') : cssClasses.toString()) : ''
    const values = value.data as ColumnResponseDTO[]
    const separator = ', '

    const result = values.length === 0
        ? ''
        : values.map((row, index) => <span key={index} className={row.cssClasses}>{row.name ?? row.value}</span>)
            // @ts-ignore
            .reduce((prev, curr) => [prev, separator, curr])

    const tooltipText = values.length === 0
        ? ''
        : values.map(row => row.name ?? row.value)
            .join(separator)

    return <span data-tip-at={'bottom-left'} data-tip-classname={'long-text-tip'} data-tip={tooltipText} data-copy-to-clipboard={true} className={classes}>{result}</span>
}
