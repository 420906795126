import * as React from 'react'
import { AppContextDTO } from 'domain/types'
import { actions } from 'domain/core/redux/appcontext.reducer'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

interface Props extends DispatchProps {
    children: React.ReactNode,
    storeAppContext: (appContext: AppContextDTO) => any,
    match: any
}

interface State {
}

class AppContextFromParams extends React.Component<Props, State> {

    advertiserId = (): number => {
        const { match } = this.props
        return Number(match.params['advertiserId'] || -1)
    }

    campaignId = (): number => {
        const { match } = this.props
        return Number(match.params['campaignId'] || -1)
    }

    advertiserName = (): string => {
        const { match } = this.props
        return match.params['advertiserName'] || ''
    }

    campaignName = (): string => {
        const { match } = this.props
        return match.params['campaignName'] || ''
    }

    componentDidMount() {
        const appContext: AppContextDTO = {
            advertiserId: this.advertiserId(),
            advertiserName: this.advertiserName(),
            campaignId: this.campaignId(),
            campaignName: this.campaignName(),
        }

        this.props.storeAppContext(appContext)
    }

    render() {
        return this.props.children
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        storeAppContext: (appContext: AppContextDTO) => actions.storeAppContext(appContext)(dispatch),
    }
}

type DispatchProps = typeof mapDispatchToProps

export default connect(null, mapDispatchToProps)(AppContextFromParams)
