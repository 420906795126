// thesebshould be identical with color_overrides.less!
export const COLOR_GRAY_1 = '#FFFFFF'
export const COLOR_GRAY_2 = '#F5F7FA'
export const COLOR_GRAY_3 = '#E4E7EA'
export const COLOR_GRAY_4 = '#CCD2D8'
export const COLOR_GRAY_5 = '#9CA5B0'
export const COLOR_GRAY_6 = '#7D8793'
export const COLOR_GRAY_7 = '#636E7B'
export const COLOR_GRAY_8 = '#384e5e'
export const COLOR_GRAY_9 = '#212932'
export const COLOR_GRAY_10 = '#000000'

export const COLOR_LIGHT_GRAY = COLOR_GRAY_2
export const COLOR_MEDIUM_GRAY = COLOR_GRAY_4
export const COLOR_DARK_GRAY = COLOR_GRAY_7
export const COLOR_ICON_GRAY = COLOR_GRAY_8
export const COLOR_TEXT_GRAY = COLOR_GRAY_9
export const COLOR_TEXT_GRAY_DISABLED = COLOR_GRAY_5
export const COLOR_TEXT_GRAY_LIGHTER = COLOR_GRAY_5
export const COLOR_TEXT_GRAY_VERY_LIGHT = COLOR_GRAY_4
export const COLOR_HEADLINE_GRAY = COLOR_GRAY_9

export const COLOR_BLUE_1 = '#E7F7FE'
export const COLOR_BLUE_2 = '#BEEAFD'
export const COLOR_BLUE_3 = '#96DCF9'
export const COLOR_BLUE_4 = '#7ACEF6'
export const COLOR_BLUE_5 = '#7ACEF6'
export const COLOR_BLUE_6 = '#2D9CDB'
export const COLOR_BLUE_7 = '#4291CE'
export const COLOR_BLUE_8 = '#387EBA'
export const COLOR_BLUE_9 = '#215384'
export const COLOR_BLUE_10 = '#113263'

export const COLOR_PRIMARY = COLOR_BLUE_6

export const CHART_LABEL_COLOR = '#212529'
export const CHART_LABEL_FONT_SIZE = 10
export const CHART_LABEL_FONT_FAMILY = 'Roboto, sans-serif'

export const exactagTheme = {
    'color': [
        '#2D9CDB',
        '#f5d76e',
        '#aed581',
        '#4dd0e1',
        '#4db6ac',
        '#81c784',
        '#e57373',
        '#f06292',
        '#ba68c8',
        '#9575cd',
        '#7986cb',
        '#dce775',
        '#ffd54f',
        '#ffb74d',
        '#ff8a65',
        '#a1887f',
        '#e0e0e0',
        '#90a4ae',
        '#17a2b8',
        '#4fc3f7',
    ],
    'backgroundColor': 'rgba(0,0,0,0)',
    'textStyle': {},
    'title': {
        'textStyle': {
            'color': '#212529',
        },
        'subtextStyle': {
            'color': '#808080',
        },
    },
    'line': {
        'itemStyle': {
            'normal': {
                'borderWidth': '1',
            },
        },
        'lineStyle': {
            'normal': {
                'width': '2',
            },
        },
        'areaStyle': {
            'color': '#2D9CDB',
            'opacity': 0.6,
        },
        'symbolSize': '4',
        'symbol': 'emptyCircle',
        'smooth': false,
    },
    'radar': {
        'itemStyle': {
            'normal': {
                'borderWidth': '1',
            },
        },
        'lineStyle': {
            'normal': {
                'width': '2',
            },
        },
        'symbolSize': '4',
        'symbol': 'emptyCircle',
        'smooth': false,
    },
    'bar': {
        'itemStyle': {
            'normal': {
                'barBorderWidth': '0',
                'barBorderColor': '#cccccc',
            },
            'emphasis': {
                'barBorderWidth': '0',
                'barBorderColor': '#cccccc',
            },
        },
    },
    'pie': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
            'emphasis': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
    },
    'scatter': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
            'emphasis': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
    },
    'boxplot': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
            'emphasis': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
    },
    'parallel': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
            'emphasis': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
    },
    'sankey': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
            'emphasis': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
    },
    'funnel': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
            'emphasis': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
    },
    'gauge': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
            'emphasis': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
    },
    'candlestick': {
        'itemStyle': {
            'normal': {
                'color': '#c12e34',
                'color0': '#2b821d',
                'borderColor': '#c12e34',
                'borderColor0': '#2b821d',
                'borderWidth': 1,
            },
        },
    },
    'graph': {
        'itemStyle': {
            'normal': {
                'borderWidth': '0',
                'borderColor': '#cccccc',
            },
        },
        'lineStyle': {
            'normal': {
                'width': 1,
                'color': '#aaaaaa',
            },
        },
        'symbolSize': '4',
        'symbol': 'emptyCircle',
        'smooth': false,
        'color': [
            '#2D9CDB',
            '#f5d76e',
            '#aed581',
            '#4dd0e1',
            '#4db6ac',
            '#81c784',
            '#e57373',
            '#f06292',
            '#ba68c8',
            '#9575cd',
            '#7986cb',
            '#dce775',
            '#ffd54f',
            '#ffb74d',
            '#ff8a65',
            '#a1887f',
            '#e0e0e0',
            '#90a4ae',
            '#17a2b8',
            '#4fc3f7',
        ],
        'label': {
            'normal': {
                'textStyle': {
                    'color': '#eeeeee',
                },
            },
        },
    },
    'map': {
        'itemStyle': {
            'normal': {
                'areaColor': '#dddddd',
                'borderColor': '#eeeeee',
                'borderWidth': 0.5,
            },
            'emphasis': {
                'areaColor': 'rgba(230,182,0,1)',
                'borderColor': '#dddddd',
                'borderWidth': 1,
            },
        },
        'label': {
            'normal': {
                'textStyle': {
                    'color': '#c12e34',
                },
            },
            'emphasis': {
                'textStyle': {
                    'color': 'rgb(193,46,52)',
                },
            },
        },
    },
    'geo': {
        'itemStyle': {
            'normal': {
                'areaColor': '#dddddd',
                'borderColor': '#eeeeee',
                'borderWidth': 0.5,
            },
            'emphasis': {
                'areaColor': 'rgba(230,182,0,1)',
                'borderColor': '#dddddd',
                'borderWidth': 1,
            },
        },
        'label': {
            'normal': {
                'textStyle': {
                    'color': '#c12e34',
                },
            },
            'emphasis': {
                'textStyle': {
                    'color': 'rgb(193,46,52)',
                },
            },
        },
    },
    'categoryAxis': {
        'axisLine': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisTick': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisLabel': {
            'show': true,
            'textStyle': {
                fontFamily: CHART_LABEL_FONT_FAMILY,
            },
            fontSize: CHART_LABEL_FONT_SIZE,
            color: CHART_LABEL_COLOR,
        },
        'splitLine': {
            'show': false,
            'lineStyle': {
                'color': [
                    '#ccc',
                ],
            },
        },
        'splitArea': {
            'show': false,
            'areaStyle': {
                'color': [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    'valueAxis': {
        'axisLine': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisTick': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisLabel': {
            'show': true,
            'textStyle': {
                fontFamily: CHART_LABEL_FONT_FAMILY,
            },
            fontSize: CHART_LABEL_FONT_SIZE,
            color: CHART_LABEL_COLOR,
        },
        'splitLine': {
            'show': true,
            'lineStyle': {
                'color': [
                    '#e9ecef',
                ],
            },
        },
        'splitArea': {
            'show': false,
            'areaStyle': {
                'color': [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    'logAxis': {
        'axisLine': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisTick': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisLabel': {
            'show': true,
            'textStyle': {
                fontFamily: CHART_LABEL_FONT_FAMILY,
            },
            fontSize: CHART_LABEL_FONT_SIZE,
            color: CHART_LABEL_COLOR,
        },
        'splitLine': {
            'show': true,
            'lineStyle': {
                'color': [
                    '#e9ecef',
                ],
            },
        },
        'splitArea': {
            'show': false,
            'areaStyle': {
                'color': [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    'timeAxis': {
        'axisLine': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisTick': {
            'show': true,
            'lineStyle': {
                'color': '#e9ecef',
            },
        },
        'axisLabel': {
            'show': true,
            'textStyle': {
                fontFamily: CHART_LABEL_FONT_FAMILY,
            },
            fontSize: CHART_LABEL_FONT_SIZE,
            color: CHART_LABEL_COLOR,
        },
        'splitLine': {
            'show': true,
            'lineStyle': {
                'color': [
                    '#333333',
                ],
            },
        },
        'splitArea': {
            'show': false,
            'areaStyle': {
                'color': [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    'toolbox': {
        'iconStyle': {
            'normal': {
                'borderColor': '#06467c',
            },
            'emphasis': {
                'borderColor': '#4187c2',
            },
        },
    },
    'legend': {
        'textStyle': {
            'color': '#333333',
        },
    },
    'tooltip': {
        'axisPointer': {
            'lineStyle': {
                'color': '#cccccc',
                'width': 1,
            },
            'crossStyle': {
                'color': '#cccccc',
                'width': 1,
            },
        },
    },
    'timeline': {
        'lineStyle': {
            'color': '#005eaa',
            'width': 1,
        },
        'itemStyle': {
            'normal': {
                'color': '#005eaa',
                'borderWidth': 1,
            },
            'emphasis': {
                'color': '#005eaa',
            },
        },
        'controlStyle': {
            'normal': {
                'color': '#005eaa',
                'borderColor': '#005eaa',
                'borderWidth': 0.5,
            },
            'emphasis': {
                'color': '#005eaa',
                'borderColor': '#005eaa',
                'borderWidth': 0.5,
            },
        },
        'checkpointStyle': {
            'color': '#005eaa',
            'borderColor': 'rgba(49,107,194,0.5)',
        },
        'label': {
            'normal': {
                'textStyle': {
                    'color': '#005eaa',
                },
            },
            'emphasis': {
                'textStyle': {
                    'color': '#005eaa',
                },
            },
        },
    },
    'visualMap': {
        'color': [
            '#1790cf',
            '#a2d4e6',
        ],
    },
    'dataZoom': {
        'backgroundColor': 'rgba(47,69,84,0)',
        'dataBackgroundColor': 'rgba(47,69,84,0.3)',
        'fillerColor': 'rgba(167,183,204,0.4)',
        'handleColor': '#a7b7cc',
        'handleSize': '100%',
        'textStyle': {
            'color': '#333333',
        },
    },
    'markPoint': {
        'label': {
            'normal': {
                'textStyle': {
                    'color': '#eeeeee',
                },
            },
            'emphasis': {
                'textStyle': {
                    'color': '#eeeeee',
                },
            },
        },
    },
}
