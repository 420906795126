import React from 'react'
import _includes from 'lodash/includes'

import { LogoutButton } from 'shared/component'
import { SystemService } from 'domain/core/service'
import { Cascader } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { AppContextDTO } from 'domain/types'
import { Dispatch } from 'redux'
import { actions } from 'domain/core/redux/appcontext.reducer'
import { actions as userActions } from 'domain/user/redux/user.reducer'
import DimensionService from 'domain/dimension/service/DimensionService'
import { AuthenticationState } from 'domain/authentication/redux/authentication.reducer'
import { RootReducer } from 'shared/redux/store'

type Props = {
    loggedInViaSecondFactor: boolean
    userName: string
    userTree?: any,
    userSettings?: any
    dispatch: Dispatch,
}

const mapTreeData = (treeData: any) => {
    const result = []
    if (treeData && Array.isArray(treeData)) {
        treeData.forEach(({ label, value, dimensionIdentifier, children }) => {
            const val = `${dimensionIdentifier}__${value}`
            const rootNode = {
                label: label,
                value: val,
                dimensionIdentifier,
                dimensionValue: value,
            }
            if (children && Array.isArray(children)) {
                rootNode['children'] = mapTreeData(children)
            }
            result.push(rootNode)
        })
    } else {
        return undefined
    }

    return result
}

const parsePreSelectedNodes = (tree: any[], selectedNode: any) => {
    if (!selectedNode) return []

    const preSelectedNodes = []
    const nodes = Object.keys(selectedNode).map(key => `${key}__${selectedNode[key]}`)

    // TODO: improve this code, it's full of assumptions that may not be true in the future
    tree.forEach(rootNode => {
        // advertisers
        if (_includes(nodes, rootNode.value)) {
            preSelectedNodes.push(rootNode.value)
        }
        // campaigns
        if (rootNode.children && rootNode.children.length > 0) {
            rootNode.children.forEach(node => {
                if (_includes(nodes, node.value)) {
                    preSelectedNodes.push(node.value)
                }
                // sub-campaigns
                if (node.children && node.children.length > 0) {
                    node.children.forEach(node => {
                        if (_includes(nodes, node.value)) {
                            preSelectedNodes.push(node.value)
                        }
                    })
                }
            })
        }
    })

    return preSelectedNodes
}

const UserHeader = (props: Props) => {
    const cascaderOptions = props.userTree && props.userTree.root
        ? mapTreeData(props.userTree.root)
        : []

    const { userSettings } = props

    const preSelectedNodes = cascaderOptions && cascaderOptions.length > 0 && userSettings && userSettings.selectedNode
        ? parsePreSelectedNodes(cascaderOptions, userSettings.selectedNode[0])
        : []

    const updateSelectedNodes = (selectedNodes: any) => {
        const node = {}
        const newContext: AppContextDTO = {} as AppContextDTO

        selectedNodes.forEach(selectedNode => {
            switch (selectedNode.dimensionIdentifier) {
                case DimensionService.getDimensionValueColumn('advertiser'):
                    newContext.advertiserId = parseInt(selectedNode.dimensionValue)
                    newContext.advertiserName = selectedNode.label
                    node[DimensionService.getDimensionValueColumn('advertiser')] = newContext.advertiserId
                    break
                case DimensionService.getDimensionValueColumn('campaign'):
                    newContext.campaignId = parseInt(selectedNode.dimensionValue)
                    newContext.campaignName = selectedNode.label
                    node[DimensionService.getDimensionValueColumn('campaign')] = newContext.campaignId
                    break
                case DimensionService.getDimensionValueColumn('sub_campaign'):
                    newContext.subCampaignId = parseInt(selectedNode.dimensionValue)
                    newContext.subCampaignName = selectedNode.label
                    node[DimensionService.getDimensionValueColumn('sub_campaign')] = newContext.subCampaignId
                    break
            }
        })

        actions.storeAppContext(newContext)(props.dispatch)

        userActions.updateSettings(
            Object.assign({}, userSettings, { selectedNode: [node] })
        )(props.dispatch)
    }

    const selectNode = (nodeValues: string[], selectedTreeNodes: any[]) => {
        event.stopPropagation()
        updateSelectedNodes(selectedTreeNodes)
    }

    const renderNode = (labels: string[], selectedNodes: any[]) => labels.map((label, index) => {
        const option = selectedNodes[index]
        return <span key={option.value}>
            {label} {index === labels.length - 1 ? '' : <RightOutlined/>}
        </span>
    })

    return (
        <React.Fragment>
            {props.loggedInViaSecondFactor &&
            <span className="user-header">
                {cascaderOptions.length > 0 && <Cascader className="user-tree-cascader"
                                                         options={cascaderOptions}
                                                         displayRender={renderNode}
                                                         onChange={selectNode}
                                                         changeOnSelect={true}
                                                         defaultValue={preSelectedNodes}
                />}
                {props.userName} <LogoutButton logout={() => SystemService.showCoreUi()}/>
            </span>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootReducer, dispatch: Dispatch): Props => {
    const authentication: AuthenticationState = state.authentication
    const { user, /*appContext*/ } = state
    const { loggedInViaSecondFactor } = authentication
    const name = authentication && authentication.user && loggedInViaSecondFactor ? authentication.user.loginName : ''

    return {
        // appContext: appContext.appContext,
        loggedInViaSecondFactor: loggedInViaSecondFactor,
        userName: name,
        userSettings: user.settings,
        userTree: user.tree,
        dispatch: dispatch,
    }
}

export default connect(mapStateToProps)(UserHeader)
