import React from 'react'
import { Button } from 'antd'
import { Transition } from 'react-transition-group'

type Props = {
    title: string,

    onDownload: () => void,

    showDownload?: boolean,
    showSettings?: boolean,

    downloadTooltip?: string,

    renderSettings?: () => React.ReactNode,
}

type State = {
    showSettingsToolbar: boolean,
}

// Animation config
const duration = 200

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
}

/**
 * Renders the icons for a particular widget and the widgets title. This is a generic component that is not attached
 * to any particular widget implementation.
 */
export default class WidgetHeader extends React.Component<Props, State> {

    static defaultProps = {
        showDownload: true,
        showSettings: true,
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            showSettingsToolbar: false,
        }
    }

    onSettingsClicked = () => {
        const { showSettingsToolbar } = this.state

        this.setState({ showSettingsToolbar: !showSettingsToolbar })
    }

    downloadButton = (children: React.ReactNode) => this.props.downloadTooltip
        ? <span data-tip={this.props.downloadTooltip} data-force-tooltip={true}>{children}</span>
        : <React.Fragment>{children}</React.Fragment>

    render() {
        const { title, showDownload, showSettings, onDownload, renderSettings } = this.props
        const { showSettingsToolbar } = this.state

        return (
            <React.Fragment>
                <div className="widget-header-content">
                    <div className="widget-header-title">
                        <h3 className="widget-title" dangerouslySetInnerHTML={{ __html: title }}/>
                    </div>
                    <div className="widget-header-buttons">
                        {showDownload && this.downloadButton(<Button onClick={onDownload}
                                                                     type="primary" htmlType="button"
                                                                     shape="circle" icon="download"
                                                                     style={{ margin: 5 }}/>)}
                        {showSettings &&
                        <Button onClick={this.onSettingsClicked} type="primary" shape="circle" icon="setting"
                                style={{ margin: 5 }}/>}
                    </div>
                </div>

                {renderSettings &&
                <Transition
                    in={showSettingsToolbar}
                    timeout={duration}>
                    {(state) => (
                        <div style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}>
                            {state === 'entering' || state === 'entered' ? renderSettings() : null}
                        </div>
                    )}
                </Transition>}
            </React.Fragment>
        )
    }
}
