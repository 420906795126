import * as React from 'react'
import { Provider } from 'react-redux'

import store from './shared/redux/store'
import App from './App'

export class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <App/>
            </Provider>
        )
    }
}