import React from 'react'
import { Router } from 'react-router-dom'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-contexify/dist/ReactContexify.min.css'
import 'app.less'
import { connect } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Alert, Layout, Spin } from 'antd'
import { hot } from 'react-hot-loader'
import { history } from 'shared/util/history'
import { AuthenticationState } from 'domain/authentication/redux/authentication.reducer'
import { generateMainMenu } from 'shared/component/MainMenu'
import EmbeddingService from 'domain/core/service/embedding.service'
import { ServiceConfigurationUIConfig } from 'domain/types'
import { BackendSessionKeepAlive } from 'shared/component'
import UserHeader from 'domain/user/component/UserHeader'
import { UserConfigs } from 'domain/user/redux/user.reducer'
import AppRouter from 'AppRouter'
import { isConfigurationPath } from 'shared/util/menu'
import ReactHint from 'domain/tooltip/ReactHint'
import CopyToClipboardHint from 'domain/tooltip/CopyToClipboardHint'
import moment from 'moment'

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin/>)

// import 'styles/whitelabel/groupm.less' // this import is necessary for webpack, white-label is not activated automatically

export interface AppProps extends StateProps, DispatchProps {
    dispatch?: ((action: any) => void),
    authentication: AuthenticationState,
    user: UserConfigs
}

type State = {
    configurations: ServiceConfigurationUIConfig[]
    menu: any
    isEmbedded: boolean
}

export class App extends React.Component<AppProps, State> {

    constructor(props: AppProps) {
        super(props)

        this.state = {
            configurations: undefined,
            menu: undefined,
            isEmbedded: true,
        }
    }

    componentWillMount() {
        const isEmbedded = EmbeddingService.isEmbedded()
        this.setState(prevState => ({
            ...prevState,
            isEmbedded,
        }))
    }

    render() {
        // set first day of the week to monday
        moment.updateLocale('en', { week: { dow: 1, doy: 4 } })

        const authentication = this.props.authentication
        const userConfigs = this.props.user

        const userLoggedInViaSecondFactorAndHasMenuData = authentication.loggedInViaSecondFactor && userConfigs?.menu
        const referrer = getReferrer()
        const isConfiguredPath = userLoggedInViaSecondFactorAndHasMenuData && referrer ?
            isConfigurationPath(referrer, userConfigs.menu.root) || referrer?.startsWith('/ui/mfa/otp') : false

        const isArticlePage = referrer && referrer.startsWith('/ui/content')

        // Reasons to show the content area (=no main menu) only:
        // - user is a non internal user: these users can currently not access the support UI and never have a use case to see the main menu
        // - in embedded mode: only render e.g. a grid so that it can be embedded in the classic UI
        // - static content pages: these pages render their own menu
        const isInternalUser = authentication.user?.internalUser == true
        const showContentAreaOnly = !isInternalUser || this.state.isEmbedded || isArticlePage

        const className = 'main root-layout ' + (showContentAreaOnly
            ? 'layout-content-only '
            : 'layout-full ') + (isArticlePage ? 'layout-root-article' : '')

        const configurations = userConfigs?.menu ? userConfigs.menu.root : []
        const showMainMenu = authentication.loggedInViaSecondFactor && !showContentAreaOnly && configurations.length > 0

        return (
            // @ts-ignore
            <Router history={history}>
                {authentication.loggedInViaFirstFactor && <BackendSessionKeepAlive/>}
                <Layout className={className}>
                    {authentication && authentication.loginError && (
                        <Alert
                            message="We're sorry, an error occurred while logging you in."
                            description={'Please contact our support team'}
                            type="error"
                            showIcon
                            className="automatic-login-alert"
                        />
                    )}

                    {/* this tag is used to render the tooltip */}
                    <ReactHint position="bottom"/>

                    {/* this tag is used to render the copy-to-clipboard button */}
                    <CopyToClipboardHint/>

                    {showMainMenu && generateMainMenu(isConfiguredPath ? referrer : null, configurations)}
                    {generateUserHeaderAndContent(authentication, showContentAreaOnly, this.state.isEmbedded, configurations)}

                </Layout>
            </Router>
        )
    }
}

const generateUserHeaderAndContent = (authentication: AuthenticationState, showContentAreaOnly: boolean, isEmbedded: boolean, configurations: any[]) => {
    return (
        // @ts-ignore
        <Layout className="main content-wrapper" tagName="section">
            <Spin wrapperClassName={'main-spinner'} spinning={authentication.loggingIn} style={{ marginTop: 200 }}>
                {!showContentAreaOnly &&
                    // @ts-ignore
                    <Layout.Header className="app-header" tagName="section">
                        {/*@ts-ignore*/}
                        <UserHeader/>
                    </Layout.Header>
                }

                {/*@ts-ignore*/}
                <Layout.Content className="app-content" tagName="section">
                    <AppRouter authentication={authentication} isEmbedded={isEmbedded} configurations={configurations}/>
                </Layout.Content>
            </Spin>
        </Layout>
    )
}

/**
 * Gets current referrer without parameters
 */
export const getReferrer = (): string => {
    // @ts-ignore
    const referrer = history.location?.state?.referrer
    if (referrer) {
        return referrer.replace(/\?.*/g, '')
    } else {
        return null
    }
}


const mapStateToProps = (state: any) => {
    const { authentication, appContext, user } = state
    return { authentication, appContext, user }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

// @ts-ignore
export default hot(module)(connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(App))
