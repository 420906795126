import React, { ReactNode, useEffect, useState } from 'react'
import { Form } from 'antd'
import FormContext, { FormContextProperties } from './FormContext'
import FormService from 'shared/service/form.service'
import { ActionPopupConfig, ConditionClauseDTO, DataRowDTO, FieldKeyValue, FormActionDTO, FormCustomListener, FormElementDTO, NumberValueClauseDTO, SelectFormElementDTO, UIFormConfig, UpdateResponseDTO } from 'domain/types'
import UrlService from 'shared/service/url.service'
import ConditionClauseService from 'shared/service/conditionClauseService'
import { ElementSetting, useRootElementContext } from 'shared/component/layout/context/RootElementContext'
import FormUtil from 'shared/util/FormUtil'
import LayoutUtil from 'shared/util/LayoutUtil'
import PayloadUtil from 'shared/util/PayloadUtil'
import { log } from 'shared/util/log'
import { formValidator } from 'shared/util/validation'
import DimensionService from 'domain/dimension/service/DimensionService'
import { Channel } from 'Constants'

type FormContextProviderProps = {
    children?: ReactNode
    uiFormConfig?: UIFormConfig
    popupConfig?: ActionPopupConfig
}

const FILTER_PATH = '/loaddimensionvalues'

const FormContextProvider: React.FC<FormContextProviderProps> = (props: FormContextProviderProps): JSX.Element => {
    const [form] = Form.useForm()
    const { updateElementSettings } = useRootElementContext()
    const [uiFormConfig, setUiFormConfig] = useState(props.uiFormConfig)
    const [popupConfig] = useState(props.popupConfig)
    const [initialValues, setInitialValues] = useState<FieldKeyValue[]>([])
    const [userSetValues, setUserSetValues] = useState<FieldKeyValue[]>([])
    const isEditMode = FormUtil.getIsEditMode(uiFormConfig.formConfig)
    const isCreateMode = FormUtil.getIsCreateMode(uiFormConfig.formConfig)
    const [readOnlyElements, setReadOnlyElements] = useState<string[]>([])
    // contains a map of form field identifiers and their loading state (true/false)
    const [loadingFieldStates, setLoadingFieldStates] = useState<{ [key: string]: boolean }>({})

    const { setFieldsValue } = form

    useEffect(() => {
        // asynchronous initial loading function
        const initialLoading = async () => {
            const selectElements = getAllSelectElements()

            if (uiFormConfig.itemData && uiFormConfig.itemData.length > 0) {
                // EDIT form
                const newInitialValues = FormUtil.getInitialValues(uiFormConfig.itemData, uiFormConfig.formConfig.layoutConfig)
                setInitialValues(newInitialValues)
                // Set values for all form elements and updates context settings
                LayoutUtil.findFormElements(uiFormConfig.formConfig.layoutConfig).forEach(element => {
                    // initially each form field has a non-loading state
                    setElementLoadingState(element.formFieldConfig.dimensionIdentifier, false)

                    // if the element is a FormSelectElement then load the select entries
                    loadSelectElementAndUpdateUiFormConfig(element.formFieldConfig.dimensionIdentifier, selectElements).then(() =>
                        setFormElementValueAndUpdateContextSettings(element, newInitialValues),
                    )
                })
            } else {
                // CREATE form
                await loadAllSelectElementsAndUpdateUiFormConfig(selectElements)
            }
        }

        initialLoading().then(() => log.debug('Initial loading is done'))
    }, [])

    /**
     * Updates the loading state for element with identifier [elementIdentifier]
     * @param elementIdentifier
     * @param isLoading
     */
    const setElementLoadingState = (elementIdentifier: string, isLoading: boolean) => {
        setLoadingFieldStates(prev => {
            prev[elementIdentifier] = isLoading

            return prev
        })
    }

    /**
     * Updates the loading state for all [selectElements]
     *
     * @param selectElements
     * @param isLoading
     */
    const setElementLoadingStateForList = (selectElements: SelectFormElementDTO[], isLoading: boolean) => {
        selectElements.forEach(element =>
            setElementLoadingState(element.formFieldConfig.dimensionIdentifier, isLoading)
        )
    }

    /**
     * Loads all {@link FormSelectElement}s from {@link uiFormConfig} and updates the global ui form config
     */
    const loadAllSelectElementsAndUpdateUiFormConfig = async (selectElements: SelectFormElementDTO[]) => {
        selectElements.forEach(currentElement => {
            loadSelectElementAndUpdateUiFormConfig(currentElement.formFieldConfig.dimensionIdentifier, selectElements)
                .then(() => log.debug(`Loading of ${currentElement.formFieldConfig.dimensionIdentifier} is done`))
        })
    }

    /**
     * Loads form select element entries for [formElementIdentifier] and updates the global ui form config
     * @param formElementIdentifier
     * @param allSelectElements
     */
    const loadSelectElementAndUpdateUiFormConfig = async (formElementIdentifier: string, allSelectElements: SelectFormElementDTO[]) => {
        const currentSelectElement = allSelectElements.find(element =>
            element.formFieldConfig.dimensionIdentifier === formElementIdentifier,
        )

        if (currentSelectElement) {
            if (!currentSelectElement.selectConfig.preventInitialLoading) {
                // Find all allSelectElements in the form that [currentElement] depends on.
                const currentElementDependencies = allSelectElements.filter(selectElement =>
                    currentSelectElement.selectConfig?.dependsOn.some(dep => selectElement.formFieldConfig.dimensionIdentifier === dep.filterIdentifier),
                )

                const filterClauses = currentElementDependencies
                    // filter all [currentElementDependencies] that have a value set.
                    .filter(selectElement => uiFormConfig.itemData.some(row => row[selectElement.identifier]))
                    .map(selectElement => {
                        return {
                            clauseType: 'Number',
                            columnName: selectElement.formFieldConfig.dimensionIdentifier,
                            type: 'EQUALS',
                            value: uiFormConfig.itemData.find(row => row[selectElement.identifier])[selectElement.identifier]?.value,
                        } as NumberValueClauseDTO
                    })

                const combineFilterQueries = ConditionClauseService.combineFilterQueries([uiFormConfig?.filter, ...filterClauses])

                setElementLoadingState(currentSelectElement.formFieldConfig.dimensionIdentifier, true)
                // loads entries for currentSelectElement and updates its (element.selectConfig.selectEntries)
                await LayoutUtil.loadSelectElements([currentSelectElement], FILTER_PATH, combineFilterQueries, null)
                setElementLoadingState(currentSelectElement.formFieldConfig.dimensionIdentifier, false)

                setUiFormConfig(prev => {
                    return { ...prev }
                })
            }
        }
    }

    /**
     * Finds all {@link SelectFormElementDTO}s with no preventInitialLoading=true
     */
    const getAllSelectElements = (): SelectFormElementDTO[] => {
        return LayoutUtil
            .findSelectElements(uiFormConfig.formConfig.layoutConfig)
            .filter(el => !el.selectConfig.preventInitialLoading)
    }

    /**
     * Implementation for custom listeners
     */
    const customFormFieldListeners = {
        [FormCustomListener.SUB_CAMPAIGN_CREATE_FORM_ON_CHANNEL_CHANGE]: (channelDropdown) => {
            setField(DimensionService.getDimensionNameColumn('sub_campaign'), channelDropdown.textValue)

            const createSameNamedLineItemFieldIdentifier = DimensionService.getDimensionValueColumn('sc_create_same_named_line_item')
            if ([Channel.SEO, Channel.DIRECT_REFERRED, Channel.DIRECT_TYPEIN].includes(channelDropdown.value)) {
                setField(createSameNamedLineItemFieldIdentifier, true)
                setReadOnlyElements(prev => [...prev, createSameNamedLineItemFieldIdentifier])
                onChange(true, createSameNamedLineItemFieldIdentifier).then(() => log.debug('onChange is done'))
            } else {
                !userSetValues.some(el => el.name == createSameNamedLineItemFieldIdentifier) && resetField(createSameNamedLineItemFieldIdentifier)
                setReadOnlyElements(prev => prev.filter(element => element !== createSameNamedLineItemFieldIdentifier))
                setUserSetValues(prev => prev.filter(el => el.name != createSameNamedLineItemFieldIdentifier))
            }
        },
    }

    /**
     * Sets field value for field identifier
     *
     * @param fieldIdentifier
     * @param newValue
     */
    const setField = (fieldIdentifier: string, newValue: any) => {
        form.setFields([{ name: fieldIdentifier, value: newValue }])
    }

    /**
     * Executes setFormElementValueAndUpdateContextSettings for initialValues from the state
     *
     * @param element
     */
    const setFormElementValueAndUpdateContextSettingsForInitialValues = (element: FormElementDTO) => {
        return setFormElementValueAndUpdateContextSettings(element, initialValues)
    }

    /**
     * Sets form element value and updates context settings if the element has useAsSetting=true flag
     *
     * @param element
     * @param allInitialValues
     */
    const setFormElementValueAndUpdateContextSettings = (element: FormElementDTO, allInitialValues: FieldKeyValue[] = initialValues) => {
        let fieldKeyValue = findFieldValue(element, allInitialValues)

        const dimensionIdentifier = element.formFieldConfig.dimensionIdentifier

        // if no field value in initial values found, then set default value
        if ((fieldKeyValue?.value === undefined || fieldKeyValue?.value === null) && element.formFieldConfig?.defaultValue !== undefined && !userSetValues.some(el => el.name == dimensionIdentifier)) {
            fieldKeyValue = { name: dimensionIdentifier, value: element.formFieldConfig.defaultValue }
        }

        if (fieldKeyValue) {
            form.setFields([fieldKeyValue])

            if (element.useAsSetting) {
                updateElementSettings({ key: dimensionIdentifier, value: fieldKeyValue.value } as ElementSetting)
            }
        }
    }

    /**
     * Finds field value for form element from the allInitialValues list
     *
     * @param element
     * @param allInitialValues
     */
    const findFieldValue = (element: FormElementDTO, allInitialValues: FieldKeyValue[]) =>
        allInitialValues.find(fieldValue => fieldValue.name === element.formFieldConfig.dimensionIdentifier)

    const resetField = (field) => {
        form.resetFields([field])
    }

    const handleCancel = (): void => {
        form.resetFields()
    }

    /**
     * Extracts filter values for required dimensions. Returns a map with keys as required dimensions and values as dimension value
     *
     * @param requiredDimensions
     * @param additionalFilters
     */
    const extractDimensionValuesFromFilter = (requiredDimensions: string[], additionalFilters?: ConditionClauseDTO): { [key: string]: any } => {
        return requiredDimensions.reduce((acc, requiredDimension) => {
            const dimensionValues: any[] = []
            if (additionalFilters) {
                dimensionValues.push(...ConditionClauseService.getAllDimensionValuesFromFilter(additionalFilters, requiredDimension))
            }

            if (dimensionValues?.length > 0) {
                return {
                    ...acc,
                    [requiredDimension]: dimensionValues[0],
                }
            } else {
                return acc
            }
        }, {})
    }

    /**
     * Validates the form data and the if the data is valid, sends it to the backend.
     * Returns asynchronously the Promise<boolean>, whether the validation AND submit were successful.
     *
     * @param onFrontendValidationSuccessCallback - callback, that will be invoked after the form data are validated
     * @param onAfterSubmit - callback, that will be invoked after the form is successfully submitted
     */
    const handleSubmit = async (onFrontendValidationSuccessCallback: () => void, onAfterSubmit?: () => void): Promise<boolean> => {
        log.debug('-- FORM: submit()')
        log.debug('-- FORM: uiFormConfig: ', uiFormConfig)

        const { baseApi } = uiFormConfig
        const { actions } = uiFormConfig.formConfig

        // validate in frontend first, submit to backend only if frontend validation is successful
        return await form.validateFields()
            .then(values => {
                // e.g. set modal contentLoading: true
                onFrontendValidationSuccessCallback()

                const payload = createFormSubmitPayload(values, actions)
                log.debug('-- FORM: rows to update: ', payload)

                return FormService.submitForm(payload, `${baseApi}/${actions.submit.url}`, actions.submit.method, UrlService.getBaseUrl())
                    .then(result => {
                        if (result.response.success) {
                            log.debug('Response was successful')

                            if (onAfterSubmit && typeof onAfterSubmit === 'function') {
                                // e.g. reload the gird
                                onAfterSubmit()
                            }

                            if (result.updatedData) {
                                if (popupConfig.onSubmitSuccess && typeof popupConfig.onSubmitSuccess === 'function') {
                                    // e.g. ActionService.editLastCreatedOrUpdatedItem
                                    popupConfig.onSubmitSuccess(result.updatedData)
                                }
                            }
                            if (!uiFormConfig.formConfig.keepOpenAfterCreateAndEdit) {
                                // we know that form is going to be closed after successful update, so let's reset it now (there would probably be a better place to do this? before close?)
                                form.resetFields()
                            }
                        } else {
                            log.debug('Response was not successful')
                            showValidationErrors(result)
                        }

                        return result.response.success
                    })
            })
            .catch(errors => {
                log.error('form validation errors: ', errors)
                return false
            })
    }

    /**
     * Creates from the form [values] payload, that will be submitted to the backend
     *
     * @param values
     * @param actions
     */
    const createFormSubmitPayload = (values: DataRowDTO, actions?: FormActionDTO): DataRowDTO[] => {
        const requiredDimensions = actions?.submit?.requiredDimensions ?? []
        const requiredAdditionalData = extractDimensionValuesFromFilter(requiredDimensions, popupConfig?.additionalFilters)

        const valuesCopy = { ...values }

        // Set all readOnly field values to undefined because undefined entries will not be sent to the backend and that is what we want.
        for (const key in valuesCopy) {
            if (readOnlyElements.indexOf(key) > -1) {
                valuesCopy[key] = undefined
            }
        }

        // only send form values as payload when creating a new entry
        const payload = isCreateMode ? [{ ...valuesCopy, ...requiredAdditionalData }] : []

        // map form values to item ids in edit mode
        const column = uiFormConfig.formConfig.mainDimension
        if (isEditMode) {
            // mainDimension id array
            const mainDimensionIds = uiFormConfig.itemData.map((row) => row[column.identifier].value)

            PayloadUtil.createEditPayload(
                DimensionService.getDimensionValueColumn(column.identifier),
                mainDimensionIds,
                valuesCopy,
            ).forEach(row => payload.push(row))
        }

        return payload
    }

    /**
     * Show each validation error in the form
     *
     * @param updateResponseDTO
     */
    const showValidationErrors = (updateResponseDTO: UpdateResponseDTO) => {
        for (const key in updateResponseDTO.validationErrors) {
            form.setFields([{ name: key, errors: updateResponseDTO.validationErrors[key] }])
        }
    }

    const onChange = async (value: any, field: string): Promise<void> => {
        setUserSetValues(pref => [...pref.filter(el => el.name != field), { name: field, value } as FieldKeyValue])
        if (value === undefined || value === '') {
            setFieldsValue({ [field]: null })
        }

        await reloadAllDependantSelectElements(value, field)
    }

    const reloadAllDependantSelectElements = async (value: any, field: string) => {
        const dependentSelectElements = LayoutUtil
            .findSelectElements(uiFormConfig?.formConfig?.layoutConfig)
            .filter(selectFormElementDTO => selectFormElementDTO?.selectConfig?.dependsOn?.find(dep => dep.filterIdentifier === field) !== undefined)

        if (dependentSelectElements.length > 0) {
            const combineFilterQueries = value?.value
                ? ConditionClauseService.combineFilterQueries([
                    uiFormConfig?.filter,
                    {
                        clauseType: 'Number',
                        columnName: field,
                        type: 'EQUALS',
                        value: value?.value,
                    } as NumberValueClauseDTO,
                ])
                : uiFormConfig?.filter
            setElementLoadingStateForList(dependentSelectElements, true)
            await LayoutUtil.loadSelectElements(dependentSelectElements, FILTER_PATH, combineFilterQueries, null)
            setElementLoadingStateForList(dependentSelectElements, false)
            setUiFormConfig({ ...uiFormConfig })
        }
    }

    const setRules = (opts: any, validation: any): void => {
        opts['rules'] = formValidator(validation)
        if (opts['rules']) opts['validateTrigger'] = 'onSubmit'
    }

    if (popupConfig) {
        popupConfig.onAbort = handleCancel
        popupConfig.onSubmit = handleSubmit
    }

    const context: FormContextProperties = {
        resetField,
        form,
        uiFormConfig,
        popupConfig,
        setFormElementValueAndUpdateContextSettingsForInitialValues,
        setReadOnlyElements,
        readOnlyElements,
        setField,
        setRules,
        userSetValues,
        onChange,
        customFormFieldListeners,
        loadingFieldStates,
    } as FormContextProperties

    return <FormContext.Provider value={context}>
        {props.children}
    </FormContext.Provider>
}

export default FormContextProvider
