import { ColumnResponseDTO, ContainerElementDTO, FieldKeyValue, FormConfigDTO, FormElementDTO, FormType, GridDataRowDTO, LayoutElementType } from 'domain/types'
import LayoutUtil from 'shared/util/LayoutUtil'
import DimensionService, { DimensionField, DimensionFieldType } from 'domain/dimension/service/DimensionService'

/**
 * Returns true if the form is in EDIT or CREATE mode.
 * @param formConfig
 */
const getIsEditOrCreateMode = (formConfig: FormConfigDTO): boolean => {
    return getIsEditMode(formConfig) || getIsCreateMode(formConfig)
}

/**
 * Returns true if the form is in EDIT mode. This method is called "getIsEditMode" instead of "isEditMode" to avoid conflicts with local variables that we want to call isEditMode
 * @param formConfig
 */
const getIsEditMode = (formConfig: FormConfigDTO): boolean => {
    return formConfig.type === FormType.EDIT
}

const getNonEditableValue = (element: FormElementDTO, rows: GridDataRowDTO[]): any => {
    const dimensionField = DimensionService.recognizeDimensionField(element.formFieldConfig.dimensionIdentifier)
    const fieldNameOrValue = DimensionService.getDimensionFieldNameOrValue(rows[0], dimensionField.identifier)

    if (rows.length === 1 || rows.every(row => DimensionService.getDimensionFieldNameOrValue(row, dimensionField.identifier) === fieldNameOrValue)) {
        return fieldNameOrValue
    } else {
        return null
    }
}

/**
 * Returns true if the form is in CREATE mode. This method is called "getIsCreateMode" instead of "isCreateMode" to avoid conflicts with local variables that we want to call isCreateMode
 * @param formConfig
 */
const getIsCreateMode = (formConfig: FormConfigDTO): boolean => {
    return formConfig.type === FormType.CREATE
}

/**
 * Creates initial values for the form
 *
 * @param itemData
 * @param layoutConfig
 */
const getInitialValues = (itemData: GridDataRowDTO[], layoutConfig: ContainerElementDTO): FieldKeyValue[] => {
    const formElements = LayoutUtil.findFormElements(layoutConfig)

    if (itemData) {
        const item = itemData[0]
        const fieldNames = [].concat(
            ...Object.keys(item)
                .filter(key => !key.startsWith('__'))
                .map(dimensionIdentifier => [DimensionService.getDimensionValueColumn(dimensionIdentifier), DimensionService.getDimensionNameColumn(dimensionIdentifier)])
        )

        return fieldNames.map(fieldName => {
            const formElementExists = formElements.some((element: FormElementDTO) => element.formFieldConfig.dimensionIdentifier === fieldName)
            if (formElementExists) {
                const dimensionField = DimensionService.recognizeDimensionField(fieldName)
                const columnResponseDTO = getFieldValue(itemData, dimensionField)
                let value = dimensionField.fieldType == DimensionFieldType.VALUE ? columnResponseDTO.value : columnResponseDTO.name

                // set any FormSelectElement to "Not Set" if empty
                const isSelectFormElement = formElements.find((element: FormElementDTO) => element.formFieldConfig.dimensionIdentifier === fieldName).elementType === LayoutElementType.FORM_ELEMENT_SELECT
                value = isSelectFormElement && value === undefined ? null : value

                return { name: fieldName, value: value } as FieldKeyValue
            } else {
                return null
            }
        }).filter(element => element)
    } else {
        return []
    }
}

/**
 * Extracts field value from the selected rows. If the field value is the same in each row,
 * then show the value. If the value is different, then show __NO_CHANGE__.
 *
 * @param itemData
 * @param dimensionField
 */
const getFieldValue = (itemData: GridDataRowDTO[], dimensionField: DimensionField): ColumnResponseDTO => {
    const fieldValues = itemData.map(item => item[dimensionField.identifier])
    if (fieldValues.length == 0) {
        return null
    } else {
        const firstElement = fieldValues[0]
        const allSelectedItemsHaveTheSameValue = fieldValues.every(element => element.value === firstElement.value)
        if (allSelectedItemsHaveTheSameValue) {
            return firstElement
        } else {
            return { value: '__NO_CHANGE__' }
        }
    }
}

const FormUtil = { getInitialValues, getIsEditOrCreateMode, getIsEditMode, getIsCreateMode, getNonEditableValue }
export default FormUtil
