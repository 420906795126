import { ActionDTO, DataRowDTO, DimensionDTO, FormConfigDTO, FormType, GridDataRowDTO } from 'domain/types'
import React, { ReactNode } from 'react'
import { getStatusId } from 'shared/util/util'
import DimensionUtil from 'shared/util/DimensionUtil'
import { ACTIONS_FIELD } from 'domain/datagrid/component/DataGrid'

const TEMP_MAIN_DIMENSION_NAME_FIELD = '__name'

/**
 * Attention: this function assumes that the last letter (e.g. 'e') in the [verb] can be replaced with 'ing':
 * e.g. deactivate => Deactivating
 * @param verb
 */
const headlineify = (verb: string) => `${verb.charAt(0).toUpperCase()}${verb.substr(1, verb.length - 2)}ing`

/**
 * Creates verb from action, that will be used for building text sentences
 *
 * @param action
 */
const createActionVerb = (action: ActionDTO) =>
    action.type.toString().toLowerCase()

/**
 * Creates modal title
 *
 * @param formConfigDto
 * @param mainDimension
 * @param itemData
 */
const createTitle = (formConfigDto: FormConfigDTO, mainDimension: DimensionDTO, itemData: GridDataRowDTO[]) => {
    return <>
        {formConfigDto.title} {mainDimension ? (itemData.length > 1 ? mainDimension.displayNamePlural : mainDimension.displayName) : ''}
        {itemData.length === 1 && itemData[0][mainDimension?.identifier]?.name && formConfigDto.type === FormType.EDIT &&
        <em> {itemData[0][mainDimension?.identifier]?.name}</em>}
    </>
}

/**
 * Extracts the temp name from the item or if no data, then returns just "item"
 *
 * @param item
 */
const getItemName = (item: DataRowDTO) => {
    return item[TEMP_MAIN_DIMENSION_NAME_FIELD] ? item[TEMP_MAIN_DIMENSION_NAME_FIELD] : 'item'
}

const createItemNameSentence = (items: DataRowDTO[], identifier: string, haveAllItemsANameValue: boolean, displayName: string) => {
    if (haveAllItemsANameValue) {
        const [last, ...rest] = items.reverse()
        return <React.Fragment>
            {` ${rest.reverse().map(item => `${getItemName(item)} (ID: ${item[identifier]})`).join(', ')}`}
            {rest.length > 0 && <span className="join-with-and"> and </span>}
            {`${getItemName(last)} (ID: ${last[identifier]})`}
        </React.Fragment>
    } else {
        const ids = items.map(item => item[identifier]).join(', ')
        return `${displayName} ${ids}`
    }
}

/**
 * Creates modal subtitle
 *
 * @param itemData
 */
const createSubtitle = (itemData: GridDataRowDTO[]): ReactNode => {
    if (itemData.length > 1) {
        return <div className="multi-edit-hint">Changes will be applied
            to <em>{itemData.length} selected items</em>.
        </div>
    } else {
        return undefined
    }
}

/**
 * Creates popup header
 *
 * @param action
 * @param selectedItems
 * @param mainDimension
 */
const createPopupHeader = (action: ActionDTO, selectedItems: GridDataRowDTO[], mainDimension: DimensionDTO) => {
    const verb = createActionVerb(action)
    const displayName = DimensionUtil.getMainDimensionTitle(selectedItems, mainDimension)
    return `${headlineify(verb)} ${selectedItems.length} ${displayName}`
}

/**
 * Creates popup sentence
 *
 * @param selectedItems
 * @param action
 * @param mainDimension
 */
const createPopupItemSentence = (selectedItems: GridDataRowDTO[], action: ActionDTO, mainDimension: DimensionDTO) => {
    const identifier = mainDimension.identifier
    const statusId = getStatusId(action.type)
    const displayName = DimensionUtil.getMainDimensionTitle(selectedItems, mainDimension)

    const items = selectedItems.map(row => {
        return {
            [identifier]: row[identifier].value,
            status_id: statusId,
            [TEMP_MAIN_DIMENSION_NAME_FIELD]: row[identifier].name ? row[identifier].name : displayName,
        } as DataRowDTO
    })
    const haveAllItemsANameValue = selectedItems.every(row => !!row[identifier].name)

    const itemString = items.length <= 3
        ? <em>{createItemNameSentence(items, identifier, haveAllItemsANameValue, displayName)}</em>
        : `${items.length} selected ${displayName}`

    return <div><p>Do you really want to {createActionVerb(action)} {itemString}?</p>
        {!!action.affectsTrackingResult && <p>This might affect tracking results.</p>}</div>
}

const PopupTextUtil = {
    createTitle: createTitle,
    createSubtitle: createSubtitle,
    createPopupHeader: createPopupHeader,
    createPopupItemSentence: createPopupItemSentence,
}

export default PopupTextUtil
