import { TopNBarWidget } from 'domain/widget/TopNBarWidget'
import React from 'react'
import { WidgetSettingsDTO, WidgetType } from 'domain/types/backend/widget.types'
import { DataListWidget } from 'domain/widget/DataListWidget'
import { TimeLineWidget } from 'domain/widget/TimeLineWidget'

const WidgetFactory = {

    /**
     * Factory method creates new widget instances based on the type parameter [widgetSettings.type]
     *
     * @param widgetSettings
     */
    create: (widgetSettings: WidgetSettingsDTO): any => {
        switch (widgetSettings.type) {
            case WidgetType.TIME_LINE_WIDGET:
                return <TimeLineWidget/>
            case WidgetType.TOP_N_WIDGET:
                return <TopNBarWidget/>
            case WidgetType.DATA_LIST_WIDGET:
                return <DataListWidget/>
            default:
                throw new TypeError(`Unsupported widget type '${widgetSettings.type}'`)
        }
    },
}

export default WidgetFactory
