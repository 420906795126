import { post } from 'shared/service'
import { AvailableFactorsResponseDTO, OtpFormResponseDTO, OtpSetupResponseFormDTO } from 'domain/types/backend/mfa.types'

declare const baseUrlUserService: string

class AUTHENTICATOR {
    static verifyCode = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(baseUrlUserService + '/mfa/otp/authenticator/verify-code', { code, rememberMe })

    static setupSecret = (): Promise<OtpSetupResponseFormDTO> =>
        post(baseUrlUserService + '/mfa/otp/authenticator/setup', {})

    static confirmSetup = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(baseUrlUserService + '/mfa/otp/authenticator/confirm-setup', { code, rememberMe })
}

class EMAIL {
    static requestCode = (): Promise<OtpFormResponseDTO> =>
        post(baseUrlUserService + '/mfa/otp/email/request-code', {})

    static verifyCode = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(baseUrlUserService + '/mfa/otp/email/verify-code', { code, rememberMe })

    static initiateSetup = (): Promise<OtpFormResponseDTO> =>
        post(baseUrlUserService + '/mfa/otp/email/setup', {})

    static confirmSetup = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(baseUrlUserService + '/mfa/otp/email/confirm-setup', { code, rememberMe })
}

const availableFactors = (): Promise<AvailableFactorsResponseDTO> => {
    return post(baseUrlUserService + '/mfa/available-factors', {})
}

const mfaService = {
    AUTHENTICATOR,
    EMAIL,
    availableFactors,
}

export default mfaService
