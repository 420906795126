import _includes from 'lodash/includes'
import UserService from 'domain/user/service/user.service'
import { actions as coreActions } from 'domain/core/redux/appcontext.reducer'
import store from 'shared/redux/store'
import { Dispatch } from 'redux'
import { ApiErrorDTO, AppContextDTO, MenuRootDTO } from 'domain/types'
import { log } from 'shared/util/log'
import { AxiosResponse } from 'axios'
import DimensionService from 'domain/dimension/service/DimensionService'
import { SELECTED_FIELD } from 'domain/datagrid/component/DataGrid'
import { UserTreeDTO } from 'domain/types/backend/tree.types'

export enum actionTypes {
    USER_LOAD_TREE = 'USER_LOAD_TREE',
    USER_LOAD_TREE_SUCCESS = 'USER_LOAD_TREE_SUCCESS',
    USER_LOAD_SETTINGS = 'USER_LOAD_SETTINGS',
    USER_LOAD_SETTINGS_SUCCESS = 'USER_LOAD_SETTINGS_SUCCESS',
    USER_UPDATE_SETTINGS = 'USER_UPDATE_SETTINGS',
    USER_UPDATE_SETTINGS_SUCCESS = 'USER_UPDATE_SETTINGS_SUCCESS',
    USER_TREE_SELECT_USER_SETTING = 'USER_TREE_SELECT_USER_SETTING',
    USER_LOAD_MENU = 'USER_LOAD_MENU',
}

export type UserConfigs = { tree?: any, settings?: UserSettingsDTO, menu?: MenuRootDTO }
export type UserAction = { type: string, userData?: UserConfigs, error?: string }

export type UserSettingsDTO = { selectedNode: any }

const loadMenu = () => (dispatch: Dispatch) => {
    return UserService.loadMenu()
        .then(menuRootDTO => {
            dispatch(loadMenuSuccess(menuRootDTO))
            return menuRootDTO
        })
}

/**
 * Callback for the loadtree promise
 * @param promise
 */
const loadTreeCallback = (promise: Promise<UserTreeDTO>) => (dispatch: Dispatch) => {
    return promise.then(tree => {
        dispatch(loadTreeSuccess(tree))
        if (tree) {
            const appContext = store.getState().appContext.appContext
            const appContextKeys = Object.keys(appContext)
            if (appContextKeys.length === 0) { // TODO: is this branch possible?
                log.debug('empty appContext after loading the user tree')
                const context = {} as AppContextDTO
                const selectedNode = {}

                const { root } = tree
                if (root && root.length > 0 && root[0].dimensionIdentifier === DimensionService.getDimensionValueColumn('advertiser')) {
                    context['advertiserId'] = root[0].value
                    context['advertiserName'] = root[0].label
                    selectedNode['advertiser_id'] = root[0].value

                    const { children } = root[0]
                    if (children && children.length > 0 && children[0].dimensionIdentifier === DimensionService.getDimensionValueColumn('campaign')) {
                        context['campaignId'] = children[0].value
                        context['campaignName'] = children[0].label
                        selectedNode['campaign_id'] = children[0].value
                    }
                }

                if (Object.keys(context).length > 0 &&
                    Object.keys(selectedNode).length > 0) {

                    log.debug('pre-populating appContext ... ', context)
                    coreActions.storeAppContext({ ...context })(dispatch)
                    const settings = { selectedNode: [selectedNode] } as UserSettingsDTO
                    log.debug('updating user settings ... ', settings)
                    updateSettings(settings)(dispatch)
                }
            } else if (_includes(appContextKeys, 'advertiserId') && _includes(appContextKeys, 'campaignId') && !_includes(appContextKeys, 'campaignName')) { // TODO: why don't we want to have "campaignName" in the appContext properties
                try {
                    const { root } = tree
                    const advertiserNode = root.find(node => node.dimensionIdentifier === DimensionService.getDimensionValueColumn('advertiser') && node.value === appContext['advertiserId'])
                    if (advertiserNode && advertiserNode.children) {
                        const campaignNode = advertiserNode.children.find(node => node.dimensionIdentifier === DimensionService.getDimensionValueColumn('campaign') && node.value === appContext['campaignId'])
                        if (campaignNode) appContext['campaignName'] = campaignNode.label
                        coreActions.storeAppContext({ ...appContext })(dispatch)
                    }
                } catch (e) {
                    log.debug('error trying to find selected campaign in user tree')
                }
            }
        }

        return tree
    })
}

const loadMenuSuccess = (menu: MenuRootDTO): UserAction => ({ type: actionTypes.USER_LOAD_MENU, userData: { menu } })

const loadTreeSuccess = (tree): UserAction => ({ type: actionTypes.USER_LOAD_TREE_SUCCESS, userData: { tree } })

const loadSettings = () => (dispatch: Dispatch) => {
    return UserService.loadUserSettings()
        .then((response: any) => {
            const settings = response.dataSet.rows[0]
            dispatch(loadSettingsSuccess(settings))
            // do we need to populate an empty appContext with the loaded settings?
            const context = {} as AppContextDTO
            if (settings && settings.selectedNode && Object.keys(store.getState().appContext.appContext).length === 0) {
                const node = settings.selectedNode[0]
                Object.keys(node).forEach(key => {
                    switch (key) {
                        case DimensionService.getDimensionValueColumn('advertiser'):
                            context['advertiserId'] = node[key]
                            break
                        case DimensionService.getDimensionValueColumn('campaign'):
                            context['campaignId'] = node[key]
                            break
                        case DimensionService.getDimensionValueColumn('sub_campaign'):
                            context['subCampaignId'] = node[key]
                            break
                    }
                })
            }

            return context
        })
}

const loadSettingsSuccess = (settings: UserSettingsDTO): UserAction => ({
    type: actionTypes.USER_LOAD_SETTINGS_SUCCESS,
    userData: { settings },
})

const updateSettings = (settings: UserSettingsDTO) => (dispatch: Dispatch) => {
    UserService.updateUserSettings({ rows: [settings] })
        .then(() => dispatch(updateSettingsSuccess(settings)))
}

const updateSettingsSuccess = (settings: UserSettingsDTO): UserAction => ({
    type: actionTypes.USER_UPDATE_SETTINGS_SUCCESS,
    userData: { settings },
})

export const actions = {
    loadMenu,
    loadTreeCallback,
    loadSettings,
    updateSettings,
}

const initialState = {}

export const reducer = (state: UserConfigs = initialState, action: UserAction) => {
    switch (action.type) {
        case actionTypes.USER_LOAD_TREE_SUCCESS:
            return { ...state, tree: action.userData.tree }
        case actionTypes.USER_LOAD_SETTINGS_SUCCESS:
            return { ...state, settings: action.userData.settings }
        case actionTypes.USER_UPDATE_SETTINGS_SUCCESS:
            return { ...state, settings: action.userData.settings }
        case actionTypes.USER_LOAD_MENU:
            return { ...state, menu: action.userData.menu }
        default:
            return state
    }
}
