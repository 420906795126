import * as React from 'react'

type Props = {
    icon: string
    size?: string
    additionalCssClass?: string
}

type State = {}

export class CustomIcon extends React.Component<Props, State> {
    render() {
        return (
            <div
                className={'icon icon-' + this.props.icon + ' ' + (this.props.size ? 'icon-' + this.props.size : '') + ' ' + this.props.additionalCssClass}>&nbsp;</div>
        )
    }

}