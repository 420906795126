import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import mfaService from './service/mfaService'
import { OtpFormResponseDTO } from 'domain/types/backend/mfa.types'
import { MailOutlined } from '@ant-design/icons'

type Props = {
    sendOnMount?: boolean,
    onSuccess: () => void,
    onError: () => void,
}

const RequestEmailCodeButton: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [codeGenerationResponse, setCodeGenerationResponse] = useState<OtpFormResponseDTO>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props?.sendOnMount === true) requestCode()
    }, [props.sendOnMount])

    const setDelayedIsLoading = (isLoading: boolean) => {
        isLoading
            ? setIsLoading(isLoading)
            : setTimeout(() => setIsLoading(isLoading), 500)
    }

    const requestCode = () => {
        setDelayedIsLoading(true)
        mfaService.EMAIL.requestCode().then((response: OtpFormResponseDTO) => {
            setDelayedIsLoading(false)
            setCodeGenerationResponse(response)
            response.successful ? props?.onSuccess() : props?.onError()
        })
    }

    return <Button onClick={requestCode} icon={<MailOutlined/>} loading={isLoading} type={props?.sendOnMount || codeGenerationResponse ? 'default' : 'primary'}>
        {props?.sendOnMount || codeGenerationResponse ? 'Re-Send Code' : 'Send Code'}
    </Button>
}

export default RequestEmailCodeButton
