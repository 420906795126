import * as echarts from 'echarts/lib/echarts'
import _cloneDeep from 'lodash/cloneDeep'
import { exactagTheme } from 'shared/echart/themes/exactagTheme'

const registerTheme = (name: string, colors: string[]) => {
    const theme = _cloneDeep(exactagTheme)
    theme.color = colors
    theme.graph.color = colors
    echarts.registerTheme('monochrome_' + name, theme)
}


export const registerMonochromeThemes = () => {
    registerTheme('blue', [
            '#6dbae6',
            '#186591',
            '#3da3de',
            '#104360',
            '#cee8f6',
            '#2087c1',
            '#9ed1ee',
            '#082130',
        ],
    )

    registerTheme('green', [
            '#89ca8c',
            '#347537',
            '#62b965',
            '#224e24',
            '#d7edd8',
            '#459c49',
            '#b0dcb2',
            '#112712',
        ],
    )


    registerTheme('purple', [
            '#c682d1',
            '#712d7c',
            '#b358c2',
            '#4b1e53',
            '#ecd5ef',
            '#963ca6',
            '#d9abe0',
            '#250f29',
        ],
    )

    registerTheme('red', [
            '#e46f6f',
            '#8f1a1a',
            '#db3f3f',
            '#5f1111',
            '#f6cfcf',
            '#bf2323',
            '#ed9f9f',
            '#2f0808',
        ],
    )

    registerTheme('yellow', [
            '#f4d35f',
            '#9f7e0a',
            '#f0c42a',
            '#6a5407',
            '#fbf0c9',
            '#d4a80e',
            '#f7e194',
            '#352a03',
        ],
    )

    registerTheme('orange', [
            '#fe7d55',
            '#aa2800',
            '#ff521c',
            '#711b00',
            '#ffd3c6',
            '#e23600',
            '#ffa88d',
            '#380d00',
        ],
    )

    registerTheme('turqouise', [
            '#6dd8e6',
            '#188391',
            '#3dcbde',
            '#105760',
            '#cef2f6',
            '#20afc1',
            '#9ee5ee',
            '#082b30',
        ],
    )

    registerTheme('gray', [
            '#a0a9b3',
            '#65707d',
            '#dfe2e5',
            '#4b545e',
            '#c0c6cc',
            '#818d99',
            '#191c1f',
        ],
    )
}
