import * as React from 'react'

import { CurrencyDTO } from 'domain/types'
import formatter from 'shared/util/formatter'

type Props = {
    currency: CurrencyDTO,
    children?: React.ReactNode,
};

type State = {
    oldSettings: any,
}

/**
 * Sets the formatting configuration based on the props of this component.
 */
export class Formatter extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        const { currency } = this.props

        const { symbol, symbolPosition } = currency

        // let's save the old settings in our state
        const oldSettings = formatter.settings()

        this.state = { oldSettings }

        formatter.setup({
            currency: {
                symbol: symbol || '€',
                format: 'post' === symbolPosition ? '%v %s' : '%s %v',
                decimal: ',',
                thousand: '.',
                precision: 2,
            },

            number: {
                thousand: '.',
                decimal: ',',
                precision: 2,
            },
        })
    }

    render() {
        return this.props.children || null
    }
}