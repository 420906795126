import * as api from 'shared/service'
import _orderBy from 'lodash/orderBy'
import { AxiosRequestConfig } from 'axios'
import {
    AppContextDTO,
    BooleanClauseDTO,
    BooleanOperator,
    ClauseType,
    ColumnConfigDTO,
    ConditionClauseType,
    ContainerElementDTO,
    GridColumnPropertiesDTO,
    GridElementDTO,
    NumberValueClauseDTO,
    PageableDTO,
    PageConfigDTO,
    QueryModeType,
    QuerySettingsDTO,
    ScenarioDTO,
} from 'domain/types'
import { ColumnRendererDTO, RendererType } from 'shared/component/renderers/renderers'
import PageService from 'shared/service/page.service'
import DimensionService from 'domain/dimension/service/DimensionService'
import { CONTEXT_MENU_FIELD, CONTEXT_MENU_FIELD_ID } from 'domain/datagrid/component/DataGrid'
import {
    ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER,
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    CAMPAIGN_DIMENSION_IDENTIFIER,
    COMMENT_DIMENSION_IDENTIFIER,
    END_DATE_DIMENSION_IDENTIFIER,
    JOB_STATUS_DIMENSION_IDENTIFIER,
    MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER,
    START_DATE_DIMENSION_IDENTIFIER,
    STATUS_DIMENSION_IDENTIFIER,
} from 'domain/adspend-optimizer/context/AdSpendOptimizerContext'

declare let baseUrlBackend: string

const BASE_URL = '/adspendscenario'
const CONFIG_URL = '/loadpageconfig'
const DATA_URL = '/loaddata'
const CREATE_URL = '/insertdata'
const UPDATE_URL = '/updatedata'
const DELETE_URL = '/deletedata'
const EXPORT_CREATE_URL = '/createexportdata'
const EXPORT_URL = '/exportdata'

const BASE_PAGINATION_SETTINGS: PageableDTO = {
    page: 0,
    pageSize: 1000,
    sortProperties: [],
    sortAscending: true,
}

const BASE_FILTER_QUERY: BooleanClauseDTO = {
    clauseType: ConditionClauseType.BOOLEAN,
    operator: BooleanOperator.AND,
    clauses: [],
}

const getBasicQuerySettings = () => (
    {
        filter: { ...BASE_FILTER_QUERY },
        paginationSettings: { ...BASE_PAGINATION_SETTINGS },
        columnNames: [],
    }
)

const fetchScenarios = async (campaignId: number, appContext, paginationSettings: any): Promise<any> => {
    const clauses = [
        {
            clauseType: ConditionClauseType.NUMBER,
            columnName: DimensionService.getDimensionValueColumn(CAMPAIGN_DIMENSION_IDENTIFIER),
            type: 'EQUALS',
            value: campaignId,
        },
        {
            clauseType: ConditionClauseType.NUMBER,
            columnName: DimensionService.getDimensionValueColumn(STATUS_DIMENSION_IDENTIFIER),
            type: 'EQUALS',
            value: 1,
        },
    ]

    const querySettings = {
        ...getBasicQuerySettings(),
        appContext,
        paginationSettings,
        columnNames: [
            DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionNameColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(START_DATE_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(END_DATE_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(COMMENT_DIMENSION_IDENTIFIER),
        ],
    }

    querySettings.filter.clauses = [...clauses]

    const result: any = await post(querySettings, `${BASE_URL}${DATA_URL}`, { baseURL: baseUrlBackend })

    result.dataSet.rows.map(entry => {
        entry[CONTEXT_MENU_FIELD] = {
            buttons: [
                {
                    icon: 'check',
                    action: { identifier: 'SELECT', name: 'Select this Scenario for Comparison' },
                    item: { id: entry[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.value },
                },
                {
                    icon: 'edit',
                    action: { identifier: 'EDIT', name: 'Edit' },
                    item: { id: entry[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.value }
                },
                {
                    icon: 'delete',
                    action: { identifier: 'DELETE', name: 'Delete' },
                    item: { id: entry[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.value },
                },
            ],
        }
        return entry
    })

    return result
}

const fetchScenario = async (scenarioId: number, appContext): Promise<any> => {
    const clause: NumberValueClauseDTO = {
        clauseType: ConditionClauseType.NUMBER,
        columnName: DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
        type: ClauseType.EQUALS,
        value: scenarioId,
    }

    const querySettings: QuerySettingsDTO = {
        filter: {
            clauseType: ConditionClauseType.BOOLEAN,
            operator: BooleanOperator.AND,
            clauses: [clause],
        } as BooleanClauseDTO,
        paginationSettings: {
            page: 0,
            pageSize: 1000,
            sortProperties: [DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)],
            sortAscending: false,
        },
        appContext: appContext,
        columnNames: [
            DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionNameColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(START_DATE_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(END_DATE_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(JOB_STATUS_DIMENSION_IDENTIFIER),
            DimensionService.getDimensionValueColumn(COMMENT_DIMENSION_IDENTIFIER),
        ],
    }

    return await post(querySettings, `${BASE_URL}${DATA_URL}`, { baseURL: baseUrlBackend })
}

const createScenario = async (scenarioData: ScenarioDTO, appContext: AppContextDTO): Promise<any> => {
    const payload = {
        appContext,
        rows: [],
    }
    payload.rows.push({
        [DimensionService.getDimensionValueColumn(CAMPAIGN_DIMENSION_IDENTIFIER)]: scenarioData.campaign_id,
        [DimensionService.getDimensionNameColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)]: scenarioData.adspend_scenario_name,
        [DimensionService.getDimensionValueColumn(COMMENT_DIMENSION_IDENTIFIER)]: scenarioData.comment || null,
        [DimensionService.getDimensionValueColumn(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER)]: `${scenarioData.optimizationLevel.toLowerCase()}_id`,
        [DimensionService.getDimensionValueColumn(START_DATE_DIMENSION_IDENTIFIER)]: scenarioData.start_date,
        [DimensionService.getDimensionValueColumn(END_DATE_DIMENSION_IDENTIFIER)]: scenarioData.end_date,
        [DimensionService.getDimensionValueColumn(ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER)]: [...scenarioData.mediaPlan],
    })

    return await post(payload, `${BASE_URL}${CREATE_URL}`, { baseURL: baseUrlBackend })
}

const updateScenario = async (scenarioData: ScenarioDTO, appContext: AppContextDTO): Promise<any> => {
    const payload = {
        appContext,
        rows: [],
    }
    payload.rows.push({
        [DimensionService.getDimensionValueColumn(CAMPAIGN_DIMENSION_IDENTIFIER)]: scenarioData.campaign_id,
        [DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)]: scenarioData.adspend_scenario_id,
        [DimensionService.getDimensionNameColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)]: scenarioData.adspend_scenario_name,
        [DimensionService.getDimensionValueColumn(COMMENT_DIMENSION_IDENTIFIER)]: scenarioData.comment || null,
        [DimensionService.getDimensionValueColumn(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER)]: `${scenarioData.optimizationLevel.toLowerCase()}_id`,
        [DimensionService.getDimensionValueColumn(START_DATE_DIMENSION_IDENTIFIER)]: scenarioData.start_date,
        [DimensionService.getDimensionValueColumn(END_DATE_DIMENSION_IDENTIFIER)]: scenarioData.end_date,
        [DimensionService.getDimensionValueColumn(ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER)]: [...scenarioData.mediaPlan],
    })

    return await put(payload, `${BASE_URL}${UPDATE_URL}`, { baseURL: baseUrlBackend })
}

const deleteScenario = async (scenarioId: string, appContext): Promise<any> => {
    const payload = {
        filter: {
            ...BASE_FILTER_QUERY, clauses: [
                {
                    columnName: DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
                    value: scenarioId,
                    type: ClauseType.EQUALS,
                    clauseType: ConditionClauseType.STRING,
                },
            ],
        },
        appContext: { ...appContext },
    }

    return await api.deleteRequest(`${BASE_URL}${DELETE_URL}`, {
        baseURL: baseUrlBackend,
        data: { ...payload },
    })
}

const loadPageConfig = (): Promise<PageConfigDTO> =>
    PageService.loadPageConfig(`${BASE_URL}${CONFIG_URL}`, baseUrlBackend)

const fetchOptimizationLevelData = async (optimizationLevel: string, campaignId: number, appContext): Promise<any> => {
    const querySettings = {
        ...getBasicQuerySettings(),
        appContext,
    }

    querySettings.paginationSettings.sortProperties = [DimensionService.getDimensionValueColumn(optimizationLevel.toLowerCase())]
    querySettings.paginationSettings.sortAscending = true
    querySettings.filter = null

    return await post(querySettings, `${BASE_URL}${DATA_URL}/${optimizationLevel}`, { baseURL: baseUrlBackend })
}

const getOptimizationLevel = (level: string): string => {
    return level === 'campaign_id'
        ? 'CAMPAIGN'
        : level === 'sub_campaign_id'
            ? 'SUB_CAMPAIGN'
            : 'CHANNEL'
}

const createCompareModePayload = (prefix: string, scenarioIds: string[], appContext: any, weekly = false) => {
    const columnNames = [
        DimensionService.getDimensionValueColumn(prefix),
        DimensionService.getDimensionNameColumn(prefix),
        DimensionService.getDimensionValueColumn('adspend_conversions'),
        DimensionService.getDimensionValueColumn('adspend_costs'),
        DimensionService.getDimensionValueColumn('adspend_total_price'),
        DimensionService.getDimensionValueColumn('adspend_roi'),
        DimensionService.getDimensionValueColumn('adspend_roas'),
        DimensionService.getDimensionValueColumn('adspend_cpo'),
    ]

    if (weekly) {
        columnNames.unshift(DimensionService.getDimensionValueColumn('weekly'), DimensionService.getDimensionNameColumn('weekly'))
    }

    return {
        columnNames: columnNames,
        appContext: appContext,
        filter: {
            operator: BooleanOperator.AND,
            clauseType: ConditionClauseType.BOOLEAN,
            clauses: [
                {
                    clauses: scenarioIds.map(id => ({
                        columnName: DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
                        value: id,
                        type: ClauseType.EQUALS,
                        clauseType: ConditionClauseType.STRING,
                    })),
                    clauseType: ConditionClauseType.BOOLEAN,
                    operator: BooleanOperator.OR,
                } as BooleanClauseDTO,
            ],
        } as BooleanClauseDTO,
        mode: {
            type: QueryModeType.COMPARE,
            // ASO only supports compare mode for "columnNames": ["adspend_scenario"]
            // because we always want to compare 2 scenarios
            columnNames: [ADSPEND_SCENARIO_DIMENSION_IDENTIFIER],
        },
        paginationSettings: {
            sortProperties: [DimensionService.getDimensionNameColumn(prefix)], // TODO sort by multiple fields, like week asc, channel asc
            sortAscending: true,
            page: 0,
            pageSize: 100,
        },
        timespanSettings: null,
    } as QuerySettingsDTO
}

const fetchComparisonData = async (prefix: string, scenarioIds: string[], appContext: any, weekly: boolean = false) => {
    return await post(createCompareModePayload(prefix, scenarioIds, appContext, weekly), `${BASE_URL}${DATA_URL}`, { baseURL: baseUrlBackend })
}

const exportComparisonData = ({ prefix, scenarioIds, appContext }) => {
    return post<any>({
        querySettingsDTO: createCompareModePayload(prefix, scenarioIds, appContext),
        appContext: appContext,
        fileType: 'xslx',
    }, `${BASE_URL}${EXPORT_CREATE_URL}`, { baseURL: baseUrlBackend })
        .then(triggerResponse => {
            if (triggerResponse.success == true) {
                const identifier = triggerResponse.identifier
                const link = document.createElement('a')
                link.href = `${baseUrlBackend}${BASE_URL}${EXPORT_URL}/${identifier}`
                link.setAttribute('download', triggerResponse.filename)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                throw new Error('There was an error processing your export.')
            }
        })
}

const createPlotModePayload = (mediaPlanType: string, mediaPlanId: number, appContext: any) => {
    const columnNames = [DimensionService.getDimensionValueColumn('adspend_costs'), DimensionService.getDimensionValueColumn('adspend_conversions')]

    return {
        columnNames: columnNames,
        appContext: appContext,
        filter: {
            operator: BooleanOperator.AND,
            clauseType: ConditionClauseType.BOOLEAN,
            clauses: [
                {
                    columnName: DimensionService.getDimensionValueColumn(mediaPlanType),
                    value: mediaPlanId,
                    type: ClauseType.EQUALS,
                    clauseType: ConditionClauseType.NUMBER,
                } as NumberValueClauseDTO,
            ],
        },
        mode: {
            type: QueryModeType.PLOT,
        },
        paginationSettings: {
            sortProperties: [DimensionService.getDimensionValueColumn('adspend_costs')],
            sortAscending: true,
            page: 0,
            pageSize: 100,
        },
        timespanSettings: null,
    } as QuerySettingsDTO
}

const fetchEfficiencyData = async (mediaPlanType: string, mediaPlanId: number, appContext: any) => {
    const payload = createPlotModePayload(mediaPlanType, mediaPlanId, appContext)
    return await post(payload, `${BASE_URL}${DATA_URL}`, { baseURL: baseUrlBackend })
}

const post = <T>(data: any, path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.post(path, data, config)
}

const put = <T>(data: any, path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.put(path, data, config)
}

const mapColumnConfigs = (pageConfig: PageConfigDTO): any => {
    // const config = ((pageConfig.layoutConfig as ContainerElementDTO).children[0] as GridElementDTO).elementConfig
    const config = (((pageConfig.layoutConfig as ContainerElementDTO).children[1] as ContainerElementDTO).children[0] as GridElementDTO).elementConfig

    const { columnConfigs } = config.gridConfig

    const actionColumn = {
        columnIdentifier: CONTEXT_MENU_FIELD_ID,
        gridColumnProperties: {
            columnHeader: '',
            width: 110,
            renderer: { type: RendererType.CONTEXT_MENU, cssClasses: [] } as ColumnRendererDTO,
            sortable: false,
            editable: false,
        } as GridColumnPropertiesDTO,
    } as ColumnConfigDTO

    return {
        ...config.gridConfig,
        // append actions column and hide status_id and job_status
        columnConfigs: [
            ...columnConfigs.filter(c =>
                c.columnIdentifier !== DimensionService.getDimensionValueColumn(STATUS_DIMENSION_IDENTIFIER) && c.columnIdentifier !== DimensionService.getDimensionValueColumn(JOB_STATUS_DIMENSION_IDENTIFIER)
            ),
            actionColumn,
        ],
        filterConfigs: _orderBy(config.gridConfig.filterConfigs, 'sortOrder', 'asc'),
    }
}

const AdSpendOptimizerService = {
    fetchScenarios,
    fetchScenario,
    createScenario,
    updateScenario,
    loadPageConfig: loadPageConfig,
    fetchOptimizationLevelData,
    getOptimizationLevel,
    fetchComparisonData,
    exportComparisonData,
    fetchEfficiencyData,
    deleteScenario,
    mapColumnConfigs,
}
export default AdSpendOptimizerService
