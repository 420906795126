import React, { ReactNode, useContext, useState } from 'react'

/**
 * We use this context to let child elements interact with the settings of the main root that displays them. This root element could e.g.
 * be a page or a modal. An example use case could be that we want child elements to be able to update the footer of the parent depending
 * whether a certain tab has been selected.
 */

export enum FooterState {
    SHOW_DEFAULT_FOOTER = 'SHOW_DEFAULT_FOOTER',
    HIDE_FOOTER = 'HIDE_FOOTER'
}

export type ElementSetting = {
    // this could e.g. be a dimensionIdentifier that is used as a setting (is_expert_mode=1) but could also be a key that is not available as a dimension (show_as_bars=1, group_by_foo_bar=0)
    key: string,
    value: any,
}

export type RootElementContextProperties = {
    children?: ReactNode,

    // footer currently only used in modals; either a react node with all elements that should be displayed in the footer or one of the FooterState values
    footer?: ReactNode | FooterState,
    updateFooter?: Function,

    // the footer that should be displayed per default if children don't want to update anything
    defaultFooter?: ReactNode,
    updateDefaultFooter?: Function,

    elementSettings: ElementSetting[],
    updateElementSettings?: (setting: ElementSetting) => void
}
export const RootElementContext = React.createContext<RootElementContextProperties>({ elementSettings: [] })

export const RootElementContextProvider = (props: RootElementContextProperties) => {
    // per default, if no changes are made: show the default footer
    const [footer, setFooter] = useState(FooterState.SHOW_DEFAULT_FOOTER)
    const [defaultFooter, setDefaultFooter] = useState()
    const [elementSettings, setElementSettings] = useState<ElementSetting[]>([])

    return (
        <RootElementContext.Provider
            value={{
                footer: footer,
                updateFooter: (newFooter) => {
                    setFooter(newFooter)
                },

                defaultFooter: defaultFooter,
                updateDefaultFooter: (newDefaultFooter) => {
                    setDefaultFooter(newDefaultFooter)
                },

                elementSettings: elementSettings,
                updateElementSettings: (newSetting: ElementSetting) => {
                    setElementSettings(prev => {

                        const available = prev.some(setting => setting.key === newSetting.key)

                        if (available) {
                            return prev.map(setting => {
                                if (setting.key === newSetting.key) {
                                    return newSetting
                                } else {
                                    return setting
                                }
                            })
                        } else {
                            return [...prev, newSetting]
                        }
                    })
                },
            }}>
            {props.children}
        </RootElementContext.Provider>
    )
}

export const useRootElementContext = (): RootElementContextProperties => {
    const context = useContext(RootElementContext)
    if (!context) {
        throw new Error('Missing RootElementContext in its parent.')
    }
    return context
}
