import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import _reduce from 'lodash/reduce'
import _mapValues from 'lodash/mapValues'

import { ApiErrorDTO } from 'domain/types'
import { log } from 'shared/util/log'
import store from 'shared/redux/store'
import { actions as authActions } from 'domain/authentication/redux/authentication.reducer'

declare let baseUrlBackend: string

axios.defaults.baseURL = baseUrlBackend

/**
 * Executes a GET request against the given URI.
 */
export const request = <T>(url: string, method: string, responseType: string, handleResponse: any, config?: AxiosRequestConfig, logRequest: boolean = true) => {
    const modifiedConfig = rewriteConfig(config)

    logRequest && log.info(`GET request to ${url} with axios config `, modifiedConfig)
    return axios({
        ...modifiedConfig,
        url: url,
        method: method,
        responseType: responseType,
    }).then(handleResponse).catch(handleException)
}

/**
 * Executes a GET request against the given URI.
 */
export const get = <T>(url: string, config?: AxiosRequestConfig, logRequest: boolean = true) => {
    const modifiedConfig = rewriteConfig(config)

    // if we do not have a standard params serializer, we will add this default one
    // it will handle brackets inside JSON strings correctly when URL encoding them
    if (!modifiedConfig.paramsSerializer) {
        modifiedConfig.paramsSerializer = (params) => {
            return _reduce(_mapValues(params, value => encodeURI(JSON.stringify(value))), (result, value, key) => {
                return (result ? result + '&' : '') + key + '=' + value
            }, '')
        }
    }

    logRequest && log.info(`GET request to ${url} with axios config `, modifiedConfig)

    return axios.get(url, modifiedConfig)
        .then(handleResponse)
        .catch(handleException)
}

/**
 * Executes a POST request against the given URI.
 */
export const post = <T>(url: string, data: any, config?: AxiosRequestConfig) => {

    const modifiedConfig = rewriteConfig(config)

    log.info(`POST request to ${url} with axios config `, modifiedConfig)

    return axios.post(url, data, modifiedConfig)
        .then(handleResponse)
        .catch(handleException)
}

/**
 * Executes a PUT request against the given URI.
 */
export const put = <T>(url: string, data: any, config?: AxiosRequestConfig) => {

    const modifiedConfig = rewriteConfig(config)

    log.info(`PUT request to ${url} with axios config `, modifiedConfig)

    return axios.put(url, data, modifiedConfig)
        .then(handleResponse)
        .catch(handleException)
}

/**
 * Executes a DELETE request against the given URI.
 */
export const deleteRequest = <T>(url: string, config?: AxiosRequestConfig) => {

    const modifiedConfig = rewriteConfig(config)

    log.info(`DELETE request to ${url} with axios config `, modifiedConfig)

    return axios.delete(url, modifiedConfig)
        .catch(handleException)
}

/**
 * Returns a new cancellation token.
 */
export const newCancelToken = (): CancelTokenSource => {
    return axios.CancelToken.source()
}

/**
 * Default configuration, applied to all custom configs.
 */
const defaultConfig = {
    timeout: 30000,
}

/**
 * Rewrites the axios configuration if necessary.
 */
export const rewriteConfig = <T>(config?: AxiosRequestConfig): any => {
    return {
        ...defaultConfig,
        ...config,
        withCredentials: true,
    }
}

/**
 * Handle successful responses. Basically returns the JSON data from the response.
 */
const handleResponse = (response) => {
    // even if we receive an error, the backend will send as an ApiErrorDto
    return response.data
}

/**
 * Handle error cases. If we (think we) found an ApiErrorDto we will reject with that, otherwise
 * we create an ApiError from the error object we got.
 */
const handleException = async (e): Promise<ApiErrorDTO> => {

    // special case: if we have unauthorized/forbidden requests, we will trigger login again
    if (e.response && (e.response.status === 401)) {
        const sleep = (millis) => {
            return new Promise(resolve => setTimeout(resolve, millis))
        }

        // @ts-ignore
        store.dispatch(authActions.loginRequest())

        await sleep(5000)

        return Promise.resolve({ httpStatus: 200, message: '' })
    }

    // maybe we got an ApiErrorDto
    if (e.response && e.response.data) {
        return Promise.reject(e.response.data)
    }

    // if not, we will create one to streamline the handleException API
    return Promise.reject({
        message: e.toString(),
        errors: [e.toString()],
    })
}