import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import _includes from 'lodash/includes'
import {
    ActionDTO,
    ActionIdentifier,
    ApiErrorDTO,
    AppContextDTO,
    ColumnConfigDTO,
    ConditionClauseDTO,
    DataSliceDTO,
    DimensionDTO,
    FilterConfigDTO,
    FilterState,
    GridConfigDTO,
    GridDataRowDTO,
    LoadResponseDTO,
    OrderDirection,
    PageableDTO,
    PathsDTO,
    TimespanSettingsDTO,
    ToolbarConfigDTO,
} from 'domain/types'
import axios, { CancelTokenSource } from 'axios'
import DataGridService from 'domain/datagrid/service/datagrid.service'
import { log } from 'shared/util/log'
import { Alert, message, Spin } from 'antd'
import WidgetHeader from 'domain/widget/WidgetHeader'
import DataGridSettingsToolbar from 'domain/widget/DataGridSettingsToolbar'
import { DataGrid } from 'domain/datagrid/component/DataGrid'
import { Pagination } from 'shared/component/Pagination'
import { ToolbarComponent } from 'shared/component/ToolbarComponent'
import withWidgetContext from 'domain/context/withWidgetContext'
import GridUtil, { areRequiredFiltersSet, createDataSettings, downloadExportData, getWrapperClassName } from 'domain/widget/generic/GridUtil'
import { PageErrorMessage } from 'domain/core/component/PageErrorMessage'
import { MergedDataGridWidgetSettings } from 'domain/widget/generic/GenericDataGridWidgetWrapper'
import { AdditionalFilterContext } from 'shared/component/layout/context/AdditionalFilterContext'
import LegacyWidgetContext from 'domain/context/LegacyWidgetContext'
import { useDataGridContext } from 'domain/widget/generic/DataGridContext'
import { TabPaneContext } from 'shared/component/layout/context/TabPaneContextProvider'
import ConditionClauseService from 'shared/service/conditionClauseService'
import ActionService from 'shared/service/ActionService'
import { WidgetSettingsDTO } from 'domain/types/backend/widget.types'
import { ToolsContext } from 'domain/widget/ToolsContext'
import GenericDataGridSearchForm from 'domain/widget/generic/GenericDataGridSearchForm'
import { FilterContainer } from 'domain/filter/component/FilterContainer'
import FilterComponentUtil from 'domain/filter/component/FilterComponentUtil'

export type DataGridWidgetSettings = WidgetSettingsDTO & {
    defaultOrderBy: string[]
    defaultOrderDirection: OrderDirection,
    baseURL?: string
    path?: string
    showDownload: boolean
    showSettings: boolean
    showFooter: boolean
    supportsRowSelection?: boolean
    hasSearch?: boolean
    toolbar?: ToolbarConfigDTO
    actions?: ActionDTO[]
    paths?: PathsDTO
    requiredFilters?: DimensionDTO[]
    dependsOn?: string[]
    // temp for being able to easily inject mock data
    columnConfig?: GridConfigDTO
    filterConfig?: FilterConfigDTO[]
    data?: DataSliceDTO,
    embedded?: boolean,
    gridId?: string
}

export type GenericDataGridWidgetProps = {
    appContext: AppContextDTO
    settings: LegacyWidgetContext<MergedDataGridWidgetSettings>
}

export type DataSettingsState = {
    mainDimension: DimensionDTO
    settings: DataGridWidgetSettings
    columns: ColumnConfigDTO[]
    supportedSearchColumns?: string[]
    searchTerm?: string
    pagination: PageableDTO,
    timespanSettings?: TimespanSettingsDTO,
}

export type GridState = {
    userNotAuthorized: boolean
    lastCreatedId: number
    cancelToken?: CancelTokenSource,
    filterCancelToken?: CancelTokenSource,
}

export type LoadingState = {
    isGridLoading: boolean
}

/**
 * Data grid widget configuring and showing a antd based data grid.
 */
export const GenericDataGridWidget: React.FC<GenericDataGridWidgetProps> = (props: GenericDataGridWidgetProps): JSX.Element => {

    const toolsContext = useContext(ToolsContext)
    const tabPaneContext = useContext(TabPaneContext)
    const additionalFilterContext = useContext(AdditionalFilterContext)

    const { getRows, updateRows, getSelectedRowIndices, updateSelectedRowIndices, resetSelectedRowIndices } = useDataGridContext()

    const { settings } = props.settings

    const generateOriginalDataSettings = (settings: MergedDataGridWidgetSettings): DataSettingsState => {
        return {
            ...{
                mainDimension: undefined,
                searchTerm: undefined,
                settings,
                columns: [],
                pagination: {
                    page: 0,
                    pageSize: 25,
                    sortAscending: settings.defaultOrderDirection === 'ASC',
                    sortProperties: settings.defaultOrderBy,
                } as PageableDTO,
                supportedSearchColumns: [],
                timespanSettings: toolsContext?.timespanSettings,
            },
            ...createDataSettings(settings, settings.defaultOrderBy, settings.defaultOrderDirection === 'ASC'),
        } as DataSettingsState
    }

    // ############################################################## States ###############################################################
    // true if the grid has been loaded with initial configs
    const [initialized, setInitialized] = useState(false)
    const [gridFilters, setGridFilters] = useState<FilterConfigDTO[]>(settings.gridConfig.filterConfigs)
    const [gridFilterStates, setGridFilterStates] = useState<FilterState[]>(FilterComponentUtil.createInitialFilterStates(settings.gridConfig.filterConfigs))
    const [currentGridEntriesCount, setCurrentGridEntriesCount] = useState<number | undefined>(undefined)
    // As default has the grid the loading state. After the data are loaded, it will be changed to false
    const [loadingState, setLoadingState] = useState({ isGridLoading: false } as LoadingState)
    const [downloadProcessing, setDownloadProcessing] = useState(false)
    const [gridState, setGridState] = useState({
        userNotAuthorized: false,
        lastCreatedId: 0,
        cancelToken: DataGridService.getCancelTokenSource(),
        filterCancelToken: DataGridService.getCancelTokenSource(),
    } as GridState)
    const [dataSettingsState, setDataSettingsState] = useState(generateOriginalDataSettings(settings))

    // ############################################################## useEffects ###########################################################
    useEffect(() => {
        setGridFilters(settings.gridConfig.filterConfigs)
        setGridFilterStates(FilterComponentUtil.createInitialFilterStates(settings.gridConfig.filterConfigs))
    }, [settings.gridConfig.filterConfigs])

    /**
     * When switching between tabs: trigger data loading for the active tab; reset inactive tab
     */
    useEffect(() => {
        if (tabPaneContext?.isTabActive) {
            if (currentGridEntriesCount) {
                // reload data grid data if the tab is active
                loadDataForDataSettingsState()
            }
        } else {
            updateLoadingState(false)
            // if tab is not active, reset all row selections (so that when switching back
            // to the now deactivated tab we don't initially see the old selection anymore)
            resetSelectedRowIndices()
        }
    }, [tabPaneContext?.isTabActive])

    useEffect(() => {
        log.debug('useEffect for componentDidMount')

        // this callback will be executed when the grid is removed; make sure to cancel any requests that are still open
        return cancelCurrentLoadingState
    }, [])

    /**
     * Load grid data with initial configs
     */
    useEffect(() => {
        setInitialized(false)

        log.debug('useEffect for props.settings and props.appContext')
        log.info('props.settings or props.appContext changed')

        const { settings } = props.settings

        // reset and set initial default state
        updateRows(undefined)
        updateSelectedRowIndices([])
        setGridState(prev => {
            return {
                ...prev,
                userNotAuthorized: false,
            }
        })

        const newDataSettingsState = generateOriginalDataSettings(settings)

        // check whether all required filters are set
        if (toolsContext?.contextInitialized && areRequiredFiltersSet(newDataSettingsState.settings.requiredFilters, combineGridAndToolsFilters(), props)) {
            loadDataAsync(newDataSettingsState, combineGridAndToolsFilters(), additionalFilterContext.additionalFilters)
        }

        setDataSettingsState(newDataSettingsState)
        setInitialized(true)
    }, [props.settings.settings.path, props.appContext])

    /**
     * Reload data when some tools configs have been changed
     */
    useEffect(() => {
        // check whether all required filters are set
        if (initialized && areRequiredFiltersSet(dataSettingsState.settings.requiredFilters, combineGridAndToolsFilters(), props)) {
            setDataSettingsState(prev => {
                const newDataSettingsState = {
                    ...prev,
                    searchTerm: toolsContext?.searchTerm,
                    timespanSettings: toolsContext?.timespanSettings,
                }

                loadDataForNewDataSettingsState(newDataSettingsState)
                return newDataSettingsState
            })
        }
    }, [
        JSON.stringify(toolsContext?.filterStates?.map(filterState => filterState.value)),
        toolsContext?.filters,
        toolsContext?.timespanSettings,
        toolsContext?.searchTerm,
        JSON.stringify(gridFilterStates?.map(filterState => filterState.value)),
    ])

    useEffect(() => {
        log.debug('useEffect for downloadProcessing')

        if (downloadProcessing === true || toolsContext?.downloadProcessing === true) {
            const combinedFilter = ConditionClauseService.getCombinedFilterClause(
                combineGridAndToolsFilters(),
                additionalFilterContext.additionalFilters,
                GridUtil.getSearchTerm(dataSettingsState.columns, dataSettingsState.supportedSearchColumns, dataSettingsState.searchTerm)
            )

            downloadExportData(dataSettingsState, combinedFilter)
                .finally(() => {
                    setDownloadProcessing(false)
                    toolsContext?.updateDownloadProcessing(false)
                })
        }
    }, [downloadProcessing, toolsContext?.downloadProcessing])

    useEffect(() => {
        if (initialized) {
            onClickOnContextMenuAction(toolsContext?.actionState?.action.identifier, getSelectedRowIndices())
        }
    }, [toolsContext?.actionState])

    // #####################################################################################################################################

    const onGridFilterChange = (filterIdentifier: string, value: string | number | string[] | number[]) => {
        const updatedFilterStates = gridFilterStates.map(filterState => {
            if (FilterComponentUtil.getFilterFormValueColumn(filterState) === filterIdentifier) {
                filterState.value = value
            }

            return filterState
        })

        setGridFilterStates(updatedFilterStates)
        updateDependentFilters(filterIdentifier, value)
    }

    /**
     * Finds the dependent filters and sets additionalFilters to them so that they react to the change
     *
     * @param filterIdentifier
     * @param value
     */
    const updateDependentFilters = (filterIdentifier: string, value: string | number | string[] | number[]) => {
        const changedFilterConfig: FilterConfigDTO = gridFilters.find(filter => {
            return FilterComponentUtil.getFilterFormValueColumn(filter) === filterIdentifier
        })
        const changedFilterState: FilterState = {
            selectFormElement: changedFilterConfig.selectFormElement,
            value: value,
        }

        const conditionClauseDTO = ConditionClauseService.buildFilterQuery([changedFilterState])

        let dependentFilterFound = false
        const updateFilters = gridFilters.map(dependentFilter => {
            const filterDependsOnTheChangedFilter =
                FilterComponentUtil.getFilterDependsOn(dependentFilter)
                    ?.some(entry => entry.filterIdentifier === filterIdentifier)

            if (filterDependsOnTheChangedFilter) {
                dependentFilterFound = true
                dependentFilter.selectFormElement.additionalFilters = conditionClauseDTO
            }

            return dependentFilter
        })

        if (dependentFilterFound) {
            setGridFilters(updateFilters)
        }
    }

    const scrollToTop = () => {
        const elements = document.querySelectorAll(`.${getWrapperClassName(dataSettingsState.mainDimension)} .ant-spin-container`)
        elements.forEach(element => element.scrollTop = 0)
    }

    /**
     * Loads asynchronously grid data and updates grid states in the "then" callback. On start the loading state will be enabled,
     * after the request is done the loading state will be disabled.
     *
     * @param dataSettingsState
     * @param filters
     * @param additionalFilters
     */
    const loadDataAsync = (dataSettingsState: DataSettingsState, filters: FilterState[], additionalFilters?: ConditionClauseDTO): void => {
        enableLoadingState()

        // cancel possible running requests, if they run with this cancel token
        const newCancelToken = cancelRunningRequestsAndGenerateNewToken()

        // combine filters, additionalFilters and the search term to one ConditionClauseDTO
        const combinedFilter = ConditionClauseService.getCombinedFilterClause(
            filters,
            additionalFilters,
            GridUtil.getSearchTerm(dataSettingsState.columns, dataSettingsState.supportedSearchColumns, dataSettingsState.searchTerm)
        )

        DataGridService
            .loadData(dataSettingsState, combinedFilter, newCancelToken)
            .then((loadResponseDTO: LoadResponseDTO | ApiErrorDTO) => {
                const isError = !!loadResponseDTO['errors'] || (
                    // loadResponseDTO should never have a status property but is has sometimes
                    // we need to find out where that DTO format comes from: UI-1492
                    // @ts-ignore
                    loadResponseDTO.httpStatus == 'INTERNAL_SERVER_ERROR' || (loadResponseDTO.status && loadResponseDTO.status == 500)
                )

                if (isError) {
                    loadDataErrorCallback(loadResponseDTO as ApiErrorDTO)
                } else {
                    loadDataSuccessCallback(loadResponseDTO as LoadResponseDTO, dataSettingsState, filters, additionalFilters)
                }
            }).finally(disableLoadingState)
    }

    /**
     * Cancels possible running requests, if they run with this cancel token,
     * and generates a new one.
     */
    const cancelRunningRequestsAndGenerateNewToken = (): CancelTokenSource => {
        gridState.cancelToken?.cancel()

        const newCancelToken = DataGridService.getCancelTokenSource()
        setGridState(prev => {
            return { ...prev, cancelToken: newCancelToken }
        })

        return newCancelToken
    }

    /**
     * Callback, that will be executed if the grid data (loaddata requests) are loaded and there are no errors
     *
     * @param loadResponseDTO
     * @param dataSettingsState
     * @param filters
     * @param additionalFilters
     */
    const loadDataSuccessCallback = (loadResponseDTO: LoadResponseDTO, dataSettingsState: DataSettingsState, filters: FilterState[], additionalFilters?: ConditionClauseDTO) => {
        updateSelectedRowIndices([])

        const rows =
            ActionService.enrichRowsWithActions(
                loadResponseDTO.dataSet,
                dataSettingsState,
                settings.actions,
                null,
                () => loadDataAsync(dataSettingsState, filters, additionalFilters),
                additionalFilters,
            )

        updateRows(rows)

        if (loadResponseDTO.paginationInfo && currentGridEntriesCount !== loadResponseDTO.paginationInfo.totalEntities) {
            setCurrentGridEntriesCount(loadResponseDTO.paginationInfo.totalEntities)
        }

        scrollToTop()
    }

    /**
     * Callback, that will be executed if the loaddata request has error
     */
    const loadDataErrorCallback = (e: ApiErrorDTO) => {
        if (axios.isCancel(e) || e.message === 'Cancel') {
            log.debug('Request canceled')
        } else {
            message.error(`We're sorry, an unexpected error occurred while loading your data.`, 5)
            log.error('GenericDataGridWidget - data rows could not be loaded: ', e.message)

            updateSelectedRowIndices([])
            updateRows(undefined)
            setCurrentGridEntriesCount(0)
        }
    }

    /**
     * Sets loading state to true
     */
    const enableLoadingState = () => updateLoadingState(true)

    /**
     * Sets loading state to false
     */
    const disableLoadingState = () => updateLoadingState(false)

    /**
     * Saves loadingState
     *
     * @param isGridLoading
     */
    const updateLoadingState = (isGridLoading: boolean) => {
        setLoadingState({ isGridLoading })
    }

    const loadDataForDataSettingsState = () => loadDataForNewDataSettingsState(dataSettingsState)
    const loadDataForNewDataSettingsState = (dataSettingsState: DataSettingsState) =>
        loadDataAsync(dataSettingsState, combineGridAndToolsFilters(), additionalFilterContext.additionalFilters)

    /**
     * Combines filters from the grid and from the tools aware panel.
     * At the moment we prefer grid filters if some are configured.
     * Otherwise, returns tools aware panel filters.
     */
    const combineGridAndToolsFilters = (): FilterState[] => {
        if (gridFilterStates?.length > 0) {
            return gridFilterStates
        } else {
            return toolsContext.filterStates
        }
    }

    /**
     * Cancels all requests for the current loading state
     */
    const cancelCurrentLoadingState = () => {
        const { cancelToken, filterCancelToken } = gridState

        cancelToken?.cancel()
        filterCancelToken?.cancel()
        setGridState(prev => {
            return {
                ...prev,
                cancelToken: undefined,
                filterCancelToken: undefined,
            }
        })
    }

    const onSort = useCallback((orderBy: string, sortAscending: boolean) => {
        const newDataSettingsState = {
            ...dataSettingsState,
            pagination: {
                ...dataSettingsState.pagination,
                sortProperties: [orderBy],
                sortAscending: sortAscending,
            } as PageableDTO,
        }

        setDataSettingsState(newDataSettingsState)
        loadDataForNewDataSettingsState(newDataSettingsState)
    }, [dataSettingsState, getRows()])

    /**
     * TODO: add generic download handling
     */
    const onDownload = useCallback(() => {
        setDownloadProcessing(true)
    }, [])

    const onPageChange = (newPage: number, newPageSize: number) => {
        const { pagination } = dataSettingsState
        const newDataSettingsState = {
            ...dataSettingsState,
            pagination: { ...pagination, page: newPage - 1, pageSize: newPageSize },
        } as DataSettingsState

        setDataSettingsState(newDataSettingsState)
        loadDataForNewDataSettingsState(newDataSettingsState)
    }

    const showResults = areRequiredFiltersSet(dataSettingsState.settings.requiredFilters, combineGridAndToolsFilters(), props)
    const { userNotAuthorized } = gridState
    const { columns, pagination } = dataSettingsState
    const {
        title,
        toolbar,
        actions,
        // don't show download button because it will be shown on the tools panel level
        showDownload = false,
        showSettings = false,
        supportsRowSelection = actions?.length > 0,
        showFooter = true,
    } = settings

    // there is no need to hide the grid content as long as we know which columns to display; we want to show header and pagination as quickly
    // as possible, even if rows are still loading
    const hideContent = !columns
    const hidePagination = !columns || !getRows()

    /**
     * get data rows from grid page for indices
     *
     * @param indices
     */
    const getRowsForIndices = (indices: number[]): GridDataRowDTO[] => {
        const result = getRows()?.rows?.filter((row, index) =>
            _includes(indices, index),
        )

        return result ? result : []
    }

    /**
     * Callback, that will be invoked on clicking to some context menu action
     */
    const onClickOnContextMenuAction = useCallback((actionIdentifier: ActionIdentifier, invokeRowIndices: number[]) => {
        const action: ActionDTO = actions.find(action => action.identifier === actionIdentifier)

        ActionService.invokeAction(
            action,
            dataSettingsState,
            getRowsForIndices(invokeRowIndices),
            null,
            () => loadDataForNewDataSettingsState(dataSettingsState),
            ConditionClauseService.filterConditionClauseBySupportedFilters(additionalFilterContext.additionalFilters, action.supportedAdditionalFilters),
        )
    }, [dataSettingsState, getRows(), actions])

    const toolbarOnInvoke = useCallback((action: ActionDTO) => {
        onClickOnContextMenuAction(action.identifier, getSelectedRowIndices())
    }, [dataSettingsState, getRows(), actions, getSelectedRowIndices()])

    const eligibleSelectedRowsWithMemo = useMemo(() => {
        return GridUtil.eligibleSelectedRows(getSelectedRowIndices(), getRows())
    }, [dataSettingsState.columns, getSelectedRowIndices()/*, getRows()*/])

    return (
        <div className={'datagrid-widget'}>

            <div className={'content-header'}>
                <div className="widget-header">
                    <WidgetHeader title={title} showSettings={showSettings} showDownload={false} onDownload={undefined}
                                  renderSettings={() => <DataGridSettingsToolbar/>}/>
                </div>
            </div>
            <div className={'content-body'}>
                <div className={'datagrid-controls panel-controls'}>
                    <div className="datagrid-filters-and-search panel-form-elements">
                        <FilterContainer filters={settings.gridConfig.filterConfigs} filterOnChange={onGridFilterChange}/>
                        <GenericDataGridSearchForm hasSearch={settings.hasSearch}/>
                        {(toolbar || showDownload) &&
                            <ToolbarComponent config={toolbar}
                                              actions={actions}
                                              onInvoke={toolbarOnInvoke}
                                              showDownload={showDownload}
                                              disableButtons={hideContent || !showResults}
                                              onDownload={onDownload}
                                              downloadProcessing={downloadProcessing}
                                              selectedRows={eligibleSelectedRowsWithMemo}/>
                        }
                    </div>
                </div>

                {
                    !showResults && !userNotAuthorized &&
                    <div>
                        <Alert
                            message="Not all required filters have been set"
                            description={
                                <> Missing selection
                                    for: <strong> {
                                        dataSettingsState.settings.requiredFilters.filter(filterDimension =>
                                            !areRequiredFiltersSet([filterDimension], toolsContext.filterStates, props),
                                        ).map(filterDimension => filterDimension.displayName).join(', ')
                                    } </strong>
                                </>}
                            type="info"
                            showIcon
                        />
                    </div>
                }


                {
                    userNotAuthorized && <PageErrorMessage type={'info'}
                                                           title={'We’re sorry, but we were unable to load the requested data'}>
                        Your user account might lack permissions to access this
                        area. <p>If you have any questions about this, please reach out to our support team at <a
                        href="mailto:support@exactag.com">support@exactag.com</a>.</p>
                    </PageErrorMessage>
                }

                <Spin spinning={loadingState.isGridLoading || !tabPaneContext?.isTabActive}
                      className={'datagrid-spinner'}
                      wrapperClassName={`datagrid-table-wrapper ${getWrapperClassName(dataSettingsState.mainDimension)}`}>
                    {
                        showResults &&
                        !hideContent &&
                        <DataGrid
                            columns={columns}
                            onSort={onSort}
                            pagination={dataSettingsState.pagination}
                            showFooter={showFooter}
                            supportsRowSelection={supportsRowSelection}
                            onClickOnContextMenuAction={onClickOnContextMenuAction}/>
                    }
                </Spin>

                <div className={'main-pagination-wrapper'}>
                    {showResults && !hidePagination && <div className={'main-pagination'}>
                        <Pagination page={pagination.page} pageSize={pagination.pageSize} sortProperties={[]} totalEntities={currentGridEntriesCount} onPageChange={onPageChange}/>
                    </div>}
                </div>
            </div>

        </div>
    )
}

export default withWidgetContext(GenericDataGridWidget)
