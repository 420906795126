import * as React from 'react'
import LegacyWidgetContext from './LegacyWidgetContext'

const withWidgetContext = (Component) => (props) => {
    return (
        <LegacyWidgetContext.Consumer>
            {({ settings, update }) => <Component settings={new LegacyWidgetContext(settings, update)} {...props}/>}
        </LegacyWidgetContext.Consumer>
    )
}

export default withWidgetContext
