import React, { ReactElement, useContext } from 'react'
import { contextMenu as CM } from 'react-contexify'
import { ADSPEND_SCENARIO_DIMENSION_IDENTIFIER, AdSpendOptimizerContext } from 'domain/adspend-optimizer/context/AdSpendOptimizerContext'
import { Col, ConfigProvider, Modal, Row, Table } from 'antd'
import { AntTableColumn, ColumnConfigDTO, GridDataRowDTO } from 'domain/types'
import ScenarioGridContextMenu from 'domain/adspend-optimizer/component/scenario-grid/ScenarioGridContextMenu'
import ScenarioGridInlineMenu from 'domain/adspend-optimizer/component/scenario-grid/ScenarioGridInlineMenu'
import { CONTEXT_MENU_FIELD_ID, customizeRenderEmpty } from 'domain/datagrid/component/DataGrid'
import { columnRenderer } from 'shared/component/renderers/renderers'
import { v1 as uuid } from 'uuid'
import GridUtil from 'domain/widget/generic/GridUtil'
import DataGridService from 'domain/datagrid/service/datagrid.service'

/**
 * Renders our default header layout for non sortable columns
 */
const defaultHeader = (label: string): ReactElement => {
    return <span key={uuid()} data-tip={label} data-force-tooltip={true}>{label}</span>
}

type ScenarioGridType = {
    dataRows: GridDataRowDTO[],
    showGrid: number,
}

export default (scenarioGridType: ScenarioGridType) => {
    // const [loading, setLoading] = useState({ loading: false, scenarioId: undefined })

    const asoContext = useContext(AdSpendOptimizerContext)

    // useEffect(() => {
    //     if (asoContext.scenarioLoading) {
    //         setLoading(asoContext.scenarioLoading)
    //     }
    // }, [asoContext.scenarioLoading])

    const invokeAction = (button: any) => {
        switch (button.action.identifier) {
            case 'SELECT':
                onSelect(button.item.id)
                break
            case 'EDIT':
                onEdit(button.item.id)
                break
            case 'DELETE':
                onDelete(button.item.id)
                break
        }
    }

    const onSelect = (id: number) => {
        const select = scenarioId => scenarioGridType.showGrid === 1
            ? asoContext.selectFirstScenario(scenarioId)
            : asoContext.selectSecondScenario(scenarioId)
        asoContext && select(id)
    }

    const onEdit = (id: number) => {
        asoContext && asoContext.toggleScenarioForm(null, id)
    }

    const onDelete = (id: number) => {
        const scenario = asoContext.allScenarios.find(s => s.adspend_scenario_id === id)
        Modal.confirm({
            title: 'Please Confirm Deletion',
            content: `Are you sure you want to delete '${scenario ? scenario.adspend_scenario_name : id}'?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                if (id !== undefined) {
                    asoContext && asoContext.deleteScenario(id)
                }
            },
        })
    }

    const inlineMenu = (value) => {
        return (<ScenarioGridInlineMenu buttons={value.buttons} handleAction={invokeAction}/>)
    }

    const columns = asoContext.scenarioGridColumns
        ? GridUtil.mapColumns(
            asoContext.scenarioGridColumns,
            (column: ColumnConfigDTO) => {
                return column.columnIdentifier === CONTEXT_MENU_FIELD_ID
                    ? inlineMenu
                    : column.gridColumnProperties.renderer
                        ? columnRenderer(column)
                        : null
            },
            (column: ColumnConfigDTO) => {
                return defaultHeader(column.gridColumnProperties.columnHeader)
            },
            (column: AntTableColumn) => () => ({}),
        )
        : []
    const rows = scenarioGridType.dataRows ? scenarioGridType.dataRows.map(s => ({ ...s } as GridDataRowDTO)) : []

    const onRow = (row, rowIndex) => {
        return {
            onContextMenu: (event) => {
                event.preventDefault()
                // @ts-ignore
                CM.show({
                    id: 'scenario-grid-context-menu',
                    event: event,
                    props: { item: row[`${ADSPEND_SCENARIO_DIMENSION_IDENTIFIER}.value`]?.value },
                })
            },
            onDoubleClick: (event) => {
                event.preventDefault()
                onSelect(row[`${ADSPEND_SCENARIO_DIMENSION_IDENTIFIER}.value`]?.value)
            },
        }
    }

    const data = rows.map((row, index) => ({...DataGridService.convertRowToTableStructure(row), key: index}))
    return (
        <div className={'scenario-grid'}>
            {scenarioGridType.showGrid > 0 &&
            <Row>
                <ScenarioGridContextMenu menuId={'scenario-grid-context-menu'}
                                         handleSelect={onSelect}
                                         handleEdit={onEdit}
                                         handleDelete={onDelete}/>
                <Col>
                    <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <Table
                            className={'ant-table ant-table-small data-grid-table data-grid-selectable'}
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            onRow={onRow}
                            onHeaderRow={(columns) => {
                                return { className: 'ant-table-thead' }
                            }}
                        />
                    </ConfigProvider>
                </Col>
            </Row>
            }
        </div>
    )
}
