import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Input, Row } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { SystemService } from 'domain/core/service'
import { passwordResetService } from 'domain/password/service'
import { FormResponseDTO } from 'domain/types'

const PasswordResetRequestFormPage: React.FC = (): JSX.Element => {

    const [form] = Form.useForm()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [resetDone, setResetDone] = useState(false)
    const [response, setResponse] = useState(undefined)

    const [, forceUpdate] = useState() // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({})
    }, [])

    const handleFormResponse = (formResponse: FormResponseDTO) => {
        if (formResponse.successful) {
            setIsSubmitting(false)
            setResetDone(true)
            setResponse({
                type: 'info',
                message: <div>
                    <p>Please check your inbox for the reset e-mail.</p>
                    <p>If you <strong>did not</strong> receive the e-mail, please consider the following:</p>
                    <ul>
                        <li>Is your username or e-mail address spelled correctly?</li>
                        <li>Please ensure your username is case-correct.</li>
                        <li>If you entered an e-mail address which is used for multiple user accounts, you will not be able to receive the reset mail.
                            Please enter your username instead.
                        </li>
                    </ul>
                    <p>Our help center provides more details about <a target={'_blank'} href={'https://ticket.exactag.com/hc/en-us/articles/4403793146002'}>why you might be unable to log in</a> and <a
                        target={'_blank'} href={'https://ticket.exactag.com/hc/en-us/articles/4403793305106'}>why you might not be receiving the reset mail</a>. If you keep experiencing issues, please contact <a
                        href="mailto:support@exactag.com">support@exactag.com</a>.
                    </p>
                </div>,
            })

            return
        }

        if (formResponse.errorMsg) {
            setResponse({
                type: 'error',
                caption: 'Password reset request failed.',
                message: <div><strong>An error occurred while trying to request a password reset.</strong>
                    <p>This could be caused by requesting a reset too frequently. If that is the case,
                        please wait a little while before your next attempt or contact our <a
                            href="mailto:support@exactag.com">support team</a>.</p>
                </div>,
            })
        } else {
            setResponse(undefined)
        }

        const errors = formResponse.errors && formResponse.errors.userNameOrEmail || []
        if (errors && errors.length > 0) {
            form.setFields([
                { name: 'userNameOrEmail', errors: errors },
            ])
        }

        setIsSubmitting(false)
        setResetDone(false)
    }

    const handleBackToLogin = (_) => {
        SystemService.showCoreUi()
    }

    // onFinish is only triggered when validation has already passed; no further validation is needed here
    const onFinish = (values) => {
        setIsSubmitting(true)
        passwordResetService
            .requestReset(values.userNameOrEmail)
            .then(handleFormResponse, handleFormResponse)
    }

    return (
        <Row justify="center">
            <div className={'content-body standalone-form password-reset-request-form'}>
                <h3>Reset Your Password</h3>

                {response && <Alert showIcon className="form-response-alert" type={response.type} message={response.message}/>}

                {!response && <div>Please enter your username or email address to reset your password:</div>}

                <Row style={{ marginTop: 16 }}>
                    <Col span={24}>
                        <Form form={form} onFinish={onFinish}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    {!response &&
                                    <Form.Item name="userNameOrEmail" rules={[
                                        { required: true, message: 'Please input your username or email address' },
                                        { max: 255, message: 'Username or email address must not be longer than 255 characters' },
                                    ]}>
                                        <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                               disabled={isSubmitting || resetDone}
                                               size={'large'}
                                               placeholder="Username or email address"/>
                                    </Form.Item>
                                    }
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button style={{ width: '100%' }} type="dashed" htmlType="button"
                                                size={'large'}
                                                onClick={handleBackToLogin}>
                                            Back to Login
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            !response &&
                                            <Button style={{ width: '100%' }} type="primary" htmlType="submit"
                                                    loading={isSubmitting}
                                                    size={'large'}
                                                    disabled={
                                                        !form.isFieldsTouched(true) ||
                                                        form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                                        resetDone
                                                    }>
                                                Reset Password
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Row>
    )
}

export default PasswordResetRequestFormPage
