import { ColumnResponseDTO, PaginationInfoDTO, SelectFormElementDTO } from 'domain/types'

export interface ConditionClauseDTO {
    clauseType: ConditionClauseType
}

export interface TrueClauseDTO extends ConditionClauseDTO {
}

export interface BooleanClauseDTO extends ConditionClauseDTO {
    clauses: ConditionClauseDTO[]
    operator: BooleanOperator
}

export interface NotClauseDTO extends ConditionClauseDTO {
    clause: ConditionClauseDTO
}

export interface ColumnNameClauseDTO extends ConditionClauseDTO {
    columnName: string
}

export interface NullabilityClauseDTO extends ColumnNameClauseDTO {
}

interface ValueOrSetClauseDTO extends ColumnNameClauseDTO {
    type: ClauseType
    // clauseType: ConditionClauseType
}

export interface BooleanValueClauseDTO extends ValueOrSetClauseDTO {
    value: boolean
}

export interface NumberValueClauseDTO extends ValueOrSetClauseDTO {
    value: number
}

export interface StringValueClauseDTO extends ValueOrSetClauseDTO {
    value: string
}

export interface NumberSetClauseDTO extends ValueOrSetClauseDTO {
    values: number[]
}

export interface StringSetClauseDTO extends ValueOrSetClauseDTO {
    values: string[]
}

export enum ConditionClauseType {
    TRUE = 'True',
    BOOLEAN = 'Boolean',
    IS_NOT_NULL = 'IsNotNull',
    IS_NULL = 'IsNull',
    NOT = 'Not',
    NUMBER_SET = 'NumberSet',
    BOOLEAN_VALUE = 'BooleanValue',
    NUMBER = 'Number',
    STRING_SET = 'StringSet',
    STRING = 'String',
}

export enum BooleanOperator {
    AND = 'AND',
    OR = 'OR'
}

export enum ClauseType {
    CONTAINS = 'CONTAINS',
    EQUALS = 'EQUALS',
    REGEX = 'REGEX',
    IN = 'IN',
}

export type InputConfigDTO = {
    label: string
    valueColumn: string
    dependsOn?: any
    sortOrder: number
}

export type FilterConfigDTO = {
    selectFormElement?: SelectFormElementDTO
    supportedFilterModes: ClauseType[]
    selectedFilterMode: ClauseType,
    selectedFilterValues: any[],
    // filterMode?: string[]
}

// data class FilterConfigDTO(
//     val selectConfig: SelectConfigDTO,
//     val filterMode: Set<ClauseType>
// )

export enum FilterType {
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    TEXT = 'TEXT'
}

export type DimensionValueDTO = {
    name: string,
    value: any
}

export type DimensionValueListDTO = {
    entries: ColumnResponseDTO[],
    paginationInfo?: PaginationInfoDTO
}
export type DimensionValueFilterConfig = {
    dimension: string
    dimensionValueDTO: DimensionValueListDTO
}

export type AppContextDTO = {
    advertiserId: number,
    campaignId?: number,
    subCampaignId?: number,
    lineItemId?: number,

    advertiserName: string,
    campaignName?: string,
    subCampaignName?: string,
    lineItemName?: string,
};
