import React, { memo } from 'react'
import LayoutElement from 'shared/component/layout/LayoutElement'
import { ContainerElementProperties } from 'domain/types'

const LayoutRenderer: React.FC<ContainerElementProperties> = (props: ContainerElementProperties): JSX.Element =>
    <LayoutElement {...props}/>

export default memo((props: ContainerElementProperties): JSX.Element =>
    <LayoutRenderer {...props}/>,
)
