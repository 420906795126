import crypto from 'crypto'

const PasswordService = {
    //FIXME this simulates the MySQL `PASSWORD` function and must be improved once the passwords are stored differently
    hash: (password: string): string => {
        const hash1 = crypto.createHash('sha1')
        hash1.update(password)
        const hash2 = crypto.createHash('sha1')
        hash2.update(hash1.digest('latin1'), 'latin1')
        return '*' + hash2.digest('hex').toUpperCase()
    }
}

export default PasswordService
