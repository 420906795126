export enum MfaFactor {
    Authenticator = "AUTHENTICATOR",
    Email = "EMAIL"
}

export type MfaFactorConfigDTO = {
    factor: MfaFactor,
    setupComplete: boolean,
    rememberMeDays: number,
    userName: string,
}

export type AvailableFactorsResponseDTO = {
    availableFactors: MfaFactorConfigDTO[]
}

export type OtpSetupResponseFormDTO = {
    secret: string,
    qrCodeContent: string,
}

export type OtpCodeFormDTO = {
    code: string,
}

export type OtpFormResponseDTO = {
    successful: boolean,
    errorMsg: string,
    errors: { [key: string]: string[] },
}
