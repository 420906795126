import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { DimensionValueListDTO, FormSelectElementProperties} from 'domain/types'
import { useFormContext } from 'shared/component/forms/context/FormContextHook'
import { formItemLayout } from 'shared/component/layout/FormElement'
import { formValidator } from 'shared/util/validation'
import { ElementSetting, useRootElementContext } from 'shared/component/layout/context/RootElementContext'
import FilterComponentUtil from 'domain/filter/component/FilterComponentUtil'
import LayoutUtil from 'shared/util/LayoutUtil'

const Option = Select.Option

const FormSelectElement: React.FC<FormSelectElementProperties> = ({ onChangeListener, editable, layoutElementConfig, showNotSetOption, showNoChangeOption, label, valueIncludesLabel = false }: FormSelectElementProperties): JSX.Element => {
    const context = useFormContext()
    const { updateElementSettings } = useRootElementContext()

    const columnName: string = layoutElementConfig.formFieldConfig.dimensionIdentifier
    const hasSelectEntries = !!layoutElementConfig?.selectConfig?.selectEntries?.entries
    const validation = formValidator(layoutElementConfig.formFieldConfig.validation)

    const [selectedEntries, setSelectedEntries] = useState<DimensionValueListDTO>()

    const getSelectEntries = () => {
        const elementDTO = LayoutUtil.findSelectElements(context?.uiFormConfig?.formConfig?.layoutConfig).find(el => el?.formFieldConfig?.dimensionIdentifier == columnName)
        return elementDTO?.selectConfig?.selectEntries
    }

    useEffect(() => {
        setSelectedEntries(getSelectEntries())
    }, [context.uiFormConfig])

    const onChange = (value, options) => {
        // set to 'Not Set' when clear button is clicked
        if (value === undefined && showNotSetOption) {
            context.setField(layoutElementConfig.formFieldConfig.dimensionIdentifier, null)
        }

        if (layoutElementConfig.useAsSetting) {
            updateElementSettings({ key: layoutElementConfig.formFieldConfig.dimensionIdentifier, value: value } as ElementSetting)
        }

        // when clicking on clear icon on the select component, options is undefined
        const textValue = options?.children ? options?.children : null
        if (onChangeListener) {
            onChangeListener({ value, textValue })
        }
    }

    return <Form.Item className={'form-item-select'} {...formItemLayout} label={label} extra={layoutElementConfig.formFieldConfig.infoText} name={columnName} rules={editable ? validation : []}>
        <Select className={'filter-input ' + layoutElementConfig.formFieldConfig?.dimensionIdentifier}
                size={'middle'}
                filterOption={true}
                disabled={!hasSelectEntries && !editable}
                loading={context.loadingFieldStates[layoutElementConfig.formFieldConfig.dimensionIdentifier] || false}
                onChange={onChange}
                allowClear={true}
                dropdownClassName={layoutElementConfig.formFieldConfig?.dimensionIdentifier}
            // if set to true, we will return not just the selected ID but also the selected label (display name); example use case: we want to select
            // publishers in an assignment form and later on display these publishers in a grid; in order to do that, we need more than just the ID,
            // we want to display the item name too
                labelInValue={valueIncludesLabel}
                optionFilterProp={'label'}
                showSearch={true}>
            {showNoChangeOption && (
                <Option key={`idx_no_change_undefined`}
                        label={'No Change'}
                        value={'__NO_CHANGE__'}
                        className={'grayed-out border-bottom'}>
                    No Change
                </Option>
            )}
            {showNotSetOption && (
                <Option key={`idx_null_null`}
                        label={'Not Set'}
                        value={null}
                        className={'grayed-out border-bottom'}>
                    Not Set
                </Option>
            )}
            {selectedEntries?.entries?.map((entry, index) =>
                <Option key={`idx_${index}_${entry.value}`}
                        label={FilterComponentUtil.getFilterValue(entry)}
                        value={entry.value}>
                    {FilterComponentUtil.getFilterValue(entry)}
                </Option>,
            )}
        </Select>
    </Form.Item>
}

export default FormSelectElement
