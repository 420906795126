import React, { useContext } from 'react'
import { Form, Switch } from 'antd'
import { FormToggleButtonElementProperties } from 'domain/types'
import FormContext from 'shared/component/forms/context/FormContext'
import { ElementSetting, useRootElementContext } from 'shared/component/layout/context/RootElementContext'

const FormToggleButtonElement: React.FC<FormToggleButtonElementProperties> = ({ layoutElementConfig, onChangeListener }: FormToggleButtonElementProperties): JSX.Element => {
    const formContext = useContext(FormContext)
    const { updateElementSettings } = useRootElementContext()

    const identifier = layoutElementConfig.formFieldConfig.dimensionIdentifier

    /**
     * onChange handler, that updates context settings for the element if the element has  useAsSetting=true
     *
     * @param checked
     */
    const onChange = (checked) => {
        if (layoutElementConfig.useAsSetting) {
            updateElementSettings({ key: identifier, value: checked } as ElementSetting)
        }

        if (onChangeListener) {
            onChangeListener(checked)
        }
    }

    /**
     * If the element belongs to the form context, then wrap the element with Form.Item,
     * so that is is possible to set its initial value. An example for toggle elements that do not have a form context are the expert mode
     * toggle buttons. They will not be wrapped in a Form.Item and do therefore not support certain features such as the "extra" info text.
     */
    return formContext
        ?
        <Form.Item name={identifier} extra={layoutElementConfig.formFieldConfig.infoText} valuePropName={'checked'}>
            <Switch defaultChecked={layoutElementConfig.formFieldConfig.defaultValue} onChange={onChange}
                    className={'toggle-button ' + layoutElementConfig.formFieldConfig?.dimensionIdentifier}/> layoutElementConfig.formFieldConfig.displayName
        </Form.Item>
        : <React.Fragment>
            <Switch defaultChecked={layoutElementConfig.formFieldConfig.defaultValue} onChange={onChange} className={'toggle-button ' + layoutElementConfig.formFieldConfig?.dimensionIdentifier}/>
            {layoutElementConfig.formFieldConfig.displayName}
        </React.Fragment>
}

export default FormToggleButtonElement
