import { Col, Row } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { FormCustomListener, FormElementDTO, FormElementProperties, FormSelectElementProperties, LayoutElementType, SelectFormElementDTO } from 'domain/types'
import FormContext from 'shared/component/forms/context/FormContext'
import FormInputElement from 'shared/component/forms/FormInputElement'
import FormNumberInputElement from 'shared/component/forms/FormNumberInputElement'
import FormSelectElement from 'shared/component/forms/FormSelectElement'
import FormTextareaElement from 'shared/component/forms/FormTextareaElement'
import FormToggleButtonElement from 'shared/component/forms/FormToggleButtonElement'
import { useRootElementContext } from 'shared/component/layout/context/RootElementContext'
import FormUtil from 'shared/util/FormUtil'
import LayoutUtil from 'shared/util/LayoutUtil'
import FormCheckboxElement from 'shared/component/forms/FormCheckboxElement'
import DimensionService from 'domain/dimension/service/DimensionService'
import { Channel } from 'Constants'

export const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
}

export type NonEditableFormElementProps = {
    layoutElementConfig: FormElementDTO
    value: any
}

export const NonEditableFormElement = ({ layoutElementConfig, value }: NonEditableFormElementProps): JSX.Element => {
    const label = layoutElementConfig.formFieldConfig.displayName || ''
    const reason = layoutElementConfig.formFieldConfig.readOnlyReason || 'This field cannot be edited.'

    return <Row key={`${layoutElementConfig.formFieldConfig.dimensionIdentifier}_form_row`} className="ant-form-item disabled-in-multi-edit">
        <Col span={formItemLayout.labelCol.span} className="ant-form-item-label disabled-label">
            <label>{label}</label>
        </Col>
        <Col span={formItemLayout.wrapperCol.span} className="ant-form-item-control-wrapper">
            <div className={'ant-form-item-control disabled-value-row ' + layoutElementConfig.formFieldConfig?.dimensionIdentifier}>
                {value && <span className="disabled-value">{value}</span>}
                <span className="disabled-reason">{reason}</span>
            </div>
        </Col>
    </Row>
}

const FormElement: React.FC<FormElementProperties> = ({ layoutElementConfig }: FormElementProperties): JSX.Element => {
    const formContext = useContext(FormContext)
    const { elementSettings } = useRootElementContext()
    const [isEditable, setIsEditable] = useState(true)
    const [disabled, setDisabled] = useState(false)

    const identifier = layoutElementConfig.formFieldConfig.dimensionIdentifier

    // /**
    //  * Implementation for custom listeners
    //  */
    // const customFormFieldListeners = {
    //     [FormCustomListener.SUB_CAMPAIGN_CREATE_FORM_ON_CHANNEL_CHANGE]: (channelDropdown) => {
    //         formContext.setField(DimensionService.getDimensionNameColumn('sub_campaign'), channelDropdown.textValue)
    //
    //         const createSameNamedLineItemFieldIdentifier = DimensionService.getDimensionValueColumn('sc_create_same_named_line_item')
    //         if([Channel.SEO, Channel.DIRECT_REFERRED, Channel.DIRECT_TYPEIN].includes(channelDropdown.value)) {
    //             // formContext.setField(createSameNamedLineItemFieldIdentifier, true)
    //             formContext.setReadOnlyElements(prev => [...prev, createSameNamedLineItemFieldIdentifier])
    //         } else {
    //             // formContext.resetField(createSameNamedLineItemFieldIdentifier)
    //             formContext.setReadOnlyElements(prev => prev.filter(element => element !== createSameNamedLineItemFieldIdentifier))
    //         }
    //     },
    //
    // }

    useEffect(() => {
        setDisabled(formContext?.readOnlyElements?.includes(identifier))
        // const disabled = formContext?.readOnlyElements?.includes(identifier)
        // disabled && formContext?.setField(identifier, disabled)
    }, [formContext?.readOnlyElements])

    /**
     * Sets the default values and updated the editable state once after the element is mounted
     */
    useEffect(() => {
        // set the default value when the item is mounted the first time
        if (formContext) {
            // this sets initial value (if available) or the default one
            formContext.setFormElementValueAndUpdateContextSettingsForInitialValues(layoutElementConfig)
        }

        updateIsEditable()
    }, [])

    /**
     * Updates editable state after the formContext settings was changed
     */
    useEffect(() => {
        updateIsEditable()
    }, [elementSettings])

    /**
     * Updates editable state if the formContext settings match the element editableOn conditions
     */
    const updateIsEditable = () => {
        LayoutUtil.evaluateElementSettingWithConditionThenUpdate(
            layoutElementConfig.formFieldConfig?.editableOn,
            elementSettings,
            (newIsEditable: boolean) => {
                if (isEditable !== newIsEditable && formContext) { // "newIsEditable" is the new state, "editable" is the old one; no need to update if old and new state are the same, this could be caused by calling both useEffect methods during intialization
                    if (newIsEditable) {
                        // if element is set to editable, then restore his initial value and update if necessary the formContext settings
                        formContext.setFormElementValueAndUpdateContextSettingsForInitialValues(layoutElementConfig)
                    } else {
                        // if the element is set to readonly and the element has default value, then set the default value into the element
                        // otherwise reset the value
                        if (layoutElementConfig.formFieldConfig?.defaultValue) {
                            formContext.setField(identifier, layoutElementConfig.formFieldConfig?.defaultValue)
                        } else {
                            // reset the field if it is not editable
                            formContext.resetField(identifier)
                        }
                    }

                    updateReadOnlyContextState(newIsEditable)
                }

                setIsEditable(newIsEditable)
            },
        )
    }

    /**
     * Save identifier in the form context as readonly/editable
     *
     * @param isEditable
     */
    const updateReadOnlyContextState = (isEditable: boolean) => {
        formContext.setReadOnlyElements(prev => {
            if (!isEditable) {
                return [...prev, identifier]
            } else {
                return prev.filter(element => element !== identifier)
            }
        })
    }

    const renderFormElement = (elementDTO: FormElementDTO): JSX.Element => {
        const config = {
            formConfig: formContext?.uiFormConfig,
            popupConfig: formContext?.popupConfig,
        }
        const rows = config.formConfig?.itemData
        const isEditMode = formContext ? FormUtil.getIsEditMode(formContext.uiFormConfig.formConfig) : false

        if (!!elementDTO.formFieldConfig.readOnly && isEditMode) {
            const nonEditableValue = FormUtil.getNonEditableValue(elementDTO, rows)

            // if the element is read only, then show NonEditableFormElement
            return <NonEditableFormElement layoutElementConfig={elementDTO} value={nonEditableValue}/>
        }

        const singleEdit = rows?.length === 1
        const label: string = elementDTO.formFieldConfig.displayName //|| dimension?.displayName
        const isRequiredField = elementDTO.formFieldConfig.validation && Array.isArray(elementDTO.formFieldConfig.validation)
            ? elementDTO.formFieldConfig.validation.find(validator => validator.type === 'REQUIRED') !== undefined
            : false
        const showNotSetOption = !isRequiredField
        const showNoChangeOption = (isEditMode && !singleEdit)

        const elementConfig: FormElementProperties = {
                layoutElementConfig: elementDTO,
                editable: isEditable,
                onChangeListener: (value) => {
                    formContext?.onChange(value, identifier)
                    formContext?.customFormFieldListeners[elementDTO.formFieldConfig.onChangeListener]?.(value)
                },
                isRequiredField, showNotSetOption, showNoChangeOption, singleEdit, label, itemData: rows, disabled
            }

    let element
    switch (elementDTO.elementType) {
        case LayoutElementType.FORM_ELEMENT_CHECKBOX: {
            element = <FormCheckboxElement {...elementConfig}/>
            break
        }
        case LayoutElementType.FORM_ELEMENT_TOGGLE_BUTTON: {
            element = <FormToggleButtonElement {...elementConfig}/>
            break
        }
        case LayoutElementType.FORM_ELEMENT_INPUT:
            element = <FormInputElement {...elementConfig}/>
            break
        case LayoutElementType.NUMBER_FORM_ELEMENT_INPUT:
            element = <FormNumberInputElement {...elementConfig}/>
            break
        case LayoutElementType.FORM_ELEMENT_TEXTAREA:
            element = <FormTextareaElement {...elementConfig}/>
            break
        case LayoutElementType.FORM_ELEMENT_SELECT: {
            // why is valueIncludesLabel set here in this way? do we still need it?
            // const { valueIncludesLabel } = element
            // elementConfig['valueIncludesLabel'] = valueIncludesLabel
            const selectElementConfig = elementConfig as FormSelectElementProperties
            selectElementConfig.selectEntries = [(elementDTO as SelectFormElementDTO).selectConfig.selectEntries]
            element = <FormSelectElement {...selectElementConfig}/>
            break
        }
        // this was removed, there is now only "select"; we have to resolve this when it is ever needed
        // but do we even support multi select form elements at the moment?
        // case 'MULTI_SELECT':
        //     Component = <FormMultiSelectComponent {...elementConfig}/>
        //     break
    }

    // we cannot return a <Form.Item> here, because antd forms will not recognize changes or validation updates
    return element
}

return <>
    <div className="form-element-container">
        {renderFormElement(layoutElementConfig)}
    </div>
</>
}

export default FormElement
