import _includes from 'lodash/includes'
import _without from 'lodash/without'

/**
 * Creates a number range
 *
 * @param start
 * @param end
 */
const range = (start, end) => Array.from({ length: (end - start + 1) }, (v, k) => k + start)

/**
 * Single select mode
 */
const getSingleSelectedRows = (selectedRows: number[], selectedRow: number): number[] => {
    let selectedRowsCopy = [...selectedRows]
    if (_includes(selectedRowsCopy, selectedRow)) {
        selectedRowsCopy = _without(selectedRows, selectedRow)
    } else {
        selectedRowsCopy.push(selectedRow)
    }

    return selectedRowsCopy
}

/**
 * Get all selected rows with filled gap
 */
const getSelectedGapRange = (selectedRows: number[], selectedRow: number): number[] => {
    const selectedRowsCopy = [...selectedRows]
    let minGap = 0
    let maxGap = 10000
    selectedRowsCopy.forEach(row => {
        if (row < selectedRow && row > minGap) {
            minGap = row
        }

        if (row > selectedRow && row < maxGap) {
            maxGap = row
        }
    })

    return selectedRowsCopy.concat(range(minGap, maxGap))
}

/**
 * Get all selected rows. If shiftKey is true, then all rows
 * between will be selected.
 */
export const getAllSelectedRows = (selectedRows: number[], selectedRow: number, shiftKey: boolean): number[] => {
    let result = []
    if (shiftKey && selectedRows?.length > 0) {
        const selectedRowsCopy = [...selectedRows]

        const maxRow = selectedRowsCopy.reduce(function(prev, current) {
            return (prev > current) ? prev : current
        })

        const minRow = selectedRowsCopy.reduce(function(prev, current) {
            return (prev < current) ? prev : current
        })

        if (selectedRow <= minRow) {
            result = selectedRowsCopy.concat(range(selectedRow, minRow))
        } else if (selectedRow >= maxRow) {
            result = selectedRowsCopy.concat(range(maxRow, selectedRow))
        } else {
            result = getSelectedGapRange(selectedRows, selectedRow)
        }
    } else {
        result = getSingleSelectedRows(selectedRows, selectedRow)
    }

    return Array.from(new Set(result))
}