import { get } from 'shared/service'
import { PingDTO } from 'domain/types'

declare const oldCoreUiUrl: string

/**
 * Provides system services backed by ApiSystemController in ui-backend
 */
export default class SystemService {

    static reloadPage() {
        window.location.reload()
    }

    static showCoreUi() {
        window.location.href = oldCoreUiUrl
    }

    static logoutAndRedirectCoreUI() {
        window.location.href = oldCoreUiUrl + "/auth/logoutAndRedirect"
    }

    static ping(): Promise<PingDTO> {
        return get('/system/ping', {}, false)
    }
}
