import React, { ReactNode, useContext } from 'react'
import { WidgetContext } from 'domain/widget/WidgetContext'
import DimensionService, { DimensionField } from 'domain/dimension/service/DimensionService'
import { ACTIONS_FIELD } from 'domain/datagrid/component/DataGrid'
import { AxisType } from 'domain/types/backend/widget.types'
import EChartWidget from 'domain/widget/EChartWidget'
import { groupBy } from 'shared/util/util'
import { DataColumnDTO, GridDataRowDTO, MetricDTO } from 'domain/types'
import MetricUtil from 'domain/widget/MetricUtil'
import WidgetDataUtil from 'domain/widget/WidgetDataUtil'
import { log } from 'shared/util/log'

type Props = {
    children?: ReactNode,
}
export const TimeLineWidget = (props: Props) => {

    const widgetDataContext = useContext(WidgetContext)
    const { response } = widgetDataContext

    let echartsOption = null
    if (response) {
        const { widgetSettings } = widgetDataContext
        const axisConfigs = widgetSettings.axisConfigurations
        const xAxes = axisConfigs?.filter(axis => axis.axisType == AxisType.X_AXIS)
        const yAxes = axisConfigs?.filter(axis => axis.axisType == AxisType.Y_AXIS)
        const dimensionFields: { [key: string]: DimensionField } = widgetSettings.querySettings.columnNames
            .filter(columnName => ACTIONS_FIELD !== columnName)
            .map(DimensionService.recognizeDimensionField)
            .reduce((map, dimensionField) => {
                    map[dimensionField.identifier] = dimensionField
                    return map
                }, {},
            )

        const metrics = yAxes.reduce((accumulator, axis) => accumulator.concat(axis.columnIdentifiers), [])
        const dimensions = Object.keys(dimensionFields).filter(columnIdentifier => metrics.indexOf(columnIdentifier) === -1)
        if (dimensions.length > 2) {
            throw new Error(`Maximal 2 dimensions are supported. These dimensions were configured: ${dimensions}`)
        }

        if (dimensions.length < 1) {
            throw new Error(`No dimensions were found`)
        }

        const twoDimensionMode = dimensions.length == 2

        let groupedBySecondDimension: Map<any, any[]>
        if (twoDimensionMode) {
            groupedBySecondDimension = groupBy(response.dataSet?.rows, (row: GridDataRowDTO): any => {
                return WidgetDataUtil.getNameOrValueOrNA(row, dimensions[1])
            })
        } else {
            groupedBySecondDimension = new Map<any, any[]>()
            groupedBySecondDimension.set('void', response.dataSet?.rows)
        }

        const groupedByXAxisDimension = response.dataSet?.rows
            ? groupBy(response.dataSet?.rows, (row: GridDataRowDTO): any => {
                return WidgetDataUtil.getNameOrValueOrNA(row, dimensions[0])
            })
            : new Map<any, any[]>()

        /**
         * Searches in the widgetSettings.dataColumns for the corresponding data column and returns display name
         *
         * @param columnIdentifier
         */
        const getDisplayName = (columnIdentifier: string) => {
            const correspondingDataColumn: DataColumnDTO = widgetSettings.dataColumns.find(dataColumn => dataColumn.identifier === columnIdentifier)
            return correspondingDataColumn?.displayName
                ? correspondingDataColumn?.displayName
                : columnIdentifier
        }

        if (!widgetSettings.dataColumns) {
            log.error('Missing widgetSettings.dataColumns, unable to proceed, widgetSettings: ', widgetSettings)
            return <div>Error</div>
        }

        echartsOption = {
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                // data: response.dataSet?.rows.map(row => row[xAxes[0].axisPropertyName][dimensionFields[xAxes[0].axisPropertyName].fieldType]),
                // data: Array.from(groupedBySecondDimension.keys()).reduce((acc, dimension) => {
                //     return acc.concat(metrics.map(kpi => generateItemName(kpi, dimension)))
                // }, []),
                data: twoDimensionMode
                    ? Array.from(groupedBySecondDimension.keys())
                    : metrics.map(getDisplayName),
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: xAxes.map(xAxis => {
                return {
                    name: xAxis.displayName,
                    nameLocation: 'middle',
                    type: 'category',
                    boundaryGap: false,
                    // data: response.dataSet?.rows.map(row => row[xAxis.axisPropertyName][dimensionFields[xAxis.axisPropertyName].fieldType]),
                    data: Array.from(groupedByXAxisDimension.keys()),
                }
            }),
            yAxis: yAxes.length > 0
                ? yAxes.map(yAxis => {
                    const metricDTO = WidgetDataUtil.getDataColumnByIdentifier(yAxis.columnIdentifiers[0], widgetSettings.dataColumns) as MetricDTO

                    return {
                        name: yAxis.displayName,
                        nameLocation: 'middle',
                        type: 'value',
                        axisLabel: {
                            formatter: MetricUtil.metricValueFormatter(metricDTO, true),
                        },
                    }
                }) : { type: 'value' },
            series: Array.from(groupedBySecondDimension.keys()).reduce((accumulator, publisher) => {

                const onePublisherSeries = metrics.map(metric => {
                        return {
                            name: twoDimensionMode
                                ? publisher
                                : getDisplayName(metric),
                            type: 'line',
                            // data: response.dataSet?.rows.map(row => row[metric][dimensionFields[metric].fieldType]),
                            data: groupedBySecondDimension.get(publisher)?.map(row => row[metric]?.value ? row[metric]?.value : 0) || [],
                            // color: '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0'),
                            // index === 0 ? '#ff0000' : '#00ff00',
                        }
                    },
                )

                return accumulator.concat(onePublisherSeries)
            }, []),
        }

        return <EChartWidget
            echartsOption={echartsOption}
            hasLegend={true}
            isLoading={widgetDataContext.isLoading}
            response={response}/>
    } else {
        return <div className={'initial-loader'}/>
    }

}
