import React, { createContext, ReactNode } from 'react'
import { MenuRootDTO } from 'domain/types'
import { useHistory } from 'react-router-dom'
import MenuUtil from 'shared/service/MenuUtil'
import { log } from 'shared/util/log'

export type ArticleLayoutContextProperties = {
    /**
     * Returns true, if the article was found in the menu configuration
     *
     * @param filename
     */
    openMenuWithFileName?: (filename: string) => boolean
}
export const ArticleLayoutContext = createContext<ArticleLayoutContextProperties>({})

type ArticleLayoutContextProviderProps = ArticleLayoutContextProperties & {
    menu?: MenuRootDTO
    children?: ReactNode
}

export const ArticleLayoutContextProvider: React.FC<ArticleLayoutContextProviderProps> = (props: ArticleLayoutContextProviderProps): JSX.Element => {

    const history = useHistory()

    const context = {
        /**
         * Returns true, if the article was found in the menu configuration
         *
         * @param filename
         */
        openMenuWithFileName: (filename: string): boolean => {
            const nodeEndingWith = MenuUtil.getMenuNodeEndingWith(filename, props.menu)
            const node = nodeEndingWith
                ? nodeEndingWith
                // search for the identifier if the full name was not found
                : MenuUtil.getMenuNodeForIdentifier(filename, props.menu)

            if (node) {
                // navigate to the path
                history.push(node.path)

                return true
            } else {
                log.warn(`menu item for file name "${filename}" not found`)

                return false
            }
        },
    } as ArticleLayoutContextProperties

    return <ArticleLayoutContext.Provider value={context}>
        {props.children}
    </ArticleLayoutContext.Provider>
}
