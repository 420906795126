/* router.js */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PrivateRoute, RedirectTo } from 'shared/component'
import AppContextFromParams from 'domain/core/component/AppContextFromParams'
import AdSpendOptimizer from 'domain/adspend-optimizer/component/AdSpendOptimizer'
import AppContextFromQuery from 'domain/core/component/AppContextFromQuery'
import PasswordResetRequestFormPage from 'domain/password/PasswordResetRequestFormPage'
import PasswordResetFormPage from 'domain/password/PasswordResetFormPage'
import LoginPage from 'LoginPage'
import { AuthenticationState } from 'domain/authentication/redux/authentication.reducer'
import GenericPage from 'domain/core/component/GenericPage'
import { getFirstMenuPaths } from 'shared/util/menu'
import { RootElementContextProvider } from 'shared/component/layout/context/RootElementContext'
import { ArticleLayout } from 'domain/content/ArticleLayout'
import MfaForm from 'domain/onetimepassword/MfaForm'

type Props = {
    authentication: AuthenticationState,
    isEmbedded: boolean,
    configurations: any[]
}

const AppRouter: React.FunctionComponent<Props> = (props: Props): JSX.Element => {

    return <Switch>
        {/* Creates routes from menu json */}
        {createPrivateRoutesFromMenuConfiguration(props.configurations, props.authentication, props.isEmbedded)}

        { /* this route is mainly for development purposes as it allows to open the scenario list by
               * providing an advertiserId and a campaignId manually (without having to define an
               * AppFilterDto as request parameter
               */}
        <PrivateRoute
            path="/ui/adSpend/:advertiserId([0-9]+)/:campaignId([0-9]+)"
            authentication={props.authentication}
            isEmbedded={false}
            children={props => (
                <AppContextFromParams {...props}>
                    <AdSpendOptimizer {...props} />
                </AppContextFromParams>)
            }/>

        { /* this route is the actual one to be used in "production". It demands a request parameter
               * called appContextDTO which holds the AppFilterDto JSON object.
               */}
        <PrivateRoute
            path="/ui/adSpend"
            authentication={props.authentication}
            isEmbedded={false}
            children={props => (
                <AppContextFromQuery location={props.location}>
                    <AdSpendOptimizer {...props} />
                </AppContextFromQuery>
            )}/>

        {/* We need route for two path types: /ui/content/:articleIdentifier and /ui/content/some/long/path/:articleIdentifier  */}
        {/* because dashboards invoke the page only with article identifier (/ui/content/:articleIdentifier) */}
        {/* and when you click on menu entry, it loads the page with the full path (/ui/content/some/long/path/:articleIdentifier). */}
        {/* This route covers both cases. */}
        <PrivateRoute
            path="/ui/content**/:articleIdentifier"
            authentication={props.authentication}
            isEmbedded={false}
            children={props => {
                return <ArticleLayout articleIdentifier={props.match.params.articleIdentifier}/>
            }}/>

        <Route path="/ui" exact
               render={() => <RedirectTo predicate={getFirstMenuPaths(props.configurations)} successPath={getFirstMenuPaths(props.configurations)}
                                         errorPath={'/ui/login'}/>}/>
        <Route path="/ui/password/request-reset" component={PasswordResetRequestFormPage}/>
        <Route path="/ui/password/reset/:token([0-9a-z\-]{36})" component={PasswordResetFormPage}/>

        <PrivateRoute path="/ui/mfa/otp/verify-code" authentication={props.authentication} isEmbedded={true} children={() => <MfaForm/>}/>

        <Route path="/ui/login" component={LoginPage}/>

        {/* if route not found, then redirect to login */}
        <Route render={() => <RedirectTo predicate={props.authentication.loggedInViaFirstFactor} successPath={'/ui'} errorPath={'/ui/login'}/>}/>
    </Switch>
}

/**
 * Creates private routes from the menu configurations
 *
 * @param menu
 * @param authentication
 * @param isEmbedded
 */
const createPrivateRoutesFromMenuConfiguration = (menu: any[], authentication: AuthenticationState, isEmbedded: boolean) => {
    return menu && menu[0] && menu.map(config => {
        if (config.items !== undefined) {
            return createPrivateRoutesFromMenuConfiguration(config.items, authentication, isEmbedded)
        } else {
            return (
                <PrivateRoute authentication={authentication}
                              isEmbedded={isEmbedded}
                              path={config['path']}
                              children={props => (
                                  <AppContextFromQuery location={props.location}>
                                      <RootElementContextProvider>
                                          <GenericPage pageConfiguration={config} {...props}/>
                                      </RootElementContextProvider>
                                  </AppContextFromQuery>
                              )}/>
            )
        }
    })
}


export default AppRouter
