import React, { MutableRefObject, ReactElement, ReactNode, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { v1 as uuid } from 'uuid'
import { Row } from 'domain/datagrid/component/DataGrid'
import { log } from 'shared/util/log'
import { ActionIdentifier } from 'domain/types'
import { getActionConfig } from 'shared/component/renderers/inline-buttons.renderer'
import { InlineButtonDTO } from 'domain/actions/InlineButtonDTO'

type ActionConfig = {
    name: string
    icon: string
}

/**
 * Action configurations
 */
const standardActions: Record<ActionIdentifier, ActionConfig> = {
    [ActionIdentifier.CREATE]: { name: 'Create', icon: 'plus' },
    [ActionIdentifier.EDIT]: { name: 'Edit', icon: 'edit' },
    [ActionIdentifier.DEACTIVATE]: { name: 'Deactivate', icon: 'eye-invisible' },
    [ActionIdentifier.ACTIVATE]: { name: 'Activate', icon: 'eye' },
    [ActionIdentifier.DELETE]: { name: 'Delete', icon: 'delete' },
}

type Props = {
    children?: ReactElement,
    // reference to the current data grid rows, on that was the context menu click triggered
    currentContextMenuRowsReference?: MutableRefObject<Row[]>,
    onClickOnContextMenuAction: (actionIdentifier: ActionIdentifier, invokeRowIndices: number[]) => void
}

/**
 * Clones the node element and adds or removes "selected-row-context-menu" class
 * depends on visible true or false
 *
 * @param node
 * @param visible
 */
const createChildren = (node: ReactElement, visible: boolean): ReactNode => {
    const className = visible
        ? 'selected-row-context-menu ' + node.props.className
        : node.props.className.replace('selected-row-context-menu ', '')


    return React.cloneElement(node, { ...node.props, className: className })
}

/**
 * Creates ant context menu object containing grid actions
 *
 * @param props
 * @constructor
 */
export const GridActionsContextMenu: React.FC<Props> = (props: Props): JSX.Element => {

    const [visible, setVisible] = useState(false)

    /**
     * Action click handler
     */
    const onMenuItemClick = (actionIdentifier: ActionIdentifier, invokeRowIndices: number[]) => {
        props.onClickOnContextMenuAction(actionIdentifier, invokeRowIndices)

        // when clicking on the context menu item, "onVisibleChange" will not be evicted (some bug? in Antd).
        // So we must set the "visible" state to false, so that the grid row will be rerendered without "selected" class
        setVisible(false)
    }

    /**
     * Creates ant context menu object containing grid actions
     *
     * @param inlineButtons
     * @param invokeRowIndices
     */
    const getGridActionsContextMenu = (inlineButtons: InlineButtonDTO[], invokeRowIndices: number[]) => {
        const itemsCountText = invokeRowIndices.length > 1
            ? ` (${invokeRowIndices.length} Items)`
            : ''

        return <Menu style={{ zIndex: 101 }}>{
            inlineButtons.length > 0 && inlineButtons.map(inlineButton => {
                const actionIdentifier = inlineButton.actionIdentifier
                const actionConfig = getActionConfig(actionIdentifier)
                return (
                    <Menu.Item key={uuid()} onClick={() => onMenuItemClick(actionIdentifier, invokeRowIndices)}
                               disabled={inlineButton.disabled}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {actionConfig.icon}
                            <span style={{ marginLeft: 5 }}>{actionConfig?.name}{itemsCountText}</span>
                        </div>
                    </Menu.Item>
                )
            })
        }</Menu>
    }

    /**
     * Creates grid actions context menu from the __actions__ column
     * @param rows
     */
    const showContextMenu = (rows: Row[]): React.ReactElement => {
        if (rows?.length > 0 && rows.some(row => row.rowActions)) {

            // These buttons will be shown in the context menu.
            // Init with the empty list.
            let contextMenuInlineButtons: InlineButtonDTO[] = []

            // Iterate over all invoked rows and collect same buttons.
            // If some button is missing in one row, then don't show the button for all rows.
            // If a button is disabled in some row, then it must be disabled for all rows.
            rows.forEach(row => {
                const rowActions = row.rowActions

                if (contextMenuInlineButtons.length === 0) {
                    contextMenuInlineButtons = rowActions.actions
                } else {
                    contextMenuInlineButtons = contextMenuInlineButtons.filter(inlineButton => {
                        return rowActions.actions.some(button => button.actionIdentifier === inlineButton.actionIdentifier)
                    }).map(inlineButton => {
                        const button = rowActions.actions.find(b => b.actionIdentifier === inlineButton.actionIdentifier)

                        if (button && button.disabled) {
                            return { ...inlineButton, disabled: true }
                        } else {
                            return { ...inlineButton }
                        }
                    })
                }
            })


            // checks whether the row contains some of the standard actions
            const rowContainsActions: boolean = contextMenuInlineButtons.some((action) => !!standardActions[action.actionIdentifier])

            if (rowContainsActions) {
                const invokeRowIndices = rows.map(row => row.rowIndex)

                return getGridActionsContextMenu(contextMenuInlineButtons, invokeRowIndices)
            } else {
                log.info('No actions are configured for the row')
            }
        }

        // return empty react element if there are no actions for the row
        return <React.Fragment/>
    }

    /**
     * Save the visibility in state so that we can use it to add a special CSS class to rows with a visible context menu
     * @param v
     */
    const onVisibleChange = (v: boolean) => {
        setVisible(v)
    }

    const children = createChildren(props.children, visible)

    return <Dropdown onVisibleChange={onVisibleChange} overlay={showContextMenu(props.currentContextMenuRowsReference.current)} trigger={['contextMenu']}>
        {children}
    </Dropdown>
}
