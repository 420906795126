import React from 'react'
import store from 'shared/redux/store'
import { ActionPopupConfig, LayoutElementDTO, ModalConfig, UIFormConfig } from 'domain/types'
import GenericForm from 'shared/component/forms/GenericForm'
import { log } from 'shared/util/log'

export type ModalAction = { type: string, modalConfig?: Partial<ModalConfig> }

export const actionTypes = {
    MODAL_OPEN: 'MODAL_OPEN',
    MODAL_CLOSE: 'MODAL_CLOSE',
    MODAL_UPDATE: 'MODAL_UPDATE',
    NONE: 'NONE',
}

const setVisibility = (identifier: string, visible: boolean): ModalAction => {
    const newState = { visible: visible }
    if (!visible) newState['content'] = undefined

    return { type: actionTypes.MODAL_UPDATE, modalConfig: { identifier, ...newState } }
}

const setLoading = (identifier: string, loading: boolean): ModalAction => {
    return { type: actionTypes.MODAL_UPDATE, modalConfig: { identifier, contentLoading: loading } }
}

const showContent = (identifier: string, content: UIFormConfig, popupConfig: ActionPopupConfig): ModalAction => {
    const genericForm = <GenericForm uiFormConfig={content} popupConfig={popupConfig}/>
    const { title, subtitle } = popupConfig

    return { type: actionTypes.MODAL_UPDATE, modalConfig: { identifier: identifier, content: genericForm, title: title, subtitle: subtitle } }
}

/**
 * Dispatch modal data with update type
 * @param updatedModalConfig
 */
const dispatchUpdateModal = (updatedModalConfig: Partial<ModalConfig>) => {
    store.dispatch({
        type: actionTypes.MODAL_UPDATE,
        modalConfig: updatedModalConfig,
    })
}

/**
 * Updates [contentLoading] state of the model window with [identifier]
 *
 * @param identifier - identifier of the modal window
 * @param loading - true/false
 */
const setModalLoading = (identifier: string, loading: boolean) => {
    dispatchUpdateModal({ identifier: identifier, contentLoading: loading })
}

const confirm = (identifier: string, config: ActionPopupConfig) => {
    // onSubmit - FormContextProvider.handleSubmit(...)
    // onAfterSubmit reloads the grid after successful submit
    const { keepOpenAfterCreateAndEdit, onSubmit, onAfterSubmit } = config

    if (onSubmit && typeof onSubmit === 'function') {
        // if the validation was successful, hide the content and show loading spinner
        const onFrontendValidationSuccessCallback = () => setModalLoading(config.identifier, true)

        onSubmit(onFrontendValidationSuccessCallback, onAfterSubmit)
            .then((validationAndSubmitSuccessful: boolean) => {
                    if (validationAndSubmitSuccessful) {
                        if (!keepOpenAfterCreateAndEdit) {
                            // if there is nothing else to do: simply close the modal
                            store.dispatch(close(identifier))
                        }
                    } else {
                        // validation (in frontend or backend) was not successful
                        setModalLoading(config.identifier, false)
                    }
                }
            )
    }
}

const cancel = (identifier: string, config: ActionPopupConfig) => {
    const { onAbort } = config

    if (onAbort && typeof onAbort === 'function') onAbort()
    // if (this.props.onCancel) this.props.onCancel()

    store.dispatch(close(identifier))
}

const open = (config: ActionPopupConfig): ModalAction => {
    const modal: ModalConfig = {
        identifier: config.identifier,
        title: config.title,
        visible: true,
        onCancel: () => actions.cancel(config.identifier, config),
        onOk: () => actions.confirm(config.identifier, config),
        content: undefined,
        contentLoading: undefined,
        modalWidth: config.modalWidth,
        modalMinHeight: config.modalMinHeight,
        additionalFooterElements: config.additionalFooterElements,
        additionalFilters: config.additionalFilters,
    } as ModalConfig

    if (config.subtitle) modal.subtitle = config.subtitle

    return { type: actionTypes.MODAL_OPEN, modalConfig: modal }
}

const close = (identifier: string): ModalAction => {
    return { type: actionTypes.MODAL_CLOSE, modalConfig: { identifier } }
}

export const actions = {
    open,
    close,
    confirm,
    cancel,
    setVisibility,
    setLoading,
    showContent,
}

/**
 * Finds modal window state in the redux store
 *
 * @param state
 * @param action
 */
const findModal = (state: ModalConfig[], action: ModalAction): ModalConfig | null =>
    state.find(modal => modal.identifier === action.modalConfig.identifier)

/**
 * Updates existing modal window
 *
 * @param state
 * @param action
 */
const updateModal = (state: ModalConfig[], action: ModalAction): ModalConfig[] => {
    const modalToUpdate = findModal(state, action)
    if (modalToUpdate) {
        const updatedModal = { ...modalToUpdate, ...action.modalConfig }
        return [...state.filter(modal => modal.identifier !== action.modalConfig.identifier), updatedModal]
    } else {
        log.warn('Modal window with identifier ' + action.modalConfig.identifier + ' not found. It could be already closed.')
        return state
    }
}

/**
 * Open a new modal window or updates the existing modal window
 *
 * @param state
 * @param action
 */
const openModal = (state: ModalConfig[], action: ModalAction): ModalConfig[] => {
    const modalToUpdate = findModal(state, action)
    if (modalToUpdate) {
        log.warn('Modal window with identifier ' + action.modalConfig.identifier + ' already exists, going to update properties')
        return updateModal(state, action)
    } else {
        return [...state, action.modalConfig as ModalConfig]
    }
}

export const reducer = (state: ModalConfig[] = [], action: ModalAction): ModalConfig[] => {
    switch (action.type) {
        case actionTypes.MODAL_OPEN: {
            return openModal(state, action)
        }
        case actionTypes.MODAL_UPDATE: {
            return updateModal(state, action)
        }
        case actionTypes.MODAL_CLOSE: {
            return [...state.filter(modal => modal.identifier !== action.modalConfig.identifier)]
        }
        default:
            return state
    }
}
