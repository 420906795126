import { AppContextDTO } from 'domain/types'
import { Dispatch } from 'redux'

export const ROOT = 'appContext'

export enum actionTypes {
    STORE_APP_CONTEXT_SUCCESS = 'STORE_APP_CONTEXT_SUCCESS',
}

const storeAppContextSuccess = (appContext: AppContextDTO) => {
    return { type: actionTypes.STORE_APP_CONTEXT_SUCCESS, appContext }
}

const storeAppContext = (appContext: AppContextDTO) => (dispatch: Dispatch) => {
    dispatch(storeAppContextSuccess(appContext))
}

export const actions = {
    storeAppContext,
    storeAppContextSuccess,
}

export type AppContextState = {
    appContext: AppContextDTO
}

const initialState: AppContextState = { appContext: {} as AppContextDTO }

export const reducer = (state: AppContextState = initialState, action): AppContextState => {
    switch (action.type) {
        case actionTypes.STORE_APP_CONTEXT_SUCCESS:
            return { appContext: action.appContext }
        default:
            return state
    }
}

export const selectors = {
    /**
     * @return {*|boolean} whether the app context is available in the Redux store.
     */
    hasAppContext: (state) => {
        return state[ROOT] && state[ROOT].appContext !== undefined
    },

    /**
     * @return the current app context from the Redux store
     */
    getAppContext: (state) => {
        return state[ROOT].appContext
    },
}
