import * as React from 'react'
import { Button, Menu } from 'antd'

export const contextMenuRenderer: React.FunctionComponent<any> = (config: any) => {

    const invokeAction = (button: any, event: any) => {
        console.log('ContextMenu Event: ', event)
        console.log('ContextMenu Action: ', button.action.name, button.item.id)
    }

    return (
        <div style={{ marginRight: 6 }}>
            {config && config.buttons && config.buttons.length > 0 &&
            <Button.Group size={'small'}>
                {config.buttons.map(button => {
                    return (
                        <Button htmlType={'button'}
                                onClick={(event) => invokeAction({ action: button.action, item: button.item }, event)}
                                icon={button.icon}/>
                    )
                })}
            </Button.Group>
            }
        </div>
    )
}
