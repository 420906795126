import { SeriesOption } from 'echarts'
import { CallbackDataParams } from 'echarts/types/dist/shared'
import { ComputationType, DimensionDTO, MetricDTO, ReportingDataSetDTO } from 'domain/types'
import SeriesUtil from 'domain/widget/SeriesUtil'
import SparkBarGenerator from 'domain/widget/SparkBarGenerator'
import MetricUtil from 'domain/widget/MetricUtil'
import NumberFormatter from 'shared/util/NumberFormatter'
import { NOT_AVAILABLE } from 'Constants'

/**
 * N/A metric value will be handled as 0
 * @param serie
 * @param index
 */
const getSerieDataValue = (serie: SeriesOption, index: number) => {
    if (!serie.data[index]) {
        return 0
    }

    const rawValue = serie.data[index].value == undefined ? serie.data[index] : serie.data[index].value
    return rawValue !== NOT_AVAILABLE ? rawValue : 0
}

/**
 * Generates a Tooltip formatter for 2 dimensional widgets such as a stacked bar widget
 *
 * @param allSeries
 * @param dimension1 dimension for the currently selected main dimension
 * @param dimension2 dimension for which the items below the main dimension are grouped by
 * @param metric
 * @param firstDimensionDataSet
 */
const getTooltipFormatterFor2Dimensions = (allSeries: SeriesOption[], dimension1: DimensionDTO, dimension2: DimensionDTO, metric: MetricDTO, firstDimensionDataSet: ReportingDataSetDTO) => (item: CallbackDataParams) => {
    const sumForDataIndex = firstDimensionDataSet.rows[item.dataIndex][metric.identifier]?.value

    const seriesStr = allSeries.map(series => {
        const isActiveItem = series.name == item.seriesName
        const additionalClassName = isActiveItem ? 'active' : ''

        // N/A metric value will be handled as 0
        const value = getSerieDataValue(series, item.dataIndex)
        const percent = value / sumForDataIndex
        const formattedNumber = MetricUtil.metricValueFormatter(metric)(value)
        const formattedPercent = NumberFormatter.formatNumberPercent(2, percent)

        const valuesForDataIndex = SeriesUtil.getValuesForDataIndex(allSeries, item.dataIndex)

        const bar = SparkBarGenerator.getSparkBar(value, valuesForDataIndex)

        // show percent value of the whole only if the metric is not a percent value
        const percentSpan =
            metric.computationType === ComputationType.CT_AVERAGE
                ? ''
                : `<span class="number-tag">${formattedPercent}</span>`

        return `<span class="series-name ${additionalClassName}">${series.name}:</span>
                <span class="series-value ${additionalClassName}">
                    ${bar} 
                    <span class="number">${formattedNumber}</span>
                    ${percentSpan}
                </span>`
    }).reverse().join('')

    // show sum metric value only for metrics with not average computation type
    const sumForDataIndexFormatted = metric.computationType == ComputationType.CT_AVERAGE
        ? ''
        : `${MetricUtil.metricValueFormatter(metric)(sumForDataIndex)} `

    return `<div class="widget-tooltip">
                <div class="tooltip-header">
                    <p class="mini-title">${dimension1.displayName}</p>
                    <p class="title">${item.name}</p>
                </div>
                <p class="values-title">
                    <span class="item-name">${item.name}: </span>
                    <span class="item-details">
                        <strong>
                            <span class="sum">${sumForDataIndexFormatted}</span>
                            <span class="metric-name">${metric.displayName}</span>
                        </strong>
                        <span class="split-by"> split by </span>
                        <strong>
                            <span class="dimension2-name">${dimension2.displayName}</span>
                        </strong>:
                    </span>
                </p>
                <div class="values">${seriesStr}</div>
            </div>`
}

const TooltipGenerator = {
    getTooltipFormatterFor2Dimensions: getTooltipFormatterFor2Dimensions,
}

export default TooltipGenerator
