import React, { useContext } from 'react'
import moment, { Moment } from 'moment'
import { DatePicker } from 'antd'
import { TimespanSettingsDTO } from 'domain/types'
import { ToolsContext } from 'domain/widget/ToolsContext'
import { ArrowRightOutlined } from '@ant-design/icons'

export const TimeSpanElement = () => {

    const toolsContext = useContext(ToolsContext)
    const { updateTimespanSettings, timespanSettings } = toolsContext

    const startDateZonedDateTimeFormat = 'YYYY-MM-DDT00:00:00Z'
    const endDateZonedDateTimeFormat = 'YYYY-MM-DDT23:59:59Z'
    const displayDateFormat = 'DD.MM.YYYY'

    const { RangePicker } = DatePicker

    const handleRangePickerChange = (values: Moment[]) => {
        const newTimeSpan: TimespanSettingsDTO = {
            start: values[0].format(startDateZonedDateTimeFormat),
            end: values[1].format(endDateZonedDateTimeFormat),
        }
        updateTimespanSettings(newTimeSpan)
    }

    const disabledDate = (current) => {
        // Can not select days after today
        return current > moment().endOf('day')
    }

    return timespanSettings
        ? <RangePicker
            ranges={{
                Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment()],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This Week': [moment().startOf('week'), moment()],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            }}
            onChange={handleRangePickerChange}
            format={displayDateFormat}
            defaultValue={[
                moment(timespanSettings.start),
                moment(timespanSettings.end),
            ]}
            value={[
                moment(timespanSettings.start),
                moment(timespanSettings.end),
            ]}
            disabledDate={disabledDate}
            separator={<ArrowRightOutlined/>}
            allowClear={false}
            allowEmpty={[false, false]}
            dropdownClassName={'calendar-custom-range'}
            size={'small'}
        />
        : <div/>
}
