export const actionTypes = {
    DIMENSIONS_LOAD: 'DIMENSIONS_LOAD',
    DIMENSIONS_LOAD_SUCCESS: 'DIMENSIONS_LOAD_SUCCESS',
    DIMENSIONS_LOAD_FAILURE: 'DIMENSIONS_LOAD_FAILURE',
}

export type DimensionsAction = { type: string, data?: any, error?: string }
export type DimensionsState = { dimensions?: any }

export const reducer = (state: DimensionsState = {}, action: DimensionsAction) => {
    switch (action.type) {
        case actionTypes.DIMENSIONS_LOAD_SUCCESS:
            return { ...state, dimensions: action.data }
        default:
            return state
    }
}
