import { MenuDTO, MenuRootDTO } from 'domain/types'

/**
 * Searches in the [menuConfig] for the node with the path, that has [identifier] in the leaf name.
 * e.g. 211656369 -> /ui/content/help/articles/setup/campaignmanagement/202646329-Commission-Model/211656369-Weights
 *
 * @param identifier
 * @param menuConfig
 */
const getMenuNodeForIdentifier = (identifier: string, menuConfig: MenuRootDTO): MenuDTO => {
    return getMenuNodeWithIdentifierRecursive(identifier, menuConfig.root)
}

/**
 * Searches in the [menuConfig] for the node with the path, that ends with [path]
 *
 * @param path
 * @param menuConfig
 */
const getMenuNodeEndingWith = (path: string, menuConfig: MenuRootDTO): MenuDTO => {
    return getMenuNodeRecursive(path, menuConfig.root, true)
}

/**
 * Searches in the [menuConfig] for the node with the [path]
 *
 * @param path
 * @param menuConfig
 */
const getMenuNode = (path: string, menuConfig: MenuRootDTO): MenuDTO => {
    return getMenuNodeRecursive(path, menuConfig.root)
}

/**
 * Searches recursive in the [items] for the node with the [path]
 *
 * @param path
 * @param items
 * @param onlyEnding
 */
const getMenuNodeRecursive = (path: string, items: MenuDTO[], onlyEnding: boolean = false): MenuDTO => {
    for(const item of items) {
        if ((!onlyEnding && item.path == path) || (onlyEnding && item.path.endsWith(path))) {
            return item
        }

        if (item.items && item.items.length > 0) {
            const result = getMenuNodeRecursive(path, item.items, onlyEnding)
            if (result) {
                return result
            }
        }
    }

    return null
}

/**
 * Searches recursive in the [items] for the node with the [path], that has [identifier] in the leaf name.
 * e.g. 211656369 -> /ui/content/help/articles/setup/campaignmanagement/202646329-Commission-Model/211656369-Weights
 *
 * @param identifier
 * @param items
 */
const getMenuNodeWithIdentifierRecursive = (identifier: string, items: MenuDTO[]): MenuDTO => {
    for(const item of items) {
        const tokens = item.path.split('/')
        const lastToken = tokens[tokens.length - 1]

        // 211656369-Weights
        const lastTokenTokens = lastToken.split('-')
        // 211656369
        const articleIdentifier = lastTokenTokens[0]

        if (!item.items && articleIdentifier === identifier) {
            return item
        }

        if (item.items && item.items.length > 0) {
            const result = getMenuNodeWithIdentifierRecursive(identifier, item.items)
            if (result) {
                return result
            }
        }
    }

    return null
}

const MenuUtil = {
    getMenuNode,
    getMenuNodeEndingWith,
    getMenuNodeForIdentifier,
}

export default MenuUtil
