// General type identifying DTOs with an ID of type number.
import { PaginationInfoDTO } from 'domain/types'

export type EntityDTO = {
    id: number,
}

export type DataColumnDTO = {
    identifier: string,
    displayName: string,
}
export type MetricDTO = DataColumnDTO & {
    computationType: ComputationType
    round: number
    type: MetricType
    unitPostfix?: string
    unitPrefix?: string
}

export type DimensionDTO = {
    //e.g. "Channel"
    // displayName: string,

    //e.g. "Channels"
    displayNamePlural: string,

    nameColumn?: string,

    //e.g. "channel"
    // identifier: string,

    //e.g. "channel.value"
    // valueColumn: string,

    //where to find the name mapping in the data (e.g.: dimension is "publisher_placement_id", names can be found under "publisher_placement_name_id")
    // nameMapping: string,

    //parent dimensions would trigger a filter reload of child dimensions when they are changed (e.g.: campaign is changed => reload sub campaign filter values)
    parents?: Array<string>
} & DataColumnDTO

export type DataSliceDTO = {
    totals: { [key: string]: any },
    maxValues: { [key: string]: any },
    minValues: { [key: string]: any },
    slice: Array<{ [key: string]: any }>,
    totalResults: number,
    pages: number,
}

/**
 * Computation type of the metric
 */
export enum ComputationType {
    CT_SUM = 'CT_SUM',
    CT_AVERAGE = 'CT_AVERAGE',
    CT_NONE = 'CT_NONE',
}

/**
 * Type of the metric value
 */
export enum MetricType {
    INT = 'int',
    FLOAT = 'float',
    STRING = 'str',
    DATE = 'date',
}

export type OrderDirection = 'ASC' | 'DESC'

export type ColumnResponseDTO = {
    value?: any,
    name?: string,
    icon?: string,
    tooltip?: string,
    data?: any,
    prefix?: string,
    cssClasses?: string,
}
export type GridDataRowDTO = { [key: string]: ColumnResponseDTO }

export type ReportingDataSetDTO = {
    rows: GridDataRowDTO[]
    totals?: GridDataRowDTO
    minValues?: DataRowDTO
    maxValues?: DataRowDTO
}

export type DataRowDTO = { [key: string]: any }

export type DataSetDTO = {
    rows: DataRowDTO[]
    totals?: DataRowDTO
    minValues?: DataRowDTO
    maxValues?: DataRowDTO
}

export type CurrencyDTO = {
    alphabeticCode: string,
    name: string,
    symbol: string,
    symbolPosition: string
}
