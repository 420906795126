import * as React from 'react'
import { Root as RootProd } from 'Root.prod'
import { Root as RootDev } from 'Root.dev'

const isProduction = process.env.NODE_ENV === 'production'

export default class Root extends React.Component {
    render() {
        return (isProduction ? <RootProd/> : <RootDev/>)
    }
}