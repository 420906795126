import React from 'react'
import StringUtil from 'shared/util/StringUtil'
import { ClockCircleOutlined, TrophyOutlined } from '@ant-design/icons'

type Props = {
    displayName: string,
}

export const FormattedColumnHeader = (props: Props) => {
    const startIndex = props.displayName.indexOf('(')
    const endIndex = props.displayName.indexOf(')')
    const needsSpecialFormatting = startIndex > 0

    const columnName = needsSpecialFormatting ? props.displayName.substr(0, startIndex - 1) : props.displayName

    const tags = []
    if (needsSpecialFormatting) {
        const tagNames = props.displayName.substr(startIndex + 1, endIndex - startIndex - 1).split(',')
        tagNames.forEach((tagName, index) => {
            tagName = tagName.trim()
            let icon = null
            switch (tagName) {
                case 'Attributed':
                    icon = <TrophyOutlined/>
                    break
                case 'ToTP':
                case 'ToCnv':
                    icon = <ClockCircleOutlined/>
                    break
            }

            tags.push(<span key={index} className={'tag ' + StringUtil.slugify(tagName)}>{icon}{tagName}</span>)
        })
    }

    const text = tags.length > 0 ? <React.Fragment><span className={'metric-name'}>{columnName}</span><span className={'tags'}>{tags}</span></React.Fragment> : <React.Fragment>{columnName}</React.Fragment>

    return <span className={'formatted-metric'}>{text}</span>
}
