/**
 * Checks whether the path is a configured menu element
 *
 * @param path
 * @param menu
 */
export const isConfigurationPath = (path: string, menu: any[]) => {
    return menu.some(item => {
        return item.path === path || (item.items && isConfigurationPath(path, item.items))
    })
}

/**
 * Gets the first menu entry path
 * @param menu
 */
export const getFirstMenuPaths = (menu: any[]): string => {
    return menu && menu.length > 0 ? getAllMenuPaths(menu)[0] : null
}

/**
 * Gets list of menu entry paths
 *
 * @param menu
 */
export const getAllMenuPaths = (menu: any[]): string[] => {
    return menu.map(item => {
        return item.items ? getAllMenuPaths(item.items) : item.path
    }).reduce((acc, val) => acc.concat(val), [])
}
