import React from 'react'
import { Button, Badge } from 'antd'
import { ActionDTO, IconTypes, ToolbarButton } from 'domain/types'
import withWidgetContext from 'domain/context/withWidgetContext'
import { DeleteOutlined, PlusOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

type Props = {
    button: ToolbarButton
    onInvoke: (action: ActionDTO) => void
    selectedRows?: number,
    disabled: boolean,
}

const icons = {
    [IconTypes.DELETE]: <DeleteOutlined/>,
    [IconTypes.PLUS]: <PlusOutlined/>,
    [IconTypes.EDIT]: <EditOutlined/>,
    [IconTypes.EYE_INVISIBLE]: <EyeInvisibleOutlined/>,
    [IconTypes.EYE]: <EyeOutlined/>,
}

class ToolbarButtonComponent extends React.Component<Props> {

    constructor(props: Props) {
        super(props)

        this.invokeAction = this.invokeAction.bind(this)
    }

    invokeAction = (): void => {
        const { button, onInvoke } = this.props

        onInvoke(button.action)
    }

    render() {
        const { button, selectedRows } = this.props

        // disable/enable needs a wrapper component, otherwise it will be impossible to have a fade in/out animation since
        // display:none will prevent any animations on the element and its child elements (but we need display:none so that
        // hidden buttons don't take up space)
        const unavailable = button.action.selectionConfig.minSelected > selectedRows
        const disabled = this.props.disabled || unavailable
        const icon = button.toolbarButtonDTO.icon ? icons[button.toolbarButtonDTO.icon] : undefined

        const buttonElement =
            <Button
                className={'toolbar-button ' + button.toolbarButtonDTO.cssClasses}
                type="default"
                onClick={this.invokeAction}
                disabled={disabled || button.toolbarButtonDTO.disabled}
                data-tip={button.toolbarButtonDTO.disabledTooltip}
                data-force-tooltip={button.toolbarButtonDTO.disabled}
            >
                {icon != undefined && icon}
                <span className="toolbar-button-text">{button.toolbarButtonDTO.label}</span>
            </Button>

        const showBadge = selectedRows > 1

        const element = showBadge
            ? <Badge count={selectedRows}>{buttonElement}</Badge>
            : buttonElement

        return (
            <React.Fragment>
                <div
                    className={'toolbar-button-wrapper action-button' + (unavailable ? ' disabled ' : ' ') + (button.toolbarButtonDTO.cssClasses && button.toolbarButtonDTO.cssClasses.length > 0 ? button.toolbarButtonDTO.cssClasses : 'default') + '-wrapper'}
                    aria-label={button.toolbarButtonDTO.label}
                >
                    {element}
                </div>
            </React.Fragment>
        )
    }
}

export default withWidgetContext(ToolbarButtonComponent)
