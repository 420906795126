import { AppContextDTO, ConditionClauseDTO, ContainerElementDTO, DimensionDTO, DimensionValueListDTO, LayoutElementDTO } from 'domain/types'
import { FormItemValidatorDTO } from 'shared/util/validation'

export type LoadFormConfigDTO = {
    type: FormType,
    filter?: ConditionClauseDTO,
    appContext?: AppContextDTO,
}

export type FormElementDTO = LayoutElementDTO & {
    formFieldConfig: FormFieldConfigDTO
    editableOn: ConditionClauseDTO
    useAsSetting: boolean
}

export type ToggleButtonFormElementDTO = FormElementDTO & {}

// export type InputFormElementDTO = FormElementDTO & {}
// export type TextAreaFormElementDTO = FormElementDTO & {}
export type SelectFormElementDTO = FormElementDTO & {
    selectConfig: SelectConfigDTO
    valueIncludesLabel
}

export type FieldKeyValue = {
    name: string,
    value: any
}

export enum FormCustomListener {
    SUB_CAMPAIGN_CREATE_FORM_ON_CHANNEL_CHANGE = "SUB_CAMPAIGN_CREATE_FORM_ON_CHANNEL_CHANGE",
}

export type FormFieldConfigDTO = {
    // identifier of the dimension that is bound to this form field
    dimensionIdentifier: string,
    dimensionNameMapping: string,
    // optional setting to override the label of this form field; if not set, the displayName of the dimension will be used
    displayName?: string,
    value?: any,
    validation?: FormItemValidatorDTO[],
    supportsMultiEdit: boolean,
    readOnly: boolean,
    readOnlyReason?: string,
    defaultValue?: any,
    editableOn?: ConditionClauseDTO,
    infoText?: string,
    onChangeListener?: FormCustomListener,
}

export type SelectDependencyDTO = {
    filterIdentifier: string,
    reloadOnDependencyChange: boolean,
}

export type SelectConfigDTO = {
    // dimensionIdentifier: string
    dependsOn?: SelectDependencyDTO[]
    multiSelect: boolean
    sortOrder: number
    loadValuesUrl: string
    selectEntries: DimensionValueListDTO
    // displayName: string
    preventInitialLoading: boolean
}

export enum FormType {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

export type FormButtonDTO = {
    label: string
    action: string
}

// export type InlineButtonDTO = {
//     actionIdentifier: string
//     disabledTooltip: string
//     disabled: boolean
// }

export enum FormSubmitMethod {
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export type FormSubmitDTO = {
    method: FormSubmitMethod
    url: string
    requiredDimensions?: string[]
}

export type FormCancelDTO = {
    url: string
}

export type FormActionDTO = {
    submit: FormSubmitDTO
    cancel: FormCancelDTO
}

export type FormConfigDTO = {
    mainDimension: DimensionDTO
    type: FormType
    // identifier: string
    title: string
    layoutConfig: ContainerElementDTO
    buttons: FormButtonDTO[]
    actions?: FormActionDTO
    keepOpenAfterCreateAndEdit: boolean
}
