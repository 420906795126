import React from 'react'
import { connect } from 'react-redux'
import CustomModal, { getDefaultFooter } from 'shared/component/modals/CustomModal'
import { RootElementContextProvider } from 'shared/component/layout/context/RootElementContext'
import { ModalConfig } from 'domain/types'
import { AdditionalFilterContextProvider } from 'shared/component/layout/context/AdditionalFilterContext'

type Props = {
    modals?: ModalConfig[]
}

const renderModal = (modal: ModalConfig, index: number): JSX.Element => {
    return <AdditionalFilterContextProvider additionalFilters={modal.additionalFilters} key={index}>
        <RootElementContextProvider defaultFooter={getDefaultFooter(modal)} key={index}>
            <CustomModal modal={modal} index={index}/>
        </RootElementContextProvider>
    </AdditionalFilterContextProvider>

}

const ModalManager = (props: Props): JSX.Element =>
    <div className="modal-provider">
        {props.modals.map((modal, index) => renderModal(modal, index))}
    </div>

const mapState = (state) => ({ modals: state.modals })

export default connect(mapState)(ModalManager)