import React, { ReactNode } from 'react'
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import ErrorBoundary from 'shared/component/ErrorBoundary'
import { ActionIdentifier } from 'domain/types'
import { RowActions } from 'domain/actions/RowActions'
import { v1 as uuid } from 'uuid'

export type ActionConfig = {
    name: string,
    icon: ReactNode
}

const standardActions = {
    [ActionIdentifier.CREATE]: {
        name: 'Create',
        icon: <PlusOutlined/>,
    } as ActionConfig,
    [ActionIdentifier.EDIT]: {
        name: 'Edit',
        icon: <EditOutlined/>,
    } as ActionConfig,
    [ActionIdentifier.DEACTIVATE]: {
        name: 'Deactivate',
        icon: <EyeInvisibleOutlined/>,
    } as ActionConfig,
    [ActionIdentifier.ACTIVATE]: {
        name: 'Activate',
        icon: <EyeOutlined/>,
    } as ActionConfig,
    [ActionIdentifier.DELETE]: {
        name: 'Delete',
        icon: <DeleteOutlined/>,
    } as ActionConfig,
}

/**
 * Gets the action config for the actionIdentifier from the standardActions list
 *
 * @param actionIdentifier
 */
export const getActionConfig = (actionIdentifier: ActionIdentifier): ActionConfig => {
    if (standardActions[actionIdentifier]) {
        return standardActions[actionIdentifier]
    }

    new Error('actionIdentifier ' + actionIdentifier + ' is not configured as standard action')
}

type Props = { rowActions: RowActions }

const InlineButtonsRenderer: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const handleClick = (actionIdentifier: ActionIdentifier, event: any) => {
        event.stopPropagation()
        props.rowActions.invokeActionHandler(actionIdentifier)
    }

    const actions = props.rowActions?.actions

    return (
        <ErrorBoundary>
            <span className="datagrid-inline-buttons">
                {actions?.length > 0 && actions.map((action, index) => {
                    const tooltip = action.disabled ? action.disabledTooltip : standardActions[action.actionIdentifier]?.name

                    return <button key={uuid()} type={'button'} className={'ant-btn ant-btn-sm'} data-tip={tooltip} data-force-tooltip={true} disabled={action.disabled}
                                onClick={(event) => {
                                    handleClick(action.actionIdentifier, event)
                                }}>
                            {standardActions[action.actionIdentifier]?.icon}
                        </button>
                })}
            </span>
        </ErrorBoundary>
    )
})

export default InlineButtonsRenderer
