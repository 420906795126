import React from 'react'
import { EfficiencyChart } from 'domain/adspend-optimizer/component/efficiency'

export const MediaPlanEfficiencyChart = ({ mediaPlanName, efficiencyData, historicPerformanceData, recommendedScenarioData }) =>
    <div className="media-plan-efficiency-chart">
        <div className="media-plan-caption">
            <h3>{mediaPlanName}</h3>
        </div>
        <EfficiencyChart efficiencyData={efficiencyData}
                         historicPerformanceData={historicPerformanceData}
                         recommendedScenarioData={recommendedScenarioData}/>
    </div>
