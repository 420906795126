import { get } from 'shared/service'
import { RestApiLogonDTO } from 'domain/types/backend/logon.types'

declare const baseUrlUserService: string

const login = (referrer: string = '/'): Promise<RestApiLogonDTO> => {
    return get(baseUrlUserService + '/auth/logon?referrer=' + encodeURI(referrer))
}

const logout = () => {
    return get(baseUrlUserService + '/auth/invalidate')
}

const AuthenticationService = {
    login,
    logout,
}

export default AuthenticationService
