import * as React from 'react'
import { useContext } from 'react'
import { ToolsContext } from 'domain/widget/ToolsContext'
import { FilterContainer } from 'domain/filter/component/FilterContainer'

export const FilterToolsContextContainer = () => {
    const filterContext = useContext(ToolsContext)
    const { filters, filterOnChange } = filterContext

    return <FilterContainer filters={filters} filterOnChange={filterOnChange}/>
}
