import React, { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { DatePicker, Input, Select } from 'antd'
import {
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    AdSpendOptimizerContext,
    CAMPAIGN_DIMENSION_IDENTIFIER,
    CHANNEL_DIMENSION_IDENTIFIER,
    END_DATE_DIMENSION_IDENTIFIER,
    START_DATE_DIMENSION_IDENTIFIER,
    STATUS_DIMENSION_IDENTIFIER,
    SUB_CAMPAIGN_DIMENSION_IDENTIFIER,
} from 'domain/adspend-optimizer/context/AdSpendOptimizerContext'
import AdSpendOptimizerService from 'domain/adspend-optimizer/service/adspend-optimizer.service'
import { dateString } from 'shared/util/util'
import { FaHandPointRight } from 'react-icons/fa'
import { MediaPlanHeadline } from 'domain/adspend-optimizer/component/scenario-form/MediaPlanHeadline'
import { MediaPlanHeader } from 'domain/adspend-optimizer/component/scenario-form/MediaPlanHeader'
import MediaPlanRow from 'domain/adspend-optimizer/component/scenario-form/MediaPlanRow'
import { GridDataRowDTO, ScenarioDTO } from 'domain/types'
import DimensionService from 'domain/dimension/service/DimensionService'

const rangePickerFooter = () =>
    <span>
        <FaHandPointRight style={{ marginTop: '3px', marginRight: '7px' }}/>
        Please select a forecast period starting on a <em>Monday</em> and ending on a <em>Sunday</em>.
    </span>

type Props = {
    mode: 'ADD' | 'EDIT',
    form: any,
    campaignId: number,
    campaignName: string,
    channelData: GridDataRowDTO[],
    subCampaignData: GridDataRowDTO[],
    scenario?: GridDataRowDTO
}

const ScenarioForm: React.FC<Props> = React.memo<Props>((props: Props): JSX.Element => {

    const asoContext = useContext(AdSpendOptimizerContext)

    const campaignData: GridDataRowDTO = {
        campaign: {
            value: asoContext && asoContext.campaignData ? asoContext.campaignData.campaignId : props.campaignId,
            name: asoContext && asoContext.campaignData ? asoContext.campaignData.campaignName : props.campaignName,
        },
        status: { value: 1 },
        adspend_budget: { value: Math.floor(props.channelData.reduce((p, v) => p + v.adspend_costs.value, 0)) },
        period: null,
        flighting_pattern: { value: 'even' },
        type: { value: 'CAMPAIGN' },
    }

    // construct media plan item array
    const mp: GridDataRowDTO[] = props.mode === 'ADD'
        ? [
            { ...campaignData },
            ...props.channelData.map(item => ({
                ...item,
                type: { value: 'CHANNEL' },
                status: { value: 1 },
                adspend_budget: item.adspend_costs,
            } as GridDataRowDTO)),
            ...props.subCampaignData.map(item => ({
                ...item,
                type: { value: 'SUB_CAMPAIGN' },
                status: { value: 1 },
                adspend_budget: item.adspend_costs,
            } as GridDataRowDTO)),
        ]
        : []
    if (props.mode === 'EDIT') {
        if (props.scenario.mediaplan_group_by.value === 'campaign_id') {
            mp.push(...props.scenario.adspend_media_plans.data.map(item => ({ ...item, type: { value: 'CAMPAIGN' } })))
        } else {
            mp.push({ ...campaignData })
        }
        if (props.scenario.mediaplan_group_by.value === 'channel_id') {
            mp.push(...props.scenario.adspend_media_plans.data.map(item => ({ ...item, type: { value: 'CHANNEL' } })))
        } else {
            mp.push(...props.channelData.map(channelData => ({ ...channelData, status: { value: 1 }, adspend_budget: channelData.adspend_costs, type: { value: 'CHANNEL' } })))
        }
        if (props.scenario.mediaplan_group_by.value === 'sub_campaign_id') {
            mp.push(...props.scenario.adspend_media_plans.data.map(mediaPlan => ({ ...mediaPlan, type: { value: 'SUB_CAMPAIGN' } })))
        } else {
            mp.push(...props.subCampaignData.map(subCampaignData => ({
                ...subCampaignData,
                status: { value: 1 },
                adspend_budget: subCampaignData.adspend_costs,
                type: { value: 'SUB_CAMPAIGN' },
            })))
        }
    }

    const [periodStart, setPeriodStart] = useState(props.mode === 'ADD' ? null : props.scenario[START_DATE_DIMENSION_IDENTIFIER].value)
    const [periodEnd, setPeriodEnd] = useState(props.mode === 'ADD' ? null : props.scenario[END_DATE_DIMENSION_IDENTIFIER].value)
    // const [scenarioPeriod, setScenarioPeriod] = useState(props.mode === 'ADD' ? [] : [periodStart, periodEnd])
    const [optimizationLevel, setOptimizationLevel] = useState(props.mode === 'ADD' ? 'CAMPAIGN' : AdSpendOptimizerService.getOptimizationLevel(props.scenario.mediaplan_group_by.value))
    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [pickingPeriodEnd, setPickingPeriodEnd] = useState(false)
    const [mediaPlanItems] = useState([...mp])
    const [periodWeeks, setPeriodWeeks] = useState(0)

    const Option = Select.Option
    const { RangePicker } = DatePicker
    const { getFieldDecorator } = props.form

    useEffect(() => {
        if (periodStart && periodEnd) {
            const duration = moment.parseZone(periodEnd)
                .add(1, 'day')
                .diff(moment.parseZone(periodStart), 'week')
            setPeriodWeeks(duration)
        } else {
            setPeriodWeeks(0)
        }
    }, [periodEnd])

    useEffect(() => {
        asoContext && asoContext.invokeFormSubmit && submitForm()
    }, [asoContext.invokeFormSubmit])

    useEffect(() => {
        if (asoContext) props.form.resetFields()
    }, [asoContext.showScenarioForm])

    const submitForm = () => {
        const { form } = props

        form.validateFields((errors, values) => {
            if (errors) {
                asoContext.resetSubmitInvocation()
            } else {
                const prefix = `${optimizationLevel}`.toLowerCase()

                const propertyNames = [
                    `adspend_${prefix}_id`,
                    `adspend_status_id`,
                    `adspend_flighting_pattern`,
                ]
                const mediaPlanItemCount = values[`adspend_mediaplan_type`].length
                const mediaPlan = []
                for (let index = 0; index < mediaPlanItemCount; index++) {
                    if (values['adspend_mediaplan_type'][index] === prefix) {
                        const mediaPlanItem = {}
                        propertyNames.forEach(pName => {
                            const newKey = keyMapping(pName.replace('adspend_', ''))
                            mediaPlanItem[newKey] = values[pName][index]
                        })
                        mediaPlanItem[DimensionService.getDimensionValueColumn(CAMPAIGN_DIMENSION_IDENTIFIER)] = props.campaignId
                        mediaPlanItem[DimensionService.getDimensionValueColumn(STATUS_DIMENSION_IDENTIFIER)] = values['adspend_status_id'][index] ? 1 : 0
                        mediaPlanItem[DimensionService.getDimensionValueColumn('adspend_budget')] = Math.floor(Number(values['adspend_planned_budget'][index]))
                        mediaPlanItem[DimensionService.getDimensionValueColumn(START_DATE_DIMENSION_IDENTIFIER)] = values['adspend_period'][index] && values['adspend_period'][index][0]
                            ? dateString(values['adspend_period'][index][0])
                            : periodStart
                        mediaPlanItem[DimensionService.getDimensionValueColumn(END_DATE_DIMENSION_IDENTIFIER)] = values['adspend_period'][index] && values['adspend_period'][index][1]
                            ? dateString(values['adspend_period'][index][1])
                            : periodEnd
                        mediaPlan.push(mediaPlanItem)
                    }
                }

                const scenarioData: ScenarioDTO = {
                    adspend_scenario_id: values.adspend_scenario_id,
                    adspend_scenario_name: values.adspend_scenario_name,
                    comment: values.adspend_scenario_comment,
                    optimizationLevel: values.adspend_scenario_optimization_level,
                    start_date: periodStart,
                    end_date: periodEnd,
                    mediaPlan: mediaPlan,
                }

                // campaign_id can not be edited, so that is why we submit campaign_id only in the create mode
                if (props.mode === 'ADD') {
                    scenarioData.campaign_id = props.campaignId
                }

                props.mode === 'ADD'
                    ? asoContext.createScenario(scenarioData)
                    : asoContext.editScenario(scenarioData)
            }
        })
    }

    /**
     * Maps frontend keys to the backend keys
     *
     * @param key
     */
    const keyMapping = (key: string): string => {
        const mappings = {
            channel_id: DimensionService.getDimensionValueColumn(CHANNEL_DIMENSION_IDENTIFIER),
            campaign_id: DimensionService.getDimensionValueColumn(CAMPAIGN_DIMENSION_IDENTIFIER),
            sub_campaign_id: DimensionService.getDimensionValueColumn(SUB_CAMPAIGN_DIMENSION_IDENTIFIER),
            flighting_pattern: DimensionService.getDimensionValueColumn('flighting_pattern'),
            status_id: DimensionService.getDimensionValueColumn(STATUS_DIMENSION_IDENTIFIER),
        }

        return mappings[key]
    }

    const disabledDate = (value: Moment): boolean => {
        return pickingPeriodEnd
            ? (moment(value).day() !== 0 || moment(value).isBefore(moment.parseZone(periodStart)))
            : (moment(value).day() !== 1)
    }

    const handleRangePickerChange = (values: Moment[]) => {
        if (!values || values.length === 0) {
            setPeriodStart(null)
            setPeriodEnd(null)
            setPickingPeriodEnd(false)
            // setScenarioPeriod(values)
        } else {
            setPickingPeriodEnd(false)
            setPeriodEnd(dateString(values[1]))
            // setScenarioPeriod([dateString(values[0]), dateString(values[1])])
        }
    }

    const handleRangePickerOpenChange = (open: boolean) => {
        setDatePickerOpen(open)
        if (!open) setPickingPeriodEnd(false)
    }

    const handleDateSelect = (values: Moment[]) => {
        if (datePickerOpen) {
            setPeriodStart(dateString(values[0]))
            setPickingPeriodEnd(true)
        }
    }

    return (
        <Form onSubmit={submitForm} className="adspend-optimizer">
            <div className={'adspend-form-item'}>
                {props.mode === 'EDIT' && <Form.Item>
                    {getFieldDecorator('adspend_scenario_id', {
                        initialValue: props.scenario[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER].value,
                    })(
                        <Input hidden/>,
                    )}
                </Form.Item>}
                <div style={{ width: 130 }} className={'adspend-label'}>
                    Scenario Name
                </div>
                <div>
                    <Form.Item>
                        {getFieldDecorator('adspend_scenario_name', {
                            validateTrigger: ['onSubmit'],
                            rules: [{
                                required: true,
                                message: 'This field is required!',
                            }],
                            initialValue: props.mode === 'ADD' ? '' : props.scenario[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER].name,
                        })(
                            <Input placeholder="Scenario Name" style={{ width: 300 }}/>,
                        )}
                    </Form.Item>
                </div>
            </div>
            <div className={'adspend-form-item'}>
                <div style={{ width: 130 }} className={'adspend-label'}>
                    Forecast Period
                </div>
                <div>
                    <div className="scenario-period">
                        <Form.Item>
                            {getFieldDecorator('adspend_scenario_period', {
                                rules: [{
                                    required: true,
                                    message: 'This field is required!',
                                }],
                                initialValue: props.mode === 'ADD'
                                    ? []
                                    : [moment.parseZone(periodStart), moment.parseZone(periodEnd)],
                            })(
                                <RangePicker disabledDate={disabledDate}
                                             onOpenChange={handleRangePickerOpenChange}
                                             onCalendarChange={handleDateSelect}
                                             onChange={handleRangePickerChange}
                                             format="DD.MM.YYYY"
                                             dropdownClassName={'calendar-custom-range'}
                                             renderExtraFooter={rangePickerFooter}
                                             style={{ width: 300 }}/>,
                            )}
                        </Form.Item>
                        {periodWeeks !== 0 && <div
                            className={'scenario-period-hint'}>({periodWeeks} week{periodWeeks > 1 && 's'})</div>}
                    </div>
                </div>
            </div>
            <div className={'adspend-form-item'}>
                <div style={{ width: 130 }} className={'adspend-label'}>
                    Description
                </div>
                <div>
                    <Form.Item>
                        {getFieldDecorator('adspend_scenario_comment', {
                            initialValue: props.mode === 'ADD' ? '' : props.scenario.comment.value,
                        })(
                            <Input.TextArea placeholder="Description" style={{ width: 300 }} rows={2}
                                            maxLength={500}/>,
                        )}
                    </Form.Item>
                </div>
            </div>
            <MediaPlanHeadline/>
            <div className={'adspend-form-item'}>
                <div style={{ width: 130 }} className={'adspend-label'}>
                    Optimization Level
                </div>
                <div>
                    <Form.Item>
                        {getFieldDecorator('adspend_scenario_optimization_level', {
                            initialValue: optimizationLevel,
                        })(
                            <Select placeholder="Optimization Level"
                                    onChange={(value: string) => {
                                        setOptimizationLevel(value)
                                    }}
                                    style={{ width: 150 }}>
                                <Option value={'CAMPAIGN'}>Campaign</Option>
                                <Option value={'CHANNEL'}>Channel</Option>
                                <Option value={'SUB_CAMPAIGN'}>Sub Campaign</Option>
                            </Select>,
                        )}
                    </Form.Item>
                </div>
            </div>
            <div className="media-plan-list">
                {mediaPlanItems.length > 0 && <React.Fragment>
                    <MediaPlanHeader optimizationLevel={optimizationLevel.toLowerCase()}/>
                    {mediaPlanItems.map((item, index) => {
                        const prefix = item['type'].value.toLowerCase()
                        if (prefix === optimizationLevel.toLowerCase())
                            return <MediaPlanRow key={`${prefix}_item_${index}`}
                                                 form={props.form}
                                                 index={index}
                                                 status={item[STATUS_DIMENSION_IDENTIFIER].value}
                                                 type={prefix}
                                                 optimizationLevel={optimizationLevel.toLowerCase()}
                                                 id={item[prefix].value}
                                                 name={item[prefix].name}
                                                 adspend_budget={item['adspend_budget'].value}
                                // todo dates are missing
                                                 mediaPlanPeriod={[item[START_DATE_DIMENSION_IDENTIFIER]?.value, item[END_DATE_DIMENSION_IDENTIFIER]?.value]}
                                                 scenarioPeriod={[periodStart || null, periodEnd || null]}
                                // todo missing
                                                 flighting={item['flighting_pattern']?.value}
                                                 weeks={Number(periodWeeks)}
                                                 mode={props.mode}/>
                    })}
                </React.Fragment>}
            </div>
        </Form>
    )
})

export default Form.create<Props>()(ScenarioForm)
