import * as React from 'react'
// import { Checkbox } from 'antd'

// rc checkbox is faster as the one from antd
import Checkbox from 'rc-checkbox'
import { ColumnResponseDTO } from 'domain/types'

export const rowSelectorRenderer: React.FunctionComponent<any> = (checked: ColumnResponseDTO) => {
    return (
        <div style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
            <Checkbox checked={checked?.value}/>
        </div>
    )
}
