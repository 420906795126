import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { AppContextDTO, BooleanClauseDTO, BooleanOperator, ClauseType, ConditionClauseType, ErrorResponse, FilterState, LoadResponseDTO, PageableDTO, QuerySettingsDTO, StringValueClauseDTO, TimespanSettingsDTO } from 'domain/types'
import ReportingService from 'shared/service/ReportingService'
import { TopNWidgetSettingsDTO, WidgetSettingsDTO } from 'domain/types/backend/widget.types'
import { WidgetReducer } from 'domain/widget/widget.reducer'
import store from 'shared/redux/store'
import EmbeddingUtil from 'shared/util/EmbeddingUtil'
import { MESSAGE } from 'domain/messaging/MessageListener'
import { ContainerSizeContextProvider } from 'domain/widget/ContainerSizeContext'
import { ToolsContext } from 'domain/widget/ToolsContext'
import ConditionClauseService from 'shared/service/conditionClauseService'
import axios, { CancelTokenSource } from 'axios'
import { log } from 'shared/util/log'
import DimensionService from 'domain/dimension/service/DimensionService'

type WidgetContextProviderProperties = {
    widgetSettings?: WidgetSettingsDTO,
    children?: ReactNode,
}

type WidgetContextProperties = WidgetContextProviderProperties & {
    updateWidgetSettings?: (widgetSettings?: WidgetSettingsDTO) => void
    response?: LoadResponseDTO,
    isLoading: boolean,
    isLegendButtonVisible: boolean
    updateIsLegendButtonVisible?: (visible: boolean) => void,
    cancelTokenSource?: CancelTokenSource
}

export const WidgetContext = createContext<WidgetContextProperties>({ isLoading: false, isLegendButtonVisible: false })

/**
 * widgetSettings and response belong together,
 * because if some columns in the widget settings will be changed,
 * then the settings and the response don't match
 * and the widget becomes unstable. So widgetSettings and response must
 * be updated synchronously in one state object.
 */
type WidgetSettingsAndResponseState = {
    widgetSettings: WidgetSettingsDTO
    response?: LoadResponseDTO | ErrorResponse
}

/**
 * This context provides row data for underlying children widgets
 *
 * @param props
 * @constructor
 */
export const WidgetContextProvider: React.FC<WidgetContextProviderProperties> = (props: WidgetContextProviderProperties): JSX.Element => {

    const toolsContext = useContext(ToolsContext)

    /**
     * Creates a new object of widgetSettings with applied timespanSettings and filters
     *
     * @param widgetSettings
     * @param timespanSettings
     * @param filters
     * @param appContext
     */
    const applyTimespanAndFilters = (widgetSettings: WidgetSettingsDTO, timespanSettings?: TimespanSettingsDTO, filters?: FilterState[], appContext?: AppContextDTO) => {
        return timespanSettings || filters
            ? {
                ...widgetSettings,
                querySettings: {
                    ...widgetSettings.querySettings,
                    appContext: appContext,
                    timespanSettings: timespanSettings,
                    filter: filters
                        ? ConditionClauseService.buildFilterQuery(filters, null)
                        : widgetSettings.querySettings?.filter,
                },
            }
            : widgetSettings
    }

    // this reference will be set on the widget and used e.g. in the [ContainerSizeContext] to compute the size of the widget
    const widgetContainerRef = useRef<any>()
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null)
    const [widgetSettingsAndResponseState, setWidgetSettingsAndResponseState] = useState<WidgetSettingsAndResponseState>({
        widgetSettings: applyTimespanAndFilters(props.widgetSettings, toolsContext?.timespanSettings, toolsContext?.filterStates, store.getState().appContext.appContext),
    })
    const [isLoading, updateIsLoading] = useState(false)
    const [isLegendButtonVisible, updateIsLegendButtonVisible] = useState(false)

    useEffect(() => {
        setWidgetSettingsAndResponseState(prev => {
            const result = {
                ...prev,
                widgetSettings: applyTimespanAndFilters(props.widgetSettings, toolsContext?.timespanSettings, toolsContext?.filterStates, store.getState().appContext.appContext),
            }

            // reset the response data only if query settings have been changed
            if (querySettingsChanged(prev.widgetSettings.querySettings, result.widgetSettings.querySettings)) {
                result.response = null
            }

            return result
        })
    }, [JSON.stringify(props.widgetSettings), toolsContext?.timespanSettings, toolsContext?.filterStates, store.getState().appContext.appContext])

    useEffect(() => {
        if (!widgetSettingsAndResponseState.response) {
            // if there is no data yet: start loading data
            updateIsLoading(true)
            loadData()
        }

        // We must serialize the dependency objects to avoid reloading "useEffect" function
        // after the props have been regenerated with identical values
    }, [JSON.stringify(widgetSettingsAndResponseState.widgetSettings?.querySettings)])

    /**
     * Loads the data and stores the result in the state.
     */
    const loadData = () => {
        const newCancelTokenSource = axios.CancelToken.source()
        setCancelTokenSource(prev => {
            // cancel the possible running previous request
            prev?.cancel()
            return newCancelTokenSource
        })

        const isTopNWidget = Object.keys(widgetSettingsAndResponseState.widgetSettings).indexOf('topNElements') >= 0
        if (isTopNWidget) {
            loadTopNDataAndStoreState(
                (widgetSettingsAndResponseState.widgetSettings as TopNWidgetSettingsDTO),
                newCancelTokenSource,
            )
        } else {
            loadDataAndStoreState(
                widgetSettingsAndResponseState.widgetSettings,
                newCancelTokenSource,
            )
        }

    }

    /**
     * Loads data from [apiPath] with [querySettings] and [cancelTokenSource]. Afterwards stores the response in the state.
     *
     * @param widgetSettings
     * @param cancelTokenSource
     */
    const loadDataAndStoreState = (widgetSettings: WidgetSettingsDTO, cancelTokenSource: CancelTokenSource) => {
        const { querySettings, apiPath } = widgetSettings
        ReportingService.loadData(apiPath || 'reporting/loadData', querySettings, cancelTokenSource)
            .then(dataLoadedHandler)
            .catch(errorHandler)
    }

    /**
     * Loads top n values of the second dimension (column 2) for the metric (column 3) and then loads the main query filtered by the
     * top n entries. Afterwards stores the filtered response in the state.
     *
     * @param widgetSettings
     * @param cancelTokenSource
     */
    const loadTopNDataAndStoreState = (widgetSettings: TopNWidgetSettingsDTO, cancelTokenSource: CancelTokenSource) => {
        const { querySettings, apiPath, topNElements } = widgetSettings

        const secondDimensionColumnName = querySettings.columnNames[1]
        const metricColumnName = querySettings.columnNames[2]
        const secondDimensionIdentifier = DimensionService.recognizeDimensionField(secondDimensionColumnName).identifier

        const topNQuerySettings = {
            ...querySettings,
            columnNames: [DimensionService.getDimensionValueColumn(secondDimensionIdentifier), metricColumnName],
            paginationSettings: {
                page: 0,
                pageSize: topNElements,
                sortAscending: false,
                sortProperties: [metricColumnName],
            } as PageableDTO,
        }

        // loading top n values
        ReportingService.loadData(apiPath || 'reporting/loadData', topNQuerySettings, cancelTokenSource)
            .then((topNResponse: LoadResponseDTO) => {
                const newTopNValues = topNResponse.dataSet.rows.map(row => row[secondDimensionIdentifier].name || row[secondDimensionIdentifier].value)

                if (newTopNValues.length > 0) {
                    const mainQuerySettings: QuerySettingsDTO = {
                        ...querySettings,
                        filter: ConditionClauseService.combineFilterQueries([
                            querySettings.filter,
                            {
                                operator: BooleanOperator.OR,
                                clauseType: ConditionClauseType.BOOLEAN,
                                clauses: newTopNValues.map(value => {
                                    return {
                                        columnName: DimensionService.getDimensionValueColumn(secondDimensionIdentifier),
                                        value: value === undefined ? '__NULL__' : value,
                                        type: ClauseType.EQUALS,
                                        clauseType: value === undefined || isNaN(Number(value.toString())) ? ConditionClauseType.STRING : ConditionClauseType.NUMBER,
                                    } as StringValueClauseDTO
                                }),
                            } as BooleanClauseDTO,
                        ]),
                    }

                    // load main data filtered by the top n values
                    loadDataAndStoreState({ querySettings: mainQuerySettings, apiPath: apiPath } as WidgetSettingsDTO, cancelTokenSource)
                } else {
                    log.debug('No top n data found')
                    setWidgetSettingsAndResponseState(prev => {
                        return { ...prev, response: topNResponse }
                    })
                    updateIsLoading(false)
                }
            }).catch(errorHandler)
    }

    /**
     * This handler will be executed after the data loading is done
     *
     * @param response
     */
    const dataLoadedHandler = (response: LoadResponseDTO | ErrorResponse) => {
        setWidgetSettingsAndResponseState(prev => {
            return { ...prev, response: response }
        })
        updateIsLoading(false)
    }

    const errorHandler = (response: ErrorResponse) => {
        if (response?.errors && response.errors.indexOf('Cancel') >= 0) {
            log.debug('request was canceled')
        } else {
            dataLoadedHandler(response)
        }
    }

    /**
     * Checks whether querySettings have been changed
     */
    const querySettingsChanged = (prevQuerySettings: QuerySettingsDTO, newQuerySettings: QuerySettingsDTO): boolean => {
        return JSON.stringify(prevQuerySettings) !== JSON.stringify(newQuerySettings)
    }

    const context = {
        widgetSettings: widgetSettingsAndResponseState.widgetSettings,
        updateWidgetSettings: (widgetSettings?: WidgetSettingsDTO) => {
            setWidgetSettingsAndResponseState(prev => {
                return { ...prev, widgetSettings: widgetSettings }
            })
            WidgetReducer.storeWidgetSettings(widgetSettings)(store.dispatch)
            EmbeddingUtil.sendMessage(MESSAGE.WIDGET_SETTINGS_CHANGED, widgetSettings)
        },
        response: widgetSettingsAndResponseState.response,
        isLoading: isLoading,
        isLegendButtonVisible: isLegendButtonVisible,
        updateIsLegendButtonVisible: updateIsLegendButtonVisible,
        cancelTokenSource: cancelTokenSource,
    } as WidgetContextProperties

    return <WidgetContext.Provider value={context}>
        <ContainerSizeContextProvider containerRef={widgetContainerRef}>
            {props.children}
        </ContainerSizeContextProvider>
    </WidgetContext.Provider>
}
