import { history } from 'shared/util/history'
import { SystemService } from 'domain/core/service'

/**
 * If there is referrer url parameter then redirect to it after 2 sec delay
 */
const redirectToReferrer = () => {
    // redirect to the referrer after 2 sec delay
    const referrer = MfaUtil.getReferrerUrlParameter()
    if (referrer) {
        setTimeout(() => {
            window.location.href = referrer
        }, 2000)
    } else {
        setTimeout(() => {
            SystemService.showCoreUi()
        }, 2000)
    }
}

/**
 * Take over the referrer url parameter and redirect to /ui/mfa/otp/verify-code after 2 sec delay
 */
const redirectToVerifyCode = () => {
    setTimeout(() => {
        history.push(MfaUtil.appendReferrer(`/ui/mfa/otp/verify-code`))
    }, 2000)
}

/**
 * Appends referrer url parameter to the url
 *
 * @param url
 */
const appendReferrer = (url: string) => {
    const referrer = MfaUtil.getReferrerUrlParameter()
    const referrerParameter = referrer ? `?referrer=${referrer}` : ''

    return url + referrerParameter
}

const MfaUtil = {
    redirectToReferrer: redirectToReferrer,
    redirectToVerifyCode: redirectToVerifyCode,
    appendReferrer: appendReferrer,
    getReferrerUrlParameter: () =>
        new URLSearchParams(location.search).get('referrer')
}

export default MfaUtil
