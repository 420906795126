import React, { Fragment } from 'react'
import { Button, Col, Row, Tooltip } from 'antd'
import { ArrowRightOutlined, DownloadOutlined } from '@ant-design/icons'
import { capitalizeEnum } from 'shared/util/util'
import ScenarioSelectDropdown from 'domain/adspend-optimizer/component/ScenarioSelectDropdown'

type Props = {
    optimizationLevel: string
    titlePrefix: string
    scenarioId1: string | number
    scenarioId2: string | number
    callback1?: any
    callback2?: any
    scenarios?
    onDownload?: any
    downloadTooltip?: string
}

export default (props: Props) => {

    const downloadButton = (children: React.ReactNode) => props.downloadTooltip
        ? <span data-tip={props.downloadTooltip} data-force-tooltip={true}>{children}</span>
        : <React.Fragment>{children}</React.Fragment>

    return (
        <Fragment>
            <Row className={'comparison-header'}>
                <Col className={'header-left'}>
                    {<h3
                        style={{ display: 'inline-block' }}>{props.titlePrefix} {capitalizeEnum(props.optimizationLevel).replace('-', ' ')}: </h3>}
                    <ScenarioSelectDropdown initValue={props.scenarioId1}
                                            size={'large'}
                                            callback={props.callback1}
                                            scenarios={props.scenarios}/>
                    <ArrowRightOutlined/>
                    <ScenarioSelectDropdown initValue={props.scenarioId2}
                                            callback={props.callback2}
                                            size={'large'}
                                            scenarios={props.scenarios}/>
                </Col>
                <Col className={'toolbar toolbar-right'}>
                    {props.onDownload && downloadButton(<Button
                        onClick={props.onDownload}
                        type="primary"
                        htmlType="button"
                        shape="circle"
                        className="download"
                    ><DownloadOutlined/></Button>)
                    }
                </Col>

            </Row>
        </Fragment>
    )
}
