import * as echarts from 'echarts/lib/echarts'
import { exactagTheme } from 'shared/echart/themes/exactagTheme'
import { registerMonochromeThemes } from 'shared/echart/themes/monochromeThemes'

/**
 * Provides basic echarts setup for our customized theme that is reused across all charts.
 */
const setup = () => {
    echarts.registerTheme('exactag', exactagTheme)
    registerMonochromeThemes()
}

export default setup
