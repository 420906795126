import React from 'react'
import LegacyWidgetContext from 'domain/context/LegacyWidgetContext'
import GenericDataGridWidget, { DataGridWidgetSettings } from 'domain/widget/generic/GenericDataGridWidget'
import { AppContextDTO, ConditionClauseDTO, GridConfigDTO, GridElementDTO, PathsDTO } from 'domain/types'
import { DataGridContextProvider } from './DataGridContext'
import store from 'shared/redux/store'
import { WidgetType } from 'domain/types/backend/widget.types'
import ElementHeader from 'shared/component/layout/ElementHeader'

type GenericDataGridWidgetWrapperProps = {
    appContext?: AppContextDTO
    gridSettings: GridElementDTO
}

export const DEFAULT_DATA_GRID_WIDGET_SETTINGS: DataGridWidgetSettings = {
    type: WidgetType.DATA_GRID_WIDGET,
    defaultOrderBy: undefined,
    defaultOrderDirection: 'ASC',
    showSettings: false,
    // don't show download button because it will be shown on the tools panel level
    showDownload: false,
    showFooter: false,
    requiredFilters: [],
    dependsOn: [],
    embedded: false,
}

export type MergedDataGridWidgetSettings = DataGridWidgetSettings & {
    gridConfig: GridConfigDTO
    baseURL: string
    paths: PathsDTO
    path: string,
    preselectedFilters?: ConditionClauseDTO,
}

const mergeSettings = (gridElementDTO: GridElementDTO): MergedDataGridWidgetSettings => ({
    ...DEFAULT_DATA_GRID_WIDGET_SETTINGS,
    gridConfig: gridElementDTO.elementConfig.gridConfig,
    baseURL: gridElementDTO.elementConfig.apiEndpoints.baseURL,
    paths: gridElementDTO.elementConfig.apiEndpoints.paths,
    path: gridElementDTO.elementConfig.path,
    preselectedFilters: gridElementDTO.preselectedFilters,
})

const GenericDataGridWidgetWrapper: React.FC<GenericDataGridWidgetWrapperProps> = React.memo((props: GenericDataGridWidgetWrapperProps): JSX.Element => {
    const widgetContextValue: LegacyWidgetContext<MergedDataGridWidgetSettings> = new LegacyWidgetContext(mergeSettings(props.gridSettings))

    return <LegacyWidgetContext.Provider value={widgetContextValue}>
        <DataGridContextProvider>
            <ElementHeader header={props.gridSettings.header}/>
            <GenericDataGridWidget appContext={store.getState().appContext.appContext}/>
        </DataGridContextProvider>
    </LegacyWidgetContext.Provider>
})

export default GenericDataGridWidgetWrapper
