import React, { useEffect, useState } from 'react'
import { Alert, Spin } from 'antd'
import { AvailableFactorsResponseDTO, MfaFactor, MfaFactorConfigDTO } from 'domain/types/backend/mfa.types'
import mfaService from './service/mfaService'
import MfaCodeVerificationForm from 'domain/onetimepassword/MfaCodeVerificationForm'
import MfaFactorSelectionForm from 'domain/onetimepassword/MfaFactorSelectionForm'
import AuthenticatorSetup from 'domain/onetimepassword/AuthenticatorSetup'
import EmailSetup from 'domain/onetimepassword/EmailSetup'
import MfaUtil from 'domain/onetimepassword/MfaUtil'
import AuthenticationService from 'domain/authentication/service/authentication.service'
import { SystemService } from 'domain/core/service'


const MfaForm: React.FC = (): JSX.Element => {

    const [availableFactors, setAvailableFactors] = useState<MfaFactorConfigDTO[]>(undefined)
    const [setUpFactors, setSetUpFactors] = useState<MfaFactorConfigDTO[]>(undefined)
    const [factorToSetUp, setFactorToSetUp] = useState<MfaFactorConfigDTO>(undefined)
    const [mfaComplete, setMfaComplete] = useState(false)

    useEffect(() => {
        mfaService.availableFactors()
            .then((availableFactorsResponseDTO: AvailableFactorsResponseDTO) => {
                    const setUpFactors = availableFactorsResponseDTO.availableFactors.filter(config => config.setupComplete)
                    setSetUpFactors(setUpFactors)
                    setAvailableFactors(availableFactorsResponseDTO.availableFactors)
                },
            )
    }, [])

    const selectFactorToSetUp = (factor: MfaFactor) => setFactorToSetUp(availableFactors.find(el => el.factor == factor))

    const onMfaComplete = () => {
        setMfaComplete(true)
        MfaUtil.redirectToReferrer()
    }

    const abortMfa = () => {
        AuthenticationService.logout().then(() => {
            SystemService.logoutAndRedirectCoreUI()
        })
    }

    return <>
        <Spin spinning={availableFactors === undefined} wrapperClassName={'generic-page-main-spinner'}/>

        {(availableFactors !== undefined) &&
            <div className={'content-body standalone-form mfa-form'}>
                {!mfaComplete && <>


                    {!factorToSetUp && <>
                        {setUpFactors?.length > 0 && <MfaCodeVerificationForm factorConfig={setUpFactors[0]} success={onMfaComplete}/>}
                        {setUpFactors?.length <= 0 && availableFactors && <MfaFactorSelectionForm factorConfigs={availableFactors} select={selectFactorToSetUp}/>}
                    </>}

                    {factorToSetUp && <>{
                        {
                            [MfaFactor.Authenticator]: <AuthenticatorSetup success={onMfaComplete}/>,
                            [MfaFactor.Email]: <EmailSetup factorConfig={factorToSetUp} success={onMfaComplete}/>,
                        }[factorToSetUp.factor]
                    }</>}

                    <div className={'mfa-form-footer'}>
                        Need some help?<br/>
                        Email our <a href="mailto:support@exactag.com">support team</a> or have a look at the <a href="https://ticket.exactag.com/hc/en-us/articles/5042492615708" target="_blank">help center</a>.
                    </div>
                </>}

                {mfaComplete &&
                    (factorToSetUp
                        ? <>
                            <h3>Setup Complete</h3>
                            <Alert showIcon type={'success'} description={<p>You have successfully completed the multi-factor authentication setup.
                                <br/>You will be logged in shortly.</p>}/>
                        </>
                        : <>
                            <h3>Verification Successful</h3>
                            <Alert showIcon type={'success'} message={<p>You will be logged in shortly.</p>}/>
                        </>)
                }

                <div className={'footer'}>
                    <a onClick={abortMfa}>Back to Login</a>
                </div>
            </div>
        }
    </>
}

export default MfaForm
