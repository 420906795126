import React, { useEffect, useState } from 'react'
import { RendererType } from 'shared/component/renderers/renderers'
import { DataGrid } from 'domain/datagrid/component/DataGrid'
import { Alert } from 'antd'
import { capitalizeEnum } from 'shared/util/util'
import { useDataGridContext } from 'domain/widget/generic/DataGridContext'
import { ColumnConfigDTO, ComparisonDataDTO, ComparisonRequestDataDTO } from 'domain/types'
import DimensionService from 'domain/dimension/service/DimensionService'

const columnConfig = (optimizationLevel: string, scenario1Name: string, scenario2Name: string): ColumnConfigDTO[] => {
    const prefix = optimizationLevel.toLowerCase()
    const optimizationLevelHeader = capitalizeEnum(optimizationLevel)

    return [
        {
            columnIdentifier: `${prefix}.name`,
            gridColumnProperties: {
                columnHeader: optimizationLevelHeader,
                renderer: { type: RendererType.NAME, cssClasses: [] },
                sortable: true,
                editable: false,
            },
            valueType: 'STRING',
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn('adspend_costs'),
            gridColumnProperties: {
                columnHeader: 'Budget',
                cssStyles: { cursor: 'pointer' },
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: [] },
                sortable: true,
                editable: false,
            },
            valueType: 'CURRENCY',
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: 'HAS_NO_DIRECTION',
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn('adspend_conversions'),
            gridColumnProperties: {
                columnHeader: 'Conversions',
                cssStyles: { cursor: 'pointer' },
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: [] },
                sortable: true,
                editable: false,
            },
            valueType: 'NUMBER',
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: 'UP',
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn('adspend_total_price'),
            gridColumnProperties: {
                columnHeader: 'Total Price',
                cssStyles: { cursor: 'pointer' },
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: [] },
                sortable: true,
                editable: false,
            },
            valueType: 'CURRENCY',
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: 'UP',
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn('adspend_roas'),
            gridColumnProperties: {
                columnHeader: 'ROAS',
                cssStyles: { cursor: 'pointer' },
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: [] },
                sortable: true,
                editable: false,
            },
            valueType: 'NUMBER',
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 1,
            goodChangeDirection: 'UP',
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn('adspend_cpo'),
            gridColumnProperties: {
                columnHeader: 'CPO',
                cssStyles: { cursor: 'pointer' },
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: [] },
                sortable: true,
                editable: false,
            },
            valueType: 'NUMBER',
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 1,
            goodChangeDirection: 'DOWN',
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn('adspend_roi'),
            gridColumnProperties: {
                columnHeader: 'ROI',
                cssStyles: { cursor: 'pointer' },
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: [] },
                sortable: true,
                editable: false,
            },
            valueType: 'PERCENT',
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: 'UP',
        },
    ]
}

const mergeRows = (columnConfigs: ColumnConfigDTO[], data: ComparisonDataDTO, optimizationLevel: string) => {
    const { rows, totals } = data
    const prefix = optimizationLevel.toLowerCase()
    const mergedRows = []
    const mergedTotals = {}

    const columns = Object.keys(rows[0].slices.data[0].data)

    rows.forEach((row, index) => {
        const mergedRow = {}
        columns.forEach(column => {
            const config = columnConfigs.find(c => c.columnIdentifier === DimensionService.getDimensionValueColumn(column))
            mergedRow[column] = {
                values: [row.slices.data[0].data[column]?.value, row.slices.data[1].data[column]?.value],
                change: {
                    absolute: row.compareValues.data.absoluteChange[column],
                    relative: row.compareValues.data.relativeChange[column],
                },
                comparisonItem1Name: config.comparisonItem1Name,
                comparisonItem2Name: config.comparisonItem2Name,
                goodChangeDirection: config.goodChangeDirection || 'UP',
                label: config.gridColumnProperties.columnHeader,
                valueType: config.valueType,
                round: config.round,
            }
        })
        mergedRow[prefix] = { name: row[prefix]['name'] }
        mergedRow['comparison_grid_row_index'] = index
        mergedRows.push(mergedRow)
    })

    if (totals && Object.keys(totals).length > 0) {
        columns.forEach(column => {
            const config = columnConfigs.find(c => c.columnIdentifier === DimensionService.getDimensionValueColumn(column))
            mergedTotals[column] = {
                values: [totals.slices.data[0].data[column]?.value, totals.slices.data[1].data[column]?.value],
                change: {
                    absolute: totals.compareValues.data.absoluteChange[column],
                    relative: totals.compareValues.data.relativeChange[column],
                },
                comparisonItem1Name: config.comparisonItem1Name,
                comparisonItem2Name: config.comparisonItem2Name,
                goodChangeDirection: config.goodChangeDirection || 'UP',
                label: config.gridColumnProperties.columnHeader,
                round: config.round,
                valueType: config.valueType,
            }
        })
        mergedTotals[prefix] = { name: `Total rows: ${mergedRows.length}` }
    }

    return { rows: mergedRows, totals: mergedTotals }
}

type ScenarioComparisonGridProps = {
    data: ComparisonDataDTO,
    requestData: ComparisonRequestDataDTO,
    optimizationLevel: string,
    scenario1Name: string,
    scenario2Name: string
}

type GridState = {
    error: boolean,
    showFooter: boolean
}

const ScenarioComparisonGrid: React.FC<ScenarioComparisonGridProps> = (props: ScenarioComparisonGridProps): JSX.Element => {
    const { updateRows } = useDataGridContext()
    const [gridState, setGridState] = useState<GridState>({ error: false, showFooter: false })

    const columnConfigs = columnConfig(props.optimizationLevel, props.scenario1Name, props.scenario2Name)

    useEffect(() => {
        try {
            const mergedData = mergeRows(columnConfigs, props.data, props.optimizationLevel)

            updateRows(mergedData)
            setGridState({ error: false, showFooter: Object.keys(mergedData.totals).length > 0 })
        } catch (e) {
            console.error(e)
            setGridState({ error: true, showFooter: false })
        }
    }, [props.data])

    return (
        <React.Fragment>
            {!gridState.error && <DataGrid columns={columnConfigs}
                                           supportsRowSelection={false}
                                           showFooter={gridState.showFooter}
                                           pagination={{ page: 0, pageSize: 10, sortAscending: true, sortProperties: [''] }}/>
            }

            {gridState.error && <Alert message={'An error occurred loading the scenario comparison data'}/>}
        </React.Fragment>
    )
}

export default ScenarioComparisonGrid
