import * as React from 'react'

import { log } from 'shared/util/log'
import { Alert } from 'antd'

type Props = {
    message?: string,
    children: React.ReactNode,
}

type State = {
    hasError: boolean,
}

/**
 * Defines an error boundary, see https://reactjs.org/docs/error-boundaries.html
 *
 * Only exceptions thrown from child components can be catched
 *
 * Be aware that only uncatched exceptions from lifecycle methods will be catched by error boundaries. Exceptions from
 * event handlers, async code, server side rendering and errors in the error boundary itself will NOT be catched.
 */
class ErrorBoundary extends React.Component<Props, State> {

    static defaultProps = {
        message: 'An unexpected error occurred!',
    }

    constructor(props: Props) {
        super(props)

        this.state = { hasError: false }
    }

    componentDidCatch(error: any, info: any) {

        log.error('ErrorBoundary catched an unexpected error: ', error)

        this.setState({ hasError: true })
    }

    render() {
        if (this.state.hasError) {
            return <Alert type='error' message={`We're sorry, an error occurred.`}/>
            // return <Alert type='error' message={ this.props.message }/>
        }

        return this.props.children
    }
}

export default ErrorBoundary