import React, { useEffect, useState } from 'react'
import _difference from 'lodash/difference'
import moment from 'moment'
import { Moment } from 'moment'
import { Button, DatePicker, Form, Input, InputNumber, Radio, Row, Switch, Tooltip } from 'antd'
import { BarcodeOutlined, CheckOutlined, CloseOutlined, FallOutlined, MinusOutlined, RiseOutlined } from '@ant-design/icons'
import { dateString, euroBudgetInputFormatter, numberInputParser } from 'shared/util/util'
import { CAMPAIGN_DIMENSION_IDENTIFIER } from 'domain/adspend-optimizer/context/AdSpendOptimizerContext'

type Props = {
    form: any
    index: number
    status: number
    type: string
    id: number
    name: string
    adspend_budget: number
    scenarioPeriod: string[]
    mediaPlanPeriod: string[]
    flighting: string
    weeks: number
    mode: 'ADD' | 'EDIT',
    optimizationLevel: string
}

const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { RangePicker } = DatePicker

const MediaPlanRow: React.FunctionComponent<Props> = React.memo((props: Props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form

    const [periodStart, setPeriodStart] = useState(props.mediaPlanPeriod[0])
    const [periodEnd, setPeriodEnd] = useState(props.mediaPlanPeriod[1])
    const [scenarioPeriod, setScenarioPeriod] = useState(props.scenarioPeriod)
    const [periodWeeks, setPeriodWeeks] = useState(props.weeks)
    const [pickingPeriodEnd, setPickingPeriodEnd] = useState(false)
    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [rowActive, setRowActive] = useState(true)

    // calculate period weeks when new end date has been set
    useEffect(() => {
        if (periodStart && periodEnd) {
            const duration = moment.parseZone(periodEnd)
                .add(1, 'day')
                .diff(moment.parseZone(periodStart), 'week')
            setPeriodWeeks(duration)
        } else {
            setPeriodWeeks(0)
        }
    }, [periodEnd])

    useEffect(() => {
        if (props.scenarioPeriod[0] && props.scenarioPeriod[1]) {
            if (!periodStart && !periodEnd && !props.mediaPlanPeriod[0] && !props.mediaPlanPeriod[1]) {
                setPeriodStart(props.scenarioPeriod[0])
                setPeriodEnd(props.scenarioPeriod[1])

                const periodFieldName = `adspend_period[${props.index}]`
                const fields = {}
                fields[periodFieldName] = [moment.parseZone(props.scenarioPeriod[0]), moment.parseZone(props.scenarioPeriod[1])]
                setFieldsValue({ ...fields })
            } else if (_difference(scenarioPeriod, [periodStart, periodEnd]).length === 0) {
                setPeriodStart(props.scenarioPeriod[0])
                setPeriodEnd(props.scenarioPeriod[1])

                const periodFieldName = `adspend_period[${props.index}]`
                const fields = {}
                fields[periodFieldName] = [moment.parseZone(props.scenarioPeriod[0]), moment.parseZone(props.scenarioPeriod[1])]
                setFieldsValue({ ...fields })
            } else {
                const currentStart = periodStart
                const currentEnd = periodEnd
                const receivedStart = props.scenarioPeriod[0]
                const receivedEnd = props.scenarioPeriod[1]
                const newStart = currentStart
                    ? dateString(moment.max(moment.parseZone(currentStart), moment.parseZone(receivedStart)))
                    : receivedStart
                const newEnd = currentEnd
                    ? dateString(moment.min(moment.parseZone(currentEnd), moment.parseZone(receivedEnd)))
                    : receivedEnd

                if (currentStart && currentEnd && (newStart !== currentStart || newEnd !== currentEnd)) {
                    setPeriodStart(newStart)
                    setPeriodEnd(newEnd)
                    const periodFieldName = `adspend_period[${props.index}]`
                    const fields = {}
                    fields[periodFieldName] = [moment.parseZone(newStart), moment.parseZone(newEnd)]
                    setFieldsValue({ ...fields })
                }
            }
            setScenarioPeriod(props.scenarioPeriod)
        }
    }, [props.scenarioPeriod[0], props.scenarioPeriod[1]])

    // update period weeks state
    useEffect(() => {
        if (!periodStart && !periodEnd) setPeriodWeeks(props.weeks)
    }, [props.weeks])

    // update budgets
    useEffect(() => {
        const active = getFieldValue(`adspend_status_id[${props.index}]`)
        setRowActive(active)
        if (active) {
            const weeks = Math.max(periodWeeks, 1)
            const budget = Math.floor(props.adspend_budget)
            const currentBudget = getFieldValue(`adspend_planned_budget[${props.index}]`)
            const field = {}
            field[`adspend_planned_budget[${props.index}]`] = props.mode === 'ADD'
                ? currentBudget % budget === 0 ? budget * weeks : currentBudget
                : budget || null
            setFieldsValue({ ...field })
        } else {
            const field = {}
            field[`adspend_planned_budget[${props.index}]`] = 0
            setFieldsValue({ ...field })
        }
    }, [getFieldValue(`adspend_status_id[${props.index}]`), periodWeeks])

    const disabledDate = (value: Moment): boolean => {
        const min = moment.parseZone(props.scenarioPeriod[0]).subtract(1, 'day')
        const max = moment.parseZone(props.scenarioPeriod[1]).add(1, 'day')
        return pickingPeriodEnd
            ? (moment(value).day() !== 0 || moment(value).isBefore(moment.parseZone(periodStart)) || moment(value).isAfter(max))
            : (moment(value).day() !== 1 || moment(value).isBefore(min) || moment(value).isAfter(max))
    }

    const handleRangePickerChange = (values: Moment[]) => {
        if (values.length === 0) {
            setPeriodStart(null)
            setPeriodEnd(null)
            setPickingPeriodEnd(false)
        } else {
            setPickingPeriodEnd(false)
            setPeriodEnd(dateString(values[1]))
        }
    }

    const handleRangePickerOpenChange = (open: boolean) => {
        setDatePickerOpen(open)
        if (!open) setPickingPeriodEnd(false)
    }

    const handleDateSelect = (values: Moment[]) => {
        if (datePickerOpen) {
            setPeriodStart(dateString(values[0]))
            setPickingPeriodEnd(true)
        }
    }

    return <Row className={`media-plan-row ${!rowActive ? 'media-plan-row-disabled' : ''}`}>
        {props.type === CAMPAIGN_DIMENSION_IDENTIFIER && <React.Fragment>
            <div style={{ width: 190 }} className={'media-plan-column'}>
                <Form.Item className={'item-hidden'}>
                    {getFieldDecorator(`adspend_mediaplan_type[${props.index}]`, {
                        initialValue: props.type,
                    })(
                        <Input hidden/>,
                    )}
                </Form.Item>
                <Form.Item className={'item-hidden'}>
                    {getFieldDecorator(`adspend_${props.type}_id[${props.index}]`, {
                        initialValue: props.id,
                    })(
                        <Input hidden/>,
                    )}
                </Form.Item>
                <Form.Item className={'item-hidden'}>
                    {getFieldDecorator(`adspend_status_id[${props.index}]`, {
                        initialValue: true,
                    })(
                        <Input hidden/>,
                    )}
                </Form.Item>
                <Form.Item>
                    <div style={{ marginTop: -10 }}>{props.name}</div>
                </Form.Item>
            </div>
        </React.Fragment>}
        {props.type !== CAMPAIGN_DIMENSION_IDENTIFIER && <React.Fragment>
            <div style={{ width: 45 }} className={'media-plan-column'}>
                <Form.Item className={'item-hidden'}>
                    {getFieldDecorator(`adspend_mediaplan_type[${props.index}]`, {
                        initialValue: props.type,
                    })(
                        <Input hidden/>,
                    )}
                </Form.Item>
                <Form.Item className={'item-hidden'}>
                    {getFieldDecorator(`adspend_${props.type}_id[${props.index}]`, {
                        initialValue: props.id,
                    })(
                        <Input hidden/>,
                    )}
                </Form.Item>
                <Form.Item style={{ marginTop: 1 }}>
                    {getFieldDecorator(`adspend_status_id[${props.index}]`, {
                        initialValue: props.status === 1, valuePropName: 'checked',
                    })(
                        <Switch size="small"
                                checkedChildren={<CheckOutlined style={{ marginTop: 2, marginLeft: 1 }}/>}
                                unCheckedChildren={<CloseOutlined style={{ marginTop: 2, marginRight: 1 }}/>}/>,
                    )}
                </Form.Item>
            </div>
            <div style={{ width: 145, marginTop: 4, paddingLeft: 3 }} className={'media-plan-column'}>
                <Form.Item>{props.name}</Form.Item>
            </div>
        </React.Fragment>}
        <div style={{ width: 130 }} className={'media-plan-column'}>
            <Form.Item className={'media-plan-budget'}>
                {getFieldDecorator(`adspend_planned_budget[${props.index}]`, {
                    validateTrigger: 'onSubmit',
                    rules: [
                        {
                            validator: (rule, value, cb) => {
                                value = Number(numberInputParser(value))
                                const val = value
                                try {
                                    if (rowActive) {
                                        if (isNaN(val)) {
                                            cb(new Error('Budget has to be a number.'))
                                        } else if (!(val >= 0)) {
                                            cb(new Error('Budget must not be negative.'))
                                        } else if (val > 1000000000) {
                                            cb(new Error('Budget is limited to 1.000.000.000'))
                                        } else {
                                            cb()
                                        }
                                    } else {
                                        cb()
                                    }
                                } catch (err) {
                                    cb(err)
                                }
                            },
                        },
                    ],
                    initialValue: props.mode === 'ADD'
                        ? Math.floor(props.adspend_budget) ? Math.floor(props.adspend_budget * Math.max(periodWeeks, 1)) : null
                        : Math.floor(props.adspend_budget) || null,
                })(
                    <InputNumber size={'small'} disabled={!rowActive} min={0} max={1000000000}
                                 step={10000} formatter={euroBudgetInputFormatter} parser={numberInputParser}
                                 style={{ width: 110 }}/>,
                )}
            </Form.Item>
        </div>
        <div style={{ width: 230 }} className={'media-plan-column'}>
            {props.type === CAMPAIGN_DIMENSION_IDENTIFIER && <Form.Item>
                {getFieldDecorator(`adspend_period[${props.index}]`, {
                    options: { initialValue: props.scenarioPeriod },
                })(
                    <div>
                        {periodStart && periodEnd && <div style={{ letterSpacing: 0.3 }}>
                            {dateString(moment.parseZone(periodStart), 'DD.MM.YYYY')}&nbsp;to&nbsp;
                            {dateString(moment.parseZone(periodEnd), 'DD.MM.YYYY')}
                        </div>}
                        <RangePicker format="DD.MM.YYYY" size={'small'}
                                     disabled={!rowActive || props.type === CAMPAIGN_DIMENSION_IDENTIFIER}
                                     disabledDate={disabledDate}
                                     dropdownClassName={'calendar-custom-range'}
                                     onCalendarChange={handleDateSelect}
                                     onChange={handleRangePickerChange}
                                     onOpenChange={handleRangePickerOpenChange}
                                     style={{ opacity: 0, position: 'absolute', maxHeight: 0 }}
                        />
                    </div>,
                )}
            </Form.Item>}
            {props.type !== CAMPAIGN_DIMENSION_IDENTIFIER && <Form.Item>
                {getFieldDecorator(`adspend_period[${props.index}]`, {
                    initialValue: props.mode === 'EDIT'
                        ? [moment.parseZone(props.mediaPlanPeriod[0]), moment.parseZone(props.mediaPlanPeriod[1])]
                        : undefined,
                    rules: [
                        {
                            validator: (rule, value, cb) => {
                                try {
                                    if (value && moment(value[1]).isBefore(moment(value[0]))) {
                                        cb(new Error('End date has to be after the start date.'))
                                    } else {
                                        cb()
                                    }
                                } catch (err) {
                                    cb(err)
                                }
                            },
                        },
                    ],
                })(
                    <RangePicker format="DD.MM.YYYY" size={'small'}
                                 disabled={!rowActive || props.type === CAMPAIGN_DIMENSION_IDENTIFIER}
                                 disabledDate={disabledDate}
                                 onCalendarChange={handleDateSelect}
                                 dropdownClassName={'calendar-custom-range'}
                                 onChange={handleRangePickerChange}
                                 onOpenChange={handleRangePickerOpenChange}
                    />,
                )}
            </Form.Item>}
        </div>
        <div style={{ width: 130 }} className={'media-plan-column flighting-patterns'}>
            <Form.Item>
                {getFieldDecorator(`adspend_flighting_pattern[${props.index}]`, {
                    initialValue: props.flighting || 'even',
                })(
                    <RadioGroup size={'small'} disabled={!rowActive}>
                        <RadioButton value={'even'}>
                            <span data-tip={'Even'} data-force-tooltip={true}>
                                <MinusOutlined/>
                            </span>
                        </RadioButton>
                        <RadioButton value={'back'}>
                            <span data-tip={'Back'} data-force-tooltip={true}>
                                <FallOutlined/>
                            </span>
                        </RadioButton>
                        <RadioButton value={'front'}>
                            <span data-tip={'Front'} data-force-tooltip={true}>
                                <RiseOutlined/>
                            </span>
                        </RadioButton>
                        <RadioButton value={'burst'}>
                            <span data-tip={'Burst'} data-force-tooltip={true}>
                                <BarcodeOutlined/>
                            </span>
                        </RadioButton>
                    </RadioGroup>,
                )}
            </Form.Item>
        </div>
    </Row>
})

export default MediaPlanRow
