import React, { useContext, useEffect } from 'react'
import { Button, Col, Modal, Row, Spin } from 'antd'
import { ContainerElementDTO, LayoutElementType, LayoutMode, ModalConfig, RowLayoutConfigDTO } from 'domain/types'
import { FooterState, useRootElementContext } from 'shared/component/layout/context/RootElementContext'
import { AdditionalFilterContext } from 'shared/component/layout/context/AdditionalFilterContext'
import LayoutRenderer from 'shared/component/layout/renderers/LayoutRenderer'

type CustomModalProps = {
    modal: ModalConfig,
    index: number
}

export const getDefaultFooter = (modal: ModalConfig): JSX.Element => {
    const additionalFooterElementsContainer = {
        elementType: LayoutElementType.CONTAINER,
        layoutConfig: { layoutMode: LayoutMode.ROWS } as RowLayoutConfigDTO,
        children: modal.additionalFooterElements,
    } as ContainerElementDTO
    return <>
        <Row>
            <Col span={8} className={'additional-footer-elements'}>
                <LayoutRenderer layoutElementConfig={additionalFooterElementsContainer}/>
            </Col>
            <Col span={16}>
                <Button key="back" onClick={modal.onCancel}>
                    {modal.cancelText || 'Cancel'}
                </Button>
                <Button key="submit" type="primary" onClick={modal.onOk} disabled={modal.contentLoading}>
                    {modal.okText || 'Save'}
                </Button>
            </Col>
        </Row>
    </>
}

/**
 * Created the footer, including any wrapper elements, to be rendered
 * @param footer
 * @param defaultFooter
 */
const getFooterToRender = (footer, defaultFooter): JSX.Element => {
    if (footer == FooterState.HIDE_FOOTER) {
        // if footer should be hidden, we just show the default footer; the elements will be hidden via CSS later
        // this way, the footer remains the same height and we can easily fade it in and out via CSS
        return defaultFooter
    } else if (footer == FooterState.SHOW_DEFAULT_FOOTER) {
        return defaultFooter
    } else {
        return footer
    }
}

const CustomModal: React.FC<CustomModalProps> = (props: CustomModalProps): JSX.Element => {
    const { footer, defaultFooter, updateDefaultFooter } = useRootElementContext()
    const additionalFilterContext = useContext(AdditionalFilterContext)
    const { modal, index } = props

    const footerToRender = getFooterToRender(footer, defaultFooter)

    useEffect(() => {
        const defaultFooter = getDefaultFooter(props.modal)
        updateDefaultFooter(defaultFooter)

        additionalFilterContext.updateAdditionalFilters(props.modal.additionalFilters)
    }, [props.modal])

    return <>
        <Modal className={'custom-modal ' + (footer == FooterState.HIDE_FOOTER ? 'no-footer' : '')}
               centered={true}
               visible={modal.visible}
               maskClosable={false}
            // we are setting a minHeight for certain modals to avoid the modal from changing the size while switching between tabs
               bodyStyle={{ minHeight: modal.modalMinHeight ? modal.modalMinHeight : 700 }}
               width={modal.modalWidth ? modal.modalWidth : 700}
               key={`__modal__${index}`}
               title={
                   <>
                       {modal.contentLoading ? 'Loading...' : (modal.title || '')}
                       {modal.subtitle}
                   </>
               }
               onOk={modal.onOk}
               onCancel={modal.onCancel}
               footer={
                   footerToRender
               }>
            <div className={'spinner-wrapper'}>
                <Spin spinning={modal.contentLoading}>
                    <div className={'content-wrapper'}>
                        {modal.content}
                    </div>
                </Spin>
            </div>
        </Modal>

    </>
}
export default CustomModal
