import { MenuEntryUIConfig, UIPageConfig } from 'domain/types'

export enum ContentType {
    HELP = 'HELP',
    NEWS = 'NEWS'
}

export type MenuRootDTO = {
    root: MenuDTO[]
}

export interface MenuDTO {
    title: string
    path: string
    icon?: string
    layoutConfigUrl?: string
    items?: MenuDTO[]
}

export type ApiEndpointsDTO = {
    baseURL: string
    paths: PathsDTO
}

export type PathsDTO = {
    base: string
    config: string
    data: string
    filter: string
    form: string
}

export enum ActionIdentifier {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    DEACTIVATE = 'DEACTIVATE',
    ACTIVATE = 'ACTIVATE',
    DOWNLOAD = 'DOWNLOAD',
}
