import React from 'react'
import { Button } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { authenticationService } from 'domain/authentication/service'
import { SystemService } from 'domain/core/service'

interface Props {
    logout: () => void
}

/**
 * Renders the logout button.
 */
export class LogoutButton extends React.Component<Props, {}> {

    render() {
        return (
            <Button type={'ghost'} size={'large'} className={'logout'} title="Logout" onClick={() => authenticationService.logout()
                .then(() => {
                    SystemService.showCoreUi()
                })} shape="circle">
                <LogoutOutlined/>
            </Button>
        )
    }
}