import { ConditionClauseDTO, ContainerElementDTO, FORM_ELEMENT_TYPES, FormElementDTO, LayoutElementDTO, LayoutElementType, SelectFormElementDTO } from 'domain/types'
import { ElementSetting } from 'shared/component/layout/context/RootElementContext'
import ConditionClauseService from 'shared/service/conditionClauseService'
import FormService from 'shared/service/form.service'
import UrlService from 'shared/service/url.service'
import { CancelTokenSource } from 'axios'

const findFormElements = (container: ContainerElementDTO): FormElementDTO[] => {
    // @ts-ignore
    return findElementsByType(container, FORM_ELEMENT_TYPES)
}

const findSelectElements = (container: ContainerElementDTO): SelectFormElementDTO[] => {
    // @ts-ignore
    return findElementsByType(container, [LayoutElementType.FORM_ELEMENT_SELECT])
}

const loadSelectElements = async (selectElements: SelectFormElementDTO[], filterPath: string, filter: ConditionClauseDTO, cancelToken: CancelTokenSource) => {

    const promises: Promise<void>[] = []

    selectElements.forEach(element => {
        // load dimension values for the select form element only if the element is not read only
        if (!element.formFieldConfig.readOnly) {
            const selectElementPromise = FormService.loadSelectElementEntries(
                element,
                `${UrlService.getFilterBaseUrl()}${filterPath}`,
                ConditionClauseService.combineFilterQueries([filter, element.additionalFilters]),
                cancelToken?.token,
            )

            promises.push(selectElementPromise)
        }
    })

    return await Promise.all(promises)
}

/**
 * Recursively finds all elements that have a type that is listed in elementTypes
 * @param container
 * @param elementTypes
 */
const findElementsByType = (container: ContainerElementDTO, elementTypes: LayoutElementType[]): LayoutElementDTO[] => {
    const elements: LayoutElementDTO[] = []

    container.children.forEach(element => {

            // find all elements that match one of the requested types and add them to the result list
            if (elementTypes.indexOf(element.elementType) >= 0) {
                elements.push(element)
            }

            // since our layout might be nested, we have to do this recursively
            if (element.hasOwnProperty('children')) {
                const childElements: LayoutElementDTO[] = findElementsByType(element as ContainerElementDTO, elementTypes)
                elements.push(...childElements)
            }
        },
    )

    return elements
}

/**
 * Compares conditionClauseDTO with contextElementSettings and executes updateCallback(true/false).
 * The boolean parameter passed to updateCallback indicates whether conditionClauseDTO was evaluated as true or false.
 *
 * When conditionClauseDTO is falsy then updateCallback will not be executed.
 *
 * @param conditionClauseDTO
 * @param contextElementSettings
 * @param updateCallback
 */
const evaluateElementSettingWithConditionThenUpdate = (conditionClauseDTO: ConditionClauseDTO, contextElementSettings: ElementSetting[], updateCallback: (matches: boolean) => void) => {
    if (conditionClauseDTO) {
        const match = ConditionClauseService.matchesDimensionValueToConditionClause(contextElementSettings, conditionClauseDTO)
        updateCallback(match)
    }
}

const LayoutUtil = {
    findFormElements,
    findSelectElements,
    findElementsByType,
    evaluateElementSettingWithConditionThenUpdate,
    loadSelectElements,
}
export default LayoutUtil
