import { get } from 'shared/service'
import { ContentType, MenuRootDTO } from 'domain/types'

declare const baseUrlBackend

const loadArticleContent = async (path: string): Promise<string> => {
    const filename = path.substring(path.lastIndexOf("/") + 1, path.length)
    return get(`${baseUrlBackend}/static${path}/${filename}.html`)
}

const loadMenu = async (type: ContentType): Promise<MenuRootDTO> => get(`${baseUrlBackend}/static/getArticleMenu/${type}`)

export const ArticleService = { loadArticleContent, loadMenu }
