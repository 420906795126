
declare const baseUrlFrontendBackend: string

const UrlService = {
    getConfiguredBaseUrlFrontendBackend: () => {
        const origin = window.location.origin
        return origin.startsWith('https') || baseUrlFrontendBackend !== ''
    },

    getBaseUrl: () => {
        const url = UrlService.getConfiguredBaseUrlFrontendBackend()
            ? baseUrlFrontendBackend
            : 'http://' + window.location.hostname + ':8081'

        return url + '/ui'
    },

    /**
     * Gets the filter service url. If running locally, then use the local port.
     * If running in deploy container, then take either baseUrlFrontendBackend
     * or if not configured then window.location.origin
     */
    getFilterBaseUrl: () => {
        const url = UrlService.getConfiguredBaseUrlFrontendBackend()
            ? baseUrlFrontendBackend
                ? baseUrlFrontendBackend
                : window.location.origin
            : 'http://' + window.location.hostname + ':8086'

        return url + '/api/filter'
    }
}

export default UrlService
