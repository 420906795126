import React, { ReactElement, ReactNode } from 'react'
import _includes from 'lodash/includes'
import {
    ActionDTO,
    ActionIdentifier,
    ActionPopupConfig,
    ActionType,
    ColumnResponseDTO,
    ConditionClauseDTO,
    ReportingDataSetDTO,
    DataRowDTO,
    DimensionDTO,
    FormConfigDTO,
    FormType,
    GridDataRowDTO,
    SelectFormElementDTO,
    UIFormConfig, FilterState,
} from 'domain/types'
import { openPopupAction } from 'shared/actions/open.popup.action'
import { message, Modal } from 'antd'
import { log } from 'shared/util/log'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStatusId } from 'shared/util/util'
import FormService from 'shared/service/form.service'
import UrlService from 'shared/service/url.service'
import { DataSettingsState } from 'domain/widget/generic/GenericDataGridWidget'
import ConditionClauseService from 'shared/service/conditionClauseService'
import { CancelTokenSource } from 'axios'
import { actions as modalActions, ModalAction } from 'shared/component/modals/redux/modals.reducer'
import store from 'shared/redux/store'
import FormUtil from 'shared/util/FormUtil'
import LayoutUtil from 'shared/util/LayoutUtil'
import { InlineButtonDTO } from 'domain/actions/InlineButtonDTO'
import { ACTIONS_FIELD } from 'domain/datagrid/component/DataGrid'
import { RowActions } from 'domain/actions/RowActions'
import PopupTextUtil from 'shared/util/PopupTextUtil'
import DimensionService, { DimensionFieldType } from 'domain/dimension/service/DimensionService'

export const DIMENSION_STATUS = 'status'

export type ModalConfirmConfig = {
    title: string,
    icon: ReactElement,
    content: ReactElement,
    okText: string,
    cancelText: string,
    onOk: () => void,
}

/**
 * Creates filter clause from the selected items and combines with the additional filters.
 * In create mode returns just additional filters without adding selected items
 *
 * @param action
 * @param additionalFilters
 * @param mainDimension
 * @param selectedItems
 */
const createPopupAdditionalFilterClause = (action: ActionDTO, additionalFilters: ConditionClauseDTO, mainDimension: DimensionDTO, selectedItems: GridDataRowDTO[]): ConditionClauseDTO => {
    if (action.identifier == ActionIdentifier.CREATE) {
        return additionalFilters
    } else {
        // if rows in the grid are selected: use these as filters when loading the form (this is how we define which item(s) are being edited)
        const selectedItemsFilterState = [{
            selectFormElement: {
                formFieldConfig: {
                    dimensionIdentifier: DimensionService.getDimensionValueColumn(mainDimension.identifier),
                },
                selectConfig: {},
            } as SelectFormElementDTO,
            value: selectedItems.map(row => row[mainDimension.identifier].value),//items[mainDimension.identifier],
        } as FilterState]

        const selectedItemsFilterClause = ConditionClauseService.buildFilterQuery(selectedItemsFilterState)

        return ConditionClauseService.combineFilterQueries([selectedItemsFilterClause, additionalFilters])
    }
}

/**
 * Callback, that will be invoked after the form was submitted and the form has "keepOpenAfterCreateAndEdit=true"
 *
 * @param updatedItemData
 * @param dataSettingsState
 * @param cancelTokenSource
 * @param onAfterSubmit
 */
const editLastCreatedOrUpdatedItem = (updatedItemData: DataRowDTO[], dataSettingsState: DataSettingsState, cancelTokenSource: CancelTokenSource, onAfterSubmit: () => void) => {
    const { settings } = dataSettingsState

    if (updatedItemData?.length > 0) {
        const editAction = settings.actions.find(value => value.identifier == ActionIdentifier.EDIT)
        invokeAction(
            editAction,
            dataSettingsState,
            convertDataRowDTOToGridDataRowDTO(updatedItemData),
            cancelTokenSource,
            onAfterSubmit,
        )
    }
}

/**
 * Converts DataRowDTO[] to GridDataRowDTO[]
 * e.g. {channel.value: 123, channel.name: "ABC", advertiser.value: 456} -> {channel: {value: 123, name: "ABC"}, advertiser: {value: 456}}
 *
 * @param updatedItemData
 */
const convertDataRowDTOToGridDataRowDTO = (updatedItemData: DataRowDTO[]): GridDataRowDTO[] => {
    return updatedItemData.map(row => {
        const gridRow = {} as GridDataRowDTO

        Object.keys(row).forEach(key => { // channel.value
            const value = row[key] // 123
            const dimensionField = DimensionService.recognizeDimensionField(key)

            const columnResponseDTO: ColumnResponseDTO = gridRow[dimensionField.identifier] ? gridRow[dimensionField.identifier] : {}
            if (dimensionField.fieldType === DimensionFieldType.VALUE) {
                columnResponseDTO.value = value
            } else {
                columnResponseDTO.name = value
            }

            gridRow[dimensionField.identifier] = columnResponseDTO
        })

        return gridRow
    })
}

/**
 * Creates loadContent callback, that will be invoked if the popup will be opened
 *
 * @param action
 * @param filter
 * @param cancelToken
 * @param dataSettingsState
 * @param updatePopupConfigCallback - updates some data in the popup config after form config is loaded
 */
const createLoadContentCallback = (
    action: ActionDTO,
    filter: ConditionClauseDTO,
    cancelToken: CancelTokenSource,
    dataSettingsState: DataSettingsState,
    updatePopupConfigCallback: (title: ReactNode, subtitle: ReactNode, formConfigDto: FormConfigDTO) => void,
) => {
    const { paths } = dataSettingsState.settings
    const { mainDimension } = dataSettingsState

    return () =>
        FormService.loadFormConfig(
            action.formType,
            filter,
            `${paths.base}${paths.form}`,
            UrlService.getBaseUrl())
            .then(async (formConfigDto: FormConfigDTO) => {
                const itemData = formConfigDto.type === FormType.EDIT
                    ? await FormService.loadFormEntities(filter, DimensionService.getDimensionValueColumn(mainDimension.identifier), formConfigDto, `${paths.base}${paths.data}`, UrlService.getBaseUrl())
                    : []

                updatePopupConfigCallback(
                    PopupTextUtil.createTitle(formConfigDto, mainDimension, itemData),
                    PopupTextUtil.createSubtitle(itemData),
                    formConfigDto,
                )

                const uiFormConfig: UIFormConfig = {
                    formConfig: formConfigDto,
                    itemData: itemData,
                    baseApi: paths.base,
                    filter: filter,
                }

                return uiFormConfig
            })
}

/**
 * Creates action popup config for ActionType.OPEN_POPUP
 *
 * @param action
 * @param cancelToken
 * @param selectedItems
 * @param additionalFilters
 * @param dataSettingsState
 * @param onAfterSubmit
 */
const createOpenPopupAction = (
    action: ActionDTO,
    cancelToken: CancelTokenSource,
    selectedItems: GridDataRowDTO[],
    additionalFilters: ConditionClauseDTO,
    dataSettingsState: DataSettingsState,
    onAfterSubmit: () => void,
): ActionPopupConfig => {

    const { mainDimension } = dataSettingsState

    const identifier = `${mainDimension.identifier}__modal`
    const mergedFilters = createPopupAdditionalFilterClause(action, additionalFilters, mainDimension, selectedItems)

    const popupConfig = {
        identifier: identifier,
        additionalFilters: mergedFilters,
        setVisibility: (visible: boolean): ModalAction => store.dispatch(modalActions.setVisibility(identifier, visible)),
        setLoading: (loading: boolean): ModalAction => store.dispatch(modalActions.setLoading(identifier, loading)),
        showContent: (content: UIFormConfig): ModalAction | void => store.dispatch(modalActions.showContent(identifier, content, popupConfig)),
        onAfterSubmit: onAfterSubmit,
        modalWidth: action.modalConfig?.modalWidth,
        modalMinHeight: action.modalConfig?.modalMinHeight,
        additionalFooterElements: action.modalConfig?.additionalFooterElements,
    } as ActionPopupConfig

    popupConfig.loadContent = createLoadContentCallback(
        action,
        mergedFilters,
        cancelToken,
        dataSettingsState,
        (title: ReactNode, subtitle: ReactNode, formConfigDto: FormConfigDTO) => {
            popupConfig.title = title
            popupConfig.subtitle = subtitle
            const keepOpenAfterCreateAndEdit = formConfigDto.keepOpenAfterCreateAndEdit && FormUtil.getIsEditOrCreateMode(formConfigDto)
            popupConfig.keepOpenAfterCreateAndEdit = keepOpenAfterCreateAndEdit

            if (keepOpenAfterCreateAndEdit) {
                popupConfig.onSubmitSuccess =
                    (updatedItemData) => {
                        editLastCreatedOrUpdatedItem(
                            updatedItemData,
                            dataSettingsState,
                            cancelToken,
                            onAfterSubmit,
                        )
                    }
            }
        },
    )

    return popupConfig
}

/**
 * Creates action popup config for actions, which change the state (ActionType.DELETE, ActionType.DEACTIVATE, ActionType.ACTIVATE),
 * and open modal confirm dialog
 *
 * @param action
 * @param selectedItems
 * @param dataSettingsState
 * @param onAfterSubmit
 */
const createStatusActionsPopup = (action: ActionDTO, selectedItems: GridDataRowDTO[], dataSettingsState: DataSettingsState, onAfterSubmit: () => void) => {
    const { paths } = dataSettingsState.settings
    const { mainDimension } = dataSettingsState
    const { identifier } = mainDimension
    const statusId = getStatusId(action.type)
    const submitData = selectedItems.map(row => {
        return { [DimensionService.getDimensionValueColumn(identifier)]: row[identifier].value, [DimensionService.getDimensionValueColumn(DIMENSION_STATUS)]: statusId } as DataRowDTO
    })

    const headline = PopupTextUtil.createPopupHeader(action, selectedItems, mainDimension)
    const contentDiv = PopupTextUtil.createPopupItemSentence(selectedItems, action, mainDimension)

    const modalConfig = {
        title: headline,
        icon: <QuestionCircleOutlined/>,
        content: contentDiv,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () =>
            statusPopupOnConfirm(submitData, `${paths.base}/updatedata`).then(onAfterSubmit),
    } as ModalConfirmConfig

    Modal.confirm(modalConfig)
}

const statusPopupOnConfirm = (submitData: DataRowDTO[], path: string) => {
    return FormService.submitForm(
        submitData,
        path,
        'PUT',
        UrlService.getBaseUrl(),
    ).catch(response => {
        if (response.message) {
            message.error(`We're sorry, an error occurred.`, 5)
            log.error(response.message)
        }
    })
}

/**
 * Rewrites actions field in the data list and set action handler
 *
 * @param dataSet
 * @param dataSettingsState
 * @param allActions
 * @param cancelTokenSource
 * @param onAfterSubmit
 * @param additionalFilters
 */
const enrichRowsWithActions = (
    dataSet: ReportingDataSetDTO,
    dataSettingsState: DataSettingsState,
    allActions: ActionDTO[],
    cancelTokenSource: CancelTokenSource,
    onAfterSubmit: () => void,
    additionalFilters?: ConditionClauseDTO,
) => {
    if (dataSet && dataSet.rows) {
        dataSet.rows = dataSet.rows.map(row => {
            const inlineButtons: InlineButtonDTO[] = row[ACTIONS_FIELD].data
            if (inlineButtons) {
                row[ACTIONS_FIELD] = {
                    data: new RowActions(
                        inlineButtons,
                        (actionIdentifier: string) => {
                            try {
                                const widgetAction = allActions.find(action => action.identifier == actionIdentifier)

                                if (widgetAction) {
                                    invokeAction(
                                        widgetAction,
                                        dataSettingsState,
                                        [row],
                                        cancelTokenSource,
                                        onAfterSubmit,
                                        ConditionClauseService.filterConditionClauseBySupportedFilters(additionalFilters, widgetAction.supportedAdditionalFilters),
                                    )


                                } else {
                                    log.error('Action with identifier \'' + actionIdentifier + '\' not found')
                                }
                            } catch (e) {
                                log.error('ERROR: ', e)
                                log.error('ACTION NOT FOUND: ', actionIdentifier)
                            }

                        },
                    ),
                }
            }

            return row
        })
    }
    return dataSet
}

const invokeAction = (
    action: ActionDTO,
    dataSettings: DataSettingsState,
    selectedItems: GridDataRowDTO[],
    cancelTokenSource: CancelTokenSource,
    onAfterSubmit: () => void,
    additionalFilters?: ConditionClauseDTO,
): void => {
    if (action.type === ActionType.OPEN_POPUP) { // create / edit cases
        const popupConfig = createOpenPopupAction(
            action,
            cancelTokenSource,
            selectedItems,
            additionalFilters,
            dataSettings,
            onAfterSubmit,
        )

        openPopupAction(popupConfig)

    } else if (isStatusAction(action)) {
        createStatusActionsPopup(
            action,
            selectedItems,
            dataSettings,
            onAfterSubmit,
        )
    } else {
        new Error(`Action type '${action.type}' not supported`)
    }
}

/**
 * Checks whether action is a status action
 *
 * @param action
 */
const isStatusAction = (action: ActionDTO) =>
    _includes([ActionType.DELETE, ActionType.DEACTIVATE, ActionType.ACTIVATE], action.type)

const ActionService = {
    enrichRowsWithActions,
    invokeAction,
}

export default ActionService
