import { LoadResponseDTO, QuerySettingsDTO } from 'domain/types'
import { post } from 'shared/service/api'
import store from 'shared/redux/store'
import { CancelTokenSource } from 'axios'

declare const baseUrlBackend

/**
 * Loads reporting slice data
 *
 * @param apiPath
 * @param querySettingsDTO
 * @param cancelTokenSource
 */
const loadData = (apiPath: string, querySettingsDTO: QuerySettingsDTO, cancelTokenSource?: CancelTokenSource): Promise<LoadResponseDTO> => {
    const settings = querySettingsDTO.appContext
        ? querySettingsDTO
        : { ...querySettingsDTO, appContext: store.getState().appContext.appContext }

    return post<LoadResponseDTO>(`${baseUrlBackend}/${apiPath}`, settings, { cancelToken: cancelTokenSource?.token })
}

const ReportingService = {
    loadData: loadData,
}

export default ReportingService
