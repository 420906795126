import * as React from 'react'
import { ApiErrorDTO, ColumnResponseDTO, ConditionClauseDTO, DimensionValueFilterConfig, DimensionValueListDTO, FilterConfigDTO, FilterState, FilterType, PageableDTO, PathsDTO, QuerySettingsDTO } from 'domain/types'
import { prefixRenderer } from 'shared/component/renderers/prefix.renderer'
import { message, Select } from 'antd'
import UrlService from 'shared/service/url.service'
import axios, { CancelTokenSource } from 'axios'
import ConditionClauseService, { DEFAULT_FILTER_VALUES_PAGE_SIZE } from 'shared/service/conditionClauseService'
import _includes from 'lodash/includes'
import DataGridService from 'domain/datagrid/service/datagrid.service'
import { log } from 'shared/util/log'

/**
 * Creates filter option text from ColumnResponseDTO. If the row has a prefix,
 * then create the option in the "prefix > name" format.
 * @param value
 */
const getFilterValue = (value: ColumnResponseDTO): string =>
    value.prefix ? prefixRenderer(value) : value.name

const Option = Select.Option

const getFilterFormValueColumn = (filter: FilterConfigDTO | FilterState): string | undefined => {
    return filter.selectFormElement?.formFieldConfig?.dimensionIdentifier
}
const getFilterFormNameColumn = (filter: FilterConfigDTO | FilterState): string | undefined => {
    return filter.selectFormElement?.formFieldConfig?.dimensionNameMapping
}

const getFilterDisplayName = (filterConfig: FilterConfigDTO) => {
    return filterConfig.selectFormElement.formFieldConfig.dimensionNameMapping
        ? FilterComponentUtil.getFilterFormNameColumn(filterConfig)
        : FilterComponentUtil.getFilterFormValueColumn(filterConfig)
}

/**
 * Creates a list of Options for the Select component.
 *
 * @param filter
 */
const createOptionList = (filter: FilterState): any[] => {
    const showIcon = filter.filterEntries?.entries?.some(row => row.icon) === true

    return filter.filterEntries?.entries?.map((option, index) => {
            const label = getFilterValue(option)
            return (
                <Option key={`idx_${index}_${option.value}`}
                        value={option.value}
                        label={label}
                >
                    {showIcon && <div className={'icon-wrapper'}>
                        <div className={`icon ${option.icon}`}>&nbsp;</div>
                    </div>}
                    <span className={'option-name'}>{label}</span>
                </Option>)
        },
    ) || []
}

const getFilterDependsOn = (filter: FilterConfigDTO | FilterState) =>
    filter.selectFormElement.selectConfig.dependsOn

/**
 * Creates initial [FilterState]s without filter entries.
 * Filter entries will be loaded by the filter elements on their own.
 *
 * @param filterConfigs
 */
const createInitialFilterStates = (filterConfigs: FilterConfigDTO[]): FilterState[] =>
    filterConfigs?.map(createInitialFilterState) || []

/**
 * Creates initial [FilterState] without filter entries.
 * Filter entries will be loaded by the filter element on their own.
 *
 * @param filterConfig
 */
const createInitialFilterState = (filterConfig: FilterConfigDTO): FilterState => {
    return {
        filterEntries: { entries: [] } as DimensionValueListDTO,
        selectFormElement: filterConfig.selectFormElement,
        inputConfig: undefined,
        value: filterConfig.selectedFilterValues,
    } as FilterState
}

const getFilterType = (filter: FilterState) => filter.selectFormElement
    ? (filter.selectFormElement.selectConfig.multiSelect ? FilterType.MULTI_SELECT : FilterType.SINGLE_SELECT)
    : FilterType.TEXT

const loadFilterEntries = (
    filter: FilterConfigDTO,
    sortBy: string[],
    url: string,
    cancelToken: CancelTokenSource,
    filters: FilterConfigDTO[] = [],
): Promise<DimensionValueFilterConfig> => {
    const paginationSettings: PageableDTO = { page: 0, pageSize: DEFAULT_FILTER_VALUES_PAGE_SIZE, sortProperties: sortBy, sortAscending: true }
    const identifier = FilterComponentUtil.getFilterFormValueColumn(filter)
    const nameMapping = FilterComponentUtil.getFilterFormNameColumn(filter)

    let filtersToApply: FilterState[] = []
    if (FilterComponentUtil.getFilterDependsOn(filter).length) {
        const filterIds = FilterComponentUtil.getFilterDependsOn(filter).map(f => f.filterIdentifier)
        filtersToApply = filters.filter(f =>
            _includes(filterIds, FilterComponentUtil.getFilterFormValueColumn(f)),
        )
    }

    const filterClause: ConditionClauseDTO = ConditionClauseService.combineFilterQueries([
        ConditionClauseService.buildFilterQuery(filtersToApply),
        filter.selectFormElement ? filter.selectFormElement.additionalFilters : undefined,
    ])
    const querySettings: QuerySettingsDTO = {
        columnNames: [identifier, nameMapping ? nameMapping : identifier],
        filter: filterClause,
        paginationSettings,
    }

    // append meta data as URL parameter
    const filterPromise = DataGridService.fetchFilterEntries(querySettings, `${url}/${querySettings.columnNames[0]}`, cancelToken.token)

    return filterPromise
        .then(filterEntries => {
            return { dimension: identifier, dimensionValueDTO: filterEntries } as DimensionValueFilterConfig
        })
        .catch((e: ApiErrorDTO) => {
            if (axios.isCancel(e) || e.message === 'Cancel') {
                log.debug('Request canceled')
            } else {
                log.error('Error in loadFilterEntries: ', e.message)
                message.error(`We're sorry, an error occurred while loading the filter values.`, 5)
            }

            return { dimension: FilterComponentUtil.getFilterFormValueColumn(filter), dimensionValueDTO: {} } as DimensionValueFilterConfig
        })
}

const getFilterLoadValuesUrl = (filterConfig: FilterConfigDTO) =>
    filterConfig.selectFormElement.selectConfig.loadValuesUrl

/**
 * Loads filter values for the [filterConfig]
 * @param filterConfig
 */
const loadFilterValuesAsync = (filterConfig: FilterConfigDTO): Promise<DimensionValueFilterConfig> => {
    const url = getFilterLoadValuesUrl(filterConfig)

    return loadFilterEntries(
        filterConfig,
        [FilterComponentUtil.getFilterDisplayName(filterConfig)],
        url ? url : `${UrlService.getFilterBaseUrl()}/loaddimensionvalues`,
        axios.CancelToken.source(),
    )
}

const FilterComponentUtil = {
    getFilterValue: getFilterValue,
    createOptionList: createOptionList,
    getFilterFormValueColumn: getFilterFormValueColumn,
    getFilterFormNameColumn: getFilterFormNameColumn,
    getFilterDisplayName: getFilterDisplayName,
    getFilterDependsOn: getFilterDependsOn,
    createInitialFilterState: createInitialFilterState,
    createInitialFilterStates: createInitialFilterStates,
    getFilterType: getFilterType,
    loadFilterValuesAsync: loadFilterValuesAsync,
}

export default FilterComponentUtil
