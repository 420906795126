import { post } from 'shared/service'
import { FormResponseDTO } from 'domain/types'
import PasswordService from 'domain/password/service/PasswordService'

declare const baseUrlUserService: string

const requestReset = (userNameOrEmail: string): Promise<FormResponseDTO> => {
    return post(baseUrlUserService + '/password/request-reset', {
        userNameOrEmail,
    })
}

const reset = (token: string, password1: string, password2: string): Promise<FormResponseDTO> => {
    return post(baseUrlUserService + '/password/reset', {
        token: token,
        password1Hash: PasswordService.hash(password1),
        password2Hash: PasswordService.hash(password2),
    })
}

const PasswordResetService = {
    requestReset,
    reset,
}

export default PasswordResetService
