import { InlineButtonDTO } from 'domain/actions/InlineButtonDTO'
import { ActionIdentifier, DataRowDTO } from 'domain/types'

export class RowActions {

    actions: InlineButtonDTO[]
    invokeActionHandler: (identifier: ActionIdentifier) => void

    constructor(actions: InlineButtonDTO[], invokeActionHandler: (identifier: ActionIdentifier) => void) {
        this.actions = actions
        this.invokeActionHandler = invokeActionHandler
    }
}