import * as React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'

import { actions as authActions, AuthenticationState } from 'domain/authentication/redux/authentication.reducer'

interface Props {
    dispatch: ((action: any) => void),
    authentication: AuthenticationState,
    user: AuthenticationState,
    location: any
}

const LoginPage: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
    useEffect(() => {
        const state: any = props.location.state
        const referrer: string = state ? state.referrer : '/ui'

        props.dispatch(authActions.login(referrer))
    }, [])

    return <>
    </>
}

const mapStateToProps = (state: any) => {
    const { authentication, user } = state
    return {
        authentication,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)