import formatter from 'shared/util/formatter'
import { tooltipRenderer } from 'shared/component/renderers/tooltip.renderer'
import { rowSelectorRenderer } from 'shared/component/renderers/row-selector.renderer'
import { contextMenuRenderer } from 'shared/component/renderers/context-menu.renderer'
import { optimizationLevelRenderer } from 'shared/component/renderers/optimization-level.renderer'
import { valueComparisonRenderer } from 'shared/component/renderers/value-comparison.renderer'
import { dataRenderer } from 'shared/component/renderers/data.renderer'
import InlineButtonsRenderer from 'shared/component/renderers/inline-buttons.renderer'
import React from 'react'
import { iconRenderer } from 'shared/component/renderers/icon.renderer'
import { ColumnConfigDTO, ColumnResponseDTO, MetricDTO } from 'domain/types'
import { prefixRenderer } from 'shared/component/renderers/prefix.renderer'
import { NOT_AVAILABLE } from 'Constants'
import MetricUtil from 'domain/widget/MetricUtil'

export const columnRenderer = (columnConfigDTO: ColumnConfigDTO): Formatter => {
    const renderer = columnConfigDTO.gridColumnProperties.renderer
    const handleUndefinedValue = (columnResponseDTO: ColumnResponseDTO) => {
        if (!columnResponseDTO) return
        const isSet = (value: any) => value !== null && value !== undefined
        return {
            ...columnResponseDTO,
            value: isSet(columnResponseDTO.value)
                ? ((columnConfigDTO?.gridColumnProperties?.isMetric)
                    ? MetricUtil.metricValueFormatter({ round: 2 } as MetricDTO)(columnResponseDTO.value)
                    : columnResponseDTO.value)
                : NOT_AVAILABLE,
            name: isSet(columnResponseDTO.name) ? columnResponseDTO.name : NOT_AVAILABLE,
        }
    }
    let fn: Formatter
    switch (renderer.type) {
        case RendererType.VALUE:
            fn = value => tooltipRenderer(value?.value, renderer.cssClasses)
            break
        case RendererType.PREFIX_NAME:
            fn = value => tooltipRenderer(prefixRenderer(value), renderer.cssClasses)
            break
        case RendererType.POSTFIX_VALUE:
            fn = value => formatter.applyPostfix(value.value, columnConfigDTO.gridColumnProperties.postfix)
            break
        case RendererType.NAME:
            fn = value => tooltipRenderer(value?.name, renderer.cssClasses)
            break
        case RendererType.DATE:
            fn = value => tooltipRenderer(formatter.formatDate(value?.value, 'DD.MM.YYYY'), renderer.cssClasses)
            break
        case RendererType.DATE_TIME:
            fn = value => tooltipRenderer(formatter.formatDateTime(value?.value, 'DD.MM.YYYY HH:mm'), renderer.cssClasses)
            break
        case RendererType.ICON:
            fn = value => iconRenderer(value)
            break
        case RendererType.ROW_SELECTOR:
            fn = rowSelectorRenderer
            break
        case RendererType.CONTEXT_MENU:
            fn = contextMenuRenderer
            break
        case RendererType.OPTIMIZATION_LEVEL:
            fn = optimizationLevelRenderer
            break
        case RendererType.VALUE_COMPARISON:
            fn = valueComparisonRenderer
            break
        case RendererType.DATA:
            fn = value => dataRenderer(value, renderer.cssClasses)
            break
        case RendererType.INLINE_BUTTONS:
            fn = value => <InlineButtonsRenderer rowActions={value.data}/>
            break
        default:
            fn = undefined
    }
    if(columnConfigDTO.gridColumnProperties.nullValuesVisible) {
        return (columnResponseDTO: ColumnResponseDTO) => fn(handleUndefinedValue(columnResponseDTO))
    } else {
        return (columnResponseDTO: ColumnResponseDTO) => fn(columnResponseDTO)
    }
}

export type Formatter = (value: ColumnResponseDTO) => any

export type ColumnRendererDTO = {
    type: RendererType
    cssClasses: string[]
}

export enum RendererType {
    // TEXT = 'TEXT',
    // NUMBER = 'NUMBER',
    // DATE = 'DATE',
    // DATE_TIME = 'DATE_TIME',
    // STATUS = 'STATUS',
    // BOOLEAN = 'BOOLEAN',
    // CSS_STYLED = 'CSS_STYLED',
    // TOOLTIP = 'TOOLTIP',
    ROW_SELECTOR = 'ROW_SELECTOR',
    CONTEXT_MENU = 'CONTEXT_MENU',
    OPTIMIZATION_LEVEL = 'OPTIMIZATION_LEVEL',
    VALUE_COMPARISON = 'VALUE_COMPARISON',
    // PERCENT = 'PERCENT',
    // JSON = 'JSON',
    // INLINE_BUTTONS = 'INLINE_BUTTONS',
    // HEALTH = 'HEALTH',
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    INLINE_BUTTONS = 'INLINE_BUTTONS',
    VALUE = 'VALUE',
    PREFIX_NAME = 'PREFIX_NAME',
    POSTFIX_VALUE = 'POSTFIX_VALUE',
    NAME = 'NAME',
    ICON = 'ICON',
    ICON_NAME = 'ICON_NAME',
    DATA = 'DATA',
}
