import React from 'react'
import ContainerElement from 'shared/component/layout/ContainerElement'
import { PanelElementProperties } from 'domain/types'
import ElementHeader from 'shared/component/layout/ElementHeader'

const PanelElement: React.FC<PanelElementProperties> = ({ layoutElementConfig }: PanelElementProperties): JSX.Element => <>
    <ElementHeader header={layoutElementConfig.header}/>
    <ContainerElement layoutElementConfig={layoutElementConfig}/>
</>

export default PanelElement
