import * as React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { history } from 'shared/util/history'
import { getFirstMenuPaths } from 'shared/util/menu'
import { MenuDTO } from 'domain/types'

const SubMenu = Menu.SubMenu

type MainMenuProps = {
    inlineIndent?: number;
    showLogo: boolean,
    firstEntryPath: string,
    entries: MenuDTO[]
}

type MainMenuState = {
    collapsed: boolean
    selected: string
    open: string[]
}

export class MainMenu extends React.Component<MainMenuProps, MainMenuState> {

    constructor(props: MainMenuProps) {
        super(props)

        const selected = props.firstEntryPath
        const subPaths = selected ? selected.split('/') : undefined
        const [_, ...consideredSubPaths] = subPaths.filter(p => p.length > 0).reverse()
        consideredSubPaths.reverse()
        const openPaths = consideredSubPaths.map((p, i) => `/${[consideredSubPaths.filter((_, ii) => ii < i).join('/'), p].filter(pp => pp.length > 0).join('/')}`)
        this.state = {
            collapsed: false,
            selected: selected ? selected : undefined,
            open: selected ? openPaths : [],
        }

        this.onCollapse = this.onCollapse.bind(this)
    }

    historyListener = () => {
        return
    }

    componentWillMount() {
        this.historyListener = history.listen((location, action) => {
            if (action === 'POP') {
                const selected = history.location.pathname
                const subPaths = selected ? selected.split('/') : undefined
                const [_, ...consideredSubPaths] = subPaths.filter(p => p.length > 0).reverse()
                consideredSubPaths.reverse()
                const openPaths = consideredSubPaths.map((p, i) => `/${[consideredSubPaths.filter((_, ii) => ii < i).join('/'), p].filter(pp => pp.length > 0).join('/')}`)
                this.setState(prevState => ({
                    ...prevState,
                    selected: selected ? selected : undefined,
                    open: selected ? openPaths : [],
                }))
            }
        })
    }

    componentWillUnmount() {
        this.historyListener()
    }

    onCollapse = (collapsed: boolean) => {
        this.setState({ collapsed })
    }

    gotoHome = () => {
        const { entries } = this.props
        this.setState(prevState => ({
            ...prevState,
            selected: entries.length && entries[0].items?.length ? `${entries[0].path}${entries[0].items[0].path}` : undefined,
            open: entries.length ? [entries[0].path] : [],
        }))
    }

    onSelect = (param) => {
        this.setState(prevState => ({
            ...prevState,
            selected: param.key,
        }))
    }

    onOpenChange = (openKeys: string[]) => {
        const latestOpenKey = openKeys.find(key => this.state.open.indexOf(key) === -1)
        const hierarchyOpenKeys = latestOpenKey ? this.state.open.filter(key => latestOpenKey.startsWith(key)) : []

        this.setState(prevState => ({
            ...prevState,
            open: latestOpenKey ? hierarchyOpenKeys.concat([latestOpenKey]) : openKeys,
        }))
    }

    getMenuItem = (entry, item) =>
        <Menu.Item key={`${item.path}`}>
            <Link to={`${item.path}`}>{item.title}</Link>
        </Menu.Item>

    getSubMenu = (entry, level) => {
        const title = entry.icon
            ? <span><div className={`icon icon-${entry.icon}`}/>
                {!this.state.collapsed && entry.title}</span>
            : <span>{!this.state.collapsed && entry.title}</span>

        const menuItems = this.getItem(entry, level)

        return level === 1
            ? <SubMenu className={'firstLevel'} title={title} key={entry.path}>{menuItems}</SubMenu>
            : <SubMenu title={title} key={entry.path}>{menuItems}</SubMenu>
    }

    getItem = (entry, level) => entry.items.map(item => this.mapItem(entry, item, level))

    mapItem = (entry, item, level) => item.items && item.items.length
        ? this.getSubMenu(item, level + 1)
        : this.getMenuItem(entry, item)

    render() {
        const { entries, showLogo, inlineIndent } = this.props
        const { Sider } = Layout
        const { selected, open } = this.state

        return (
            // currently collapsible:false due to various bug in the collapsed layout
            <Sider width={250} className={'main-sidebar'} theme={'light'} collapsible={false}
                   collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                {showLogo && <Link to={'/'} onClick={this.gotoHome}>
                    <div id="logo" className={this.state.collapsed ? 'small' : 'wide'}>
                        <span className={'company'}>exactag</span>
                        <span className={'subtitle'}>Support</span>
                    </div>
                </Link>}
                <div className={'menu-wrapper'}>
                    <Menu mode="inline" className="menu"
                          style={{ border: 'unset' }}
                          inlineIndent={inlineIndent || 25}
                          selectedKeys={[selected]} openKeys={open}
                          onClick={this.onSelect} onOpenChange={this.onOpenChange}>
                        {entries && entries.map(entry => entry.items && entry.items.length
                            ? this.getSubMenu(entry, 1)
                            : <Menu.Item key={`${entry.path}`}>
                                <Link to={`/${entry.path}`}>{entry.title}</Link>
                            </Menu.Item>,
                        )}
                    </Menu>
                </div>
            </Sider>
        )
    }
}

/**
 * Generates main menu
 *
 * @param referrer
 * @param configurations
 */
export const generateMainMenu = (referrer: string, configurations: any[]) => {
    return <MainMenu
        showLogo={true}
        firstEntryPath={
            referrer ? referrer : getFirstMenuPaths(configurations)
        }
        entries={configurations}
    />
}
