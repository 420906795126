import * as React from 'react'

type Props = {
    className?: string
}

type State = {}

export class ContentBox extends React.Component<Props, State> {

    render() {
        return (
            <div className={(this.props.className || '') + ' content-box'}>
                {this.props.children}
            </div>
        )
    }

}