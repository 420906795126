import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import LayoutElement from 'shared/component/layout/LayoutElement'
import { FooterState, useRootElementContext } from 'shared/component/layout/context/RootElementContext'
import { TabElementDTO, TabLayoutConfigDTO } from 'domain/types'
import { TabPaneContextProvider } from 'shared/component/layout/context/TabPaneContextProvider'

type Props = {
    renderSettings?: any
    layoutConfig?: TabLayoutConfigDTO
    children: TabElementDTO[]
}

export type TabState = {
    actualTabIndex: number
    previousTabIndex: number
}

const TabsRenderer: React.FC<Props> = (props: Props): JSX.Element => {
    const { updateFooter } = useRootElementContext()

    const [tabState, setTabState] = useState({ actualTabIndex: 0, previousTabIndex: 0 } as TabState)

    /**
     * Update the footer based on the settings for tab at index [tabIndex]
     * @param key should be equal to tab index so that we can easily get the tab config
     */
    const onTabChange = (key: string) => {
        const tabConfig = props.children[+key]

        // update state with some delay to have more smooth tab change
        setTimeout(() => {
            setTabState(prev => {
                return { actualTabIndex: +key, previousTabIndex: prev.actualTabIndex } as TabState
            })
        }, 200)

        if (tabConfig) {
            if (tabConfig.hideFooter) {
                updateFooter(FooterState.HIDE_FOOTER)
            } else {
                updateFooter(FooterState.SHOW_DEFAULT_FOOTER)
            }
        } else {
            updateFooter(FooterState.SHOW_DEFAULT_FOOTER)
        }
    }

    useEffect(() => {
        //this method is called when the component is rendered for the first time. We use it to set the footer state to the state
        //that is defined by the config of the first tab (because that tab is visible per default)
        onTabChange('0')
    }, [])

    return <>
        <Tabs className={'layout-tabs-container'} animated={{ inkBar: true, tabPane: false }} onChange={onTabChange}>
            {props.children.map((child, index) => {
                const tabTitle = child.title ? child.title : `Tab ${index + 1}`
                const tabDisabled = child.disabled

                let tabContent
                if (tabDisabled && child.disabledTooltip) {
                    tabContent =
                        <div data-tip={child.disabledTooltip} data-tip-classname={'long-text-tip'} data-force-tooltip={true}>
                            {tabTitle}
                        </div>
                } else {
                    tabContent = tabTitle
                }

                return <Tabs.TabPane disabled={tabDisabled} tab={tabContent} key={index.toString()} className={'layout-element layout-element-tabs'}>
                    <TabPaneContextProvider tabState={tabState} tabIndex={index}>
                        <LayoutElement layoutElementConfig={child}/>
                    </TabPaneContextProvider>
                </Tabs.TabPane>
            })}
        </Tabs>
    </>

}

export default TabsRenderer
