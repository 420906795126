import { ActionDTO, ApiEndpointsDTO, AppContextDTO, ConditionClauseDTO, FilterConfigDTO, GridConfigDTO, TimespanSettingsDTO, ToolbarButtonDTO } from 'domain/types'
import { WidgetSettingsDTO } from 'domain/types/backend/widget.types'

export enum LayoutMode {
    COLUMNS = 'COLUMNS',
    ROWS = 'ROWS',
    TABS = 'TABS'
}

export enum IconTypes {
    DELETE = 'delete',
    PLUS = 'plus',
    EDIT = 'edit',
    EYE_INVISIBLE = 'eye-invisible',
    EYE = 'eye',
}

export enum LayoutElementType {
    ELEMENT = 'ELEMENT',
    CONTAINER = 'CONTAINER',
    PANEL = 'PANEL',
    TAB = 'TAB',
    FORM_ELEMENT_SELECT = 'FORM_ELEMENT_SELECT',
    FORM_ELEMENT_TEXTAREA = 'FORM_ELEMENT_TEXTAREA',
    FORM_ELEMENT_INPUT = 'FORM_ELEMENT_INPUT',
    NUMBER_FORM_ELEMENT_INPUT = 'NUMBER_FORM_ELEMENT_INPUT',
    FORM_ELEMENT_TOGGLE_BUTTON = 'FORM_ELEMENT_TOGGLE_BUTTON',
    FORM_ELEMENT_CHECKBOX = 'FORM_ELEMENT_CHECKBOX',
    GRID = 'GRID',
    TOOLBAR = 'TOOLBAR',
    HTML_CONTENT = 'HTML_CONTENT',
    WIDGET_ELEMENT_DATA_LIST = 'WIDGET_ELEMENT_DATA_LIST',
    TIMESPAN_AWARE_PANEL = 'TIMESPAN_AWARE_PANEL',
    TOOL_AWARE_PANEL = 'TOOL_AWARE_PANEL',
}

export const FORM_ELEMENT_TYPES = [
    LayoutElementType.FORM_ELEMENT_SELECT,
    LayoutElementType.FORM_ELEMENT_TEXTAREA,
    LayoutElementType.FORM_ELEMENT_INPUT,
    LayoutElementType.NUMBER_FORM_ELEMENT_INPUT,
    LayoutElementType.FORM_ELEMENT_TOGGLE_BUTTON,
    LayoutElementType.FORM_ELEMENT_CHECKBOX,
]

export type RenderSettingsDTO = {
    additionalCssClasses: string[]
    visibleOn?: ConditionClauseDTO
    width: string
    height: string
}

export interface LayoutElementDTO {
    elementType: LayoutElementType
    identifier?: string
    renderSettings?: RenderSettingsDTO
    additionalFilters?: ConditionClauseDTO
    preselectedFilters?: ConditionClauseDTO
}

export interface LayoutConfigDTO {
    layoutMode: LayoutMode
}

export interface ColumnLayoutConfigDTO extends LayoutConfigDTO {
}

export interface RowLayoutConfigDTO extends LayoutConfigDTO {
}

export interface TabLayoutConfigDTO extends LayoutConfigDTO {
}

export interface ContainerElementDTO extends LayoutElementDTO {
    children: LayoutElementDTO[]
    layoutConfig?: LayoutConfigDTO
}

export interface HtmlContentElementDTO extends LayoutElementDTO {
    isInfoText?: boolean,
    header?: string,
    content?: string
}

export interface WidgetElementDTO extends LayoutElementDTO {
    header?: string,
    widgetSettings?: WidgetSettingsDTO
}

export interface DataListWidgetElementDTO extends WidgetElementDTO {
}

export interface ToolAwarePanelElementDTO extends PanelElementDTO {
    filters?: FilterConfigDTO[]
    timespanSettings?: TimespanSettingsDTO
    hasSearch: boolean
    actions: ActionDTO[]
    buttons: ToolbarButtonDTO[]
}

export interface PanelElementDTO extends ContainerElementDTO {
    header?: string
}

export interface TabElementDTO extends ContainerElementDTO {
    title: string
    disabled: boolean
    disabledTooltip?: string
    hideFooter: boolean
}

export type GridElementConfigDTO = {
    gridId: string,
    gridConfig: GridConfigDTO,
    path: string,
    apiEndpoints: ApiEndpointsDTO
}

export interface GridElementDTO extends LayoutElementDTO {
    elementConfig: GridElementConfigDTO,
    header?: string
}

export type ToolbarButton = {
    toolbarButtonDTO: ToolbarButtonDTO
    action: ActionDTO
}

export type PageConfigDTO = {
    layoutConfig: LayoutElementDTO
}

export type LoadConfigDTO = {
    filter?: ConditionClauseDTO,
    appContext: AppContextDTO
}
