import { FilterConfigDTO, FilterState, FilterType } from 'domain/types'
import { InputComponent, MultiSelectComponent, SelectComponent } from 'domain/filter/component/index'
import * as React from 'react'
import { useEffect, useState } from 'react'
import FilterComponentUtil from 'domain/filter/component/FilterComponentUtil'

type Props = {
    filter: FilterConfigDTO,
    onChange: (identifier: string, value: string | number | string[] | number[]) => void
}
export const FilterElement = (props: Props) => {
    const { filter, onChange } = props
    const [filterState, setFilterState] = useState<FilterState>(FilterComponentUtil.createInitialFilterState(filter))
    const { displayName } = filterState.selectFormElement.formFieldConfig

    useEffect(() => {
        setFilterState(FilterComponentUtil.createInitialFilterState(filter))
        FilterComponentUtil.loadFilterValuesAsync(filter).then(filterEntries => {
            setFilterState(prev => {
                return {
                    ...prev,
                    filterEntries: filterEntries.dimensionValueDTO,
                    value: prev.value === null || prev.value === undefined
                        ? filter.selectedFilterValues
                        : prev.value,
                }
            })
        })
    }, [filter])

    const handleChange = (identifier: string, value: string | number | string[] | number[]) => {
        setFilterState((prev: FilterState) => {
            return {
                ...prev,
                value: value,
            }
        })

        onChange(identifier, value)
    }

    switch (FilterComponentUtil.getFilterType(filterState)) {
        case FilterType.SINGLE_SELECT:
            return <SelectComponent filter={filterState} label={displayName} onChange={handleChange}/>
        case FilterType.MULTI_SELECT:
            return <MultiSelectComponent filter={filterState} label={displayName} onChange={handleChange}/>
        case FilterType.TEXT:
            return <InputComponent filter={filterState} onChange={handleChange}/>
    }
}
