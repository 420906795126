import React from 'react'

import { Redirect, Route } from 'react-router-dom'
import { AuthenticationState } from 'domain/authentication/redux/authentication.reducer'
import { RouteProps } from 'react-router'

interface Props extends RouteProps {
    authentication: AuthenticationState
    isEmbedded: boolean
}

/*
 ATTENTION: Only add paths here that are supposed to be embedded in the ClassicUI and used by regular users.
 Support UI URLs are not supposed to be included here.
 */
const pathWhitelist = [
    '/ui/content/',
    '/ui/widget',
    '/ui/adSpend',
    '/ui/setup/advertiser',
    '/ui/setup/labels',
    '/ui/setup/campaignmanagement/channels',
    '/ui/setup/campaignmanagement/sub_campaigns',
    '/ui/setup/campaignmanagement/lineitems',
    '/ui/setup/campaignmanagement/placements',
    '/ui/setup/campaignmanagement/publisher_assignments',
    '/ui/setup/campaignmanagement/publisher_sites',
    '/ui/setup/campaignmanagement/publishers',
    '/ui/setup/campaignmanagement/creatives',
    '/ui/reporting/partnerEnhancedAvm/avmToCnv',
    '/ui/reporting/partnerEnhancedAvm/avmToTP',
]

/**
 * Checks whether location starts with some path from the white list
 *
 * @param location
 */
const pathOnWhitelist = (location: string): boolean => {
    return pathWhitelist.some(path => location.startsWith(path))
}

/**
 * Redirects to successPath if predicate is true, else redirects to errorPath
 *
 * @param predicate
 * @param successPath
 * @param errorPath
 * @constructor
 */
export const RedirectTo = ({ predicate, successPath, errorPath }) => {
    const path = predicate ? successPath : errorPath
    // eslint-disable-next-line no-restricted-globals
    const referrer = [location.pathname, location.search].filter(val => val).join('')

    return <Redirect to={{ pathname: path, state: { referrer: referrer } }}/>
}

export const PrivateRoute = ({ authentication, isEmbedded, children, ...rest }: Props) => {
    const location = rest.location


    if (authentication?.loggedInViaFirstFactor) {
        const onWhitelist = location ? pathOnWhitelist(location.pathname) : true

        /**
         * Show the page if the path is on the white list, an internal user opens the page not embedded, or if it is an otp route
         */
        if (onWhitelist || (authentication.user.internalUser && !isEmbedded) || location.pathname.startsWith('/ui/mfa/otp/')) {
            return <Route children={props => typeof children === 'function' ? children(props) : children} {...rest} />
        } else {
            /*
            Fallback view in case someone is trying to load the support UI although page is loaded in embedded mode
            This should in theory never happen but if it does, we do at least not want to display any data
            This view is also used within the hidden login helper iFrame that is loaded in the core UI; it is supposed to
            trigger a login and continuous ping without triggering any data loading
            */
            return <div>We're sorry but we were unable to load this page.
                Please try refreshing your browser window.</div>
        }

    } else {
        const referrer = [location.pathname, location.search].filter(val => val).join('')

        return <Redirect to={{ pathname: '/ui/login', state: { referrer: referrer } }}/>
    }
}
