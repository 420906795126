import * as React from 'react'
import { ColumnResponseDTO } from 'domain/types'

export const iconRenderer = (value: ColumnResponseDTO) => {
    const iconClassName = value.icon ? 'icon ' + value.icon : ''

    // hardcoded workaround because tooltip for the health icons looks weird without a min width
    const tipClassName = value.icon && value.icon.indexOf('health') >= 0 ? 'long-text-tip' : ''

    return (
        <div className={'icon-wrapper'}>
            <span
                data-tip={value.tooltip}
                data-tip-classname={tipClassName}
                data-force-tooltip={true}
                className={iconClassName}
            />
        </div>
    )
}
