import { MfaFactor, MfaFactorConfigDTO } from 'domain/types/backend/mfa.types'
import { Alert, Button } from 'antd'
import OtpCode from 'domain/onetimepassword/OtpCode'
import mfaService from './service/mfaService'
import React, { useState } from 'react'
import RequestEmailCodeButton from 'domain/onetimepassword/RequestEmailCodeButton'
import { ReloadOutlined } from '@ant-design/icons'

type Props = {
    factorConfig: MfaFactorConfigDTO,
    success: () => void
}

const MfaCodeVerificationForm: (props: Props) => JSX.Element = (props: Props): JSX.Element => {

    const [codeVerificationResponse, setCodeVerificationResponse] = useState(undefined)
    const [emailCodeGenerationResponse, setEmailCodeGenerationResponse] = useState(undefined)

    const CODE_REJECTED_ERROR_MSG = {
        type: 'error',
        heading: 'Verification Failed',
        button: <Button type="primary" htmlType="submit"
                        size="large"
                        icon={<ReloadOutlined/>}
                        onClick={() => {
                            setCodeVerificationResponse(undefined)
                        }}>
            Try Again
        </Button>,
        message: <div>The provided code was invalid.<br/>
        </div>,
    }

    const EMAIL_CODE_REQUEST_ERROR_MSG = {
        type: 'error',
        caption: 'An Error occurred.',
        message: <div>
            If you did not receive an email with your code try to resend another code. If you keep getting this error, please reach out to our support team: support@exactag.com
        </div>,
    }

    const callToAction = (factor: MfaFactor) => ({
        [MfaFactor.Authenticator]: 'Please enter the 6-digit code that is displayed in your authenticator app:',
        [MfaFactor.Email]: `We sent an e-mail containing a 6-digit code to ${props?.factorConfig?.userName}. Please enter the Code here:`,
    }[factor])

    return <>
        {!codeVerificationResponse && <>
            <div className={'mfa-form-segment'}>
                <h3><strong>Two-Factor Authentication</strong></h3>
                <p>{callToAction(props?.factorConfig?.factor)}</p>

                {emailCodeGenerationResponse &&
                    <Alert showIcon className="form-response-alert" type={emailCodeGenerationResponse.type} message={emailCodeGenerationResponse.message}/>}

                <OtpCode
                    codeCheck={mfaService[props?.factorConfig?.factor].verifyCode}
                    rememberMeDays={props?.factorConfig?.rememberMeDays || 0}
                    onSuccess={props?.success}
                    onError={() => setCodeVerificationResponse(CODE_REJECTED_ERROR_MSG)}
                />
            </div>

            {props?.factorConfig?.factor == MfaFactor.Email &&
                <div className={'mfa-form-segment'}>
                    <p>You didn't receive the previous e-mail?</p>
                    <RequestEmailCodeButton
                        sendOnMount={true}
                        onSuccess={() => {
                            return
                        }}
                        onError={() => setEmailCodeGenerationResponse(EMAIL_CODE_REQUEST_ERROR_MSG)}
                    />
                </div>}

        </>}
        {codeVerificationResponse && <div className={'mfa-form-segment'}>
            <Alert className="form-response-alert" type={codeVerificationResponse.type} message={codeVerificationResponse.heading} description={codeVerificationResponse.message}/>
            <br/>
            {codeVerificationResponse.button}
        </div>}
    </>
}

export default MfaCodeVerificationForm
