import React from 'react'
import { Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CustomIcon } from 'domain/core/component/CustomIcon'

type Props = {
    formattedNumber: string
    label: string
    icon?: string
    additionalCssClass?: string
    currencyString?: string
    span?: number
}

const renderNumber = (formattedNumber: string, currencyString?: string) => formattedNumber
    ? <React.Fragment>
        {currencyString && <span className={'currency'}>{currencyString}</span>}
        {formattedNumber}
    </React.Fragment>
    : <React.Fragment>
        <span className={'spacer'}>00,0k</span> <LoadingOutlined/>
    </React.Fragment>

const KPIEntry = (props: Props) =>
    <Col span={props.span ? props.span : 8}>
        <div className={'kpi ' + props.additionalCssClass}>
            <div className={'label-and-number'}>
                <div className={'number'}>
                    {renderNumber(props.formattedNumber, props.currencyString)}
                </div>
                <div className={'label'}>{props.label}</div>
            </div>
            {props.icon && <CustomIcon icon={props.icon} size={'large'}/>}
        </div>
    </Col>

export default KPIEntry