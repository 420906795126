import { ActionIdentifier, FormType, LayoutElementDTO } from 'domain/types'

export enum ActionType {
    OPEN_POPUP = 'OPEN_POPUP',
    DELETE = 'DELETE',
    DEACTIVATE = 'DEACTIVATE',
    ACTIVATE = 'ACTIVATE',
}

export type ActionSelectionConfigurationDTO = {
    minSelected: number
    maxSelected: number
}

export type ActionDTO = {
    identifier: ActionIdentifier
    type: ActionType
    formType?: FormType
    selectionConfig: ActionSelectionConfigurationDTO
    modalConfig?: ModalConfigDTO
    affectsTrackingResult?: boolean,
    supportedAdditionalFilters?: string[],
}

export type ModalConfigDTO = {
    modalWidth?: number
    modalMinHeight?: number
    additionalFooterElements: LayoutElementDTO[]
}
