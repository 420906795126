import * as React from 'react'
import { Pagination as AntdPagination } from 'antd'
import { PageableDTO } from 'domain/types'

type Props = PageableDTO & {
    onPageChange: any
    pageSizeOptions?: string[]
    totalEntities: number
}

export class Pagination extends React.Component<Props> {

    totals = (total, range) => `${range[0]}-${range[1]} of ${total} items`

    /**
     * Checks whether the props object has been changed
     *
     * @param nextProps
     */
    propsChanged = (nextProps: Props): boolean => {
        return this.props.page != nextProps.page
            || this.props.pageSize != nextProps.pageSize
            || this.props.sortAscending != nextProps.sortAscending
            || this.props.sortProperties != nextProps.sortProperties
            || this.props.totalEntities != nextProps.totalEntities
            || this.props.onPageChange != nextProps.onPageChange
    }

    /**
     * Checks whether the component must be rerendered
     *
     * @param nextProps
     * @param nextState
     */
    shouldComponentUpdate(nextProps, nextState) {
        return this.propsChanged(nextProps)
    }

    render() {
        const {
            page,
            pageSize,
            totalEntities,
            onPageChange,
            pageSizeOptions = ['10', '25', '50', '100', '250'],
        } = this.props

        return (
            <div className={'pagination'}>
                <AntdPagination current={page + 1}
                                total={totalEntities}
                                pageSize={pageSize}
                                pageSizeOptions={pageSizeOptions}
                                showSizeChanger={true}
                                onChange={onPageChange}
                                showTotal={this.totals}
                                size={'small'}
                                showQuickJumper={true}
                />
            </div>
        )
    }
}
