import { SeriesOption } from 'echarts'

/**
 * Generates a Tooltip formatter for 2 dimensional widgets such as a stacked bar widget
 *
 * @param allSeries
 * @param dataIndex each series can contain multiple data points; we decide which point to look at via the dataIndex
 */
const getSumForDataIndex = (allSeries: SeriesOption[], dataIndex: number): number => {
    return allSeries.reduce((accumulator, currentValue) => accumulator + currentValue.data[dataIndex], 0)
}

/**
 * Out of the entire series data, get the values at a given index. If we think of allSeries as a 2-dimensional matrix and a single series is a column, then
 * the resulting dataset would be a row within that matrix.
 *
 * @param allSeries
 * @param dataIndex
 */
const getValuesForDataIndex = (allSeries: SeriesOption[], dataIndex: number): any[] => {
    return allSeries.map((value: SeriesOption) => value.data[dataIndex])
}


const getMinValue = (series: SeriesOption): number => {
    const values = series.data as number[]
    return Math.min(...values) as number
}

const getMaxValue = (series: SeriesOption): number => {
    const values = series.data as number[]
    return Math.max(...values) as number
}

const SeriesUtil = {
    getSumForDataIndex: getSumForDataIndex,
    getValuesForDataIndex: getValuesForDataIndex,
    getMinValue: getMinValue,
    getMaxValue: getMaxValue,
}

export default SeriesUtil
