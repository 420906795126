import React from 'react'
import { Row } from 'antd'
import { capitalizeEnum } from 'shared/util/util'
import { CAMPAIGN_DIMENSION_IDENTIFIER } from 'domain/adspend-optimizer/context/AdSpendOptimizerContext'

type Props = {
    optimizationLevel: string
}

export const MediaPlanHeader = (props: Props) => (
    <Row className={'media-plan-header'}>
        {props.optimizationLevel === CAMPAIGN_DIMENSION_IDENTIFIER &&
        <div style={{ width: 190, marginLeft: 2 }} className={'media-plan-column'}>Campaign Name</div>
        }
        {props.optimizationLevel !== CAMPAIGN_DIMENSION_IDENTIFIER && <React.Fragment>
            <div style={{ width: 45, marginLeft: 2 }} className={'media-plan-column'}>Active</div>
            <div style={{ width: 145, marginLeft: 2 }} className={'media-plan-column'}>
                {capitalizeEnum(props.optimizationLevel).replace('_', ' ').replace('-', ' ')}
            </div>
        </React.Fragment>
        }
        <div style={{ width: 140, marginLeft: 2 }} className={'media-plan-column'}>Budget</div>
        <div style={{ width: 230, marginLeft: 2 }} className={'media-plan-column'}>Period</div>
        <div style={{ width: 130, marginLeft: 2 }} className={'media-plan-column'}>Flighting Pattern
        </div>
    </Row>
)
