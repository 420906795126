import { history } from 'shared/util/history'
import { authenticationService } from '../service'
import { UserDTO } from 'domain/types'
import { Dispatch } from 'redux'
import { actions as userActions } from 'domain/user/redux/user.reducer'
import { actions as coreActions } from 'domain/core/redux/appcontext.reducer'
import EmbeddingUtil from 'shared/util/EmbeddingUtil'
import { log } from 'shared/util/log'
import UserService from 'domain/user/service/user.service'

declare let baseUrlFrontend: string

export const actionTypes = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_WITH_FIRST_FACTOR_SUCCESS: 'LOGIN_WITH_FIRST_FACTOR_SUCCESS',
    LOGIN_WITH_SECOND_FACTOR_SUCCESS: 'LOGIN_WITH_SECOND_FACTOR_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
}

export type AuthenticationState = {
    loggingIn?: boolean,
    // logon request was successful (user got the jwt cookie)
    loggedInViaFirstFactor?: boolean,
    // logon request was successful (user got the jwt cookie) and mfa was provided
    loggedInViaSecondFactor?: boolean,
    user?: UserDTO,
    loginError?: string,
}
export type AuthenticationAction = { type: string, user?: any, error?: string }

const login = (referrer: string = '/') => (dispatch: Dispatch) => {
    dispatch(loginRequest())

    authenticationService.login(referrer)
        .then(({ loggedInViaSecondFactor, user, redirectUri }) => {
            /* redirectUri will either
            * - trigger the oauth process in case no valid authorization cookie exists (https://login-dev.exactag.com/exactagoauth/authorise)
            *   This is a real redirect to classicUI.
            * - point to /mfa/ot/verify-code in case the authorization cookie has mfaProvided=false
            *   this must not trigger a page reload as that would cause the authorization cookie to be evaluated with every refresh,
            *   which would result in an infinite loop. Therefore, we need to use history.push here.
            */
            if (redirectUri && redirectUri.indexOf('/ui/mfa/otp/verify-code') < 0) {
                window.location.href = redirectUri
                return
            } else if (redirectUri && referrer.indexOf('/ui/mfa/otp/verify-code') < 0) {
                history.push('/ui/mfa/otp/verify-code')
                return
            }

            dispatch(success(user, loggedInViaSecondFactor))

            const isEmbedded = EmbeddingUtil.isEmbedded(referrer)
            const isSimulateEmbedded = EmbeddingUtil.isSimulateEmbedded(referrer)

            // when loading widget data, no need to load menu and all user settings
            if (referrer.startsWith('/ui/widget') || referrer.startsWith('/ui/embeddingtest')) {
                if (isEmbedded && !isSimulateEmbedded) {
                    EmbeddingUtil.initializeEasyXdm(baseUrlFrontend)
                }
                history.push(referrer)
            } else if (referrer.startsWith('/ui/mfa/otp/')) {
                history.push(referrer)
            } else {
                const isSimulateEmbedded = EmbeddingUtil.isSimulateEmbedded(referrer)

                // promises, for that we will wait to execute some actions
                // even though we do not display the manu in the embedded mode we still need it in both cases in order for navigation to work
                const promises: Promise<any>[] = [userActions.loadMenu()(dispatch)]

                // if NewUI is embedded then it is not necessary to load the advertiser tree because it will not be displayed and the selected
                // advertiser tree node will be sent from the ClassicUI instead
                if (isEmbedded || isSimulateEmbedded) {
                    Promise.all(promises).then(() => {
                        if (isEmbedded && !isSimulateEmbedded) {
                            EmbeddingUtil.initializeEasyXdm(baseUrlFrontend)
                        }

                        // we are now on /api/user/auth/logon
                        // and we redirect to the requested page (e.g. /ui/setup/campaignmanagement/lineitems)
                        history.push(referrer)
                    })
                } else {
                    const loadTreePromise = UserService.loadTree()

                    // loading /api/user/settings/loaddata => `selectedNode: [{advertiser_id: 3007}`
                    userActions.loadSettings()(dispatch).then(context => {
                        coreActions.storeAppContext({ ...context })(dispatch)
                        promises.push(userActions.loadTreeCallback(loadTreePromise)(dispatch))

                        Promise.all(promises).then(() => {
                            history.push(referrer)
                        })
                    })
                }
            }
        })
        .catch((error: any) => dispatch(failure(error)))
}

const loginRequest = (): AuthenticationAction => {
    return { type: actionTypes.LOGIN_REQUEST }
}

const success = (user: UserDTO, loggedInViaSecondFactor: boolean): AuthenticationAction => {
    return { type: loggedInViaSecondFactor ? actionTypes.LOGIN_WITH_SECOND_FACTOR_SUCCESS : actionTypes.LOGIN_WITH_FIRST_FACTOR_SUCCESS, user: user }
}

const failure = (error: any): AuthenticationAction => {
    if (typeof error.message === 'string') {
        log.error(error.message)
    }
    if (typeof error.stack === 'string') {
        log.error(error.stack)
    }

    if (typeof error === 'string') {
        return { type: actionTypes.LOGIN_FAILURE, error }
    } else if (typeof error.message === 'string') {
        return { type: actionTypes.LOGIN_FAILURE, error: error.message }
    } else {
        return { type: actionTypes.LOGIN_FAILURE, error: error.toString() }
    }
}

export const actions = {
    loginRequest,
    login,
    failure,
}

const initialState: AuthenticationState = { loggingIn: false, loggedInViaFirstFactor: false, loggedInViaSecondFactor: false }

export const reducer = (state: AuthenticationState = initialState, action: AuthenticationAction): AuthenticationState => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                loggingIn: true,
                loggedInViaFirstFactor: false,
                loggedInViaSecondFactor: false,
            }
        case actionTypes.LOGIN_WITH_FIRST_FACTOR_SUCCESS:
            return {
                loggingIn: false,
                loggedInViaFirstFactor: true,
                loggedInViaSecondFactor: false,
                user: action.user,
            }
        case actionTypes.LOGIN_WITH_SECOND_FACTOR_SUCCESS:
            return {
                loggingIn: false,
                loggedInViaFirstFactor: true,
                loggedInViaSecondFactor: true,
                user: action.user,
            }
        case actionTypes.LOGIN_FAILURE:
            return {
                loggingIn: false,
                loggedInViaFirstFactor: false,
                loggedInViaSecondFactor: false,
                user: undefined,
                loginError: action.error,
            }
        default:
            return state
    }
}
