import React, { useContext, useEffect, useState } from 'react'
import { ArticleService } from 'domain/content/ArticleService'
import { ArticleLayoutContext } from 'domain/content/ArticleLayoutContext'
import { BackTop, Spin } from 'antd'
import { log } from 'shared/util/log'

type Props = {
    path: string,
    title: string
}

declare const baseUrlBackend

export const ArticlePage = (props: Props) => {

    const articleLayoutContext = useContext(ArticleLayoutContext)
    const [htmlContent, setHtmlContent] = useState('')
    const [spinning, setSpinning] = useState(true)

    /**
     * Loads article html content, if the url path changes.
     */
    useEffect(() => {
        setSpinning(true)
        fetchContent()
    }, [props.path])

    /**
     * Adds click event listener to all links on the article page
     * and removes the listener, when the component is unmounted
     */
    useEffect(() => {
        executeActionOnEachArticleLink('addEventListener')

        return () => executeActionOnEachArticleLink('removeEventListener')
    });

    /**
     * Adds or removes click listener on all links on the article page
     *
     * @param action
     */
    const executeActionOnEachArticleLink = (action: 'addEventListener' | 'removeEventListener') => {
        const elements = document.querySelectorAll('.article-content a')

        if (elements && elements.length > 0) {
            elements.forEach(element => {
                element[action]('click', clickOnInternalLink)
            })
        }
    }

    /**
     * Callback for the click event. It reads the filename of the link and
     * opens the corresponded menu item with the required filename.
     *
     * @param event
     */
    const clickOnInternalLink = (event) => {
        const href = event.target.href
        const tokens = href.split('/')
        const filename = tokens[tokens.length - 1]

        // try to find the filename in the menu configuration
        const articleFound = articleLayoutContext.openMenuWithFileName(filename)

        if (articleFound) {
            event.preventDefault()
            return false
        } else {
            // if article was not found in the menu configuration
            log.debug(`Article '${filename}' was not found`)

            return true
        }

    }

    /**
     * Loads the html content of the article from the backend service
     * and saves it in the [htmlContent] state
     */
    const fetchContent = () => {
        ArticleService.loadArticleContent(props.path)
            .then((content: string) => {
                setHtmlContent(appendBaseUrlBackend(content))
                setSpinning(false)
            })
    }

    /**
     * Changes urls in the img tags of the html htmlContent to absolute paths of the backend service
     *
     * @param htmlContent
     */
    const appendBaseUrlBackend = (htmlContent: string): string => {
        return htmlContent
            .split('<img src="').join(`<img src="${baseUrlBackend}/static${props.path}/`)
            // append _blank target, so that external links open in the new window
            .split('<a href="http').join('<a target="_blank" href="http')
            .split('<a href=\'http').join('<a target="_blank" href=\'http')
            // append _self target, so that anchor links open in the same window
            .split('<a href="#').join('<a target="_self" href="#')
            .split('<a href=\'#').join('<a target="_self" href=\'#')
    }

    return <Spin spinning={spinning}>
        {spinning
            ? <div className={'article-content'}/>
            : <div className={'article-content'}>
                <BackTop visibilityHeight={100}/>

                <h1>{props.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
            </div>}
    </Spin>
}
