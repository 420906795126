const ArrayUtil = {
    /**
     * Gets a sublist of the array after [fromIndex]
     *
     * @param array
     * @param fromIndex
     */
    tail: (array: any[], fromIndex: number): any[] => {
        return array.slice(fromIndex, array.length)
    },

    /**
     * Gets first N elements from the array
     *
     * @param array
     * @param maxElement
     */
    getFirstNElements: <T>(array: T[], maxElement: number) => {
        return array.slice(0, Math.min(maxElement, array.length))
    }
}

export default ArrayUtil
