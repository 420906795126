import { get, put } from 'shared/service'
import { MenuRootDTO } from 'domain/types'
import { UserTreeDTO } from 'domain/types/backend/tree.types'

declare const baseUrlUserService: string

const USER_SERVICE_URL: string = `${baseUrlUserService}/userinfo`
const USER_SETTINGS_URL: string = `${baseUrlUserService}/settings`
const USER_MENU_URL: string = `${baseUrlUserService}/menu`

const loadMenu = (): Promise<MenuRootDTO> => get(`${USER_MENU_URL}/getSupportMenu`)

const loadTree = (): Promise<UserTreeDTO> => get(`${USER_SERVICE_URL}/gettree`)

const loadUserSettings = () => get(`${USER_SETTINGS_URL}/loaddata`)

const updateUserSettings = (settings: any) => put(`${USER_SETTINGS_URL}/updatedata`, settings)

const UserService = {
    loadMenu,
    loadTree,
    loadUserSettings,
    updateUserSettings,
}

export default UserService
