import React from 'react'
import { LayoutElementDTO, RowLayoutConfigDTO } from 'domain/types'
import LayoutElement from 'shared/component/layout/LayoutElement'

type Props = {
    layoutConfig: RowLayoutConfigDTO
    children: LayoutElementDTO[]
}

const ChildrenRenderer: React.FC<Props> = ({ layoutConfig, children }: Props): JSX.Element => {
    return <>
        {children.map((child: LayoutElementDTO, index) => <LayoutElement layoutElementConfig={child} key={index}/>)}
    </>
}

export default ChildrenRenderer
