import { AppContextDTO } from 'domain/types'
import store from 'shared/redux/store'
import { actions } from 'domain/core/redux/appcontext.reducer'
import { log } from 'shared/util/log'
import { history } from 'shared/util/history'
import { WidgetReducer } from 'domain/widget/widget.reducer'
import { WidgetSettingsDTO } from 'domain/types/backend/widget.types'

export const MESSAGE = {
    CHANGE_WIDGET_SETTINGS: 'change-widget-settings',
    WIDGET_SETTINGS_CHANGED: 'widget-settings-changed',
    CHANGE_APP_FILTER: 'change-app-filter',
    CHANGE_PATH: 'change-app-path',
    OPEN_IFRAME_WINDOW: 'OPEN_IFRAME_WINDOW',
}

/**
 * Central point for handling incoming easyXDM messages.
 *
 * The old core-ui uses easyXDM to signal app context (previously known as "app filter")
 * changes to our React components.
 */
class MessageListener {

    /**
     * Listens for various message types and dispatches accordingly.
     *
     * @param message
     */
    onMessage = (message: { type: string, body: any }) => {
        if (message.type === MESSAGE.CHANGE_APP_FILTER) {
            const appContext: AppContextDTO = message.body
            log.debug('Dispatching an AppContext change:', appContext)
            actions.storeAppContext(appContext)(store.dispatch)
        }
        if (message.type === MESSAGE.CHANGE_PATH) {
            log.debug('Change app path:' + message.body)
            history.push(message.body)
        }
        if (message.type === MESSAGE.CHANGE_WIDGET_SETTINGS) {
            const widgetSettings: WidgetSettingsDTO = message.body

            log.debug('Dispatching a WidgetSettings change:', widgetSettings)
            WidgetReducer.storeWidgetSettings(widgetSettings)(store.dispatch)
        }
    }
}

const messageListener = new MessageListener()

// We save messageListener.onMessage in the window object to make it globally accessible.
// We need this to switch pages in the cypress tests.
// @ts-ignore
window.messageListenerOnMessage = messageListener.onMessage

export default messageListener
