import messageListener from 'domain/messaging/MessageListener'
import { log } from 'shared/util/log'
import { isJsonObject } from 'shared/util/util'

const browserWindow: any = window || {}
const easyXDM = browserWindow.easyXDM || {}

const EmbeddingUtil = {
    /**
     * Returns true when referrer contains embedded=true
     *
     * @param referrer
     */
    isEmbedded: (referrer: string) => {
        return referrer && referrer.indexOf('embedded=true') >= 0
    },

    /**
     * Returns true when referrer contains isSimulateEmbedded=true
     *
     * @param referrer
     */
    isSimulateEmbedded: (referrer: string) => {
        return referrer && referrer.indexOf('simulateEmbedded=true') >= 0
    },

    initializeEasyXdm: (baseUrlFrontend: string) => {
        const config = {
            isHost: false,
            local: `${baseUrlFrontend}/asset/js/easyXDM/name.html`,
            onMessage: function(message, origin) {
                log.debug('Received the following message: ', message, origin)

                messageListener.onMessage(isJsonObject(message) ? JSON.parse(message) : message)
            },
        }

        const transport = new easyXDM.Socket(config)

        // save [transport] in the browser window, so that we can later send messages to the host container
        browserWindow.transport = transport

        // inform the embedder that the user has been logged in
        // TODO: use "sendMessage" methode with type specification
        transport.postMessage('logInSuccess')
    },

    /**
     * Sends [message] of [type] to the host component
     *
     * @param type
     * @param message
     */
    sendMessage: (type: string, message: any) => {
        // @ts-ignore
        if (!window.transport) {
            return
        }
        // @ts-ignore
        window.transport.postMessage(
            JSON.stringify({
                type: type,
                body: message,
            }),
        )
    },
}
export default EmbeddingUtil
