import ReactEcharts from 'echarts-for-react'
import React from 'react'
import { EChartsOption } from 'echarts-for-react/src/types'
import { ColorTheme } from 'domain/types/backend/widget.types'

type Props = {
    echartsOption: EChartsOption,
    theme: ColorTheme
}

/**
 * Wrapper class for ReactEcharts with default properties
 *
 * @param props
 * @constructor
 */
const CustomEChart = (props: Props) => {
    return <ReactEcharts
        style={{ height: '100%' }}
        option={props.echartsOption}
        notMerge={true}
        lazyUpdate={true}
        theme={props.theme}
        opts={{ renderer: 'canvas' }}/>
}

export default CustomEChart
