import { useContext } from 'react'
import FormContext, { FormContextProperties } from './FormContext'

export const useFormContext = (): FormContextProperties => {
    const context = useContext(FormContext)

    if (!context) {
        throw new Error('Missing FormContextProvider in its parent.')
    }

    return context
}