import React, { useContext, useEffect, useState } from 'react'
import TooltipGenerator from 'domain/widget/TooltipGenerator'
import WidgetDataUtil from 'domain/widget/WidgetDataUtil'
import DimensionUtil from 'shared/util/DimensionUtil'
import { WidgetContext } from 'domain/widget/WidgetContext'
import TopNBarWidgetDataUtil from 'domain/widget/TopNBarWidgetDataUtil'
import { EChartsOption } from 'echarts-for-react/src/types'
import ArrayUtil from 'shared/util/ArrayUtil'
import { BarSeriesOption, LineSeriesOption, SeriesOption } from 'echarts'
import { BarDirection, ResponsiveWidgetSettings, TopNBarWidgetSettingsDTO } from 'domain/types/backend/widget.types'
import { ComputationType, DimensionDTO, MetricDTO, PageableDTO, ReportingDataSetDTO } from 'domain/types'
import { TopNBarWidgetSettingsPanel } from 'domain/widget/TopNBarWidgetSettingsPanel'
import { decode } from 'html-entities'
import EChartWidget from 'domain/widget/EChartWidget'
import BarWidgetSettingsUtil from 'domain/widget/BarWidgetSettingsUtil'
import WidgetSettingsUtil from 'domain/widget/WidgetSettingsUtil'
import { COLOR_TEXT_GRAY } from 'shared/echart/themes/exactagTheme'
import { Size } from 'domain/types/frontend/dimension.types'
import DimensionService from 'domain/dimension/service/DimensionService'
import { NOT_AVAILABLE } from 'Constants'
import ReportingService from 'shared/service/ReportingService'

const DEFAULT_STACKED_BAR_SERIES_UI_SETTINGS: BarSeriesOption | LineSeriesOption = {
    // type: 'line',
    barMaxWidth: 40,
    emphasis: { focus: 'series' },
}

/**
 * Checks whether the [dimensionIdentifier] is a time dimension
 * @param dimensionIdentifier
 */
const isTimeDimension = (dimensionIdentifier: string): boolean => {
    return ['ts'].indexOf(dimensionIdentifier) >= 0
}

const DEFAULT_STACKED_BAR_WIDGET_UI_SETTINGS: EChartsOption =
    {
        aria: {
            decal: {
                show: false,
            },
        },
        tooltip: {
            confine: true,
            className: 'echarts-tooltip',
            trigger: 'item',
            axisPointer: { type: 'shadow' },
        },
        legend: {},
        grid: {},
        yAxis: {
            type: 'value',
            nameTextStyle: { align: 'left', color: COLOR_TEXT_GRAY },
            axisLabel: { hideOverlap: true },
        },
        xAxis: {
            type: 'category',
            axisLabel: { hideOverlap: true },
            axisLine: {},
        },
    }

/**
 * Stacked bar widget with max [topNElements]. The rest will be combined to "Other".
 * @constructor
 */
export const TopNBarWidget = () => {

    const widgetDataContext = useContext(WidgetContext)
    const [echartsOption, setEchartsOption] = useState<EChartsOption>(undefined)
    const { response, cancelTokenSource } = widgetDataContext

    const widgetSettings = widgetDataContext.widgetSettings as TopNBarWidgetSettingsDTO

    // temporary solution - we expect that the first two columns are dimensions and
    // the third column is a metricIdentifier
    const firstDimensionColumnName = widgetSettings.querySettings.columnNames[0]
    const secondDimensionColumnName = widgetSettings.querySettings.columnNames[1]
    const metricColumnName = widgetSettings.querySettings.columnNames[2]
    const firstDimensionIdentifier = DimensionService.recognizeDimensionField(firstDimensionColumnName).identifier
    const secondDimensionIdentifier = DimensionService.recognizeDimensionField(secondDimensionColumnName).identifier
    const metricIdentifier = DimensionService.recognizeDimensionField(metricColumnName).identifier

    const firstDimensionDTO = WidgetDataUtil.getDataColumnByIdentifier(firstDimensionIdentifier, widgetSettings.dataColumns) as DimensionDTO
    const secondDimensionDTO = WidgetDataUtil.getDataColumnByIdentifier(secondDimensionIdentifier, widgetSettings.dataColumns) as DimensionDTO
    const metricDTO = WidgetDataUtil.getDataColumnByIdentifier(metricIdentifier, widgetSettings.dataColumns) as MetricDTO

    const firstDimensionDisplayColumnName = DimensionUtil.getNameColumn(firstDimensionDTO)
    const secondDimensionDisplayColumnName = DimensionUtil.getNameColumn(secondDimensionDTO)

    // Example custom colors:
    // {
    //     'SEO':  '#ff8a65',
    //     'Postview': '#4db6ac',
    //     'Conversion': '#f5d76e',
    //     'Click':  '#81c784',
    //     'View': '#2D9CDB',
    //     'Other':  '#a1887f',
    //     'N/A':  '#9CA5B0',
    //     'Other Action Types': '#A1887F',
    // }
    const customColors = widgetSettings.customColors ? widgetSettings.customColors : {}


    useEffect(() => {
        if (response && response.response && response.response.success) {
            if (widgetSettings.showOthers && metricDTO.computationType === ComputationType.CT_SUM) {
                const othersQuerySettings = {
                    ...widgetSettings.querySettings,
                    columnNames: widgetSettings.querySettings.columnNames.filter(columnName => DimensionService.recognizeDimensionField(columnName).identifier == firstDimensionIdentifier).concat([metricColumnName]),
                    // sort by the metric descending to get the top n dimension values
                    paginationSettings: {...widgetSettings.querySettings.paginationSettings, sortProperties: [metricColumnName], sortAscending: false},
                }

                // load the data for the first dimension to calculate "Others"
                ReportingService.loadData(widgetSettings.apiPath || 'reporting/loadData', othersQuerySettings, cancelTokenSource)
                    .then(otherResponse => setEchartsOption(generateEchartsOptions(otherResponse.dataSet)))
                    .catch(() => setEchartsOption(undefined))
            } else {
                setEchartsOption(generateEchartsOptions())
            }
        } else {
            setEchartsOption(undefined)
        }
    }, [response, widgetSettings])

    /**
     * Generates echart options from the response that was loaded in the WidgetContext
     *
     * @param firstDimensionDataSet
     */
    const generateEchartsOptions = (firstDimensionDataSet: ReportingDataSetDTO = null): EChartsOption => {
        // don't limit time dimension
        const limitFirstDimension = !isTimeDimension(firstDimensionIdentifier)
        const allSeries =
            TopNBarWidgetDataUtil.getTopNBarWidgetSeriesOptions(
                response.dataSet,
                firstDimensionDTO,
                secondDimensionDTO,
                metricDTO,
                widgetSettings.topNElements,
                widgetSettings.showOthers,
                limitFirstDimension,
                customColors,
                firstDimensionDataSet,
            )

        const seriesUiSettings =
            BarWidgetSettingsUtil.generateSeriesOptions(DEFAULT_STACKED_BAR_SERIES_UI_SETTINGS, widgetSettings, metricDTO)
        const seriesUiSettingsWithLabel: SeriesOption = { ...seriesUiSettings }

        const allSeriesWithUiSettings = WidgetSettingsUtil.enrichSeriesWithUiSettings(allSeries, seriesUiSettingsWithLabel)
        const firstDimensionUniqueValues = limitFirstDimension
            ? WidgetDataUtil.getColumnValuesOrderedByMetricDesc(firstDimensionDisplayColumnName, response.dataSet.rows, metricIdentifier)
            : WidgetDataUtil.getUniqueValues(firstDimensionDisplayColumnName, response.dataSet.rows)
        const secondDimensionUniqueValues =
            WidgetDataUtil.getColumnValuesOrderedByMetricDesc(secondDimensionDisplayColumnName, response.dataSet.rows, metricIdentifier)

        // let legend = ArrayUtil.getFirstNElements(secondDimensionUniqueValues, widgetSettings.topNElements).reverse()
        let legend = secondDimensionUniqueValues.reverse()
        let xValues = ArrayUtil.getFirstNElements(firstDimensionUniqueValues, limitFirstDimension ? widgetSettings.topNElements : Number.MAX_SAFE_INTEGER)

        // Only show the "Others" entry for sum computation metrics because those are currently the only ones where we can correctly calculate the total value
        if (widgetSettings.showOthers && metricDTO.computationType === ComputationType.CT_SUM) {
            // if there are more elements than topNElements, then add "other" element
            // if (secondDimensionUniqueValues.length > widgetSettings.topNElements) {
            if (widgetSettings.showOthers) {
                const othersSecondDimensionDisplayName = WidgetDataUtil.getOtherTitle(secondDimensionDTO)
                legend = [othersSecondDimensionDisplayName].concat(legend)
            }

            // if there are more elements than topNElements, then add "other" element
            if (firstDimensionUniqueValues.length > widgetSettings.topNElements) {
                const othersFirstDimensionDisplayName = WidgetDataUtil.getOtherTitle(firstDimensionDTO)
                xValues = xValues.concat([othersFirstDimensionDisplayName])
            }
        }

        const tooltipFormatter = TooltipGenerator.getTooltipFormatterFor2Dimensions(allSeries, firstDimensionDTO, secondDimensionDTO, metricDTO, firstDimensionDataSet)

        const echartsOptionsWithDecalPattern: EChartsOption =
            WidgetSettingsUtil.applyDecalPattern(DEFAULT_STACKED_BAR_WIDGET_UI_SETTINGS, widgetSettings.showDecalPattern)

        const echartsOptionsWithLabels = BarWidgetSettingsUtil.processMetricLabels(echartsOptionsWithDecalPattern, widgetSettings.stacked, metricDTO)

        //apply custom colors and format item names
        const formattedLegend = legend.map(element => {
            const customColor = customColors[element || NOT_AVAILABLE]
            const name = WidgetDataUtil.formatItemName(element)
            if (customColor) {
                return {
                    name: name,
                    itemStyle: { color: customColor },
                }
            } else {
                return name
            }
        })

        const combinedEchartsOption =
            WidgetDataUtil.combineUiSettingsAndWidgetData(
                echartsOptionsWithLabels,
                tooltipFormatter,
                // echart doesn't decode html entities (e.g. "&gt;"). So we must decode them on our own.
                xValues.map(element => decode(element)),
                allSeriesWithUiSettings,
                formattedLegend,
                firstDimensionDTO,
                metricDTO,
            )

        const containsNegativeValues = WidgetDataUtil.containsNegativeValues(response.dataSet.rows, metricDTO)

        let echartsOption: EChartsOption
        if (containsNegativeValues) {
            // we'll only format the x-axis differently when there are negative values; in that case, it can get hard to visually distinguish
            // the X-axis from other grid lines so we make it more prominent
            echartsOption = BarWidgetSettingsUtil.highlightXAxisLine(combinedEchartsOption)
        } else {
            echartsOption = combinedEchartsOption
        }

        if (widgetSettings.barDirection === BarDirection.HORIZONTAL) {
            WidgetDataUtil.swapXAndYAxis(echartsOption)

            // show metric name as "subtext" to get the proper center alignment
            echartsOption.xAxis.name = null
            echartsOption.title = {
                ...echartsOption.title,
                subtext: metricDTO.displayName,
                left: 'center',
                bottom: 0,
                subtextStyle: {
                    color: COLOR_TEXT_GRAY,
                },
            }


        } else {
            if (!isTimeDimension(firstDimensionIdentifier)) {
                echartsOption.xAxis.axisLabel = {
                    ...(echartsOption.xAxis.axisLabel ? echartsOption.xAxis.axisLabel : {}),
                    rotate: 45,
                }
            }
        }

        return echartsOption
    }


    /**
     * Callback for adjusting TopNBarWidget specific configs regarding to [containerSize]
     *
     * @param responsiveWidgetSettings
     * @param containerSize
     */
    const onContainerResize = (responsiveWidgetSettings: ResponsiveWidgetSettings, containerSize: Size): ResponsiveWidgetSettings => {
        if (widgetSettings.showValues && responsiveWidgetSettings.isLegendHorizontal) {
            // when showing values, we need more space on the right side to avoid them from being cut off
            responsiveWidgetSettings.echartsOption.grid.right = '10%'
        }
        return responsiveWidgetSettings
    }

    if (response && echartsOption) {
        return <EChartWidget
            hasLegend={true}
            echartsOption={echartsOption}
            onContainerResize={onContainerResize}
            isLoading={widgetDataContext.isLoading}
            response={response}
            widgetSettingsPanel={<TopNBarWidgetSettingsPanel/>}/>
    } else {
        return <div className={'initial-loader'}/>
    }
}
