import * as React from 'react'
import { Tag } from 'antd'
import { ColumnResponseDTO } from 'domain/types'

const getOptimizationLevelDisplayValue = (level: ColumnResponseDTO): string => {
    return level.value === 'campaign_id'
        ? 'Campaign'
        : level.value === 'sub_campaign_id'
            ? 'Sub Campaign'
            : 'Channel'
}

export const optimizationLevelRenderer: React.FunctionComponent<any> = (value: ColumnResponseDTO) => {
    const displayedValue = getOptimizationLevelDisplayValue(value)
    return (
        <Tag className={'ant-tag ant-tag-has-color optimization-level optimization-level-' + value.value} color="#2db7f5">
            {displayedValue}
        </Tag>
    )
}
